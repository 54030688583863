import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SlideProps,
  SliderProps,
  Modal,
  Backdrop,
  TextField
} from '@mui/material';
import useSWR from 'swr';
import Api from '../../api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import useAuthStore from '../../store/zustand/auth';
import Loader from '../shared/Loader';
import { ROLES } from '../../helpers/roles';
import { toastNotification } from '../../utils/notificationHelper';
import ToastNotification from '../shared/ToastNotification';

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  maxHeight: 500,
  overflow: 'scroll',
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 600
}));

const BoxCentered = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '100%',
  justifyContent: 'center'
}));

const DrawerButton = styled(Button)<SlideProps>(() => ({
  color: 'white',
  marginBottom: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  width: '100%'
}));

interface Props {
  id: string;
  onClose: () => void;
}

const CustomerWatcherModal: React.FC<Props> = function CustomerWatcherModal({
  id,
  onClose
}) {
  const { t } = useTranslation();
  const { id: profileId } = useAuthStore((state) => state);
  const [usersByRoleApi, setUsersByRoleApi] = useState<boolean>(false);
  const [watchersApi, setWatchersApi] = useState<boolean>(false);
  const [addWatcherApi, setAddWatcherApi] = useState<boolean>(false);
  const [removeWatcherApi, setRemoveWatcherApi] = useState<boolean>(false);
  const [roleUsers, setRoleUsers] = useState<any>();
  const [error, setError] = useState<string>();
  const [valuesAutoComplete, setValuesAutoComplete] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [watchers, setWatchers] = useState<Array<any>>([]);
  const [targetWatcher, setTargetWatcher] = useState<string>();
  const [toastNotification, setToastNotification] =
    useState<toastNotification>();

  const {
    data: dataUsersByRole,
    error: errorUsersByRole,
    mutate: mutateUsersByRole
  } = useSWR(
    usersByRoleApi
      ? {
          roles: [ROLES.BUSINESS_DEVELOPMENT, ROLES.SALES, ROLES.OPERATIONS],
          url: 'get/users/roles'
        }
      : null,
    Api.UserResocesApi.getUsersByRole
  );

  const {
    data: dataWatchers,
    error: errorWatchers,
    mutate: mutateWatchers
  } = useSWR(
    watchersApi
      ? {
          id,
          url: 'get/customer/watchers'
        }
      : null,
    Api.ClientApi.getCustomerWatchers
  );
  const { data: dataAddWatchers, error: errorAddWatchers } = useSWR(
    addWatcherApi
      ? {
          id,
          url: 'get/customer/watchers/add',
          userId: targetWatcher
        }
      : null,
    Api.ClientApi.addCustomerWatcher
  );
  const { data: dataRemoveWatchers, error: errorRemoveWatchers } = useSWR(
    removeWatcherApi
      ? {
          id,
          url: 'get/customer/watchers/remove',
          userId: targetWatcher
        }
      : null,
    Api.ClientApi.removeCustomerWatcher
  );

  // const addMeAsWatcher = () => {
  //   setLoading(true);
  //   setWatchers(
  //     Array.isArray(valuesAutoComplete)
  //       ? [...valuesAutoComplete, profile]
  //       : [profile]
  //   );
  //   setValuesAutoComplete(undefined);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1000);
  // };

  // const checkMeAsWatcher = () => {
  //   let watcher = false;
  //   if (Array.isArray(valuesAutoComplete)) {
  //     if (valuesAutoComplete?.length > 0) {
  //       valuesAutoComplete.forEach((item: any) => {
  //         if (item.id === profile?.id) {
  //           watcher = true;
  //         }
  //       });
  //     }
  //   } else if (valuesAutoComplete?.id === profileId) {
  //     watcher = true;
  //   }
  //   return watcher;
  // };

  // const getAddMeWatcher = () => {
  //   if (true) {
  //     return '';
  //   }
  //   return (
  //     <Box
  //       sx={{
  //         display: 'flex',
  //         justifyContent: 'left',
  //         width: '100%'
  //       }}
  //     >
  //       <Button
  //         disabled={checkMeAsWatcher()}
  //         onClick={() => {
  //           addMeAsWatcher();
  //         }}
  //         variant="text"
  //       >
  //         <i className="custom-icon icon-add" style={{ fontSize: 18 }} />
  //         <Typography>{t('MODAL.addMe')}</Typography>
  //       </Button>
  //     </Box>
  //   );
  // };

  useEffect(() => {
    if (errorWatchers) {
      setWatchersApi(false);
      setToastNotification({
        text: t('NOTIFICATION.TOAST.generalError'),
        type: 'error'
      });
    }
  }, [errorWatchers]);

  useEffect(() => {
    if (dataAddWatchers) {
      setAddWatcherApi(false);
      setToastNotification({
        text: t('NOTIFICATION.TOAST.addedSuccess', ['Watcher']),
        type: 'success'
      });
    }
  }, [dataAddWatchers]);

  useEffect(() => {
    if (dataRemoveWatchers) {
      setRemoveWatcherApi(false);
      setToastNotification({
        text: t('NOTIFICATION.TOAST.deleteSuccess', ['Watcher']),
        type: 'success'
      });
    }
  }, [dataRemoveWatchers]);

  useEffect(() => {
    if (errorAddWatchers) {
      setAddWatcherApi(false);
      setToastNotification({
        text: t('NOTIFICATION.TOAST.addingError', ['Watcher']),
        type: 'error'
      });
    }
  }, [errorAddWatchers]);

  useEffect(() => {
    if (errorRemoveWatchers) {
      setRemoveWatcherApi(false);
      setToastNotification({
        text: t('NOTIFICATION.TOAST.deleteError', ['Watcher']),
        type: 'error'
      });
    }
  }, [errorRemoveWatchers]);

  useEffect(() => {
    if (dataUsersByRole) {
      setUsersByRoleApi(false);
      setRoleUsers(
        dataUsersByRole.map((item: any) => {
          const userName = `${item.firstName} ${item.lastName}`;
          const userEmail = `${item.email}`;
          const label = `${userName} (${userEmail})`;
          const w: any = {
            id: item.id,
            label,
            name: label,
            value: item.id
          };
          return w;
        })
      );
    }
  }, [dataUsersByRole]);

  useEffect(() => {
    if (roleUsers) {
      const valuesToSet: any = [];
      roleUsers.forEach((item: any) => {
        watchers.forEach((watcher: any) => {
          if (item.value === watcher.value) {
            valuesToSet.push(item);
          }
        });
      });
      setValuesAutoComplete(valuesToSet);
    }
  }, [roleUsers]);

  useEffect(() => {
    if (errorUsersByRole) {
      setUsersByRoleApi(false);
      const message =
        errorUsersByRole?.response?.data?.message || t('ERROR.general');
      setError(message);
    }
  }, [errorUsersByRole]);

  useEffect(() => {
    if (dataWatchers) {
      setUsersByRoleApi(true);
      setWatchers(
        dataWatchers?.map((watcher: any) => {
          const { user } = watcher;
          const userName = `${user.firstName} ${user.lastName}`;
          const label = `${userName} (${user.email})`;
          return {
            id: user.id,
            label,
            name: label,
            value: user.id
          };
        })
      );
    }
  }, [dataWatchers]);

  useEffect(() => {
    setWatchersApi(true);
    return () => {
      mutateUsersByRole(undefined, true);
      mutateWatchers(undefined, true);
    };
  }, []);

  return (
    <Container sx={{ margin: '40px', width: 'auto' }}>
      <Modal
        open
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          onClose();
        }}
      >
        <ModalContainer>
          {error && (
            <BoxCentered>
              <Typography color="error.main" variant="heading_400">
                {t('ERROR.errorTitle')}
              </Typography>
              <Typography variant="primary">{error}</Typography>
            </BoxCentered>
          )}
          {!error && (
            <>
              <Typography color="secondary.400" variant="modalTitle">
                <i
                  className="custom-icon icon-eye"
                  style={{ fontSize: 40, marginBottom: 24 }}
                />
                <br />
                {t('MODAL.watchers')}
              </Typography>
              <br />
              {!roleUsers && <Loader customColor="primary" customSize={36} />}
              {roleUsers && (
                <Box width="100%">
                  {loading && <Loader customColor="primary" customSize={24} />}
                  {!loading && (
                    <>
                      {valuesAutoComplete && (
                        <AutoCompleteInput
                          multiple
                          defaultValue={watchers.map((item: any) => {
                            const label = `${item.userName} (${item.userEmail})`;
                            return {
                              label,
                              name: label,
                              value: item.userId
                            };
                          })}
                          onChange={(event: any, newValue: any | null) => {
                            const oldWatchers = valuesAutoComplete?.map(
                              (user: any) => user.id
                            );
                            const newWatchers = newValue?.map(
                              (user: any) => user.id
                            );
                            if (oldWatchers.length > newWatchers.length) {
                              // remove
                              const difference = oldWatchers.filter(
                                (value: string) => !newWatchers.includes(value)
                              );
                              setTargetWatcher(difference?.[0]);
                              setRemoveWatcherApi(true);
                            } else {
                              // add
                              const difference = newWatchers.filter(
                                (value: string) => !oldWatchers.includes(value)
                              );
                              setTargetWatcher(difference?.[0]);
                              setAddWatcherApi(true);
                            }
                            setValuesAutoComplete(newValue);
                          }}
                          options={roleUsers}
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                '.MuiOutlinedInput-notchedOutline': {
                                  borderStyle: 'hidden'
                                },
                                borderStyle: 'hidden',
                                fontSize: '15px'
                              }}
                              // eslint-disable-next-line
                              {...params}
                            />
                          )}
                          value={valuesAutoComplete}
                        />
                      )}
                      {/* {getAddMeWatcher()} */}
                    </>
                  )}
                  <br />
                  <DrawerButton
                    onClick={() => {
                      onClose();
                    }}
                    sx={{ height: 46, width: 114 }}
                    variant="cancel"
                  >
                    <Typography color="error.main">
                      {t('GENERAL.closeButton')}
                    </Typography>
                  </DrawerButton>
                </Box>
              )}
            </>
          )}
        </ModalContainer>
      </Modal>
      {toastNotification && (
        <ToastNotification
          onClose={() => setToastNotification(undefined)}
          text={toastNotification?.text}
          type={toastNotification?.type}
        />
      )}
    </Container>
  );
};

CustomerWatcherModal.defaultProps = {};

export default CustomerWatcherModal;
