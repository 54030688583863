import {
  ClientsApi,
  ClientsApiClientFindAllPaginateRequest,
  ClientsAddressesApi,
  ClientsContactsApi,
  ClientsApiClientUpdateRequest,
  ClientsApiClientCreateRequest,
  ClientsPortalApi,
  CreateClientDTOStatusEnum,
  ClientsCommunicationMethodsApi
} from '../api-client/generated/api';

// TODO: Enviar junto con respuesta un string de donde se quedo.
const createClient = async (params: any, isImport: boolean = false) => {
  if (!params) {
    return null;
  }
  window.localStorage.setItem(
    'client-process',
    JSON.stringify({ process: 'client', response: {} })
  );
  const {
    billingAddress,
    billingEmail,
    businessName,
    email,
    firstName,
    jobTitle,
    lastName,
    notes,
    phone,
    timeZone,
    addresses,
    contacts,
    workOrderEmails
  } = params;

  const dtoParams: ClientsApiClientCreateRequest = {
    createClientDTO: {
      billingAddress,
      billingEmail,
      businessName,
      email,
      firstName,
      jobTitle,
      lastName,
      notes,
      phone,
      timeZone,
      workOrderEmails
    }
  };
  const apiCaller = new ClientsApi();
  const responseData = await apiCaller.clientCreate(dtoParams);
  const { data } = responseData;
  // Creating Client's Addresses
  if (responseData.status === 201) {
    window.localStorage.setItem(
      'client-process',
      JSON.stringify({
        process: 'address',
        response: {
          idClient: data.id
        }
      })
    );
    if (
      isImport &&
      Object.values(addresses[0]).some(
        (item) => item === undefined || item === ''
      )
    ) {
      return data;
    }
    const { id } = data;
    const apiPlaceCaller = new ClientsAddressesApi();
    const responseData = await apiPlaceCaller.addressUpsert({
      clientId: id,
      upsertAddressDTO: { addresses }
    });
    // Creating Client's Contacts
    // eslint-disable-next-line no-console
    console.log('status lanzado: ', responseData.status);
    if (responseData.status === 201 || responseData.status === 200) {
      window.localStorage.setItem(
        'client-process1',
        JSON.stringify({
          process: 'contacts',
          response: addresses
        })
      );
      if (
        isImport &&
        Object.values(contacts[0]).some(
          (item) => item === undefined || item === ''
        )
      ) {
        return data;
      }
      const apiContactCaller = new ClientsContactsApi();
      const responseData = await apiContactCaller.contactUpsert({
        clientId: id,
        upsertContactDTO: { contacts }
      });
      data.contacts = responseData.data;
    }
    data.addresses = responseData.data;
  }
  return data;
};

const updateClient = async (params: any) => {
  if (!params) {
    return null;
  }
  const {
    billingAddress,
    billingEmail,
    businessName,
    email,
    firstName,
    jobTitle,
    lastName,
    notes,
    phone,
    timeZone,
    addresses,
    id,
    contacts,
    workOrderEmails,
    status,
    archived,
    type,
    relationship,
    communicationMethods: methods,
    communicationMethodsDeleted
  } = params;

  const dtoParams: ClientsApiClientUpdateRequest = {
    id,
    updateClientDTO: {
      archived,
      billingAddress,
      billingEmail,
      businessName,
      email,
      firstName,
      jobTitle,
      lastName,
      notes,
      phone,
      relationship,
      status,
      timeZone,
      type,
      workOrderEmails
    }
  };
  const communicationMethods = methods?.map((method: any) => {
    return {
      id: method?.id,
      isPrimary: method?.isPrimary,
      name: method?.name,
      type: method?.type,
      value: method?.value
    };
  });
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientUpdate(dtoParams);
  const { data } = response;
  if (response.status === 200) {
    const { id } = data;
    const apiPlaceCaller = new ClientsAddressesApi();
    const responseData = await apiPlaceCaller.addressUpsert({
      clientId: id,
      upsertAddressDTO: { addresses }
    });
    if (response.status === 200) {
      const apiContactCaller = new ClientsContactsApi();
      const responseData = await apiContactCaller.contactUpsert({
        clientId: id,
        upsertContactDTO: { contacts }
      });
      const apiCommunicationMethodsCaller =
        new ClientsCommunicationMethodsApi();
      if (response.status === 200 && communicationMethods?.length > 0) {
        const responseData =
          await apiCommunicationMethodsCaller.communicationMethodUpsert({
            clientId: id,
            upsertCommunicationMethodDTO: { communicationMethods }
          });
      }
      if (response.status === 200 && communicationMethodsDeleted?.length > 0) {
        const responseData =
          await apiCommunicationMethodsCaller.communicationMethodSoftRemove({
            clientId: id,
            deleteCommunicationMethodBodyDTO: {
              ids: communicationMethodsDeleted
            }
          });
      }
      return responseData;
    }
    return responseData;
  }
  return data;
};

const getClient = async (params: string) => {
  if (!params) {
    return null;
  }
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientFindOne({ id: params });
  const { data } = response;
  return data;
};

const getClients = async (params: any) => {
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientFindAll(params);
  const { data } = response;
  return data;
};

const getClientsPaginated = async (
  params: ClientsApiClientFindAllPaginateRequest
) => {
  if (!params) {
    return null;
  }
  const { type: t, status: s, archived, items, page }: any = params;
  const type: Array<string> = Array.isArray(t) ? t : [t];
  const status: Array<string> = Array.isArray(s) ? t : [s];
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientFindAllPaginate({
    archived,
    items,
    page,
    status,
    type
  });
  const { data } = response;
  return data;
};

const deleteClient = async (id: string) => {
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientRemove({ id });
  const { status, statusText } = response;
  return { status, statusText };
};

const getAddresses = async ({ id }: any) => {
  const apiCaller = new ClientsAddressesApi();
  const response = await apiCaller.addressFindAll({ clientId: id });
  const { data } = response;
  return data;
};

const deleteContact = async (id: any) => {
  const apiCaller = new ClientsContactsApi();
  const { data } = await apiCaller.contactSoftRemove(id);
  return data;
};

const deleteAddress = async (id: any) => {
  const apiCaller = new ClientsAddressesApi();
  const { data } = await apiCaller.addressRemove(id);
  return data;
};

const getContacts = async ({ id }: any) => {
  const apiCaller = new ClientsContactsApi();
  const response = await apiCaller.contactFindAll({ clientId: id });
  const { data } = response;
  return data;
};

const getProposals = async (params: any) => {
  const apiCaller = new ClientsPortalApi();
  const response = await apiCaller.clientPortalGetProposalsofClient();
  return response.data;
};

const getInvoices = async (params: any) => {
  const apiCaller = new ClientsPortalApi();
  const response: any = await apiCaller.clientPortalGetinvoicesofClient();
  return response.data;
};

const getProjectInvoices = async (params: any) => {
  const { id } = params;
  const apiCaller = new ClientsPortalApi();
  const response: any =
    await apiCaller.clientPortalGetinvoicesofClientPerProject({ id });
  return response.data;
};

const getProjects = async (params: any) => {
  const apiCaller = new ClientsPortalApi();
  const response: any = await apiCaller.clientPortalGetProjectsOfClient();
  return response.data;
};

const getInvoicePaymentLink = async (params: any) => {
  const { id } = params;
  const apiCaller = new ClientsPortalApi();
  const response: any = await apiCaller
    .clientPortalGetPaymentLinkFromInvoice({
      id
    })
    .then((res: any) => {
      if (res?.data?.InvoiceLink) {
        return res;
      }
      return { ...res, data: {} };
    })
    .catch((err: any) => err.response);
  return response.data;
};

const resendOnboardingInvitation = async (params: any) => {
  const { id } = params;
  const apiCaller = new ClientsApi();
  const response: any = apiCaller.clientResendOnboarding({ id });
  return response.data;
};

const createSimpleClient = async (params: any) => {
  // Create a client with email only to send onboarding invitation
  const { email } = params;
  const apiCaller = new ClientsApi();
  const response: any = apiCaller.clientCreate({ createClientDTO: { email } });
  return response;
};

const getCustomerWatchers = async (params: any) => {
  const { id } = params;
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientFindWatcherPerClient({ id });
  const { data } = response;
  return data;
};

const addCustomerWatcher = async (params: any) => {
  const { id: clientId, userId } = params;
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientAddWatcher({ clientId, userId });
  const { data } = response;
  return data;
};

const removeCustomerWatcher = async (params: any) => {
  const { id: clientId, userId } = params;
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientDeleteWatcher({ clientId, userId });
  const { data } = response;
  return { data, status: response.status };
};

const getCustomerByType = async (params: any) => {
  const { clientId, userId } = params;
  const apiCaller = new ClientsApi();
  const response = await apiCaller.clientDeleteWatcher({ clientId, userId });
  const { data } = response;
  return data;
};

export default {
  addCustomerWatcher,
  createClient,
  createSimpleClient,
  deleteAddress,
  deleteClient,
  deleteContact,
  getAddresses,
  getClient,
  getClients,
  getClientsPaginated,
  getContacts,
  getCustomerWatchers,
  getInvoicePaymentLink,
  getInvoices,
  getProjectInvoices,
  getProjects,
  getProposals,
  removeCustomerWatcher,
  resendOnboardingInvitation,
  updateClient
};
