/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */

import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Container,
  Modal,
  Typography,
  SliderProps,
  TextField,
  styled,
  TableHead,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Tooltip,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import useSWR from 'swr';
import SendIcon from '@mui/icons-material/Send';
import { width } from '@mui/system';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import {
  accentColors,
  mainColors,
  secondaryColors,
  successColors,
  surfaceColors,
  textColors,
  themePalette,
  warningColors
} from '../../../theme/themeConfig';
import ImageViewer from '../../../components/ImageViewer';
import Api from '../../../api';
import {
  DrawerButton,
  ModalContainer
} from '../../../components/modals/GlobalConfirmModal';
import {
  TaskIterationStatusStatusEnum,
  TimeFormatEnum
} from '../../../api-client/generated/api';
import { convertTimeFormat, formatDate } from '../../../utils/calendarHelper';
import {
  capitalizeString,
  downloadAll,
  getFormatedTimeByTimeZone,
  getTimeFromISO8601,
  resourseColors,
  taskInteractionStyles,
  TaskStatus,
  TaskStyle
} from '../../../utils/general';
import TimeComponent from '../../../components/input/TimeComponent';
import ImageCarousel from './components/ImageCarousel';
import { getWorkedTimeAverageTime } from '../../../utils/calculatorHelper';
import useAuthStore from '../../../store/zustand/auth';

const TaskImageListContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1.5),
  gridTemplateColumns: `repeat(auto-fill, minmax(${theme.spacing(7)}, 2fr))`,
  padding: theme.spacing(0.5)
}));

const ListHead = styled(TableHead)(({ theme }) => ({
  '.MuiTableHead-root': { boxShadow: 0 },
  display: 'flex',
  width: '100%'
}));

const ListTitle = styled(Typography)(({ theme }) => ({
  color: textColors.DARK_TEXT,
  fontFamily: 'Lexend',
  fontSize: '28px',
  fontWeight: '600',
  marginLeft: '1rem'
}));

const TaskImageContainer = styled(Box)(({ theme }) => ({
  '&:hover': {
    transform: 'scale(1.1)'
  },
  alignContent: 'center',
  alignItems: 'center',
  border: `1px solid ${theme.palette.secondary[100]}`,
  cursor: 'pointer',
  display: 'flex',
  height: theme.spacing(7),
  justifyContent: 'center',
  marginRight: '5px',
  overflow: 'hidden',
  transition: '1s ease',
  width: theme.spacing(7)
}));

interface IServiceTableBlockCheck {
  isCheked: boolean;
  text: string;
}

export interface NoteResponseDTO {
  createdAt: string;
  id: string;
  noteText: string;
  updatedAt: string;
  user: object;
}

interface IServiceTableBlock {
  checklist?: Array<IServiceTableBlockCheck> | [];
  images?: any;
  materials?: any;
  note?: any;
  taskIterationID?: any;
  title: string;
  type: string;
}
type VariantType = 'secondary-bold' | 'secondary';

interface IServiceTableRowTD {
  children?: any;
  colorH: string;
  fontSize: string;
  text?: string;
  typeTx?: VariantType;
  // width: number;
}

interface Props {
  calendarEvenState: string | undefined;
  dashboard: any;
  data: any;
  onlyTask: boolean;
  serviceInfo: any;
  setShowModal: (v: boolean) => void;
}

const ProjectTaskDetailsModal: React.FC<Props> =
  function ProjectTaskDetailsModal({
    calendarEvenState,
    dashboard,
    data,
    onlyTask,
    setShowModal,
    serviceInfo
  }) {
    const { t } = useTranslation();
    const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
    const userState = useAuthStore((state) => state);
    const getTaskStyle = (task: TaskStatus): TaskStyle => {
      return taskInteractionStyles[task] || taskInteractionStyles.awaiting;
    };

    function ServiceTableBlockCheck({
      isCheked,
      text
    }: IServiceTableBlockCheck) {
      return (
        <Box
          style={{
            alignContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start'
          }}
        >
          <Checkbox
            disabled
            checked={isCheked}
            onChange={() => {
              console.log('click here');
            }}
            size="small"
          />
          <Typography
            align="left"
            style={{ color: '#4B4D56', fontSize: '12px' }}
          >
            {text}
          </Typography>
        </Box>
      );
    }

    function ServiceTableBlock({
      title,
      type,
      materials,
      taskIterationID,
      checklist,
      note,
      images
    }: IServiceTableBlock) {
      const [modalOpen, setModalOpen] = useState(false); // Estado para controlar si el modal está abierto
      const [selectedNote, setSelectedNote] = useState<NoteResponseDTO[]>([]);
      const [fireCreateNote, setFireCreateNote] = useState<boolean>(false);
      const [noteText, setNoteText] = useState<string>('');
      const [currentTaskDetails, setCurrentTaskDetails] = useState<any>({});
      const [imgData, setImgData] = useState('');
      const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

      const { data: createTaskNoteData, error: createTaskNoteDataError } =
        useSWR(
          fireCreateNote && noteText !== '' && taskIterationID
            ? {
                entity: 'taskIteration',
                entityId: `${taskIterationID}`,
                noteText: `${noteText}`,
                taskIterationId: Number(taskIterationID) ?? 0
              }
            : null,
          Api.CalendarApi.noteCreate
        );

      // Función para manejar el clic en el icono de agregar
      const handleAddIconClick = (note: any) => {
        setSelectedNote(note); // Establece la nota seleccionada
        setModalOpen(true); // Abre el modal
      };

      useEffect(() => {
        if (createTaskNoteData && !createTaskNoteDataError) {
          const auxNotes = [...selectedNote, createTaskNoteData];
          setSelectedNote(auxNotes);
          setNoteText('');
          setFireCreateNote(false);
        }
      }, [createTaskNoteData, createTaskNoteDataError, selectedNote, noteText]);

      return (
        <Box>
          {type === 'materials' && (
            <Box style={{ padding: '12px' }}>
              {materials &&
                materials?.map((index: any, key: number) => (
                  <Typography
                    component="li"
                    key={key}
                    style={{
                      color: `${secondaryColors.SECONDARY_5}`,
                      fontSize: '12px'
                    }}
                    variant="secondary"
                  >
                    {index?.name}
                  </Typography>
                ))}
            </Box>
          )}

          {type === 'note' && (
            <>
              <Box>
                <Box
                  style={{
                    display: 'flex',
                    paddingTop: '20px'
                  }}
                >
                  {calendarEvenState !== 'Created' && (
                    <DrawerButton
                      onClick={() => handleAddIconClick(note)}
                      sx={{
                        height: '28px !important'
                      }}
                      variant="primary"
                    >
                      <Typography fontSize={11}>Open Notes</Typography>
                    </DrawerButton>
                  )}
                </Box>
              </Box>
              <Box>
                {modalOpen && (
                  <Modal
                    onClose={() => {
                      setModalOpen(false);
                    }}
                    open={modalOpen}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        '.textAreaInput-taskDetails': {
                          // backgroundColor: `${textColors.TEXT} !important`,
                          // backgroundColor: 'red',
                          fontSize: '16px !important',
                          padding: '0px !important'
                        }
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: surfaceColors.SURFACE_6,

                          borderRadius: 2,
                          height: 600,
                          p: 4,
                          width: 800
                        }}
                      >
                        {
                          <Box>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'grid',
                                padding: '0.5rem 2rem 1.5rem 2rem;'
                              }}
                            >
                              <ListHead>
                                <Box>
                                  <ListTitle variant="primary">Notes</ListTitle>
                                </Box>
                              </ListHead>
                              <Box
                                sx={{
                                  border: 'solid 1px rgba(203, 204, 211, 0.7)',
                                  borderRadius: '3px',
                                  marginTop: '22px',
                                  maxHeight:
                                    selectedNote && selectedNote.length > 3
                                      ? '350px'
                                      : 'auto',
                                  overflowY:
                                    selectedNote && selectedNote.length > 3
                                      ? 'auto'
                                      : 'visible',
                                  padding: '13px'
                                }}
                              >
                                <List dense sx={{ borderRadius: '8px' }}>
                                  {selectedNote &&
                                    selectedNote?.length > 0 &&
                                    selectedNote
                                      .sort(
                                        (a, b) =>
                                          new Date(b.updatedAt).getTime() -
                                          new Date(a.updatedAt).getTime()
                                      )

                                      .map((noteData: any, index: number) => {
                                        const initials =
                                          `${noteData?.user?.firstName} ${noteData?.user?.lastName}`
                                            .toUpperCase()
                                            .split(' ')
                                            .slice(0, 2)
                                            .map((word: string) =>
                                              word[0]?.toUpperCase()
                                            )
                                            .join('');
                                        const auxKey = `avatar-technician-${index}`;
                                        return (
                                          <ListItem
                                            key={`task-details-note-listItem-${noteData.id}`}
                                            sx={{
                                              borderColor: `${secondaryColors.SECONDARY_1} !important`,
                                              borderRadius: '4px',
                                              width: '100% !important'
                                            }}
                                          >
                                            <ListItemButton
                                              sx={{
                                                alignItems: 'flex-start',
                                                backgroundColor: 'white',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '100%'
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  display: 'flex'
                                                }}
                                              >
                                                <ListItemAvatar
                                                  style={{
                                                    paddingTop: '5px'
                                                  }}
                                                >
                                                  <Avatar
                                                    alt={`${noteData?.user?.firstName} ${noteData?.user?.lastName}`}
                                                    key={auxKey}
                                                    src={`${
                                                      noteData?.user?.fileBank
                                                        .length > 0
                                                        ? noteData?.user
                                                            ?.fileBank[0]
                                                            ?.fileUrl
                                                        : ''
                                                    }`}
                                                    sx={{
                                                      marginRight: '-20px'
                                                    }}
                                                  >
                                                    {noteData?.user?.fileBank[0]
                                                      ?.fileUrl
                                                      ? null
                                                      : initials}
                                                  </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                  id={`${noteData?.id}-title`}
                                                  primary={`${
                                                    noteData?.user?.firstName
                                                  } ${
                                                    noteData?.user?.lastName
                                                    // eslint-disable-next-line no-undef
                                                  } - ${formatDate(
                                                    new Date(
                                                      noteData?.createdAt
                                                    )
                                                  )} ${getTimeFromISO8601(
                                                    noteData?.createdAt
                                                  )}`}
                                                  sx={{
                                                    '.MuiListItemText-primary':
                                                      {
                                                        color: `${secondaryColors.SECONDARY} !important`,
                                                        fontSize:
                                                          '14px !important',
                                                        textTransform:
                                                          'capitalize !important'
                                                      }
                                                  }}
                                                />
                                              </Box>
                                              <ListItemText
                                                id={`${noteData?.id}-text`}
                                                primary=""
                                                secondary={`${noteData.noteText}`}
                                                sx={{
                                                  '.MuiListItemText-secondary':
                                                    {
                                                      color: `${secondaryColors.SECONDARY} !important`,
                                                      fontSize:
                                                        '1rem !important'
                                                    },
                                                  textAlign: 'justify'
                                                }}
                                              />
                                            </ListItemButton>
                                          </ListItem>
                                        );
                                      })}
                                </List>
                              </Box>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  paddingTop: '17px'
                                }}
                              >
                                <TextField
                                  fullWidth
                                  multiline
                                  className="textAreaInput-taskDetails"
                                  disabled={
                                    currentTaskDetails?.status ===
                                    TaskIterationStatusStatusEnum.Created
                                  }
                                  id="outlined-multiline-static"
                                  label=""
                                  maxRows={2}
                                  onChange={(textField) => {
                                    setNoteText(textField.target.value);
                                  }}
                                  placeholder="Write here"
                                  rows={1}
                                  sx={{
                                    backgroundColor: 'white !important',
                                    fontSize: '16px',
                                    minHeight: 'auto',
                                    padding: '0rem 2rem 1.5rem 2rem !important',
                                    width: '100%'
                                  }}
                                  value={noteText}
                                  variant="outlined"
                                />
                                <Button
                                  fullWidth
                                  onClick={() => {
                                    setFireCreateNote(true);
                                    // setModalOpen(false);
                                  }}
                                  sx={{
                                    '&:hover': {
                                      backgroundColor: 'transparent'
                                    },
                                    alignItems: 'center',
                                    backgroundColor: 'transparent',
                                    margin: '0 !important',
                                    minWidth: '2.5rem',
                                    padding: '0 !important',
                                    width: 'auto'
                                  }}
                                  variant="primary"
                                >
                                  <SendIcon
                                    sx={{
                                      color: mainColors.MAIN,
                                      fontSize: '2.25rem',
                                      margin: '0px',
                                      padding: '0px'
                                    }}
                                  />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        }
                      </Box>
                    </Box>
                  </Modal>
                )}
              </Box>
            </>
          )}

          {type === 'checklist' && (
            <Box>
              {checklist &&
                checklist?.map((index: any, key: number) => {
                  return (
                    <ServiceTableBlockCheck
                      isCheked={index?.checked}
                      key={key}
                      text={index?.name}
                    />
                  );
                })}
            </Box>
          )}

          {type === 'images' && (
            <Box display="flex" flexDirection="column" gap={0}>
              {images?.length !== 0 && (
                <Button
                  onClick={() => {
                    downloadAll(images);
                  }}
                  variant="text"
                >
                  {t('GENERAL.downloadAllButton')}
                </Button>
              )}
              <ImageCarousel
                images={images}
                openViewer={setIsImageViewerOpen}
                setImageUrlViewer={setImgData}
              />
              {/* <TaskImageListContainer>
                {images.map((image: any, key: number) => {
                  return (
                    <Box
                      style={{
                        alignContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                      }}
                    >
                      <TaskImageContainer
                        onClick={() => {
                          setIsImageViewerOpen(true);
                          setImgData(image?.large || image?.fileUrl);
                        }}
                      >
                        <img
                          alt={image.fileName}
                          key={`taskIteration-image-${image.id}}`}
                          src={image.small || image.fileUrl}
                        />
                      </TaskImageContainer>
                    </Box>
                  );
                })}
              </TaskImageListContainer> */}
              <Box>
                {isImageViewerOpen && imgData !== '' && (
                  <ImageViewer
                    isOpen={isImageViewerOpen}
                    setIsOpen={setIsImageViewerOpen}
                    url={imgData}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      );
    }

    function ServiceTableRowTD({
      fontSize,
      colorH,
      typeTx,
      text,
      children
    }: IServiceTableRowTD) {
      return (
        <Box
          style={{
            // border: 'solid 0.1px rgba(203, 204, 211, 0.7)',
            // color: `${secondaryColors.SECONDARY}`,
            height: '100%'
            // width: `${width}%`
          }}
        >
          <Typography color={colorH} fontSize={fontSize} variant={typeTx}>
            {text}
            {children}
          </Typography>
        </Box>
      );
    }

    function ServiceTableHeader() {
      return (
        <TableRow>
          <TableCell align="center" colSpan={0} width={120}>
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="TECHNICIAN"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={70}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="ESTIMATED TIME"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={70}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="STATUS"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={140}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="CHECKLIST"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={80}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="TIME"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={140}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="GEOFENCING"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px' }}
            width={140}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="IMAGES"
              typeTx="secondary-bold"
            />
          </TableCell>
          <TableCell
            align="center"
            colSpan={0}
            sx={{ padding: '5px', width: '120px' }}
          >
            <ServiceTableRowTD
              colorH={`${themePalette.TITLE}`}
              fontSize="12px"
              text="NOTES"
              typeTx="secondary-bold"
            />
          </TableCell>
        </TableRow>
      );
    }

    const sumWorkedTime =
      data?.taskIteration?.length > 0
        ? getWorkedTimeAverageTime(data?.taskIteration)
        : 0;

    return (
      <Container
        sx={{
          alignItems: 'normal',
          margin: '40px',
          // padding: '40px'
          width: '90% !important'
        }}
      >
        <Modal
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          onClose={() => {
            setShowModal(false);
          }}
          open={auxManualClose}
          sx={{
            alignItems: 'center'
          }}
        >
          <ModalContainer
            sx={{
              '@media (min-width: 600px)': {
                minWidth: '600px'
              },
              alignItems: 'center',
              // padding: '7rem',
              position: 'fixed',
              width: '90%  !important'
            }}
          >
            <Box
              justifyContent="space-between"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100% !important'
              }}
            >
              <Box
                style={{
                  textAlign: 'left',
                  width: '90% '
                }}
              >
                <Box>
                  <Typography
                    color="#005DA2"
                    fontSize={14}
                    style={{
                      lineHeight: '20px'
                    }}
                    variant="secondary-bold"
                  >
                    {data.name}
                  </Typography>{' '}
                </Box>
                <Box sx={{ display: 'flex', marginBottom: '1rem' }}>
                  <Typography
                    fontSize={12}
                    fontWeight={800}
                    sx={{ marginRight: '0.5rem' }}
                    variant="primary-bold"
                  >
                    Total task estimated time:{' '}
                  </Typography>
                  <Typography fontSize={12} fontWeight={600} variant="primary">
                    <TimeComponent
                      readOnly
                      setTime={() => {}}
                      time={
                        data.totalTimeInHours /
                          (data?.technicians.length || 1) || 0.0
                      }
                    />
                  </Typography>
                  <Typography
                    fontSize={12}
                    fontWeight={800}
                    sx={{ marginLeft: '2rem', marginRight: '0.5rem' }}
                    variant="primary-bold"
                  >
                    Total worked time:{' '}
                  </Typography>
                  <Typography fontSize={12} fontWeight={600} variant="primary">
                    <TimeComponent
                      readOnly
                      setTime={() => {}}
                      time={sumWorkedTime / 60 || 0.0}
                    />
                  </Typography>
                </Box>

                <TableContainer sx={{ maxHeight: '70vh' }}>
                  <Table>
                    <TableHead>
                      <ServiceTableHeader />
                    </TableHead>
                    <TableBody>
                      {data?.technicians?.map((technician: any, index: any) => {
                        const auxName =
                          technician?.name ||
                          `${technician.firstName} ${technician.lastName}`;
                        const initials = auxName
                          ?.split(' ')
                          ?.slice(0, 2)
                          ?.map((word: string) => word[0]?.toUpperCase())
                          ?.join('');
                        const auxKey = `detail-project-team-${technician.id}`;
                        const iteration =
                          data?.taskIteration?.find(
                            (taskIte: any) =>
                              taskIte.technician.id === technician.id
                          ) || null;
                        return (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={0}
                              sx={{ padding: '5px' }}
                            >
                              <Box
                                key={auxKey}
                                sx={{
                                  display: 'flex'
                                  /* marginLeft: '-6px',
                                  marginTop: '-9px',
                                  padding: '7px' */
                                }}
                              >
                                {initials && (
                                  <Tooltip arrow title={technician.name}>
                                    <Avatar
                                      alt={
                                        technician.name
                                          ? `${technician.name}`
                                          : `${technician.firstName} ${technician.lastName}`
                                      }
                                      sx={{
                                        backgroundColor: resourseColors[index],
                                        cursor: 'pointer',
                                        fontSize: '13px',
                                        height: 30,
                                        marginRight: '0.25px',
                                        width: 30
                                      }}
                                    >
                                      {technician.profilePic ? null : initials}
                                    </Avatar>
                                  </Tooltip>
                                )}
                                <Typography
                                  color={`${secondaryColors.SECONDARY}`}
                                  fontSize={onlyTask ? '10px' : '12px'}
                                  style={{
                                    alignItems: 'center',
                                    marginLeft: '0.5rem'
                                  }}
                                  variant="secondary"
                                >{`${auxName}`}</Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={0}
                              sx={{
                                maxWidth: '55px !important',
                                padding: '5px'
                              }}
                            >
                              <Typography
                                color={`${secondaryColors.SECONDARY}`}
                                fontSize={12}
                                style={{
                                  alignItems: 'center'
                                }}
                                variant="secondary"
                              >
                                <TimeComponent
                                  readOnly
                                  short
                                  setTime={() => {}}
                                  time={
                                    data.totalTimeInHours /
                                      (data?.technicians.length || 1) || 0.0
                                  }
                                />
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={0}
                              sx={{ padding: '5px' }}
                            >
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: 1
                                }}
                              >
                                <Box
                                  style={{
                                    backgroundColor: getTaskStyle(
                                      !iteration?.status
                                        ? 'awaiting'
                                        : iteration?.status
                                    )?.backgroundColor,
                                    border: `solid 2px ${
                                      getTaskStyle(
                                        !iteration?.status
                                          ? 'awaiting'
                                          : iteration?.status
                                      )?.borderColor
                                    }`,
                                    borderRadius: '12px',
                                    display: 'flex',
                                    height: '100%',
                                    justifyContent: 'center',
                                    width: '100%'
                                  }}
                                >
                                  {iteration &&
                                  iteration?.status === 'completed' &&
                                  iteration?.workedTime ? (
                                    <Typography
                                      fontSize={11}
                                      style={{
                                        color: getTaskStyle(iteration?.status)
                                          .textColor
                                      }}
                                    >
                                      <TimeComponent
                                        readOnly
                                        short
                                        setTime={() => {}}
                                        time={
                                          parseFloat(iteration.workedTime) /
                                            60 || 0.0
                                        }
                                      />
                                    </Typography>
                                  ) : (
                                    <Typography
                                      fontSize={11}
                                      style={{
                                        color: getTaskStyle(
                                          !iteration?.status
                                            ? 'awaiting'
                                            : iteration?.status
                                        ).textColor
                                      }}
                                    >
                                      {!iteration ||
                                      iteration?.status === 'created'
                                        ? 'Awaiting'
                                        : capitalizeString(
                                            !iteration?.status
                                              ? 'awaiting'
                                              : iteration?.status ===
                                                'inProgress'
                                              ? 'In Progress'
                                              : iteration?.status
                                          )}
                                    </Typography>
                                  )}
                                </Box>
                                {iteration?.approvalStatus ===
                                  'ApprovalRequired' && (
                                  <Typography
                                    color="warning.main"
                                    sx={{
                                      alignItems: 'center',
                                      display: 'flex'
                                    }}
                                    variant="primary"
                                  >
                                    <Tooltip
                                      title={t(
                                        'TOOLTIP.PROJECT.taskApprovalRequired'
                                      )}
                                    >
                                      <i className="custom-icon icon-warning" />
                                    </Tooltip>
                                  </Typography>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={0}
                              sx={{ padding: '5px' }}
                            >
                              {iteration && iteration?.checkList.length > 0 && (
                                <Typography
                                  color={`${
                                    iteration?.checkListCompleted
                                      ? accentColors.ACCENT_2
                                      : mainColors.MAIN_6
                                  }`}
                                  fontSize={12}
                                  variant="secondary"
                                >
                                  {iteration.checkListCompleted
                                    ? 'Completed'
                                    : 'Incomplete'}
                                </Typography>
                              )}
                              {!iteration && data.checklist.length > 0 && (
                                <Typography
                                  color={mainColors.MAIN_6}
                                  fontSize={12}
                                  variant="secondary"
                                >
                                  Incomplete
                                </Typography>
                              )}
                              {data.checklist.length === 0 && 'N/A'}
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={0}
                              sx={{
                                maxWidth: '65px !important',
                                padding: '5px'
                              }}
                            >
                              {iteration?.startTime ? (
                                <>
                                  <Typography
                                    color={`${secondaryColors.SECONDARY}`}
                                    fontSize={12}
                                    style={{
                                      alignItems: 'center'
                                    }}
                                    variant="secondary"
                                  >
                                    Start time:{' '}
                                    {getFormatedTimeByTimeZone(
                                      iteration?.startTime,
                                      serviceInfo?.timezone || 0,
                                      userState.timeFormat ===
                                        TimeFormatEnum._24h
                                    )}
                                  </Typography>
                                  {iteration?.endTime && <br />}
                                  {iteration?.endTime && (
                                    <Typography
                                      color={`${secondaryColors.SECONDARY}`}
                                      fontSize={12}
                                      style={{
                                        alignItems: 'center'
                                      }}
                                      variant="secondary"
                                    >
                                      End time:{' '}
                                      {getFormatedTimeByTimeZone(
                                        iteration?.endTime,
                                        serviceInfo?.timezone || 0,
                                        userState.timeFormat ===
                                          TimeFormatEnum._24h
                                      )}
                                    </Typography>
                                  )}
                                </>
                              ) : (
                                <Typography
                                  color={`${secondaryColors.SECONDARY}`}
                                  fontSize={12}
                                  variant="secondary"
                                >
                                  --
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={0}
                              sx={{ padding: '5px' }}
                            >
                              {iteration && (
                                <Typography
                                  color={
                                    iteration.geofencingApproved
                                      ? successColors.SUCCESS
                                      : warningColors.ERROR
                                  }
                                  fontSize={12}
                                  variant="secondary"
                                >
                                  <Tooltip
                                    title={`Latitude: ${
                                      iteration.lat || '-'
                                    }, Longitude: ${iteration.long || '-'}`}
                                  >
                                    <i className="custom-icon icon-location" />
                                  </Tooltip>
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={0}
                              sx={{ padding: '5px' }}
                            >
                              {iteration && (
                                <Box
                                  key={iteration?.id}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <ServiceTableBlock
                                    images={iteration?.fileBank}
                                    title="IMAGES"
                                    type="images"
                                  />
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={0}
                              sx={{ padding: '5px' }}
                            >
                              {iteration && iteration?.status !== 'created' && (
                                <React.Fragment key={iteration?.id}>
                                  <ServiceTableBlock
                                    note={iteration?.notes}
                                    taskIterationID={iteration?.id}
                                    title="NOTES"
                                    type="note"
                                  />
                                </React.Fragment>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </ModalContainer>
        </Modal>
      </Container>
    );
  };

export default ProjectTaskDetailsModal;
