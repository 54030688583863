/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import '../../style/App.scss';
import { Typography, Box, MenuItem, TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import TableComponent from '../shared/TableComponent';
import { HeaderFormContainer, TitleFormBox } from '../form/parts';
import api from '../../api';
import { getCurrency, getMonthString } from '../../utils/general';
import { UpdateInvoiceStatusDTOStatusEnum } from '../../api-client/generated';
import SelectInput from '../form/SelectInput';
import Loader from '../shared/Loader';
import ToastNotification from '../shared/ToastNotification';

interface Props {
  id: string;
}

const InvoiceInformation: React.FC<Props> = function InvoiceInformation({
  id
}) {
  const { t } = useTranslation();
  const monthArray = ['All Month', ...getMonthString('normal')];
  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<Dayjs>();
  const [data, setData] = useState<any>();
  const [invoiceApi, setInvoiceApi] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars

  // ================================================================================ SWR
  const { data: invoiceData, error: invoiceError } = useSWR(
    invoiceApi
      ? { id, month, url: 'client/invoices/filters', year: year?.year() }
      : null,
    api.InvoicesApi.getClientInvoicesFiltered
  );

  const getTotalInvoiceAmount = () => {
    let toBack = 0;
    if (data?.length > 0) {
      data.forEach((invoice: any) => {
        toBack += Number(invoice.amount);
      });
    }
    return getCurrency(toBack);
  };

  const getTotalInvoiceOverdue = () => {
    let toBack = 0;
    if (data?.length > 0) {
      data.forEach((invoice: any) => {
        if (invoice.status === UpdateInvoiceStatusDTOStatusEnum.Overdue) {
          toBack += Number(invoice.amount);
        }
      });
    }
    return getCurrency(toBack);
  };

  const getTotalInvoicePaid = () => {
    let toBack = 0;
    if (data?.length > 0) {
      data.forEach((invoice: any) => {
        if (invoice.status === UpdateInvoiceStatusDTOStatusEnum.Paid) {
          toBack += Number(invoice.amount);
        }
      });
    }
    return getCurrency(toBack);
  };

  const getTotalInvoicePending = () => {
    let toBack = 0;
    if (data?.length > 0) {
      data.forEach((invoice: any) => {
        if (invoice.status === UpdateInvoiceStatusDTOStatusEnum.Pending) {
          toBack += Number(invoice.amount);
        }
      });
    }
    return getCurrency(toBack);
  };

  const getTotalInvoices = () => {
    return data?.length || 0;
  };

  // ================================================================================ LIFECYCLE

  useEffect(() => {
    if (invoiceData) {
      setData(
        invoiceData?.map((item: any) => {
          return { ...item, amount: Number(item?.amount) / 100 };
        })
      );
      setInvoiceApi(false);
    }
  }, [invoiceData]);

  useEffect(() => {
    if (invoiceError) {
      setData([]);
      setError(true);
      setInvoiceApi(false);
    }
  }, [invoiceError]);

  useEffect(() => {
    const today = dayjs();
    setMonth(today.month() + 1);
    setYear(today);
    setInvoiceApi(true);
  }, []);

  // ================================================================================ RENDER

  return (
    <Box p={5} sx={{ backgroundColor: 'white' }} width="90%">
      <HeaderFormContainer isMobile={isMobile}>
        <TitleFormBox isMobile={isMobile}>
          <Typography sx={{ marginBottom: '4px' }} variant="title">
            {t('CUSTOMER.clientInformation')}
          </Typography>
        </TitleFormBox>
      </HeaderFormContainer>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            maxDate={new Date()}
            onChange={(value: any) => {
              setYear(value);
              setInvoiceApi(true);
              setError(false);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  backgroundColor: 'transparent !important'
                }}
              />
            )}
            value={year}
            views={['year']}
          />
        </LocalizationProvider>
        {month !== undefined && (
          <Box sx={{ width: 250 }}>
            <SelectInput
              defaultValue={monthArray[month]}
              height="58px !important"
              onChange={(value: any) => {
                setMonth(monthArray.indexOf(value?.target?.value));
                setInvoiceApi(true);
                setError(false);
              }}
            >
              {monthArray.map((item: string) => (
                <MenuItem value={item}>
                  <Typography variant="primary">{item}</Typography>
                </MenuItem>
              ))}
            </SelectInput>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 2
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">{getTotalInvoiceAmount()}</Typography>
          <Typography color="secondary.400" variant="body_300">
            {t('CUSTOMER.totalInvoices')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color="warning.main" variant="h5">
            {getTotalInvoicePending()}
          </Typography>
          <Typography color="secondary.400" variant="body_300">
            {t('CUSTOMER.pendingInvoices')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color="error.main" variant="h5">
            {getTotalInvoiceOverdue()}
          </Typography>
          <Typography color="secondary.400" variant="body_300">
            {t('CUSTOMER.overdueInvoices')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography color="success.main" variant="h5">
            {getTotalInvoicePaid()}
          </Typography>
          <Typography color="secondary.400" variant="body_300">
            {t('CUSTOMER.paidInvoices')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">{getTotalInvoices()}</Typography>
          <Typography color="secondary.400" variant="body_300">
            {t('CUSTOMER.totalInvoices')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '50px'
        }}
      >
        <Box sx={{ marginY: '10px' }}>
          {!data && <Loader customColor="primary" customSize={48} />}
          {data?.length === 0 && (
            <Typography variant="primary">
              {t('ERROR.listingError', [`${t('LISTING_PAGES.invoices')}`])}
            </Typography>
          )}
          {data?.length > 0 && (
            <TableComponent
              fullSize
              action={() => {}}
              headKeys={[
                'invoiceNumber',
                'sendDate',
                'dueDate',
                'amount',
                'status'
              ]}
              isItemLoaded={(index: any) => true}
              itemCount={data?.length}
              loadMoreItems={() => {}}
              valuesArray={{ data } as unknown as { data: any[] }}
            />
          )}
        </Box>
      </Box>
      {error && (
        <ToastNotification onClose={() => setError(false)} type="error" />
      )}
    </Box>
  );
};

export default InvoiceInformation;
