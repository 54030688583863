import {
  Tabs,
  Tab,
  Container,
  Box,
  Typography,
  Chip,
  useTheme,
  PaletteColor
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { HeaderFormContainer, TitleFormBox } from '../../components/form/parts';
import GeneralInformation from './steps/GeneralInformation';
import Services from './steps/Services';
import Schedule from './steps/Schedule';
// import Resources from './steps/Resources';
import CostCalculator from './steps/CostCalculator';
import api from '../../api';
import Proposal from './steps/Proposal';
import Banner from '../../components/shared/Banner';
import { getCustomColorBasedOnStatus } from '../../utils/general';
import Loader from '../../components/shared/Loader';

const MobileStyle = {
  margin: '20px',
  width: 'auto'
};

const DesktopStyle = {
  marginBottom: '20px',
  marginTop: '44px',
  marginX: '32px',
  width: 'auto'
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      // eslint-disable-next-line
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface Props {
  leadId?: any;
  newLead?: boolean;
}

const FormLead: React.FC<Props> = function FormLead({
  leadId = null,
  newLead = false
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(0);
  const [lead, setLead] = useState<any>({});
  const [serviceIndex, setServiceIndex] = useState<number>(0);
  const [step1, setStep1] = useState<{ fire: boolean; nextStep: boolean }>({
    fire: false,
    nextStep: false
  });
  // const [getDraft, setGetDraft] = useState<boolean>(false);
  const [getCurrentLead, setGetCurrentLead] = useState<boolean>(false);
  const [getCompany, askCompany] = useState<boolean>(false);
  const [fireAPIUpdateLead, setFireAPIUpdateLead] = useState<boolean>(false);
  const [fireAPICreateLead, setFireAPICreateLead] = useState<boolean>(false);
  const [fireAPIUploadDocs, setFireAPIUploadDocs] = useState<boolean>(false);
  const [curentLeadId, setCurentLeadId] = useState<any>(null);
  const [leadFiles, setLeadFiles] = useState<any>({});
  const [initialLeadFiles, setInitialLeadFiles] = useState<any>([]);
  const [disableCalculator, setDisableCalculator] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const editableStatus = ['Draft'];
  const statusColor: PaletteColor = getCustomColorBasedOnStatus(
    lead?.status,
    theme
  );

  // ======================================================================== SWR

  const { data: leadData, error: getLeadError } = useSWR(
    getCurrentLead && leadId ? leadId : null,
    api.leadsApi.leadApi.getLead
  );

  const { data: dataCreatedLead, error: createLeadError } = useSWR(
    fireAPICreateLead && lead ? lead : null,
    api.leadsApi.leadApi.createLead
  );

  const { data: dataCompany, mutate: companyMutate } = useSWR(
    getCompany ? { url: 'api/company' } : null,
    api.CompanyApi.getCurrentCompany
  );

  const { data: dataUpdateLead, error: updateClientError } = useSWR(
    lead?.status !== 'Converted' && fireAPIUpdateLead ? lead : null,
    api.leadsApi.leadApi.updateLeadGeneralInfo
  );

  const { data: fileResponse, error: errorFile } = useSWR(
    fireAPIUploadDocs && lead.id
      ? {
          entityId: lead.id,
          files: leadFiles?.length > 0 ? leadFiles : [],
          initialLeadFiles,
          relatedEntity: 'lead'
        }
      : null,
    api.StorageApi.uploadEntityFiles
  );

  const { data: step1Data } = useSWR(
    step1.fire ? lead : null,
    api.leadsApi.stepsApi.setStep1
  );

  const handleSaveLead = (lead: any) => {
    if (lead?.address?.id) {
      const auxLead = {
        ...lead,
        addressId: lead?.address?.id,
        clientId: lead?.client?.id,
        companyId: lead?.company?.id,
        contactId: lead?.contact?.id
      };
      setLead(auxLead);
      setFireAPICreateLead(true);
      if (lead?.fileBank && lead?.fileBank?.length > 0) {
        setLeadFiles(lead?.fileBank || null);
      }
    }
  };

  const handleUpdateLead = (lead: any) => {
    if (leadId) {
      setFireAPIUpdateLead(true);
    }

    setLeadFiles(lead?.fileBank || null);
    setLead(lead || {});
  };

  const saveStepper = ({ next }: any) => {
    switch (step) {
      case 0:
        setStep1({ fire: true, nextStep: next });
        setIsLoading(true);
        break;
      default:
        if (Number.isNaN(next)) {
          setStep(step === 4 ? 0 : step + 1);
        } else {
          setStep(next);
        }
        break;
    }
  };

  const goToListingPages = () => {
    navigate('/app/leads/listing-page');
  };

  const readOnlyFunc = (info: any) => {
    if (newLead) {
      return false;
    }
    if (editableStatus.includes(info?.status)) {
      return false;
    }
    if (info?.proposals?.length === 0) {
      return false;
    }
    if (info?.status === 'Working Lead') {
      const currentProposal = info?.proposals?.[0] || {};
      if (currentProposal?.status === 'created') {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setFireAPIUpdateLead(false);
    if (dataUpdateLead) {
      setFireAPIUploadDocs(true);
    }
    if (errorFile) {
      setFireAPIUploadDocs(false);
    }
    setLead(lead);
  }, [dataUpdateLead]);

  useEffect(() => {
    if (updateClientError) {
      setFireAPIUpdateLead(false);
    }
  }, [updateClientError]);

  useEffect(() => {
    if (fileResponse && !errorFile) {
      setFireAPIUploadDocs(false);
      setLead({ ...lead, fileBank: leadFiles });
      if (fileResponse?.data && fileResponse?.data?.length > 0) {
        setInitialLeadFiles(fileResponse.data.map((file: any) => file.id));
      }

      setLeadFiles(null);
    }
    if (errorFile) {
      setFireAPIUploadDocs(false);
    }
  }, [fileResponse]);

  useEffect(() => {
    if (lead && !lead.company) {
      askCompany(true);
    }
  }, [lead]);

  useEffect(() => {
    if (dataCreatedLead?.id) {
      setFireAPICreateLead(false);
      setLead(dataCreatedLead);

      if (leadFiles?.length && leadFiles.length > 0) {
        setFireAPIUploadDocs(true);
      }

      setCurentLeadId(dataCreatedLead.id);
      navigate(`/app/leads/edit-lead`, {
        state: { id: dataCreatedLead?.id, newLead: true }
      });
      setStep(1);
    }
  }, [dataCreatedLead]);

  useEffect(() => {
    if (step1Data) {
      if (step1?.nextStep) {
        setStep(step === 4 ? 0 : step + 1);
      } else {
        goToListingPages();
      }
      setStep1({ ...step1, fire: false });
    }
  }, [step1Data]);

  useEffect(() => {
    if (dataCompany) {
      askCompany(false);
      setLead({ ...lead, company: dataCompany });
    }
  }, [dataCompany]);

  useEffect(() => {
    if (!dataCompany) askCompany(true);
    setLead({});
  }, []);

  useEffect(() => {
    if (leadId) {
      setGetCurrentLead(true);
      setCurentLeadId(leadId);
    } else {
      setLead({ id: 'newLead' });
      // setGetDraft(true);
    }
  }, [leadId]);

  useEffect(() => {
    if (leadData && getCurrentLead) {
      setLeadFiles(null);
      setGetCurrentLead(false);
      setLead({ ...leadData, readOnly: readOnlyFunc(leadData) });
      if (newLead) {
        setTimeout(() => {
          setStep(1);
        }, 0);
      } else if (
        leadData.fileBank?.length > 0 &&
        leadData?.originalFiles?.length > 0
      ) {
        setInitialLeadFiles(leadData.originalFiles.map((file: any) => file.id));
      }
    }
  }, [leadData]);

  const getCurrentTimezone = () => {
    const timezoneOffsetLocal = new Date().getTimezoneOffset();
    return -timezoneOffsetLocal;
  };

  const differentTimezone = () => {
    if (lead?.address?.timezoneIANA) {
      return (
        getCurrentTimezone() !==
        dayjs().tz(lead?.address?.timezoneIANA).utcOffset()
      );
    }
    if (lead?.address?.timezone) {
      return getCurrentTimezone() !== lead?.address?.timezone;
    }
    return false;
  };

  return (
    <Container sx={isMobile ? MobileStyle : DesktopStyle}>
      <Box
        sx={{
          alignItems: 'stretch',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          justifyContent: 'flex-start'
        }}
      >
        <HeaderFormContainer
          customStyles={{ display: 'flex' }}
          isMobile={isMobile}
        >
          <TitleFormBox isMobile={isMobile} sx={{ gap: '16px' }}>
            <Typography
              color="primary.main"
              sx={{
                alignItems: 'center',
                display: 'flex',
                fontSize: isMobile ? '24px' : '2.25rem',
                fontWeight: '700',
                gap: 2,
                lineHeight: '36px'
              }}
            >
              {t('LEAD.EDIT.title')}
              {lead?.status && (
                <Chip
                  label={
                    <Typography variant="button_100">{lead?.status}</Typography>
                  }
                  style={{
                    backgroundColor: statusColor[100],
                    border: `1px solid ${statusColor.main}`,
                    borderRadius: '24px !important',
                    color: statusColor[500]
                  }}
                />
              )}
            </Typography>
            <Typography color="secondary.400" variant="body_300">
              {t('LEAD.EDIT.description')}
            </Typography>
          </TitleFormBox>
        </HeaderFormContainer>
        {(lead?.readOnly || differentTimezone()) && (
          <Box display="flex" flexDirection="column" gap="16px">
            {lead?.readOnly && (
              <Banner
                icon
                text={t('BANNER.LEAD.readOnly')}
                textCustomStyle={{ fontSize: '0.875rem', fontWeight: '400' }}
                type="error"
              />
            )}
            {differentTimezone() && (
              <Banner
                icon
                text={t('BANNER.LEAD.differentTimezone')}
                textCustomStyle={{ fontSize: '0.875rem', fontWeight: '400' }}
                type="warning"
              />
            )}
          </Box>
        )}
        <Tabs
          aria-label="basic tabs example"
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            if (newValue !== 1) {
              if (lead?.serviceTimes?.length > 0) {
                const cleanedServicesTimes = lead?.serviceTimes?.filter(
                  (item: any) => item?.id && typeof item?.id === 'number'
                );
                setLead({ ...lead, serviceTimes: cleanedServicesTimes });
              }
            }
            setStep(newValue);
          }}
          value={step}
        >
          <Tab label="General Information" />
          <Tab disabled={!curentLeadId} label="Service Time" />
          <Tab
            disabled={!curentLeadId || lead?.serviceTimes?.length === 0}
            label="Resources"
          />
          <Tab
            disabled={!curentLeadId || disableCalculator}
            label="Cost Calculator"
          />
          <Tab disabled={!curentLeadId || disableCalculator} label="Proposal" />
        </Tabs>
        <Box p={3} style={{ backgroundColor: 'rgb(255,255,255)' }}>
          <TabPanel index={0} value={step}>
            {lead &&
              (isLoading ? (
                <Loader customColor="primary" customSize={48} />
              ) : (
                <GeneralInformation
                  curentLeadId={curentLeadId}
                  saveStepper={saveStepper}
                  sendValues={curentLeadId ? handleUpdateLead : handleSaveLead}
                  setValues={setLead}
                  values={{ ...lead }}
                />
              ))}
          </TabPanel>
          <TabPanel index={1} value={step}>
            <Services
              saveStepper={saveStepper}
              sendValues={handleUpdateLead}
              setServiceIndex={setServiceIndex}
              setValues={setLead}
              values={lead}
            />
          </TabPanel>
          <TabPanel index={2} value={step}>
            <Schedule
              saveStepper={saveStepper}
              sendValues={handleUpdateLead}
              serviceIndex={serviceIndex}
              setStep={setStep}
              setValues={setLead}
              values={lead}
            />
          </TabPanel>
          <TabPanel index={3} value={step}>
            <CostCalculator
              // availability={availability}
              saveStepper={saveStepper}
              setValues={handleUpdateLead}
              values={lead}
            />
          </TabPanel>
          <TabPanel index={4} value={step}>
            <Proposal
              clientView={false}
              prevProposalData={{}}
              saveStepper={saveStepper}
              setAcceptProposal={() => {}}
              setStep={setStep}
              setValues={setLead}
              values={lead}
            />
          </TabPanel>
        </Box>
      </Box>
    </Container>
  );
};

TabPanel.defaultProps = {
  children: null
};

FormLead.defaultProps = {
  leadId: null,
  newLead: false
};

export default FormLead;
