import React from 'react';
import '../../style/App.scss';
import { Box, useTheme, Typography, PaletteColor } from '@mui/material';
import {
  generalIconStatus,
  getCustomColorBasedOnStatus
} from '../../utils/general';

interface BannerProps {
  icon?: boolean;
  text: string;
  textCustomStyle?: object;
  type: string;
}

const Banner: React.FC<BannerProps> = function Banner({
  type,
  text,
  icon,
  textCustomStyle
}) {
  const theme = useTheme();

  const currentPalette: PaletteColor = getCustomColorBasedOnStatus(type, theme);

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: `${currentPalette[100]}`,
        border: `2px solid ${currentPalette.main}`,
        borderRadius: 1,
        color: currentPalette.main,
        display: 'flex',
        gap: 1,
        height: 'auto',
        p: 2,
        width: '100%'
      }}
    >
      <Typography variant="secondary-bold">
        {icon && (
          <i
            className={`custom-icon icon-${generalIconStatus[type]}`}
            style={{ alignItems: 'center', display: 'flex' }}
          />
        )}
      </Typography>
      <Typography sx={{ ...textCustomStyle }} variant="secondary-bold">
        {text}
      </Typography>
    </Box>
  );
};

Banner.defaultProps = {
  icon: false,
  textCustomStyle: {}
};

export default Banner;
