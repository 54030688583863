/* eslint-disable import/prefer-default-export */
import {
  CommunicationMethodPropertiesDTOTypeEnum,
  CreateClientDTORelationshipEnum,
  CreateClientDTOStatusEnum,
  CreateClientDTOTypeEnum
} from '../api-client/generated';

export const customerType = [
  CreateClientDTOTypeEnum.Client,
  CreateClientDTOTypeEnum.Contact,
  CreateClientDTOTypeEnum.Prospect
];

export const customerRelationship = [
  CreateClientDTORelationshipEnum.B2B,
  CreateClientDTORelationshipEnum.B2C
];

export const customerStatus = [
  CreateClientDTOStatusEnum.Active,
  CreateClientDTOStatusEnum.Inactive
];

export const customerCommunicationMethods = [
  CommunicationMethodPropertiesDTOTypeEnum.Email,
  CommunicationMethodPropertiesDTOTypeEnum.Fax,
  CommunicationMethodPropertiesDTOTypeEnum.Phone,
  CommunicationMethodPropertiesDTOTypeEnum.WebAddress
];
