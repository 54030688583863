/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line react/no-unstable-nested-components
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Avatar,
  FormControl,
  Tooltip,
  Modal,
  FormControlLabel,
  IconButton,
  Divider,
  SvgIconTypeMap
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import {
  CloudCircleOutlined,
  CloudSync,
  CloudSyncOutlined,
  PhoneOutlined,
  PlaceOutlined,
  SaveOutlined,
  SvgIconComponent
} from '@mui/icons-material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {
  textColors,
  secondaryColors,
  themePalette,
  mainColors
} from '../../theme/themeConfig';
import { HeaderFormContainer, TitleFormBox } from '../../components/form/parts';
import Api from '../../api';
import { ReactComponent as UserIcons } from '../../assets/icons/user-circle.svg';
import { ReactComponent as LocationIcons } from '../../assets/icons/location-point-icons.svg';
import { ReactComponent as PhoneIcons } from '../../assets/icons/phone-icons.svg';
import { ReactComponent as Refresh } from '../../assets/icons/refresh.svg';
import StepDetailProjects from './StepDetailProjects';
import { statusStyle, resourseColors } from '../../utils/general';
import EditButton from '../../components/list/EditButton';
import RegularInput from '../../components/input/RegularInput';
import { AntSwitch, IOSSwitch } from '../../components/companySettings/parts';
import ToastNotification from '../../components/shared/ToastNotification';
import { toastNotification } from '../../utils/notificationHelper';
import ConfirmModal from '../../components/modals/ConfirmModal';
import AvatarGroup from '../../components/AvatarGroup';

interface Props {
  // eslint-disable-next-line react/require-default-props
  isClient?: boolean;
}

const DetailProject: React.FC<Props> = function DetailProject({
  isClient = false
}) {
  const [detailProject, setDetailProject] = useState<any>({});
  const [detailProjectEdit, setDetailProjectEdit] = useState<any>({});
  const [editFlag, setEditFlag] = useState<boolean>();
  const [apiEdit, setApiEdit] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fireDelete, setFireDelete] = useState<boolean>(false);
  const [showOkModal, setShowOkModal] = useState<boolean>(false);
  const [deleteItem, setDelete] = useState<string | null>(null);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const urlParams = useParams();
  const { state: params } = useLocation();
  const getProjectByIdAction = isClient
    ? Api.ClientPageApi.getProjectClientById
    : Api.ProjectApi.getProjectById;
  const type = params?.type;
  const dayChecked = params?.dayChecked;
  const utcUpadtedAt = params?.utcUpadtedAt;
  const { mutate: projectEnforceGeofencingMutate } = useSWRConfig();
  const [toastNotification, setToastNotification] =
    useState<toastNotification>();

  const urlParamId = urlParams['*'];
  const locationStateId = location?.state?.id || urlParamId || null;

  const {
    data: responseData,
    error: responseError,
    mutate
  } = useSWR(
    locationStateId || reload
      ? locationStateId
      : new URLSearchParams(window.location.search).get('id'),
    getProjectByIdAction
  );

  useEffect(() => {
    if (reload) {
      mutate(null, true);
    }
  }, [reload, mutate]);

  const { data: editNamResponse, error: editNameError } = useSWR(
    apiEdit ? { ...detailProjectEdit, url: 'edit-project/name' } : null,
    Api.ProjectApi.changeProjectName
  );

  const { data: cancelProjectResponse, error: cancelProjectError } = useSWR(
    fireDelete ? { ...detailProject, url: 'edit-project/name' } : null,
    Api.ProjectApi.cancelProject
  );

  useEffect(() => {
    if (cancelProjectResponse && !cancelProjectError) {
      setDetailProject({
        ...detailProject,
        status: 'Cancelled'
      });
      setShowOkModal(true);
    }
  }, [cancelProjectResponse]);

  useEffect(() => {
    if (showOkModal) {
      setShowDeleteModal(false);
      setShowOkModal(false);
    }
  }, [showOkModal]);

  useEffect(() => {
    if (responseData && !responseError) {
      setReload(false);
      const auxServiceTimes = responseData?.proposal?.lead?.serviceTimes.map(
        (serviceTime: any) => {
          const auxStartTime = dayjs()
            .hour(parseFloat(serviceTime?.startTime?.split(':')[0]))
            .minute(parseFloat(serviceTime?.startTime?.split(':')[1]))
            .add(
              Number(responseData?.proposal?.lead.address?.timezone || 0),
              'minute'
            );

          const auxEndTime = dayjs()
            .hour(parseFloat(serviceTime?.endTime?.split(':')[0]))
            .minute(parseFloat(serviceTime?.endTime?.split(':')[1]))
            .add(
              Number(responseData?.proposal?.lead.address?.timezone || 0),
              'minute'
            );

          return {
            ...serviceTime,
            formated24hEndTime: auxEndTime.format('HH:mm'),
            formated24hStartTime: auxStartTime.format('HH:mm'),
            formatedEndTime: auxEndTime.format('hh:mm A'),
            formatedStartTime: auxStartTime.format('hh:mm A'),
            timezone: responseData?.proposal?.lead.address?.timezone
          };
        }
      );

      const auxResponse = {
        ...responseData,
        id: responseData?.id,
        proposal: {
          ...responseData?.proposal,
          lead: {
            ...responseData?.proposal?.lead,
            serviceTimes: auxServiceTimes
          }
        }
      };

      setDetailProject(auxResponse);
    }
  }, [responseData]);

  const handleRedirect = () => {
    if (params?.lastPage) {
      navigate(params.lastPage, { state: params });
    } else if (isClient) {
      navigate('/customer-page/projects/list');
    } else {
      navigate('/app/projects/list');
    }
  };

  useEffect(() => {
    if (editNamResponse) {
      setApiEdit(false);
      mutate(null, true);
    }
  }, [editNamResponse]);

  useEffect(() => {
    if (editNameError) {
      setApiEdit(false);
    }
  }, [editNameError]);

  const header = () => {
    return (
      <Box>
        <Typography
          sx={{
            ...statusStyle(detailProject?.status)
          }}
          variant="body_200"
        >
          {detailProject.status}
        </Typography>
      </Box>
    );
  };

  function ClientInformation() {
    const clientName = detailProject?.proposal?.lead?.client?.firstName;
    const clientLastName = detailProject?.proposal?.lead?.client?.lastName;
    const clientPhone = detailProject?.proposal?.lead?.client?.phone;
    const clientAddress = detailProject?.proposal?.lead?.address?.address;

    const clientData = [
      {
        icon: <AccountCircleOutlinedIcon fontSize="small" />,
        label: `${clientName} ${clientLastName}`
      },
      {
        icon: <PlaceOutlined fontSize="small" />,
        label: clientAddress
      },
      {
        icon: <PhoneOutlined fontSize="small" />,
        label: clientPhone
      }
    ];

    function ClientInformation({
      label,
      IconComponent
    }: {
      IconComponent: ReactNode;
      label: string;
    }) {
      return (
        <Box
          sx={{
            color: secondaryColors.SECONDARY,
            display: 'flex',
            gap: '0.5em'
          }}
        >
          {IconComponent}
          <Typography variant="body_200">{label}</Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'grid', gap: '1em' }}>
        <Typography
          style={{
            fontWeight: 'bold'
          }}
          variant="heading_300"
        >
          {`${t('CUSTOMER.clientInformation')}:`}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
          {clientData.map(({ label, icon }, index) => {
            return (
              <ClientInformation
                IconComponent={icon}
                key={index}
                label={label}
              />
            );
          })}
        </Box>
      </Box>
    );
  }

  function ProjectName() {
    const handleEdit = () => {
      setEditFlag(!editFlag);
      if (editFlag) {
        setApiEdit(true);
      } else {
        setDetailProjectEdit(detailProject);
      }
    };

    return (
      <Box
        style={{
          display: 'grid'
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold'
          }}
          variant="heading_300"
        >
          {`${t('LEAD.CREATE.GENERAL_INFORMATION.projectName')}:`}
        </Typography>
        {detailProject?.proposal?.lead && (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 2,
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            {!editFlag ? (
              <Typography color={secondaryColors.SECONDARY} variant="body_200">
                {detailProject?.proposal?.lead?.name}
              </Typography>
            ) : (
              <RegularInput
                customStyle={{ width: '100%' }}
                onChange={(value) => {
                  setDetailProjectEdit({
                    ...detailProjectEdit,
                    proposal: {
                      ...detailProjectEdit?.proposal,
                      lead: {
                        ...detailProjectEdit?.proposal?.lead,
                        name: value
                      }
                    }
                  });
                }}
                value={detailProjectEdit?.proposal?.lead?.name}
              />
            )}
            {/* !editFlag ? 'edit' : 'save' */}
            {!isClient &&
              detailProject?.status !== 'Cancelled' &&
              detailProject?.status !== 'Completed' && (
                <IconButton onClick={handleEdit}>
                  {!editFlag ? (
                    <BorderColorOutlinedIcon fontSize="small" />
                  ) : (
                    <SaveOutlined fontSize="small" />
                  )}
                </IconButton>
              )}
          </Box>
        )}
      </Box>
    );
  }

  function ProjectDuration() {
    const projectStartDate = detailProject?.proposal?.lead?.projectStartDate;
    const projectEndDate = detailProject?.proposal?.lead?.projectEndDate;
    const projectType = detailProject?.proposal?.lead?.projectType;

    return (
      <Box>
        {projectStartDate && (
          <Typography
            sx={{
              color: secondaryColors.SECONDARY,
              display: 'flex',
              gap: '4px'
            }}
            variant="body_200"
          >
            {`${dayjs(projectStartDate).format('DD MMMM, YYYY')}`}

            {projectType !== 'Ad-Hoc' && projectEndDate && (
              <Box sx={{ display: 'flex', gap: '4px' }}>
                <span>-</span>
                {dayjs(projectEndDate).format('DD MMMM, YYYY')}
              </Box>
            )}
          </Typography>
        )}
      </Box>
    );
  }

  function ProjectType() {
    const projectType = detailProject?.proposal?.lead?.projectType;
    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '0.5em' }}>
        <Typography
          style={{ color: secondaryColors.SECONDARY_5 }}
          variant="body_200"
        >
          {`${t('LISTING_PAGES.projectType')}:`}
        </Typography>
        <Typography
          style={{
            color: secondaryColors.SECONDARY
          }}
          variant="body_200"
        >
          {projectType}
        </Typography>
      </Box>
    );
  }

  function ProjectSupervisor() {
    const supervisors = detailProject?.proposal?.lead?.supervisors;

    return (
      <Box sx={{ display: 'grid', gap: '0.25em' }}>
        <Typography variant="body_200">
          {`${t('GENERAL.supervisor')}:`}
        </Typography>
        {supervisors?.length > 0 ? (
          <AvatarGroup max={2} users={supervisors} />
        ) : (
          <Typography
            sx={{ color: secondaryColors.SECONDARY }}
            variant="body_200"
          >
            This project has no supervisors
          </Typography>
        )}
      </Box>
    );
  }

  function ProjectTeam() {
    // Filter to only show the unique technicians
    const uniqueTechnicians =
      detailProject?.proposal?.lead?.technicians?.filter(
        (technician: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.id === technician.id)
      );

    return (
      <Box sx={{ display: 'grid', gap: '0.25em' }}>
        <Typography variant="body_200">{`${t('GENERAL.team')}:`}</Typography>
        {uniqueTechnicians?.length > 0 ? (
          <AvatarGroup max={10} users={uniqueTechnicians} />
        ) : (
          <Typography
            sx={{ color: secondaryColors.SECONDARY }}
            variant="body_200"
          >
            This project has no technicians assigned
          </Typography>
        )}
      </Box>
    );
  }

  function EnforceGeofencing() {
    const enforceGeofencing =
      detailProject?.status !== 'Cancelled' &&
      detailProject?.status !== 'Completed';

    const handleSwitchChange = () => {
      setDetailProject({
        ...detailProject,
        enforceGeofencing: !detailProject.enforceGeofencing
      });
      setToastNotification({
        text: t('NOTIFICATION.TOAST.projectUpdatedSuccess'),
        type: 'success'
      });
      projectEnforceGeofencingMutate(
        '/project/enforceGeofencing',
        Api.ProjectApi.patchChangeProjectEnforceGeofencing({
          enforceGeofencing: !detailProject.enforceGeofencing,
          id: detailProject.id
        })
      );
    };

    return enforceGeofencing ? (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Typography variant="body_200">
          {`${t('COMPANY_SETTINGS.enforceGeofencing')}:`}
        </Typography>

        <FormControlLabel
          control={<AntSwitch checked={detailProject?.enforceGeofencing} />}
          disabled={false}
          label=""
          onChange={handleSwitchChange}
          sx={{ m: 0 }}
        />
      </Box>
    ) : null;
  }

  function CancelProjectButton() {
    const isButtonDisabled =
      detailProject?.status === 'Cancelled' ||
      detailProject?.status === 'Completed';

    return (
      <Button
        color="error"
        disabled={isButtonDisabled}
        onClick={() => setShowDeleteModal(true)}
        sx={{
          marginTop: '1em',
          width: '100%'
        }}
        variant="cancel"
      >
        <Typography variant="primary-bold">
          {t('GENERAL.cancelProject')}
        </Typography>
      </Button>
    );
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 3, paddingTop: 2 }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'left'
        }}
      >
        <Box
          sx={{
            alignContent: 'center',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 4
          }}
        >
          <Typography
            sx={{
              alignItems: 'center',
              display: 'flex',
              fontSize: '28px',
              fontWeight: '900',
              gap: 2,
              justifyContent: 'center'
            }}
            variant="primary-bold"
          >
            <ArrowBackIcon fill="grey" onClick={() => handleRedirect()} />
            {t('MENU.projects')}
          </Typography>
        </Box>
        <Button
          onClick={() => {
            setReload(true);
            setDetailProject({});
          }}
          variant="text"
        >
          <Typography
            sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}
            variant="body_100"
          >
            <CloudSyncOutlined fontSize="small" />

            {t('GENERAL.sync')}
          </Typography>
        </Button>
      </Box>
      <Grid
        container
        pl={6}
        spacing={2}
        style={{ backgroundColor: `${themePalette.BACKGROUND}` }}
      >
        {detailProject?.proposal?.lead && (
          <Grid
            sx={{
              backgroundColor: 'white',
              marginBottom: '16px',
              padding: '1.5em 1.2em'
            }}
            xs={3}
          >
            <Box
              sx={{
                display: 'grid',
                gap: '1em'
              }}
            >
              {toastNotification && (
                <ToastNotification
                  onClose={() => setToastNotification(undefined)}
                  text={toastNotification.text}
                  type={toastNotification.type}
                />
              )}
              {header()}
              {ProjectName()}
              <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.3)' }} />
              <Box sx={{ display: 'grid', gap: '1em' }}>
                {ProjectDuration()}
                {ProjectType()}
              </Box>
              <Divider />
              {ClientInformation()}
              {!isClient && (
                <>
                  <Divider />
                  {ProjectSupervisor()}
                  {ProjectTeam()}
                  {EnforceGeofencing()}
                </>
              )}
              {!isClient && CancelProjectButton()}
            </Box>
          </Grid>
        )}
        <Grid item xs={9}>
          <StepDetailProjects
            dayChecked={dayChecked}
            detailProject={detailProject}
            isClient={isClient}
            projectType={detailProject?.proposal?.lead?.projectType}
            refresh={() => {
              setReload(true);
            }}
            typeView={type}
            utcUpadtedAt={utcUpadtedAt}
          />
        </Grid>
      </Grid>
      {showDeleteModal && (
        <ConfirmModal
          modalText={t('MODAL.confirmCancelProject')}
          modalType="project"
          setSentFlag={setFireDelete}
          setShowModal={setShowDeleteModal}
          setShowOkModal={setShowOkModal}
        />
      )}
    </Box>
  );
};

export default DetailProject;
