/* tslint:disable */
/* eslint-disable */
/**
 * Bluberry API
 * Bluberry API Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptCompanyInvitationDTO
 */
export interface AcceptCompanyInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof AcceptCompanyInvitationDTO
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof AcceptCompanyInvitationDTO
     */
    'companyDescription'?: string;
}
/**
 * 
 * @export
 * @interface AcceptCompanyInvitationResponseDTO
 */
export interface AcceptCompanyInvitationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof AcceptCompanyInvitationResponseDTO
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface AcceptInvitationBodyDTO
 */
export interface AcceptInvitationBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationBodyDTO
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AcceptInvitationResponseDTO
 */
export interface AcceptInvitationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof AcceptInvitationResponseDTO
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface ActiveUserDTO
 */
export interface ActiveUserDTO {
    /**
     * 
     * @type {string}
     * @memberof ActiveUserDTO
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface ActivityLog
 */
export interface ActivityLog {
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface AddressPropertiesDTO
 */
export interface AddressPropertiesDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressPropertiesDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressPropertiesDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddressPropertiesDTO
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof AddressPropertiesDTO
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof AddressPropertiesDTO
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof AddressPropertiesDTO
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof AddressPropertiesDTO
     */
    'timezone': number;
    /**
     * 
     * @type {string}
     * @memberof AddressPropertiesDTO
     */
    'timezoneIANA': string;
}
/**
 * 
 * @export
 * @interface AddressResponseDTO
 */
export interface AddressResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof AddressResponseDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'phone': string;
    /**
     * 
     * @type {number}
     * @memberof AddressResponseDTO
     */
    'timezone': number;
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'timezoneIANA': string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponseDTO
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof AddressResponseDTO
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof AddressResponseDTO
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface AssignationBodyDTO
 */
export interface AssignationBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof AssignationBodyDTO
     */
    'technicianId': string;
    /**
     * 
     * @type {number}
     * @memberof AssignationBodyDTO
     */
    'taskId': number;
    /**
     * 
     * @type {number}
     * @memberof AssignationBodyDTO
     */
    'leadServiceTimeId': number;
    /**
     * 
     * @type {string}
     * @memberof AssignationBodyDTO
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof AssignationBodyDTO
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof AssignationBodyDTO
     */
    'toDate': string;
}
/**
 * 
 * @export
 * @interface AssignmentsCounts
 */
export interface AssignmentsCounts {
    /**
     * 
     * @type {number}
     * @memberof AssignmentsCounts
     */
    'required3Days'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentsCounts
     */
    'assigned3Days'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentsCounts
     */
    'requiredLess10Days'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentsCounts
     */
    'assignedLess10Days'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentsCounts
     */
    'requiredMore10Days'?: number;
    /**
     * 
     * @type {number}
     * @memberof AssignmentsCounts
     */
    'assignedMore10Days'?: number;
}
/**
 * 
 * @export
 * @interface AuthChangePasswordBodyDTO
 */
export interface AuthChangePasswordBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordBodyDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordBodyDTO
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof AuthChangePasswordBodyDTO
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface AvailabilityDTO
 */
export interface AvailabilityDTO {
    /**
     * 
     * @type {object}
     * @memberof AvailabilityDTO
     */
    'serviceTime': object;
}
/**
 * 
 * @export
 * @interface AvailableTimeRequestedDTO
 */
export interface AvailableTimeRequestedDTO {
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeRequestedDTO
     */
    'requestedTimeStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeRequestedDTO
     */
    'requestedTimeEnd'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailableTimeRequestedDTO
     */
    'requestedAvailableDays'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AvailableTimeResponseDTO
 */
export interface AvailableTimeResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeResponseDTO
     */
    'requestedTimeStart': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeResponseDTO
     */
    'requestedTimeEnd': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeResponseDTO
     */
    'requestedTimeZone': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AvailableTimeResponseDTO
     */
    'requestedAvailableDays': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeResponseDTO
     */
    'deletedAt': string;
    /**
     * 
     * @type {object}
     * @memberof AvailableTimeResponseDTO
     */
    'company': object;
    /**
     * 
     * @type {object}
     * @memberof AvailableTimeResponseDTO
     */
    'reviewedBy': object;
    /**
     * 
     * @type {object}
     * @memberof AvailableTimeResponseDTO
     */
    'requestedBy': object;
}
/**
 * 
 * @export
 * @interface CalendarEventsListDTO
 */
export interface CalendarEventsListDTO {
    /**
     * 
     * @type {Array<CalendarEventsResponseDTO>}
     * @memberof CalendarEventsListDTO
     */
    'data': Array<CalendarEventsResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof CalendarEventsListDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface CalendarEventsProjectDetailResponseDTO
 */
export interface CalendarEventsProjectDetailResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsProjectDetailResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsProjectDetailResponseDTO
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsProjectDetailResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {CeServiceTimeResponseDTO}
     * @memberof CalendarEventsProjectDetailResponseDTO
     */
    'serviceTime': CeServiceTimeResponseDTO;
    /**
     * 
     * @type {Array<CeAssignmentDTO>}
     * @memberof CalendarEventsProjectDetailResponseDTO
     */
    'assignments'?: Array<CeAssignmentDTO>;
    /**
     * 
     * @type {Array<CeIterationDTO>}
     * @memberof CalendarEventsProjectDetailResponseDTO
     */
    'calendarEventIterations'?: Array<CeIterationDTO>;
}
/**
 * 
 * @export
 * @interface CalendarEventsResponseDTO
 */
export interface CalendarEventsResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'state': string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventsResponseDTO
     */
    'closed': boolean;
    /**
     * 
     * @type {object}
     * @memberof CalendarEventsResponseDTO
     */
    'leadService': object;
    /**
     * 
     * @type {object}
     * @memberof CalendarEventsResponseDTO
     */
    'project': object;
    /**
     * 
     * @type {object}
     * @memberof CalendarEventsResponseDTO
     */
    'company': object;
    /**
     * 
     * @type {object}
     * @memberof CalendarEventsResponseDTO
     */
    'leadServiceTime': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarEventsResponseDTO
     */
    'travelTimeIterations': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarEventsResponseDTO
     */
    'calendarEventIterations': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarEventsResponseDTO
     */
    'taskIterations': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CalendarEventsResponseDTO
     */
    'client': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarEventsResponseDTO
     */
    'assignments': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventsResponseDTO
     */
    'requiredAssignments'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventsResponseDTO
     */
    'currentAssignments'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventsResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventsResponseDTO
     */
    'locationTimezone'?: number;
}
/**
 * 
 * @export
 * @interface CalendarObjectDTO
 */
export interface CalendarObjectDTO {
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'calendarId': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'body': string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarObjectDTO
     */
    'isAllday': boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof CalendarObjectDTO
     */
    'goingDuration': number;
    /**
     * 
     * @type {number}
     * @memberof CalendarObjectDTO
     */
    'comingDuration': number;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'location': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarObjectDTO
     */
    'attendees': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'state': string;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarObjectDTO
     */
    'isVisible': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarObjectDTO
     */
    'isPending': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarObjectDTO
     */
    'isFocused': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarObjectDTO
     */
    'isReadOnly': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarObjectDTO
     */
    'isPrivate': boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'backgroundColor': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'dragBackgroundColor': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarObjectDTO
     */
    'borderColor': string;
    /**
     * 
     * @type {object}
     * @memberof CalendarObjectDTO
     */
    'customStyle': object;
    /**
     * 
     * @type {AvailabilityDTO}
     * @memberof CalendarObjectDTO
     */
    'raw': AvailabilityDTO;
}
/**
 * 
 * @export
 * @interface CategoryDetailsResponseDTO
 */
export interface CategoryDetailsResponseDTO {
    /**
     * 
     * @type {object}
     * @memberof CategoryDetailsResponseDTO
     */
    'id': object;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDetailsResponseDTO
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface CategoryPaginatedResponseDTO
 */
export interface CategoryPaginatedResponseDTO {
    /**
     * 
     * @type {Array<CategoryResponseDTO>}
     * @memberof CategoryPaginatedResponseDTO
     */
    'data': Array<CategoryResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof CategoryPaginatedResponseDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface CategoryResponseDTO
 */
export interface CategoryResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CategoryResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CategoryResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CeAssignmentDTO
 */
export interface CeAssignmentDTO {
    /**
     * 
     * @type {string}
     * @memberof CeAssignmentDTO
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CeAssignmentDTO
     */
    'leadServiceTask': number;
    /**
     * 
     * @type {CeTechnicianResponseDTO}
     * @memberof CeAssignmentDTO
     */
    'technician': CeTechnicianResponseDTO;
}
/**
 * 
 * @export
 * @interface CeCheckListResponseDTO
 */
export interface CeCheckListResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CeCheckListResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CeCheckListResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CeCheckListResponseDTO
     */
    'checked': boolean;
}
/**
 * 
 * @export
 * @interface CeFileBankResponseDTO
 */
export interface CeFileBankResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CeFileBankResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CeFileBankResponseDTO
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof CeFileBankResponseDTO
     */
    'fileUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CeFileBankResponseDTO
     */
    'small': string;
    /**
     * 
     * @type {string}
     * @memberof CeFileBankResponseDTO
     */
    'medium': string;
    /**
     * 
     * @type {string}
     * @memberof CeFileBankResponseDTO
     */
    'large': string;
}
/**
 * 
 * @export
 * @interface CeIterationDTO
 */
export interface CeIterationDTO {
    /**
     * 
     * @type {string}
     * @memberof CeIterationDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CeIterationDTO
     */
    'approvalStatus': string;
    /**
     * 
     * @type {CeFileBankResponseDTO}
     * @memberof CeIterationDTO
     */
    'technician': CeFileBankResponseDTO;
}
/**
 * 
 * @export
 * @interface CeMaterialItem
 */
export interface CeMaterialItem {
    /**
     * 
     * @type {string}
     * @memberof CeMaterialItem
     */
    'materialId': string;
    /**
     * 
     * @type {number}
     * @memberof CeMaterialItem
     */
    'cost': number;
    /**
     * 
     * @type {string}
     * @memberof CeMaterialItem
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CeServiceResponseDTO
 */
export interface CeServiceResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CeServiceResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CeServiceResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<CeTaskResponseDTO>}
     * @memberof CeServiceResponseDTO
     */
    'tasks': Array<CeTaskResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof CeServiceResponseDTO
     */
    'totalTimeInHours': number;
}
/**
 * 
 * @export
 * @interface CeServiceTimeResponseDTO
 */
export interface CeServiceTimeResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof CeServiceTimeResponseDTO
     */
    'id': number;
    /**
     * 
     * @type {Array<CeServiceResponseDTO>}
     * @memberof CeServiceTimeResponseDTO
     */
    'services': Array<CeServiceResponseDTO>;
}
/**
 * 
 * @export
 * @interface CeTaskIterationResponseDTO
 */
export interface CeTaskIterationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CeTaskIterationResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CeTaskIterationResponseDTO
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof CeTaskIterationResponseDTO
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof CeTaskIterationResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof CeTaskIterationResponseDTO
     */
    'scheduledOn': string;
    /**
     * 
     * @type {string}
     * @memberof CeTaskIterationResponseDTO
     */
    'approvalStatus': string;
    /**
     * 
     * @type {number}
     * @memberof CeTaskIterationResponseDTO
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof CeTaskIterationResponseDTO
     */
    'long': number;
    /**
     * 
     * @type {string}
     * @memberof CeTaskIterationResponseDTO
     */
    'geofencingApproved': string;
    /**
     * 
     * @type {Array<CeFileBankResponseDTO>}
     * @memberof CeTaskIterationResponseDTO
     */
    'fileBank': Array<CeFileBankResponseDTO>;
    /**
     * 
     * @type {Array<CeCheckListResponseDTO>}
     * @memberof CeTaskIterationResponseDTO
     */
    'checkList': Array<CeCheckListResponseDTO>;
    /**
     * 
     * @type {Array<object>}
     * @memberof CeTaskIterationResponseDTO
     */
    'notes': Array<object>;
    /**
     * 
     * @type {CeFileBankResponseDTO}
     * @memberof CeTaskIterationResponseDTO
     */
    'technician': CeFileBankResponseDTO;
}
/**
 * 
 * @export
 * @interface CeTaskResponseDTO
 */
export interface CeTaskResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CeTaskResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CeTaskResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CeTaskResponseDTO
     */
    'checklist': Array<string>;
    /**
     * 
     * @type {Array<CeMaterialItem>}
     * @memberof CeTaskResponseDTO
     */
    'materials': Array<CeMaterialItem>;
    /**
     * 
     * @type {string}
     * @memberof CeTaskResponseDTO
     */
    'measure': string;
    /**
     * 
     * @type {number}
     * @memberof CeTaskResponseDTO
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof CeTaskResponseDTO
     */
    'measurePerHour': number;
    /**
     * 
     * @type {number}
     * @memberof CeTaskResponseDTO
     */
    'totalTimeInHours': number;
    /**
     * 
     * @type {Array<CeTaskIterationResponseDTO>}
     * @memberof CeTaskResponseDTO
     */
    'taskIteration': Array<CeTaskIterationResponseDTO>;
    /**
     * 
     * @type {Array<CeTechnicianResponseDTO>}
     * @memberof CeTaskResponseDTO
     */
    'technicians': Array<CeTechnicianResponseDTO>;
    /**
     * 
     * @type {number}
     * @memberof CeTaskResponseDTO
     */
    'estimate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CeTaskResponseDTO
     */
    'estimateByTechnician'?: number;
    /**
     * 
     * @type {number}
     * @memberof CeTaskResponseDTO
     */
    'minimumResources'?: number;
}
/**
 * 
 * @export
 * @interface CeTechnicianResponseDTO
 */
export interface CeTechnicianResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CeTechnicianResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CeTechnicianResponseDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CeTechnicianResponseDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CeTechnicianResponseDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CeTechnicianResponseDTO
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface ChangeLeadNameDTO
 */
export interface ChangeLeadNameDTO {
    /**
     * 
     * @type {string}
     * @memberof ChangeLeadNameDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ChangeLeadNameDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ChangePasswordBodyDTO
 */
export interface ChangePasswordBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordBodyDTO
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ClientCalendarViewDTO
 */
export interface ClientCalendarViewDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientCalendarViewDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientCalendarViewDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientCalendarViewDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientCalendarViewDTO
     */
    'businessName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientCalendarViewDTO
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface ClientDTO
 */
export interface ClientDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof ClientDTO
     */
    'billingAddress': string;
}
/**
 * 
 * @export
 * @interface ClientIdDto
 */
export interface ClientIdDto {
    /**
     * 
     * @type {string}
     * @memberof ClientIdDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ClientImpersonateDTO
 */
export interface ClientImpersonateDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientImpersonateDTO
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof ClientImpersonateDTO
     */
    'clientId': string;
}
/**
 * 
 * @export
 * @interface ClientListDTO
 */
export interface ClientListDTO {
    /**
     * 
     * @type {Array<ClientResponseDTO>}
     * @memberof ClientListDTO
     */
    'data': Array<ClientResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof ClientListDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface ClientProjectResponse
 */
export interface ClientProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientProjectResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProjectResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProjectResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientProjectResponse
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface ClientResponseDTO
 */
export interface ClientResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'jobTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'businessName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'billingAddress': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientResponseDTO
     */
    'billingEmail': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'timeZone': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'notes': string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientResponseDTO
     */
    'onboardingComplete': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientResponseDTO
     */
    'workOrderEmails': Array<string>;
    /**
     * 
     * @type {Array<AddressResponseDTO>}
     * @memberof ClientResponseDTO
     */
    'addresses'?: Array<AddressResponseDTO>;
    /**
     * 
     * @type {Array<ContactResponseDTO>}
     * @memberof ClientResponseDTO
     */
    'contacts'?: Array<ContactResponseDTO>;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponseDTO
     */
    'relationship'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientResponseDTO
     */
    'archived'?: boolean;
    /**
     * 
     * @type {Array<CommunicationMethodResponseDTO>}
     * @memberof ClientResponseDTO
     */
    'communicationMethods'?: Array<CommunicationMethodResponseDTO>;
}
/**
 * 
 * @export
 * @interface ClientWatcherDTO
 */
export interface ClientWatcherDTO {
    /**
     * 
     * @type {number}
     * @memberof ClientWatcherDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ClientWatcherDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClientWatcherDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ClientWatcherDTO
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {UserDto}
     * @memberof ClientWatcherDTO
     */
    'user': UserDto;
}
/**
 * 
 * @export
 * @interface CommunicationMethodPropertiesDTO
 */
export interface CommunicationMethodPropertiesDTO {
    /**
     * 
     * @type {string}
     * @memberof CommunicationMethodPropertiesDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommunicationMethodPropertiesDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommunicationMethodPropertiesDTO
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CommunicationMethodPropertiesDTO
     */
    'isPrimary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommunicationMethodPropertiesDTO
     */
    'type': CommunicationMethodPropertiesDTOTypeEnum;
}

export const CommunicationMethodPropertiesDTOTypeEnum = {
    Email: 'email',
    Phone: 'phone',
    Fax: 'fax',
    WebAddress: 'web address'
} as const;

export type CommunicationMethodPropertiesDTOTypeEnum = typeof CommunicationMethodPropertiesDTOTypeEnum[keyof typeof CommunicationMethodPropertiesDTOTypeEnum];

/**
 * 
 * @export
 * @interface CommunicationMethodResponseDTO
 */
export interface CommunicationMethodResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CommunicationMethodResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommunicationMethodResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CommunicationMethodResponseDTO
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof CommunicationMethodResponseDTO
     */
    'type': CommunicationMethodResponseDTOTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CommunicationMethodResponseDTO
     */
    'isPrimary': boolean;
}

export const CommunicationMethodResponseDTOTypeEnum = {
    Email: 'email',
    Phone: 'phone',
    Fax: 'fax',
    WebAddress: 'web address'
} as const;

export type CommunicationMethodResponseDTOTypeEnum = typeof CommunicationMethodResponseDTOTypeEnum[keyof typeof CommunicationMethodResponseDTOTypeEnum];

/**
 * 
 * @export
 * @interface CompanyInvitationPaginatedResponseDTO
 */
export interface CompanyInvitationPaginatedResponseDTO {
    /**
     * 
     * @type {Array<CompanyInvitationResponseDTO>}
     * @memberof CompanyInvitationPaginatedResponseDTO
     */
    'data': Array<CompanyInvitationResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof CompanyInvitationPaginatedResponseDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface CompanyInvitationResponseDTO
 */
export interface CompanyInvitationResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitationResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitationResponseDTO
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitationResponseDTO
     */
    'administratorEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitationResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitationResponseDTO
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface CompanyRemindersDTO
 */
export interface CompanyRemindersDTO {
    /**
     * 
     * @type {number}
     * @memberof CompanyRemindersDTO
     */
    'upcomingProjects'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyRemindersDTO
     */
    'proposals'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyRemindersDTO
     */
    'invoices'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyRemindersDTO
     */
    'lunchTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRemindersDTO
     */
    'dinnerTime'?: string;
}
/**
 * 
 * @export
 * @interface CompanyResponseDTO
 */
export interface CompanyResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'companyDescription'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDTO
     */
    'onboardingCompleted': boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'maxHoursWithoutSupervision'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'minimumInvoice'?: number;
    /**
     * 
     * @type {CompanyRemindersDTO}
     * @memberof CompanyResponseDTO
     */
    'companyReminders'?: CompanyRemindersDTO;
    /**
     * 
     * @type {Array<CompanyTaxDTO>}
     * @memberof CompanyResponseDTO
     */
    'taxes'?: Array<CompanyTaxDTO>;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'logo': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDTO
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'extraHourlyRate': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'termRenewalIncrease': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'paymentTerms': number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDTO
     */
    'allowAudit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDTO
     */
    'enforceGeofencing': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'geofencingMeasure': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'geofencingRadius': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyResponseDTO
     */
    'defaultAvailableDays': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'varianceUp': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'varianceDown': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'timeFormat': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'defaultResourceTimeEnd': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'defaultResourceTimeStart': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyResponseDTO
     */
    'fileBank': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDTO
     */
    'onboardingComplete': boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'locationHistoryRetentionDays': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'widgetsAllowed'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDTO
     */
    'officePhoneNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDTO
     */
    'inactiveTimeLimitDays': number;
}
/**
 * 
 * @export
 * @interface CompanyResponseInProfileDTO
 */
export interface CompanyResponseInProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'companyDescription'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseInProfileDTO
     */
    'onboardingCompleted': boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'maxHoursWithoutSupervision'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'minimumInvoice'?: number;
    /**
     * 
     * @type {CompanyRemindersDTO}
     * @memberof CompanyResponseInProfileDTO
     */
    'companyReminders'?: CompanyRemindersDTO;
    /**
     * 
     * @type {Array<CompanyTaxDTO>}
     * @memberof CompanyResponseInProfileDTO
     */
    'taxes'?: Array<CompanyTaxDTO>;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'logo': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseInProfileDTO
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'extraHourlyRate': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'termRenewalIncrease': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'paymentTerms': number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseInProfileDTO
     */
    'allowAudit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseInProfileDTO
     */
    'enforceGeofencing': boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'geofencingMeasure': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'geofencingRadius': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyResponseInProfileDTO
     */
    'defaultAvailableDays': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'varianceUp': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'varianceDown': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'timeFormat': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'defaultResourceTimeEnd': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'defaultResourceTimeStart': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyResponseInProfileDTO
     */
    'fileBank': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseInProfileDTO
     */
    'onboardingComplete': boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'locationHistoryRetentionDays': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'widgetsAllowed'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseInProfileDTO
     */
    'officePhoneNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseInProfileDTO
     */
    'inactiveTimeLimitDays': number;
}
/**
 * 
 * @export
 * @interface CompanyTaxDTO
 */
export interface CompanyTaxDTO {
    /**
     * 
     * @type {string}
     * @memberof CompanyTaxDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyTaxDTO
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyTaxDTO
     */
    'tax': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyTaxDTO
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface CompleteClientOnboardingDTO
 */
export interface CompleteClientOnboardingDTO {
    /**
     * 
     * @type {string}
     * @memberof CompleteClientOnboardingDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteClientOnboardingDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteClientOnboardingDTO
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteClientOnboardingDTO
     */
    'businessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompleteClientOnboardingDTO
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteClientOnboardingDTO
     */
    'billingAddress'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompleteClientOnboardingDTO
     */
    'billingEmail'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompleteClientOnboardingDTO
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompleteClientOnboardingDTO
     */
    'workOrderEmails'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ConfigurationPasswordComplexityDTO
 */
export interface ConfigurationPasswordComplexityDTO {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationPasswordComplexityDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationPasswordComplexityDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationPasswordComplexityDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<PasswordComplexityDTO>}
     * @memberof ConfigurationPasswordComplexityDTO
     */
    'data': Array<PasswordComplexityDTO>;
}
/**
 * 
 * @export
 * @interface ContactCalendarViewDTO
 */
export interface ContactCalendarViewDTO {
    /**
     * 
     * @type {number}
     * @memberof ContactCalendarViewDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ContactCalendarViewDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContactCalendarViewDTO
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface ContactPropertiesDTO
 */
export interface ContactPropertiesDTO {
    /**
     * 
     * @type {number}
     * @memberof ContactPropertiesDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactPropertiesDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContactPropertiesDTO
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface ContactResponseDTO
 */
export interface ContactResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof ContactResponseDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ContactResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContactResponseDTO
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface CopyFileBodyDTO
 */
export interface CopyFileBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof CopyFileBodyDTO
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof CopyFileBodyDTO
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof CopyFileBodyDTO
     */
    'relatedEntity': string;
}
/**
 * 
 * @export
 * @interface CounterOffer
 */
export interface CounterOffer {
    /**
     * 
     * @type {number}
     * @memberof CounterOffer
     */
    'serviceTimeId': number;
    /**
     * 
     * @type {string}
     * @memberof CounterOffer
     */
    'frequency'?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterOffer
     */
    'frequencyWeek'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CounterOffer
     */
    'frequencyDays'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CounterOffer
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterOffer
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface CreateAdHocProposalBodyDTO
 */
export interface CreateAdHocProposalBodyDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'proposalPenalizationPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'proposalDiscountPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'variancePenalizationPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'varianceDiscountPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'paymentDaysTerm'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'discount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'approvalRequired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'autoRenewal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'proposalValue': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'allowCounterOffers'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'termRenewalIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'reminder'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'invoiceFrequency'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'sendOn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'securityDeposit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'depositAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'marginInProposal'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'leadId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'messageTemplateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'statusMessage'?: string;
    /**
     * 
     * @type {Array<ServiceTimePricing>}
     * @memberof CreateAdHocProposalBodyDTO
     */
    'serviceTimePricing': Array<ServiceTimePricing>;
}
/**
 * 
 * @export
 * @interface CreateAdvancedDashboardDto
 */
export interface CreateAdvancedDashboardDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdvancedDashboardDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvancedDashboardDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvancedDashboardDto
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAdvancedDashboardDto
     */
    'icon': string;
}
/**
 * 
 * @export
 * @interface CreateAssignmentsDto
 */
export interface CreateAssignmentsDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAssignmentsDto
     */
    'technicianId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssignmentsDto
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssignmentsDto
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssignmentsDto
     */
    'calendarEventId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAssignmentsDto
     */
    'leadServiceTaskId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAssignmentsDto
     */
    'dateScheduled': string;
}
/**
 * 
 * @export
 * @interface CreateCalendarEventsDTO
 */
export interface CreateCalendarEventsDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEventsDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEventsDTO
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEventsDTO
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEventsDTO
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEventsDTO
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEventsDTO
     */
    'state': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCalendarEventsDTO
     */
    'closed': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEventsDTO
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCalendarEventsDTO
     */
    'clientId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCalendarEventsDTO
     */
    'leadServiceTimeId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCalendarEventsDTO
     */
    'leadServiceId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCalendarEventsDTO
     */
    'requiredAssignments'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCalendarEventsDTO
     */
    'currentAssignments'?: number;
}
/**
 * 
 * @export
 * @interface CreateCategoryDTO
 */
export interface CreateCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateChecklistDTO
 */
export interface CreateChecklistDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateChecklistDTO
     */
    'categories'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateChecklistDTO
     */
    'list'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateClientDTO
 */
export interface CreateClientDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'businessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'billingAddress'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateClientDTO
     */
    'billingEmail'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'timeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateClientDTO
     */
    'workOrderEmails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'type'?: CreateClientDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'status'?: CreateClientDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateClientDTO
     */
    'relationship'?: CreateClientDTORelationshipEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClientDTO
     */
    'archived'?: boolean;
}

export const CreateClientDTOTypeEnum = {
    Client: 'Client',
    Contact: 'Contact',
    Prospect: 'Prospect'
} as const;

export type CreateClientDTOTypeEnum = typeof CreateClientDTOTypeEnum[keyof typeof CreateClientDTOTypeEnum];
export const CreateClientDTOStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type CreateClientDTOStatusEnum = typeof CreateClientDTOStatusEnum[keyof typeof CreateClientDTOStatusEnum];
export const CreateClientDTORelationshipEnum = {
    B2B: 'B2B',
    B2C: 'B2C'
} as const;

export type CreateClientDTORelationshipEnum = typeof CreateClientDTORelationshipEnum[keyof typeof CreateClientDTORelationshipEnum];

/**
 * 
 * @export
 * @interface CreateClientWatcherResponseDTO
 */
export interface CreateClientWatcherResponseDTO {
    /**
     * 
     * @type {ClientIdDto}
     * @memberof CreateClientWatcherResponseDTO
     */
    'client': ClientIdDto;
    /**
     * 
     * @type {UserIdDto}
     * @memberof CreateClientWatcherResponseDTO
     */
    'user': UserIdDto;
    /**
     * 
     * @type {string}
     * @memberof CreateClientWatcherResponseDTO
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateClientWatcherResponseDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CreateClientWatcherResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientWatcherResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CreateCompanyInvitationDTO
 */
export interface CreateCompanyInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInvitationDTO
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInvitationDTO
     */
    'companyDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInvitationDTO
     */
    'administratorEmail': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInvitationDTO
     */
    'administratorFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyInvitationDTO
     */
    'administratorLastName': string;
}
/**
 * 
 * @export
 * @interface CreateInvitationDTO
 */
export interface CreateInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationDTO
     */
    'jobTitle': string;
    /**
     * 
     * @type {number}
     * @memberof CreateInvitationDTO
     */
    'rate': number;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInvitationDTO
     */
    'roleIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInvitationDTO
     */
    'skillIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateInvitationDTO
     */
    'availableHoursPerDay'?: number;
}
/**
 * 
 * @export
 * @interface CreateLeadGeneralInfoDto
 */
export interface CreateLeadGeneralInfoDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLeadGeneralInfoDto
     */
    'clientId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadGeneralInfoDto
     */
    'addressId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadGeneralInfoDto
     */
    'contactId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadGeneralInfoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadGeneralInfoDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadGeneralInfoDto
     */
    'projectType'?: CreateLeadGeneralInfoDtoProjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadGeneralInfoDto
     */
    'projectStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadGeneralInfoDto
     */
    'projectEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeadGeneralInfoDto
     */
    'manualPricing'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadGeneralInfoDto
     */
    'profitPercentage'?: number;
}

export const CreateLeadGeneralInfoDtoProjectTypeEnum = {
    Regular: 'Regular',
    AdHoc: 'Ad-Hoc'
} as const;

export type CreateLeadGeneralInfoDtoProjectTypeEnum = typeof CreateLeadGeneralInfoDtoProjectTypeEnum[keyof typeof CreateLeadGeneralInfoDtoProjectTypeEnum];

/**
 * 
 * @export
 * @interface CreateLeadServiceDto
 */
export interface CreateLeadServiceDto {
    /**
     * 
     * @type {Array<LeadServicePropertiesDto>}
     * @memberof CreateLeadServiceDto
     */
    'services'?: Array<LeadServicePropertiesDto>;
}
/**
 * 
 * @export
 * @interface CreateLeadServiceTaskDto
 */
export interface CreateLeadServiceTaskDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLeadServiceTaskDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadServiceTaskDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeadServiceTaskDto
     */
    'skills'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeadServiceTaskDto
     */
    'checklist'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeadServiceTaskDto
     */
    'materials'?: Array<string>;
    /**
     * 
     * @type {TaskMeasure}
     * @memberof CreateLeadServiceTaskDto
     */
    'measure': TaskMeasure;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTaskDto
     */
    'measurePerHour': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeadServiceTaskDto
     */
    'imageRequired': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTaskDto
     */
    'minimumResources': number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTaskDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTaskDto
     */
    'mediaRate'?: number;
}
/**
 * 
 * @export
 * @interface CreateLeadServiceTimeDto
 */
export interface CreateLeadServiceTimeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLeadServiceTimeDto
     */
    'frequency': CreateLeadServiceTimeDtoFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadServiceTimeDto
     */
    'frequencyWeek'?: CreateLeadServiceTimeDtoFrequencyWeekEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTimeDto
     */
    'frequencyDays'?: CreateLeadServiceTimeDtoFrequencyDaysEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeadServiceTimeDto
     */
    'individualTasks'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeadServiceTimeDto
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadServiceTimeDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadServiceTimeDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadServiceTimeDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadServiceTimeDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTimeDto
     */
    'travelTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTimeDto
     */
    'travelCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTimeDto
     */
    'setupTimeCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTimeDto
     */
    'teardownTimeCost'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeadServiceTimeDto
     */
    'supervisors'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeadServiceTimeDto
     */
    'assignSupervisor'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeadServiceTimeDto
     */
    'isScheduled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateLeadServiceTimeDto
     */
    'supervisorTime'?: number;
}

export const CreateLeadServiceTimeDtoFrequencyEnum = {
    Weekly: 'Weekly',
    Biweekly: 'Biweekly',
    Monthly: 'Monthly'
} as const;

export type CreateLeadServiceTimeDtoFrequencyEnum = typeof CreateLeadServiceTimeDtoFrequencyEnum[keyof typeof CreateLeadServiceTimeDtoFrequencyEnum];
export const CreateLeadServiceTimeDtoFrequencyWeekEnum = {
    First: 'First',
    Second: 'Second',
    Third: 'Third',
    Fourth: 'Fourth'
} as const;

export type CreateLeadServiceTimeDtoFrequencyWeekEnum = typeof CreateLeadServiceTimeDtoFrequencyWeekEnum[keyof typeof CreateLeadServiceTimeDtoFrequencyWeekEnum];
export const CreateLeadServiceTimeDtoFrequencyDaysEnum = {
    NUMBER_null: null
} as const;

export type CreateLeadServiceTimeDtoFrequencyDaysEnum = typeof CreateLeadServiceTimeDtoFrequencyDaysEnum[keyof typeof CreateLeadServiceTimeDtoFrequencyDaysEnum];

/**
 * 
 * @export
 * @interface CreateMaterialDTO
 */
export interface CreateMaterialDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMaterialDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateMessageInProjectDTO
 */
export interface CreateMessageInProjectDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateMessageInProjectDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateMessageInProposalDTO
 */
export interface CreateMessageInProposalDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateMessageInProposalDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateNoteDTO
 */
export interface CreateNoteDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateNoteDTO
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteDTO
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteDTO
     */
    'noteText': string;
    /**
     * 
     * @type {number}
     * @memberof CreateNoteDTO
     */
    'taskIterationId'?: number;
}
/**
 * 
 * @export
 * @interface CreateProposalBodyDTO
 */
export interface CreateProposalBodyDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'proposalPenalizationPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'proposalDiscountPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'variancePenalizationPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'varianceDiscountPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'paymentDaysTerm'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'discount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProposalBodyDTO
     */
    'approvalRequired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProposalBodyDTO
     */
    'autoRenewal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProposalBodyDTO
     */
    'allowCounterOffers'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'termRenewalIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'proposalValue': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'reminder': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProposalBodyDTO
     */
    'invoiceFrequency': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProposalBodyDTO
     */
    'sendOn': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProposalBodyDTO
     */
    'securityDeposit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'depositAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'marginInProposal'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProposalBodyDTO
     */
    'leadId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProposalBodyDTO
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProposalBodyDTO
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateProposalBodyDTO
     */
    'messageTemplateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateProposalBodyDTO
     */
    'statusMessage'?: string;
    /**
     * 
     * @type {Array<ServiceTimePricing>}
     * @memberof CreateProposalBodyDTO
     */
    'serviceTimePricing': Array<ServiceTimePricing>;
}
/**
 * 
 * @export
 * @interface CreateProposalMessagesTemplateDTO
 */
export interface CreateProposalMessagesTemplateDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateProposalMessagesTemplateDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProposalMessagesTemplateDTO
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateRolDTO
 */
export interface CreateRolDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateRolDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRolDTO
     */
    'description': string;
    /**
     * 
     * @type {Array<Permissions>}
     * @memberof CreateRolDTO
     */
    'permissions': Array<Permissions>;
}
/**
 * 
 * @export
 * @interface CreateServiceDTO
 */
export interface CreateServiceDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceDTO
     */
    'setupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceDTO
     */
    'teardownTime'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateServiceDTO
     */
    'tasks': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateSkillDTO
 */
export interface CreateSkillDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateSkillDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSkillDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSkillDTO
     */
    'categoryId'?: string;
}
/**
 * 
 * @export
 * @interface CreateTaskDTO
 */
export interface CreateTaskDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTaskDTO
     */
    'skills'?: Array<string>;
    /**
     * 
     * @type {Array<TaskChecklistDTO>}
     * @memberof CreateTaskDTO
     */
    'taskChecklist'?: Array<TaskChecklistDTO>;
    /**
     * 
     * @type {Array<CreateTaskMaterialDTO>}
     * @memberof CreateTaskDTO
     */
    'materials'?: Array<CreateTaskMaterialDTO>;
    /**
     * 
     * @type {Array<CreateTaskMaterialDTO>}
     * @memberof CreateTaskDTO
     */
    'taskMaterials'?: Array<CreateTaskMaterialDTO>;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskDTO
     */
    'measure': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaskDTO
     */
    'measurePerHour': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaskDTO
     */
    'imageRequired': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateTaskDTO
     */
    'minimumResources': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaskDTO
     */
    'isTemplate'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateTaskMaterialDTO
 */
export interface CreateTaskMaterialDTO {
    /**
     * 
     * @type {object}
     * @memberof CreateTaskMaterialDTO
     */
    'cost': object;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskMaterialDTO
     */
    'materialId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskMaterialDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateTrackingLogDto
 */
export interface CreateTrackingLogDto {
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingLogDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingLogDto
     */
    'current': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingLogDto
     */
    'update': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingLogDto
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingLogDto
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrackingLogDto
     */
    'clientId': string;
}
/**
 * 
 * @export
 * @interface CreateWidgetDataDTO
 */
export interface CreateWidgetDataDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetDataDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetDataDTO
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetDataDTO
     */
    'chartType': CreateWidgetDataDTOChartTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetDataDTO
     */
    'primaryDataType': CreateWidgetDataDTOPrimaryDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetDataDTO
     */
    'secondaryDataType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetDataDTO
     */
    'timeConfig'?: CreateWidgetDataDTOTimeConfigEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateWidgetDataDTO
     */
    'itemCounter': number;
    /**
     * 
     * @type {string}
     * @memberof CreateWidgetDataDTO
     */
    'periodConfig'?: CreateWidgetDataDTOPeriodConfigEnum;
}

export const CreateWidgetDataDTOChartTypeEnum = {
    Line: 'Line',
    Area: 'Area',
    Bar: 'Bar',
    Donut: 'Donut',
    Pie: 'Pie',
    Radar: 'Radar',
    RadialBar: 'RadialBar'
} as const;

export type CreateWidgetDataDTOChartTypeEnum = typeof CreateWidgetDataDTOChartTypeEnum[keyof typeof CreateWidgetDataDTOChartTypeEnum];
export const CreateWidgetDataDTOPrimaryDataTypeEnum = {
    Projects: 'Projects',
    Leads: 'Leads',
    Proposals: 'Proposals',
    Technicians: 'Technicians',
    Users: 'Users',
    Clients: 'Clients',
    Invoices: 'Invoices',
    Tasks: 'Tasks',
    TasksIteration: 'TasksIteration',
    CalendarEventIteration: 'CalendarEventIteration',
    Visits: 'Visits',
    Assignments: 'Assignments',
    Timesheet: 'Timesheet'
} as const;

export type CreateWidgetDataDTOPrimaryDataTypeEnum = typeof CreateWidgetDataDTOPrimaryDataTypeEnum[keyof typeof CreateWidgetDataDTOPrimaryDataTypeEnum];
export const CreateWidgetDataDTOTimeConfigEnum = {
    Weekly: 'Weekly',
    Biweekly: 'Biweekly',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Biannually: 'Biannually',
    Annually: 'Annually'
} as const;

export type CreateWidgetDataDTOTimeConfigEnum = typeof CreateWidgetDataDTOTimeConfigEnum[keyof typeof CreateWidgetDataDTOTimeConfigEnum];
export const CreateWidgetDataDTOPeriodConfigEnum = {
    Today: 'Today',
    ThisWeek: 'ThisWeek',
    ThisMonth: 'ThisMonth',
    ThisYear: 'ThisYear',
    Historical: 'Historical'
} as const;

export type CreateWidgetDataDTOPeriodConfigEnum = typeof CreateWidgetDataDTOPeriodConfigEnum[keyof typeof CreateWidgetDataDTOPeriodConfigEnum];

/**
 * 
 * @export
 * @interface CreateWidgetOnAdvanceDashboardDTO
 */
export interface CreateWidgetOnAdvanceDashboardDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateWidgetOnAdvanceDashboardDTO
     */
    'widgetId': number;
}
/**
 * 
 * @export
 * @interface DatesToAssignDTO
 */
export interface DatesToAssignDTO {
    /**
     * 
     * @type {string}
     * @memberof DatesToAssignDTO
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof DatesToAssignDTO
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof DatesToAssignDTO
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof DatesToAssignDTO
     */
    'calendarEventId': string;
    /**
     * 
     * @type {string}
     * @memberof DatesToAssignDTO
     */
    'assignedId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatesToAssignDTO
     */
    'proyectId'?: string;
}
/**
 * 
 * @export
 * @interface DecisionMessageDTO
 */
export interface DecisionMessageDTO {
    /**
     * 
     * @type {string}
     * @memberof DecisionMessageDTO
     */
    'message'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DecisionMessageDTO
     */
    'requestUpdatedProposal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DecisionMessageDTO
     */
    'signature'?: string;
}
/**
 * 
 * @export
 * @interface DelayedArrivesListDTO
 */
export interface DelayedArrivesListDTO {
    /**
     * ID of the event
     * @type {number}
     * @memberof DelayedArrivesListDTO
     */
    'id': number;
    /**
     * Scheduled start time of the event
     * @type {string}
     * @memberof DelayedArrivesListDTO
     */
    'scheduledStartTime': string;
    /**
     * Actual start time of the event
     * @type {string}
     * @memberof DelayedArrivesListDTO
     */
    'actualStartTime': string;
    /**
     * Variance between scheduled and actual start time in milliseconds
     * @type {number}
     * @memberof DelayedArrivesListDTO
     */
    'variance': number;
    /**
     * Name of the event
     * @type {string}
     * @memberof DelayedArrivesListDTO
     */
    'eventName': string;
    /**
     * Name of the project associated with the event
     * @type {string}
     * @memberof DelayedArrivesListDTO
     */
    'projectName': string;
    /**
     * ID of the calendar event
     * @type {string}
     * @memberof DelayedArrivesListDTO
     */
    'calendarEventId': string;
    /**
     * ID of the project
     * @type {string}
     * @memberof DelayedArrivesListDTO
     */
    'projectId': string;
}
/**
 * 
 * @export
 * @interface DeleteAddressBodyDTO
 */
export interface DeleteAddressBodyDTO {
    /**
     * Address IDs
     * @type {Array<string>}
     * @memberof DeleteAddressBodyDTO
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteCommunicationMethodBodyDTO
 */
export interface DeleteCommunicationMethodBodyDTO {
    /**
     * Communication Method IDs
     * @type {Array<string>}
     * @memberof DeleteCommunicationMethodBodyDTO
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteContactBodyDTO
 */
export interface DeleteContactBodyDTO {
    /**
     * Contact IDs
     * @type {Array<string>}
     * @memberof DeleteContactBodyDTO
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteFileBodyDTO
 */
export interface DeleteFileBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof DeleteFileBodyDTO
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof DeleteFileBodyDTO
     */
    'metaDescription': string;
}
/**
 * 
 * @export
 * @interface DeleteFilesBodyDTO
 */
export interface DeleteFilesBodyDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteFilesBodyDTO
     */
    'fileIds': Array<string>;
}
/**
 * 
 * @export
 * @interface DetailsResponseDTO
 */
export interface DetailsResponseDTO {
    /**
     * 
     * @type {object}
     * @memberof DetailsResponseDTO
     */
    'id': object;
    /**
     * 
     * @type {string}
     * @memberof DetailsResponseDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordCreateDTO
 */
export interface ForgotPasswordCreateDTO {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordCreateDTO
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface GenerateScheduleDTO
 */
export interface GenerateScheduleDTO {
    /**
     * 
     * @type {string}
     * @memberof GenerateScheduleDTO
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateScheduleDTO
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateScheduleDTO
     */
    'frequency': string;
    /**
     * 
     * @type {string}
     * @memberof GenerateScheduleDTO
     */
    'frequencyWeek': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateScheduleDTO
     */
    'frequencyDays': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenerateScheduleDTO
     */
    'projectId': string;
    /**
     * 
     * @type {number}
     * @memberof GenerateScheduleDTO
     */
    'serviceTimeId': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GeofencingMeasureEnum = {
    Kilometer: 'Kilometer',
    Miles: 'Miles'
} as const;

export type GeofencingMeasureEnum = typeof GeofencingMeasureEnum[keyof typeof GeofencingMeasureEnum];


/**
 * 
 * @export
 * @interface GetProposalByTokenDTO
 */
export interface GetProposalByTokenDTO {
    /**
     * 
     * @type {object}
     * @memberof GetProposalByTokenDTO
     */
    'proposal': object;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetProposalByTokenDTO
     */
    'fileBank': Array<object>;
    /**
     * 
     * @type {boolean}
     * @memberof GetProposalByTokenDTO
     */
    'counterOfferSubmitted': boolean;
}
/**
 * 
 * @export
 * @interface InvitationsPaginationResponseDTO
 */
export interface InvitationsPaginationResponseDTO {
    /**
     * 
     * @type {Array<object>}
     * @memberof InvitationsPaginationResponseDTO
     */
    'data': Array<object>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof InvitationsPaginationResponseDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface InvoiceByIdResponseDTO
 */
export interface InvoiceByIdResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceByIdResponseDTO
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'sendDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'dueDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'paidAt': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'rangeStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'rangeEndDate': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceByIdResponseDTO
     */
    'paymentTermsInDays': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'clientName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'projectName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceByIdResponseDTO
     */
    'services': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'subTotal': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceByIdResponseDTO
     */
    'taxes': number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceByIdResponseDTO
     */
    'total': number;
    /**
     * 
     * @type {Array<ActivityLog>}
     * @memberof InvoiceByIdResponseDTO
     */
    'activityLogs': Array<ActivityLog>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceByIdResponseDTO
     */
    'lastSeen': string;
}
/**
 * 
 * @export
 * @interface InvoiceListResponseDTO
 */
export interface InvoiceListResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceListResponseDTO
     */
    'invoiceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponseDTO
     */
    'sendDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponseDTO
     */
    'dueDate': string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceListResponseDTO
     */
    'paidAt': string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceListResponseDTO
     */
    'amount': number;
}
/**
 * 
 * @export
 * @interface LeadCalendarViewDTO
 */
export interface LeadCalendarViewDTO {
    /**
     * 
     * @type {string}
     * @memberof LeadCalendarViewDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LeadCalendarViewDTO
     */
    'projectType': string;
    /**
     * 
     * @type {string}
     * @memberof LeadCalendarViewDTO
     */
    'projectStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof LeadCalendarViewDTO
     */
    'projectEndDate': string;
    /**
     * 
     * @type {ClientCalendarViewDTO}
     * @memberof LeadCalendarViewDTO
     */
    'client': ClientCalendarViewDTO;
    /**
     * 
     * @type {ContactCalendarViewDTO}
     * @memberof LeadCalendarViewDTO
     */
    'contact': ContactCalendarViewDTO;
}
/**
 * 
 * @export
 * @interface LeadGeneralInfoResponseDTO
 */
export interface LeadGeneralInfoResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'projectType': string;
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'projectStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'projectEndDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'manualPricing': boolean;
    /**
     * 
     * @type {number}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'profitPercentage': number;
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'statusMessage': string;
    /**
     * 
     * @type {string}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'source': string;
    /**
     * 
     * @type {ClientResponseDTO}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'client': ClientResponseDTO;
    /**
     * 
     * @type {AddressResponseDTO}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'address': AddressResponseDTO;
    /**
     * 
     * @type {ContactResponseDTO}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'contact': ContactResponseDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'serviceTimes': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'fileBank'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadGeneralInfoResponseDTO
     */
    'proposals': Array<string>;
}
/**
 * 
 * @export
 * @interface LeadIndividualTaskResponseDto
 */
export interface LeadIndividualTaskResponseDto {
    /**
     * 
     * @type {number}
     * @memberof LeadIndividualTaskResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadIndividualTaskResponseDto
     */
    'parentTaskId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadIndividualTaskResponseDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadIndividualTaskResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadIndividualTaskResponseDto
     */
    'measure'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadIndividualTaskResponseDto
     */
    'taskId'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadIndividualTaskResponseDto
     */
    'measurePerHour'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LeadIndividualTaskResponseDto
     */
    'isImageRequired'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LeadIndividualTaskResponseDto
     */
    'minimumResources'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadIndividualTaskResponseDto
     */
    'resourcesAssigned'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadIndividualTaskResponseDto
     */
    'skills'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadIndividualTaskResponseDto
     */
    'checklist'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadIndividualTaskResponseDto
     */
    'materials'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LeadIndividualTaskResponseDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadIndividualTaskResponseDto
     */
    'technicians': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LeadIndividualTaskResponseDto
     */
    'mediaRate'?: number;
}
/**
 * 
 * @export
 * @interface LeadPaginationResponse2DTO
 */
export interface LeadPaginationResponse2DTO {
    /**
     * 
     * @type {Array<LeadGeneralInfoResponseDTO>}
     * @memberof LeadPaginationResponse2DTO
     */
    'data': Array<LeadGeneralInfoResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof LeadPaginationResponse2DTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface LeadPaginationResponseDTO
 */
export interface LeadPaginationResponseDTO {
    /**
     * 
     * @type {Array<object>}
     * @memberof LeadPaginationResponseDTO
     */
    'data': Array<object>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof LeadPaginationResponseDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface LeadProjectResponse
 */
export interface LeadProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof LeadProjectResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LeadProjectResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LeadProjectResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof LeadProjectResponse
     */
    'projectType': string;
    /**
     * 
     * @type {string}
     * @memberof LeadProjectResponse
     */
    'projectStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof LeadProjectResponse
     */
    'projectEndDate': string;
    /**
     * 
     * @type {object}
     * @memberof LeadProjectResponse
     */
    'address': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadProjectResponse
     */
    'supervisors': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadProjectResponse
     */
    'technicians': Array<string>;
    /**
     * 
     * @type {ClientProjectResponse}
     * @memberof LeadProjectResponse
     */
    'client': ClientProjectResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadProjectResponse
     */
    'serviceTimes': Array<string>;
}
/**
 * 
 * @export
 * @interface LeadServicePropertiesDto
 */
export interface LeadServicePropertiesDto {
    /**
     * 
     * @type {string}
     * @memberof LeadServicePropertiesDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LeadServicePropertiesDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadServicePropertiesDto
     */
    'setupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadServicePropertiesDto
     */
    'teardownTime'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadServicePropertiesDto
     */
    'checklist'?: Array<string>;
    /**
     * 
     * @type {Array<CreateLeadServiceTaskDto>}
     * @memberof LeadServicePropertiesDto
     */
    'tasks'?: Array<CreateLeadServiceTaskDto>;
}
/**
 * 
 * @export
 * @interface LeadServiceResponseDto
 */
export interface LeadServiceResponseDto {
    /**
     * 
     * @type {number}
     * @memberof LeadServiceResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceResponseDto
     */
    'parentServiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceResponseDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceResponseDto
     */
    'setupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceResponseDto
     */
    'teardownTime'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadServiceResponseDto
     */
    'checklist'?: Array<string>;
    /**
     * 
     * @type {Array<LeadServiceTaskResponseDto>}
     * @memberof LeadServiceResponseDto
     */
    'tasks'?: Array<LeadServiceTaskResponseDto>;
}
/**
 * 
 * @export
 * @interface LeadServiceTaskResponseDto
 */
export interface LeadServiceTaskResponseDto {
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTaskResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTaskResponseDto
     */
    'parentTaskId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTaskResponseDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTaskResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTaskResponseDto
     */
    'measure'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTaskResponseDto
     */
    'taskId'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTaskResponseDto
     */
    'measurePerHour'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LeadServiceTaskResponseDto
     */
    'isImageRequired'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTaskResponseDto
     */
    'minimumResources'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTaskResponseDto
     */
    'resourcesAssigned'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadServiceTaskResponseDto
     */
    'skills'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadServiceTaskResponseDto
     */
    'checklist'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadServiceTaskResponseDto
     */
    'materials'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTaskResponseDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadServiceTaskResponseDto
     */
    'technicians': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTaskResponseDto
     */
    'mediaRate'?: number;
}
/**
 * 
 * @export
 * @interface LeadServiceTimeIdResponseDTO
 */
export interface LeadServiceTimeIdResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeIdResponseDTO
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface LeadServiceTimeResponseDTO
 */
export interface LeadServiceTimeResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTimeResponseDTO
     */
    'frequency': string;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTimeResponseDTO
     */
    'frequencyWeek': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadServiceTimeResponseDTO
     */
    'frequencyDays': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTimeResponseDTO
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTimeResponseDTO
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTimeResponseDTO
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof LeadServiceTimeResponseDTO
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'travelTime': number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'travelCost': number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'setupTimeCost': number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'teardownTimeCost': number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'individualTaskSetupTime': number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'individualTaskTeardownTime': number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'individualTaskSetupTimeCost': number;
    /**
     * 
     * @type {number}
     * @memberof LeadServiceTimeResponseDTO
     */
    'individualTaskTeardownTimeCost': number;
    /**
     * 
     * @type {boolean}
     * @memberof LeadServiceTimeResponseDTO
     */
    'isScheduled': boolean;
    /**
     * 
     * @type {Array<LeadServiceResponseDto>}
     * @memberof LeadServiceTimeResponseDTO
     */
    'services': Array<LeadServiceResponseDto>;
    /**
     * 
     * @type {Array<LeadIndividualTaskResponseDto>}
     * @memberof LeadServiceTimeResponseDTO
     */
    'individualTasks': Array<LeadIndividualTaskResponseDto>;
    /**
     * 
     * @type {object}
     * @memberof LeadServiceTimeResponseDTO
     */
    'supervisor': object;
    /**
     * 
     * @type {boolean}
     * @memberof LeadServiceTimeResponseDTO
     */
    'assignSupervisor': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeadServiceTimeResponseDTO
     */
    'availability': Array<string>;
}
/**
 * 
 * @export
 * @interface LocationHistoryCreationDTO
 */
export interface LocationHistoryCreationDTO {
    /**
     * 
     * @type {number}
     * @memberof LocationHistoryCreationDTO
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof LocationHistoryCreationDTO
     */
    'longitude': number;
    /**
     * 
     * @type {TechnicianMainActionsEnum}
     * @memberof LocationHistoryCreationDTO
     */
    'action': TechnicianMainActionsEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationHistoryCreationDTO
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof LocationHistoryCreationDTO
     */
    'calendarEventId': string;
    /**
     * 
     * @type {number}
     * @memberof LocationHistoryCreationDTO
     */
    'taskIterationId': number;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    'rememberMe'?: boolean;
}
/**
 * 
 * @export
 * @interface LoginResponseDTO
 */
export interface LoginResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    'accessToken': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponseDTO
     */
    'requestTwoFactor': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDTO
     */
    'tfaMethod': string;
}
/**
 * 
 * @export
 * @interface MaterialDetailsResponseDTO
 */
export interface MaterialDetailsResponseDTO {
    /**
     * 
     * @type {object}
     * @memberof MaterialDetailsResponseDTO
     */
    'id': object;
    /**
     * 
     * @type {string}
     * @memberof MaterialDetailsResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDetailsResponseDTO
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface MaterialListDTO
 */
export interface MaterialListDTO {
    /**
     * 
     * @type {Array<MaterialResponseDTO>}
     * @memberof MaterialListDTO
     */
    'data': Array<MaterialResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof MaterialListDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface MaterialResponseDTO
 */
export interface MaterialResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof MaterialResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface MeasureResponseDTO
 */
export interface MeasureResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof MeasureResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MeasureResponseDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface NoteResponseDTO
 */
export interface NoteResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDTO
     */
    'noteText': string;
    /**
     * 
     * @type {object}
     * @memberof NoteResponseDTO
     */
    'user': object;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'itemsPerPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'totalItems': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'currentPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'previousPage': number | null;
    /**
     * 
     * @type {number}
     * @memberof PaginationResponse
     */
    'nextPage': number | null;
}
/**
 * 
 * @export
 * @interface PasswordComplexityDTO
 */
export interface PasswordComplexityDTO {
    /**
     * 
     * @type {string}
     * @memberof PasswordComplexityDTO
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordComplexityDTO
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordComplexityDTO
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof PasswordComplexityDTO
     */
    'regex': string;
}
/**
 * 
 * @export
 * @interface PermissionResponseDTO
 */
export interface PermissionResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof PermissionResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponseDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Permissions = {
    SuperUser: 'SuperUser',
    Administrator: 'Administrator',
    Technician: 'Technician',
    Operator: 'Operator',
    Finance: 'Finance',
    ModifyConfiguration: 'ModifyConfiguration',
    Viewdocument: 'Viewdocument',
    Adddocument: 'Adddocument',
    Searchdocument: 'Searchdocument',
    Deletedocument: 'Deletedocument',
    Chat: 'Chat',
    CreateChatGroup: 'CreateChatGroup',
    ManageUsers: 'ManageUsers',
    CreateUsers: 'CreateUsers',
    ViewCalendar: 'ViewCalendar',
    ViewProjects: 'ViewProjects',
    ViewFinancialdata: 'ViewFinancialdata',
    ViewDashboard: 'ViewDashboard',
    CreateLead: 'CreateLead',
    CreateProject: 'CreateProject',
    CreateTask: 'CreateTask',
    DeleteTask: 'DeleteTask',
    ViewTask: 'ViewTask',
    CreateContract: 'CreateContract',
    EditLead: 'EditLead',
    EditProject: 'EditProject',
    CloseProject: 'CloseProject',
    DeleteProject: 'DeleteProject',
    ViewInvoices: 'ViewInvoices',
    EditInvoice: 'EditInvoice',
    Editclientprofile: 'Editclientprofile',
    DeleteClient: 'DeleteClient',
    ViewTimeKeeping: 'ViewTimeKeeping',
    EditTime: 'EditTime',
    ApproveTime: 'ApproveTime',
    RejectTime: 'RejectTime',
    CreateService: 'CreateService',
    EditService: 'EditService',
    DeleteService: 'DeleteService',
    ViewService: 'ViewService',
    EditTask: 'EditTask',
    CreateSkill: 'CreateSkill',
    EditSkill: 'EditSkill',
    DeleteSkill: 'DeleteSkill',
    ViewSkill: 'ViewSkill',
    CreateCategory: 'CreateCategory',
    EditCategory: 'EditCategory',
    DeleteCategory: 'DeleteCategory',
    ViewCategory: 'ViewCategory',
    CreateCompany: 'CreateCompany',
    EditCompany: 'EditCompany',
    DeleteCompany: 'DeleteCompany',
    ViewCompany: 'ViewCompany',
    CreateMaterial: 'CreateMaterial',
    EditMaterial: 'EditMaterial',
    DeleteMaterial: 'DeleteMaterial',
    ViewMaterial: 'ViewMaterial',
    CreateClient: 'CreateClient',
    EditClient: 'EditClient',
    ViewClient: 'ViewClient',
    UploadCompanyFiles: 'UploadCompanyFiles',
    ViewLead: 'ViewLead',
    CreateProposal: 'CreateProposal',
    ViewProposal: 'ViewProposal',
    DeleteProposal: 'DeleteProposal',
    ApproveTimesheet: 'ApproveTimesheet',
    ViewTimeSheet: 'ViewTimeSheet',
    Supervisor: 'Supervisor',
    ViewClientWatchers: 'ViewClientWatchers',
    DeleteClientWatchers: 'DeleteClientWatchers',
    PostClientWatchers: 'PostClientWatchers'
} as const;

export type Permissions = typeof Permissions[keyof typeof Permissions];


/**
 * 
 * @export
 * @interface ProfileResponseDTO
 */
export interface ProfileResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'timeZone': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'timezoneIana': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileResponseDTO
     */
    'archived': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'availableTimeStart': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'availableTimeEnd': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileResponseDTO
     */
    'availableDays': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'jobTitle': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ProfileResponseDTO
     */
    'rate': number;
    /**
     * 
     * @type {CompanyResponseInProfileDTO}
     * @memberof ProfileResponseDTO
     */
    'company': CompanyResponseInProfileDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileResponseDTO
     */
    'roles': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileResponseDTO
     */
    'skills': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProfileResponseDTO
     */
    'userRoles': Array<string>;
    /**
     * 
     * @type {ProfileResponseDTOMetadata}
     * @memberof ProfileResponseDTO
     */
    'metadata': ProfileResponseDTOMetadata;
    /**
     * 
     * @type {object}
     * @memberof ProfileResponseDTO
     */
    'twoFactor': object;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTO
     */
    'timeFormat': string;
}
/**
 * 
 * @export
 * @interface ProfileResponseDTOMetadata
 */
export interface ProfileResponseDTOMetadata {
    /**
     * 
     * @type {Array<ProfileResponseDTOMetadataImagesInner>}
     * @memberof ProfileResponseDTOMetadata
     */
    'images'?: Array<ProfileResponseDTOMetadataImagesInner>;
}
/**
 * 
 * @export
 * @interface ProfileResponseDTOMetadataImagesInner
 */
export interface ProfileResponseDTOMetadataImagesInner {
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTOMetadataImagesInner
     */
    'type'?: ProfileResponseDTOMetadataImagesInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponseDTOMetadataImagesInner
     */
    'value'?: string;
}

export const ProfileResponseDTOMetadataImagesInnerTypeEnum = {
    Base64: 'base64',
    Url: 'url'
} as const;

export type ProfileResponseDTOMetadataImagesInnerTypeEnum = typeof ProfileResponseDTOMetadataImagesInnerTypeEnum[keyof typeof ProfileResponseDTOMetadataImagesInnerTypeEnum];

/**
 * 
 * @export
 * @interface ProjectAssignResourcesDTO
 */
export interface ProjectAssignResourcesDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectAssignResourcesDTO
     */
    'technicianId': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectAssignResourcesDTO
     */
    'leadServiceTaskId': number;
    /**
     * 
     * @type {Array<DatesToAssignDTO>}
     * @memberof ProjectAssignResourcesDTO
     */
    'datesToAssign': Array<DatesToAssignDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProjectAssignResourcesDTO
     */
    'reason'?: string;
}
/**
 * 
 * @export
 * @interface ProjectDataResponseDTO
 */
export interface ProjectDataResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectDataResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {ClientDTO}
     * @memberof ProjectDataResponseDTO
     */
    'client': ClientDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectDataResponseDTO
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDataResponseDTO
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDataResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDataResponseDTO
     */
    'type': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDataResponseDTO
     */
    'assignments': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof ProjectDataResponseDTO
     */
    'supervisor'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDataResponseDTO
     */
    'technicians': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDataResponseDTO
     */
    'tasks': Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectEnforceGeofencingDTO
 */
export interface ProjectEnforceGeofencingDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectEnforceGeofencingDTO
     */
    'enforceGeofencing': boolean;
}
/**
 * 
 * @export
 * @interface ProjectListResponseDTO
 */
export interface ProjectListResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseDTO
     */
    'leadName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseDTO
     */
    'leadProjectStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseDTO
     */
    'leadProjectEndDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseDTO
     */
    'clientFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseDTO
     */
    'clientLastName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseDTO
     */
    'clientCompanyName': string;
    /**
     * 
     * @type {Array<AssignmentsCounts>}
     * @memberof ProjectListResponseDTO
     */
    'assignments'?: Array<AssignmentsCounts>;
}
/**
 * 
 * @export
 * @interface ProjectNoteDTO
 */
export interface ProjectNoteDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectNoteDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNoteDTO
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ProjectNotesResponseDTO
 */
export interface ProjectNotesResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectNotesResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNotesResponseDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNotesResponseDTO
     */
    'noteText': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNotesResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectNotesResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {UserBasicInformationDTO}
     * @memberof ProjectNotesResponseDTO
     */
    'operator': UserBasicInformationDTO;
}
/**
 * 
 * @export
 * @interface ProjectPaginationResponse
 */
export interface ProjectPaginationResponse {
    /**
     * 
     * @type {Array<ProjectListResponseDTO>}
     * @memberof ProjectPaginationResponse
     */
    'data': Array<ProjectListResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof ProjectPaginationResponse
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface ProjectResourcesDTO
 */
export interface ProjectResourcesDTO {
    /**
     * Service skills
     * @type {Array<string>}
     * @memberof ProjectResourcesDTO
     */
    'skills': Array<string>;
    /**
     * 
     * @type {Array<DatesToAssignDTO>}
     * @memberof ProjectResourcesDTO
     */
    'dates': Array<DatesToAssignDTO>;
    /**
     * Service Time ID
     * @type {number}
     * @memberof ProjectResourcesDTO
     */
    'serviceTimeId': number;
    /**
     * Service Task ID
     * @type {number}
     * @memberof ProjectResourcesDTO
     */
    'serviceTaskId': number;
}
/**
 * 
 * @export
 * @interface ProjectResponseCalendarViewDTO
 */
export interface ProjectResponseCalendarViewDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseCalendarViewDTO
     */
    'id': string;
    /**
     * 
     * @type {ProposalCalendarViewDTO}
     * @memberof ProjectResponseCalendarViewDTO
     */
    'proposal': ProposalCalendarViewDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseCalendarViewDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseCalendarViewDTO
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ProjectResponseDTO
 */
export interface ProjectResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectResponseDTO
     */
    'enforceGeofencing': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectResponseDTO
     */
    'notes': Array<string>;
    /**
     * 
     * @type {ProposalProjectResponse}
     * @memberof ProjectResponseDTO
     */
    'proposal': ProposalProjectResponse;
}
/**
 * 
 * @export
 * @interface ProjectUnassignmentParamsDTO
 */
export interface ProjectUnassignmentParamsDTO {
    /**
     * Project UUID
     * @type {Array<string>}
     * @memberof ProjectUnassignmentParamsDTO
     */
    'assignmentsIds': Array<string>;
}
/**
 * @type ProjectUpdateProjectStatusRequest
 * @export
 */
export type ProjectUpdateProjectStatusRequest = UpdateCancellationRequestedProjectStatusDTO | UpdateOnHoldProjectStatusDTO;

/**
 * 
 * @export
 * @interface ProposalCalendarViewDTO
 */
export interface ProposalCalendarViewDTO {
    /**
     * 
     * @type {string}
     * @memberof ProposalCalendarViewDTO
     */
    'id': string;
    /**
     * 
     * @type {LeadCalendarViewDTO}
     * @memberof ProposalCalendarViewDTO
     */
    'lead': LeadCalendarViewDTO;
}
/**
 * 
 * @export
 * @interface ProposalCounterOfferDTO
 */
export interface ProposalCounterOfferDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProposalCounterOfferDTO
     */
    'deleted': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProposalCounterOfferDTO
     */
    'notModified': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProposalCounterOfferDTO
     */
    'action': string;
    /**
     * 
     * @type {Array<CounterOffer>}
     * @memberof ProposalCounterOfferDTO
     */
    'counterOffer': Array<CounterOffer>;
}
/**
 * @type ProposalCreateProposalRequest
 * @export
 */
export type ProposalCreateProposalRequest = CreateAdHocProposalBodyDTO | CreateProposalBodyDTO;

/**
 * 
 * @export
 * @interface ProposalDetailsResponseDTO
 */
export interface ProposalDetailsResponseDTO {
    /**
     * 
     * @type {object}
     * @memberof ProposalDetailsResponseDTO
     */
    'id': object;
    /**
     * 
     * @type {string}
     * @memberof ProposalDetailsResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProposalDetailsResponseDTO
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ProposalDetailsResponseDTO
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof ProposalDetailsResponseDTO
     */
    'addedCounter': number;
}
/**
 * 
 * @export
 * @interface ProposalProjectResponse
 */
export interface ProposalProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ProposalProjectResponse
     */
    'id': string;
    /**
     * 
     * @type {LeadProjectResponse}
     * @memberof ProposalProjectResponse
     */
    'lead': LeadProjectResponse;
}
/**
 * 
 * @export
 * @interface ProposalTemplateMessageResponseDTO
 */
export interface ProposalTemplateMessageResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof ProposalTemplateMessageResponseDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProposalTemplateMessageResponseDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ProposalTemplateMessageResponseDTO
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ProposalTemplateMessageResponseDTO
     */
    'addedCounter': number;
    /**
     * 
     * @type {string}
     * @memberof ProposalTemplateMessageResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProposalTemplateMessageResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProposalTemplateMessageResponseDTO
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface ProposalTemplateMessagesPaginatedResponseDTO
 */
export interface ProposalTemplateMessagesPaginatedResponseDTO {
    /**
     * 
     * @type {Array<ProposalTemplateMessageResponseDTO>}
     * @memberof ProposalTemplateMessagesPaginatedResponseDTO
     */
    'data': Array<ProposalTemplateMessageResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof ProposalTemplateMessagesPaginatedResponseDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface ResendCompanyInvitationDTO
 */
export interface ResendCompanyInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof ResendCompanyInvitationDTO
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResendCompanyInvitationDTO
     */
    'companyDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResendCompanyInvitationDTO
     */
    'administratorFirstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResendCompanyInvitationDTO
     */
    'administratorLastName'?: string;
}
/**
 * 
 * @export
 * @interface ResendInvitationDTO
 */
export interface ResendInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof ResendInvitationDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResendInvitationDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResendInvitationDTO
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResendInvitationDTO
     */
    'rate'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResendInvitationDTO
     */
    'roleIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResendInvitationDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResendInvitationDTO
     */
    'skillIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ResetPasswordTokenDTO
 */
export interface ResetPasswordTokenDTO {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordTokenDTO
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface RolPermissionResponseDTO
 */
export interface RolPermissionResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RolPermissionResponseDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface RolResponseDTO
 */
export interface RolResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof RolResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RolResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RolResponseDTO
     */
    'description': string;
    /**
     * 
     * @type {Array<RolPermissionResponseDTO>}
     * @memberof RolResponseDTO
     */
    'permissions'?: Array<RolPermissionResponseDTO>;
}
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RolesEnum = {
    SuperUser: 'SuperUser',
    Administrator: 'Administrator',
    Operations: 'Operations',
    Finance: 'Finance',
    Technician: 'Technician',
    Supervisor: 'Supervisor',
    Sales: 'Sales',
    BusinessDevelopment: 'BusinessDevelopment'
} as const;

export type RolesEnum = typeof RolesEnum[keyof typeof RolesEnum];


/**
 * 
 * @export
 * @interface SearchResponseDTO
 */
export interface SearchResponseDTO {
    /**
     * 
     * @type {Array<ServiceDetailsResponseDTO>}
     * @memberof SearchResponseDTO
     */
    'services': Array<ServiceDetailsResponseDTO>;
    /**
     * 
     * @type {Array<TaskDetailsResponseDTO>}
     * @memberof SearchResponseDTO
     */
    'tasks': Array<TaskDetailsResponseDTO>;
    /**
     * 
     * @type {Array<SkillDetailsResponseDTO>}
     * @memberof SearchResponseDTO
     */
    'skills': Array<SkillDetailsResponseDTO>;
    /**
     * 
     * @type {Array<CategoryDetailsResponseDTO>}
     * @memberof SearchResponseDTO
     */
    'categories': Array<CategoryDetailsResponseDTO>;
    /**
     * 
     * @type {Array<MaterialDetailsResponseDTO>}
     * @memberof SearchResponseDTO
     */
    'materials': Array<MaterialDetailsResponseDTO>;
    /**
     * 
     * @type {Array<ProposalDetailsResponseDTO>}
     * @memberof SearchResponseDTO
     */
    'proposalMessageTemplate': Array<ProposalDetailsResponseDTO>;
    /**
     * 
     * @type {Array<DetailsResponseDTO>}
     * @memberof SearchResponseDTO
     */
    'users': Array<DetailsResponseDTO>;
    /**
     * 
     * @type {Array<DetailsResponseDTO>}
     * @memberof SearchResponseDTO
     */
    'companies': Array<DetailsResponseDTO>;
}
/**
 * 
 * @export
 * @interface SecondaryDataTypesResponseDTO
 */
export interface SecondaryDataTypesResponseDTO {
    /**
     * Secondary data types available for selection
     * @type {Array<string>}
     * @memberof SecondaryDataTypesResponseDTO
     */
    'secondaryDataTypes': Array<string>;
}
/**
 * 
 * @export
 * @interface ServiceDetailsResponseDTO
 */
export interface ServiceDetailsResponseDTO {
    /**
     * 
     * @type {object}
     * @memberof ServiceDetailsResponseDTO
     */
    'id': object;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetailsResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceDetailsResponseDTO
     */
    'description': string;
    /**
     * 
     * @type {Array<ServiceTaskResponseDTO>}
     * @memberof ServiceDetailsResponseDTO
     */
    'tasks'?: Array<ServiceTaskResponseDTO>;
}
/**
 * @type ServiceGetOneById200Response
 * @export
 */
export type ServiceGetOneById200Response = ServiceResponseDTO | ServiceTaskSkillCategoriesResponseDto | ServiceTaskSkillsResponseDto;

/**
 * 
 * @export
 * @interface ServicePaginatedResponseDTO
 */
export interface ServicePaginatedResponseDTO {
    /**
     * 
     * @type {Array<ServiceResponseDTO>}
     * @memberof ServicePaginatedResponseDTO
     */
    'data': Array<ServiceResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof ServicePaginatedResponseDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface ServiceResponseDTO
 */
export interface ServiceResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ServiceResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceResponseDTO
     */
    'setupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResponseDTO
     */
    'teardownTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<TaskResponseDTO>}
     * @memberof ServiceResponseDTO
     */
    'tasks'?: Array<TaskResponseDTO>;
}
/**
 * 
 * @export
 * @interface ServiceTaskResponseDTO
 */
export interface ServiceTaskResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<TaskSkillResponseDTO>}
     * @memberof ServiceTaskResponseDTO
     */
    'skills'?: Array<TaskSkillResponseDTO>;
    /**
     * 
     * @type {Array<CreateTaskMaterialDTO>}
     * @memberof ServiceTaskResponseDTO
     */
    'materials'?: Array<CreateTaskMaterialDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceTaskResponseDTO
     */
    'isTemplate'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ServiceTaskResponseDTO
     */
    'measure'?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof ServiceTaskResponseDTO
     */
    'taskChecklist'?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceTaskResponseDTO
     */
    'fileBank': Array<string>;
}
/**
 * 
 * @export
 * @interface ServiceTaskSkillCategoriesResponseDto
 */
export interface ServiceTaskSkillCategoriesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillCategoriesResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillCategoriesResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillCategoriesResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceTaskSkillCategoriesResponseDto
     */
    'setupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTaskSkillCategoriesResponseDto
     */
    'teardownTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillCategoriesResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillCategoriesResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<TaskSkillWithCategoriesResponseDto>}
     * @memberof ServiceTaskSkillCategoriesResponseDto
     */
    'tasks'?: Array<TaskSkillWithCategoriesResponseDto>;
}
/**
 * 
 * @export
 * @interface ServiceTaskSkillsResponseDto
 */
export interface ServiceTaskSkillsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillsResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillsResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillsResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceTaskSkillsResponseDto
     */
    'setupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTaskSkillsResponseDto
     */
    'teardownTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillsResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTaskSkillsResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<TaskWithSkillsResponseDto>}
     * @memberof ServiceTaskSkillsResponseDto
     */
    'tasks'?: Array<TaskWithSkillsResponseDto>;
}
/**
 * 
 * @export
 * @interface ServiceTimePricing
 */
export interface ServiceTimePricing {
    /**
     * 
     * @type {number}
     * @memberof ServiceTimePricing
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTimePricing
     */
    'pricePerVisit': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTimePricing
     */
    'margin': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTimePricing
     */
    'markup': number;
    /**
     * 
     * @type {number}
     * @memberof ServiceTimePricing
     */
    'baseCost': number;
}
/**
 * 
 * @export
 * @interface SkillDetailsResponseDTO
 */
export interface SkillDetailsResponseDTO {
    /**
     * 
     * @type {object}
     * @memberof SkillDetailsResponseDTO
     */
    'id': object;
    /**
     * 
     * @type {string}
     * @memberof SkillDetailsResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SkillDetailsResponseDTO
     */
    'description': string;
    /**
     * 
     * @type {CategoryResponseDTO}
     * @memberof SkillDetailsResponseDTO
     */
    'category': CategoryResponseDTO;
}
/**
 * 
 * @export
 * @interface SkillDto
 */
export interface SkillDto {
    /**
     * The count of how many times this skill is matched or unmatched
     * @type {string}
     * @memberof SkillDto
     */
    'count': string;
    /**
     * The name of the skill
     * @type {string}
     * @memberof SkillDto
     */
    'name': string;
    /**
     * The unique identifier of the skill
     * @type {string}
     * @memberof SkillDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface SkillPaginatedResponseDTO
 */
export interface SkillPaginatedResponseDTO {
    /**
     * 
     * @type {Array<SkillResponseDTO>}
     * @memberof SkillPaginatedResponseDTO
     */
    'data': Array<SkillResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof SkillPaginatedResponseDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface SkillResourceDTO
 */
export interface SkillResourceDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof SkillResourceDTO
     */
    'skills': Array<string>;
}
/**
 * 
 * @export
 * @interface SkillResponseDTO
 */
export interface SkillResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof SkillResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SkillResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SkillResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SkillResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SkillResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {CategoryResponseDTO}
     * @memberof SkillResponseDTO
     */
    'category'?: CategoryResponseDTO;
}
/**
 * 
 * @export
 * @interface SkillWithCategoriesResponseDto
 */
export interface SkillWithCategoriesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SkillWithCategoriesResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SkillWithCategoriesResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SkillWithCategoriesResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof SkillWithCategoriesResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SkillWithCategoriesResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {CategoryResponseDTO}
     * @memberof SkillWithCategoriesResponseDto
     */
    'category'?: CategoryResponseDTO;
}
/**
 * 
 * @export
 * @interface SkillsDataResponseDTO
 */
export interface SkillsDataResponseDTO {
    /**
     * List of skills that are matched
     * @type {Array<SkillDto>}
     * @memberof SkillsDataResponseDTO
     */
    'matchSkills': Array<SkillDto>;
    /**
     * List of skills that are unmatched
     * @type {Array<SkillDto>}
     * @memberof SkillsDataResponseDTO
     */
    'unMatchSkills': Array<SkillDto>;
}
/**
 * 
 * @export
 * @interface SwapTaskTechnicianDTO
 */
export interface SwapTaskTechnicianDTO {
    /**
     * 
     * @type {string}
     * @memberof SwapTaskTechnicianDTO
     */
    'previousTechnician': string;
    /**
     * 
     * @type {string}
     * @memberof SwapTaskTechnicianDTO
     */
    'newTechnician': string;
}
/**
 * 
 * @export
 * @interface TaskChecklistDTO
 */
export interface TaskChecklistDTO {
    /**
     * 
     * @type {string}
     * @memberof TaskChecklistDTO
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskChecklistDTO
     */
    'completed'?: boolean;
}
/**
 * 
 * @export
 * @interface TaskDecisionBodyDTO
 */
export interface TaskDecisionBodyDTO {
    /**
     * 
     * @type {number}
     * @memberof TaskDecisionBodyDTO
     */
    'actualTotal': number;
    /**
     * 
     * @type {string}
     * @memberof TaskDecisionBodyDTO
     */
    'approvalStatus': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDecisionBodyDTO
     */
    'typeData': string;
}
/**
 * 
 * @export
 * @interface TaskDetailsResponseDTO
 */
export interface TaskDetailsResponseDTO {
    /**
     * 
     * @type {object}
     * @memberof TaskDetailsResponseDTO
     */
    'id': object;
    /**
     * 
     * @type {string}
     * @memberof TaskDetailsResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TaskDetailsResponseDTO
     */
    'description': string;
    /**
     * 
     * @type {Array<TaskSkillResponseDTO>}
     * @memberof TaskDetailsResponseDTO
     */
    'skills'?: Array<TaskSkillResponseDTO>;
}
/**
 * 
 * @export
 * @interface TaskIterationChecklistDTO
 */
export interface TaskIterationChecklistDTO {
    /**
     * 
     * @type {boolean}
     * @memberof TaskIterationChecklistDTO
     */
    'complete': boolean;
}
/**
 * 
 * @export
 * @interface TaskIterationStatus
 */
export interface TaskIterationStatus {
    /**
     * 
     * @type {string}
     * @memberof TaskIterationStatus
     */
    'status': TaskIterationStatusStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TaskIterationStatus
     */
    'lat': number;
    /**
     * 
     * @type {number}
     * @memberof TaskIterationStatus
     */
    'long': number;
    /**
     * 
     * @type {boolean}
     * @memberof TaskIterationStatus
     */
    'geofencingApproved': boolean;
}

export const TaskIterationStatusStatusEnum = {
    Created: 'created',
    InProgress: 'inProgress',
    Completed: 'completed'
} as const;

export type TaskIterationStatusStatusEnum = typeof TaskIterationStatusStatusEnum[keyof typeof TaskIterationStatusStatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const TaskMeasure = {
    SquareFeet: 'Square feet',
    Unit: 'Unit'
} as const;

export type TaskMeasure = typeof TaskMeasure[keyof typeof TaskMeasure];


/**
 * 
 * @export
 * @interface TaskPaginatedResponseDTO
 */
export interface TaskPaginatedResponseDTO {
    /**
     * 
     * @type {Array<TaskResponseDTO>}
     * @memberof TaskPaginatedResponseDTO
     */
    'data': Array<TaskResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof TaskPaginatedResponseDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface TaskResponseDTO
 */
export interface TaskResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<TaskSkillResponseDTO>}
     * @memberof TaskResponseDTO
     */
    'skills'?: Array<TaskSkillResponseDTO>;
    /**
     * 
     * @type {Array<CreateTaskMaterialDTO>}
     * @memberof TaskResponseDTO
     */
    'materials'?: Array<CreateTaskMaterialDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof TaskResponseDTO
     */
    'isTemplate'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof TaskResponseDTO
     */
    'measure'?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof TaskResponseDTO
     */
    'taskChecklist'?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskResponseDTO
     */
    'fileBank': Array<string>;
}
/**
 * 
 * @export
 * @interface TaskSkillResponseDTO
 */
export interface TaskSkillResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof TaskSkillResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskSkillResponseDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TaskSkillResponseDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskSkillResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaskSkillResponseDTO
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TaskSkillWithCategoriesResponseDto
 */
export interface TaskSkillWithCategoriesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<SkillWithCategoriesResponseDto>}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'skills'?: Array<SkillWithCategoriesResponseDto>;
    /**
     * 
     * @type {Array<CreateTaskMaterialDTO>}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'materials'?: Array<CreateTaskMaterialDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'isTemplate'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'measure'?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'taskChecklist'?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskSkillWithCategoriesResponseDto
     */
    'fileBank': Array<string>;
}
/**
 * 
 * @export
 * @interface TaskWithSkillsResponseDto
 */
export interface TaskWithSkillsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TaskWithSkillsResponseDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskWithSkillsResponseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TaskWithSkillsResponseDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskWithSkillsResponseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TaskWithSkillsResponseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {Array<TaskSkillResponseDTO>}
     * @memberof TaskWithSkillsResponseDto
     */
    'skills'?: Array<TaskSkillResponseDTO>;
    /**
     * 
     * @type {Array<CreateTaskMaterialDTO>}
     * @memberof TaskWithSkillsResponseDto
     */
    'materials'?: Array<CreateTaskMaterialDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof TaskWithSkillsResponseDto
     */
    'isTemplate'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof TaskWithSkillsResponseDto
     */
    'measure'?: object;
    /**
     * 
     * @type {Array<object>}
     * @memberof TaskWithSkillsResponseDto
     */
    'taskChecklist'?: Array<object>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskWithSkillsResponseDto
     */
    'fileBank': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TechnicianMainActionsEnum = {
    Arrived: 'Arrived',
    Completed: 'Completed',
    ClockOut: 'Clock Out',
    OnRoad: 'On Road',
    StartTask: 'Start task'
} as const;

export type TechnicianMainActionsEnum = typeof TechnicianMainActionsEnum[keyof typeof TechnicianMainActionsEnum];


/**
 * 
 * @export
 * @interface TechnicianSearchResponseDTO
 */
export interface TechnicianSearchResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof TechnicianSearchResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianSearchResponseDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianSearchResponseDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianSearchResponseDTO
     */
    'lastName': string;
    /**
     * 
     * @type {number}
     * @memberof TechnicianSearchResponseDTO
     */
    'rate': number;
    /**
     * 
     * @type {string}
     * @memberof TechnicianSearchResponseDTO
     */
    'availableTimeStart': string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianSearchResponseDTO
     */
    'availableTimeEnd': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TechnicianSearchResponseDTO
     */
    'availableDays': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TechnicianSearchResponseDTO
     */
    'jobTitle': string;
    /**
     * 
     * @type {number}
     * @memberof TechnicianSearchResponseDTO
     */
    'availableHours': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TechnicianSearchResponseDTO
     */
    'skills': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TimeFormatEnum = {
    _24h: '24h',
    _12h: '12h'
} as const;

export type TimeFormatEnum = typeof TimeFormatEnum[keyof typeof TimeFormatEnum];


/**
 * 
 * @export
 * @interface TrackingLogListDTO
 */
export interface TrackingLogListDTO {
    /**
     * 
     * @type {Array<TrackingLogResponseDTO>}
     * @memberof TrackingLogListDTO
     */
    'data': Array<TrackingLogResponseDTO>;
    /**
     * 
     * @type {PaginationResponse}
     * @memberof TrackingLogListDTO
     */
    'pagination': PaginationResponse;
}
/**
 * 
 * @export
 * @interface TrackingLogResponseDTO
 */
export interface TrackingLogResponseDTO {
    /**
     * 
     * @type {number}
     * @memberof TrackingLogResponseDTO
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'current': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'update': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'entity': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'companyId': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'clientId': string;
    /**
     * 
     * @type {string}
     * @memberof TrackingLogResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {object}
     * @memberof TrackingLogResponseDTO
     */
    'company': object;
    /**
     * 
     * @type {object}
     * @memberof TrackingLogResponseDTO
     */
    'users': object;
    /**
     * 
     * @type {object}
     * @memberof TrackingLogResponseDTO
     */
    'client': object;
}
/**
 * 
 * @export
 * @interface TwoFactorBodyDTO
 */
export interface TwoFactorBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorBodyDTO
     */
    'code': string;
    /**
     * 
     * @type {boolean}
     * @memberof TwoFactorBodyDTO
     */
    'rememberMe'?: boolean;
}
/**
 * 
 * @export
 * @interface TwoFactorConfirmBodyDTO
 */
export interface TwoFactorConfirmBodyDTO {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorConfirmBodyDTO
     */
    'code': string;
}
/**
 * 
 * @export
 * @interface TwoFactorCreateBodyDTO
 */
export interface TwoFactorCreateBodyDTO {
    /**
     * 
     * @type {TwoFactorMethod}
     * @memberof TwoFactorCreateBodyDTO
     */
    'method': TwoFactorMethod;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorCreateBodyDTO
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TwoFactorMethod = {
    Email: 'Email',
    Phone: 'Phone',
    AuthenticatorApp: 'AuthenticatorApp'
} as const;

export type TwoFactorMethod = typeof TwoFactorMethod[keyof typeof TwoFactorMethod];


/**
 * 
 * @export
 * @interface TwoFactorUpdateBodyDTO
 */
export interface TwoFactorUpdateBodyDTO {
    /**
     * 
     * @type {TwoFactorMethod}
     * @memberof TwoFactorUpdateBodyDTO
     */
    'method': TwoFactorMethod;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorUpdateBodyDTO
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwoFactorUpdateBodyDTO
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface UnblockUserDTO
 */
export interface UnblockUserDTO {
    /**
     * 
     * @type {string}
     * @memberof UnblockUserDTO
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UpdateAdvancedDashboardDto
 */
export interface UpdateAdvancedDashboardDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvancedDashboardDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvancedDashboardDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvancedDashboardDto
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAdvancedDashboardDto
     */
    'icon'?: string;
}
/**
 * 
 * @export
 * @interface UpdateCancellationRequestedProjectStatusDTO
 */
export interface UpdateCancellationRequestedProjectStatusDTO {
    /**
     * Project status
     * @type {string}
     * @memberof UpdateCancellationRequestedProjectStatusDTO
     */
    'status': UpdateCancellationRequestedProjectStatusDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCancellationRequestedProjectStatusDTO
     */
    'reason'?: string;
}

export const UpdateCancellationRequestedProjectStatusDTOStatusEnum = {
    OnHold: 'On Hold',
    InProgress: 'In Progress',
    ReSchedule: 'Re-schedule',
    CancellationRequested: 'Cancellation Requested'
} as const;

export type UpdateCancellationRequestedProjectStatusDTOStatusEnum = typeof UpdateCancellationRequestedProjectStatusDTOStatusEnum[keyof typeof UpdateCancellationRequestedProjectStatusDTOStatusEnum];

/**
 * 
 * @export
 * @interface UpdateCategoryDTO
 */
export interface UpdateCategoryDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCategoryDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateChecklistDTO
 */
export interface UpdateChecklistDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateChecklistDTO
     */
    'categories'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateChecklistDTO
     */
    'list'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateClientDTO
 */
export interface UpdateClientDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'businessName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'billingAddress'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateClientDTO
     */
    'billingEmail'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'timeZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'notes'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateClientDTO
     */
    'workOrderEmails'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'type'?: UpdateClientDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'status'?: UpdateClientDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateClientDTO
     */
    'relationship'?: UpdateClientDTORelationshipEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateClientDTO
     */
    'archived'?: boolean;
}

export const UpdateClientDTOTypeEnum = {
    Client: 'Client',
    Contact: 'Contact',
    Prospect: 'Prospect'
} as const;

export type UpdateClientDTOTypeEnum = typeof UpdateClientDTOTypeEnum[keyof typeof UpdateClientDTOTypeEnum];
export const UpdateClientDTOStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;

export type UpdateClientDTOStatusEnum = typeof UpdateClientDTOStatusEnum[keyof typeof UpdateClientDTOStatusEnum];
export const UpdateClientDTORelationshipEnum = {
    B2B: 'B2B',
    B2C: 'B2C'
} as const;

export type UpdateClientDTORelationshipEnum = typeof UpdateClientDTORelationshipEnum[keyof typeof UpdateClientDTORelationshipEnum];

/**
 * 
 * @export
 * @interface UpdateCompanyDTO
 */
export interface UpdateCompanyDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDTO
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDTO
     */
    'companyDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'maxHoursWithoutSupervision'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'minimumInvoice'?: number;
    /**
     * 
     * @type {CompanyRemindersDTO}
     * @memberof UpdateCompanyDTO
     */
    'reminders'?: CompanyRemindersDTO;
    /**
     * 
     * @type {Array<CompanyTaxDTO>}
     * @memberof UpdateCompanyDTO
     */
    'taxes'?: Array<CompanyTaxDTO>;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDTO
     */
    'logo'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'invoiceNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'extraHourlyRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'termRenewalIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'paymentTerms'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDTO
     */
    'allowAudit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDTO
     */
    'enforceGeofencing'?: boolean;
    /**
     * 
     * @type {GeofencingMeasureEnum}
     * @memberof UpdateCompanyDTO
     */
    'geofencingMeasure'?: GeofencingMeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'geofencingRadius'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCompanyDTO
     */
    'defaultAvailableDays'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDTO
     */
    'defaultResourceTimeStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyDTO
     */
    'defaultResourceTimeEnd'?: string;
    /**
     * 
     * @type {TimeFormatEnum}
     * @memberof UpdateCompanyDTO
     */
    'timeFormat'?: TimeFormatEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'varianceUp'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'varianceDown'?: number;
    /**
     * 
     * @type {Array<UpdateFileDTO>}
     * @memberof UpdateCompanyDTO
     */
    'files'?: Array<UpdateFileDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyDTO
     */
    'onboardingCompleted'?: boolean;
    /**
     * Number of days to retain location history data
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'locationHistoryRetentionDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'widgetsAllowed'?: number;
    /**
     * Office Phone Number
     * @type {string}
     * @memberof UpdateCompanyDTO
     */
    'officePhoneNumber'?: string;
    /**
     * Number of days before automatically deactivating an account due to prolonged inactivity
     * @type {number}
     * @memberof UpdateCompanyDTO
     */
    'inactiveTimeLimitDays'?: number;
}
/**
 * 
 * @export
 * @interface UpdateCurrentCompanyDTO
 */
export interface UpdateCurrentCompanyDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentCompanyDTO
     */
    'companyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentCompanyDTO
     */
    'companyDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'maxHoursWithoutSupervision'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'minimumInvoice'?: number;
    /**
     * 
     * @type {CompanyRemindersDTO}
     * @memberof UpdateCurrentCompanyDTO
     */
    'reminders'?: CompanyRemindersDTO;
    /**
     * 
     * @type {Array<CompanyTaxDTO>}
     * @memberof UpdateCurrentCompanyDTO
     */
    'taxes'?: Array<CompanyTaxDTO>;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentCompanyDTO
     */
    'logo'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'invoiceNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'extraHourlyRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'termRenewalIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'paymentTerms'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCurrentCompanyDTO
     */
    'allowAudit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCurrentCompanyDTO
     */
    'enforceGeofencing'?: boolean;
    /**
     * 
     * @type {GeofencingMeasureEnum}
     * @memberof UpdateCurrentCompanyDTO
     */
    'geofencingMeasure'?: GeofencingMeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'geofencingRadius'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCurrentCompanyDTO
     */
    'defaultAvailableDays'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentCompanyDTO
     */
    'defaultResourceTimeStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentCompanyDTO
     */
    'defaultResourceTimeEnd'?: string;
    /**
     * 
     * @type {TimeFormatEnum}
     * @memberof UpdateCurrentCompanyDTO
     */
    'timeFormat'?: TimeFormatEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'varianceUp'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'varianceDown'?: number;
    /**
     * 
     * @type {Array<UpdateFileDTO>}
     * @memberof UpdateCurrentCompanyDTO
     */
    'files'?: Array<UpdateFileDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCurrentCompanyDTO
     */
    'onboardingCompleted'?: boolean;
    /**
     * Number of days to retain location history data
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'locationHistoryRetentionDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'widgetsAllowed'?: number;
    /**
     * Office Phone Number
     * @type {string}
     * @memberof UpdateCurrentCompanyDTO
     */
    'officePhoneNumber'?: string;
    /**
     * Number of days before automatically deactivating an account due to prolonged inactivity
     * @type {number}
     * @memberof UpdateCurrentCompanyDTO
     */
    'inactiveTimeLimitDays'?: number;
}
/**
 * 
 * @export
 * @interface UpdateCurrentProfileDto
 */
export interface UpdateCurrentProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentProfileDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentProfileDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentProfileDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentProfileDto
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCurrentProfileDto
     */
    'photo'?: string;
    /**
     * 
     * @type {TimeFormatEnum}
     * @memberof UpdateCurrentProfileDto
     */
    'timeFormat'?: TimeFormatEnum;
}
/**
 * 
 * @export
 * @interface UpdateFileDTO
 */
export interface UpdateFileDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateFileDTO
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFileDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFileDTO
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateInvoiceStatusDTO
 */
export interface UpdateInvoiceStatusDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateInvoiceStatusDTO
     */
    'status': UpdateInvoiceStatusDTOStatusEnum;
}

export const UpdateInvoiceStatusDTOStatusEnum = {
    Pending: 'PENDING',
    Overdue: 'OVERDUE',
    Paid: 'PAID',
    Cancelled: 'CANCELLED'
} as const;

export type UpdateInvoiceStatusDTOStatusEnum = typeof UpdateInvoiceStatusDTOStatusEnum[keyof typeof UpdateInvoiceStatusDTOStatusEnum];

/**
 * 
 * @export
 * @interface UpdateLeadGeneralInfoDto
 */
export interface UpdateLeadGeneralInfoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'clientId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'addressId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'contactId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'projectType'?: UpdateLeadGeneralInfoDtoProjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'projectStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'projectEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'manualPricing'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadGeneralInfoDto
     */
    'profitPercentage'?: number;
}

export const UpdateLeadGeneralInfoDtoProjectTypeEnum = {
    Regular: 'Regular',
    AdHoc: 'Ad-Hoc'
} as const;

export type UpdateLeadGeneralInfoDtoProjectTypeEnum = typeof UpdateLeadGeneralInfoDtoProjectTypeEnum[keyof typeof UpdateLeadGeneralInfoDtoProjectTypeEnum];

/**
 * 
 * @export
 * @interface UpdateLeadIndividualTaskDto
 */
export interface UpdateLeadIndividualTaskDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadIndividualTaskDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLeadIndividualTaskDto
     */
    'technicians'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateLeadIndividualTaskTimesDto
 */
export interface UpdateLeadIndividualTaskTimesDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadIndividualTaskTimesDto
     */
    'individualTaskSetupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadIndividualTaskTimesDto
     */
    'individualTaskTeardownTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadIndividualTaskTimesDto
     */
    'individualTaskSetupTimeCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadIndividualTaskTimesDto
     */
    'individualTaskTeardownTimeCost'?: number;
}
/**
 * 
 * @export
 * @interface UpdateLeadServiceDto
 */
export interface UpdateLeadServiceDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadServiceDto
     */
    'setupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeadServiceDto
     */
    'teardownTime'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLeadServiceDto
     */
    'checklist'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateOnHoldProjectStatusDTO
 */
export interface UpdateOnHoldProjectStatusDTO {
    /**
     * Project status
     * @type {string}
     * @memberof UpdateOnHoldProjectStatusDTO
     */
    'status': UpdateOnHoldProjectStatusDTOStatusEnum;
    /**
     * New Start Date
     * @type {string}
     * @memberof UpdateOnHoldProjectStatusDTO
     */
    'startDate': string;
    /**
     * New End Date
     * @type {string}
     * @memberof UpdateOnHoldProjectStatusDTO
     */
    'endDate': string;
}

export const UpdateOnHoldProjectStatusDTOStatusEnum = {
    OnHold: 'On Hold',
    InProgress: 'In Progress',
    ReSchedule: 'Re-schedule',
    CancellationRequested: 'Cancellation Requested'
} as const;

export type UpdateOnHoldProjectStatusDTOStatusEnum = typeof UpdateOnHoldProjectStatusDTOStatusEnum[keyof typeof UpdateOnHoldProjectStatusDTOStatusEnum];

/**
 * 
 * @export
 * @interface UpdateProjectNoteDTO
 */
export interface UpdateProjectNoteDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectNoteDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectNoteDTO
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProposalBodyDTO
 */
export interface UpdateProposalBodyDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'proposalPenalizationPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'proposalDiscountPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'variancePenalizationPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'varianceDiscountPercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'paymentDaysTerm'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'discount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProposalBodyDTO
     */
    'approvalRequired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProposalBodyDTO
     */
    'autoRenewal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProposalBodyDTO
     */
    'allowCounterOffers'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'proposalValue': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'termRenewalIncrease'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'reminder'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProposalBodyDTO
     */
    'invoiceFrequency'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProposalBodyDTO
     */
    'sendOn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProposalBodyDTO
     */
    'securityDeposit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'depositAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'marginInProposal'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProposalBodyDTO
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ServiceTimePricing>}
     * @memberof UpdateProposalBodyDTO
     */
    'serviceTimePricing'?: Array<ServiceTimePricing>;
    /**
     * 
     * @type {number}
     * @memberof UpdateProposalBodyDTO
     */
    'messageTemplateId'?: number;
}
/**
 * 
 * @export
 * @interface UpdateProposalMessagesTemplateDTO
 */
export interface UpdateProposalMessagesTemplateDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateProposalMessagesTemplateDTO
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProposalMessagesTemplateDTO
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRequestStatusDTO
 */
export interface UpdateRequestStatusDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestStatusDTO
     */
    'requestId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequestStatusDTO
     */
    'status': string;
}
/**
 * 
 * @export
 * @interface UpdateRolDTO
 */
export interface UpdateRolDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateRolDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRolDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<Permissions>}
     * @memberof UpdateRolDTO
     */
    'permissions'?: Array<Permissions>;
}
/**
 * 
 * @export
 * @interface UpdateServiceDTO
 */
export interface UpdateServiceDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateServiceDTO
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceDTO
     */
    'setupTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateServiceDTO
     */
    'teardownTime'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateServiceDTO
     */
    'tasks'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSkillDTO
 */
export interface UpdateSkillDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateSkillDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSkillDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSkillDTO
     */
    'categoryId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTaskDTO
 */
export interface UpdateTaskDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDTO
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTaskDTO
     */
    'skills'?: Array<string>;
    /**
     * 
     * @type {Array<TaskChecklistDTO>}
     * @memberof UpdateTaskDTO
     */
    'taskChecklist'?: Array<TaskChecklistDTO>;
    /**
     * 
     * @type {Array<CreateTaskMaterialDTO>}
     * @memberof UpdateTaskDTO
     */
    'materials'?: Array<CreateTaskMaterialDTO>;
    /**
     * 
     * @type {Array<CreateTaskMaterialDTO>}
     * @memberof UpdateTaskDTO
     */
    'taskMaterials'?: Array<CreateTaskMaterialDTO>;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskDTO
     */
    'measure': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTaskDTO
     */
    'measurePerHour': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskDTO
     */
    'imageRequired': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateTaskDTO
     */
    'minimumResources': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskDTO
     */
    'isTemplate'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserProfileDto
 */
export interface UpdateUserProfileDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserProfileDto
     */
    'rate'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserProfileDto
     */
    'userRoles'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserProfileDto
     */
    'skills'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    'availableTimeStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserProfileDto
     */
    'avaialableTimeEnd'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserProfileDto
     */
    'availableDays'?: Array<string>;
    /**
     * 
     * @type {TimeFormatEnum}
     * @memberof UpdateUserProfileDto
     */
    'timeFormat'?: TimeFormatEnum;
}
/**
 * 
 * @export
 * @interface UpdateWidgetDTO
 */
export interface UpdateWidgetDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateWidgetDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWidgetDTO
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWidgetDTO
     */
    'chartType'?: UpdateWidgetDTOChartTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateWidgetDTO
     */
    'primaryDataType'?: UpdateWidgetDTOPrimaryDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateWidgetDTO
     */
    'secondaryDataType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWidgetDTO
     */
    'timeConfig'?: UpdateWidgetDTOTimeConfigEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateWidgetDTO
     */
    'itemCounter'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateWidgetDTO
     */
    'periodConfig'?: UpdateWidgetDTOPeriodConfigEnum;
}

export const UpdateWidgetDTOChartTypeEnum = {
    Line: 'Line',
    Area: 'Area',
    Bar: 'Bar',
    Donut: 'Donut',
    Pie: 'Pie',
    Radar: 'Radar',
    RadialBar: 'RadialBar'
} as const;

export type UpdateWidgetDTOChartTypeEnum = typeof UpdateWidgetDTOChartTypeEnum[keyof typeof UpdateWidgetDTOChartTypeEnum];
export const UpdateWidgetDTOPrimaryDataTypeEnum = {
    Projects: 'Projects',
    Leads: 'Leads',
    Proposals: 'Proposals',
    Technicians: 'Technicians',
    Users: 'Users',
    Clients: 'Clients',
    Invoices: 'Invoices',
    Tasks: 'Tasks',
    TasksIteration: 'TasksIteration',
    CalendarEventIteration: 'CalendarEventIteration',
    Visits: 'Visits',
    Assignments: 'Assignments',
    Timesheet: 'Timesheet'
} as const;

export type UpdateWidgetDTOPrimaryDataTypeEnum = typeof UpdateWidgetDTOPrimaryDataTypeEnum[keyof typeof UpdateWidgetDTOPrimaryDataTypeEnum];
export const UpdateWidgetDTOTimeConfigEnum = {
    Weekly: 'Weekly',
    Biweekly: 'Biweekly',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Biannually: 'Biannually',
    Annually: 'Annually'
} as const;

export type UpdateWidgetDTOTimeConfigEnum = typeof UpdateWidgetDTOTimeConfigEnum[keyof typeof UpdateWidgetDTOTimeConfigEnum];
export const UpdateWidgetDTOPeriodConfigEnum = {
    Today: 'Today',
    ThisWeek: 'ThisWeek',
    ThisMonth: 'ThisMonth',
    ThisYear: 'ThisYear',
    Historical: 'Historical'
} as const;

export type UpdateWidgetDTOPeriodConfigEnum = typeof UpdateWidgetDTOPeriodConfigEnum[keyof typeof UpdateWidgetDTOPeriodConfigEnum];

/**
 * 
 * @export
 * @interface UpdateWidgetOrderDTO
 */
export interface UpdateWidgetOrderDTO {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateWidgetOrderDTO
     */
    'widgetIds': Array<number>;
}
/**
 * 
 * @export
 * @interface UpsertAddressDTO
 */
export interface UpsertAddressDTO {
    /**
     * 
     * @type {Array<AddressPropertiesDTO>}
     * @memberof UpsertAddressDTO
     */
    'addresses': Array<AddressPropertiesDTO>;
}
/**
 * 
 * @export
 * @interface UpsertCommunicationMethodDTO
 */
export interface UpsertCommunicationMethodDTO {
    /**
     * 
     * @type {Array<CommunicationMethodPropertiesDTO>}
     * @memberof UpsertCommunicationMethodDTO
     */
    'communicationMethods': Array<CommunicationMethodPropertiesDTO>;
}
/**
 * 
 * @export
 * @interface UpsertContactDTO
 */
export interface UpsertContactDTO {
    /**
     * 
     * @type {Array<ContactPropertiesDTO>}
     * @memberof UpsertContactDTO
     */
    'contacts': Array<ContactPropertiesDTO>;
}
/**
 * 
 * @export
 * @interface UserBasicInformationDTO
 */
export interface UserBasicInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof UserBasicInformationDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicInformationDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicInformationDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicInformationDTO
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface UserDashboardResponseDTO
 */
export interface UserDashboardResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDashboardResponseDTO
     */
    'timeZone': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDashboardResponseDTO
     */
    'calendarEvents': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDashboardResponseDTO
     */
    'calendarEventsInProgress': Array<string>;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName': string;
    /**
     * 
     * @type {Array<UserRoleDto>}
     * @memberof UserDto
     */
    'userRoles': Array<UserRoleDto>;
}
/**
 * 
 * @export
 * @interface UserIdDto
 */
export interface UserIdDto {
    /**
     * 
     * @type {string}
     * @memberof UserIdDto
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface UserResponseDTO
 */
export interface UserResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    'block': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'unblockUntil': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'blockMessage': string;
    /**
     * 
     * @type {number}
     * @memberof UserResponseDTO
     */
    'rate': number;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    'availability': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'jobTitle': string;
    /**
     * 
     * @type {object}
     * @memberof UserResponseDTO
     */
    'metadata': object;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'passwordLastUpdated': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'timeZone': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'availableTimeStart': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'availableTimeEnd': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseDTO
     */
    'availableDays': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    'canSwap': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    'isOwner': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'ftsFullNameEn': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'updatedAt': string;
    /**
     * 
     * @type {CompanyResponseInProfileDTO}
     * @memberof UserResponseDTO
     */
    'company': CompanyResponseInProfileDTO;
    /**
     * 
     * @type {object}
     * @memberof UserResponseDTO
     */
    'reportedBy': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseDTO
     */
    'reportTo': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseDTO
     */
    'inviters': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof UserResponseDTO
     */
    'twoFactor': object;
    /**
     * 
     * @type {object}
     * @memberof UserResponseDTO
     */
    'resetPassword': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseDTO
     */
    'userHistory': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseDTO
     */
    'skills': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseDTO
     */
    'userRoles': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseDTO
     */
    'roles': Array<string>;
    /**
     * 
     * @type {object}
     * @memberof UserResponseDTO
     */
    'availabilityData': object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseDTO
     */
    'advancedDashboards': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    'timeFormat': string;
}
/**
 * 
 * @export
 * @interface UserRoleDto
 */
export interface UserRoleDto {
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'roleId': string;
    /**
     * 
     * @type {string}
     * @memberof UserRoleDto
     */
    'createdAt': string;
    /**
     * 
     * @type {RoleDto}
     * @memberof UserRoleDto
     */
    'role': RoleDto;
}
/**
 * 
 * @export
 * @interface UserTimezoneDTO
 */
export interface UserTimezoneDTO {
    /**
     * 
     * @type {string}
     * @memberof UserTimezoneDTO
     */
    'timezone': string;
    /**
     * 
     * @type {string}
     * @memberof UserTimezoneDTO
     */
    'timezoneIana': string;
}
/**
 * 
 * @export
 * @interface UserTimezoneIanaDTO
 */
export interface UserTimezoneIanaDTO {
    /**
     * 
     * @type {string}
     * @memberof UserTimezoneIanaDTO
     */
    'timezoneIana': string;
}
/**
 * 
 * @export
 * @interface UserWeekStatsResponseDTO
 */
export interface UserWeekStatsResponseDTO {
    /**
     * Hours Worked
     * @type {object}
     * @memberof UserWeekStatsResponseDTO
     */
    'hoursWorked': object;
    /**
     * Hours Scheduled
     * @type {object}
     * @memberof UserWeekStatsResponseDTO
     */
    'hoursScheduled': object;
    /**
     * Tasks Assigned
     * @type {object}
     * @memberof UserWeekStatsResponseDTO
     */
    'tasksAssigned': object;
    /**
     * Start date
     * @type {string}
     * @memberof UserWeekStatsResponseDTO
     */
    'startDate': string;
    /**
     * End date
     * @type {string}
     * @memberof UserWeekStatsResponseDTO
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface VerifyResponseDTO
 */
export interface VerifyResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof VerifyResponseDTO
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface WatchersIdsArrayDTO
 */
export interface WatchersIdsArrayDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof WatchersIdsArrayDTO
     */
    'watchers': Array<string>;
}
/**
 * 
 * @export
 * @interface WidgetCatergoriesPresetsDTO
 */
export interface WidgetCatergoriesPresetsDTO {
    /**
     * Primary data types available for selection
     * @type {Array<string>}
     * @memberof WidgetCatergoriesPresetsDTO
     */
    'primaryDataTypes': Array<string>;
    /**
     * Chart types available for visualization
     * @type {Array<string>}
     * @memberof WidgetCatergoriesPresetsDTO
     */
    'chartTypes': Array<string>;
    /**
     * Period configurations available for data aggregation
     * @type {Array<string>}
     * @memberof WidgetCatergoriesPresetsDTO
     */
    'periodConfigs': Array<string>;
    /**
     * Time configurations available for report generation
     * @type {Array<string>}
     * @memberof WidgetCatergoriesPresetsDTO
     */
    'timeConfig': Array<string>;
}
/**
 * 
 * @export
 * @interface WidgetDataDTO
 */
export interface WidgetDataDTO {
    /**
     * 
     * @type {WidgetResponseDTO}
     * @memberof WidgetDataDTO
     */
    'widget': WidgetResponseDTO;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetDataDTO
     */
    'nestedData': boolean;
    /**
     * 
     * @type {object}
     * @memberof WidgetDataDTO
     */
    'data': object;
}
/**
 * @type WidgetGetCategories200Response
 * @export
 */
export type WidgetGetCategories200Response = SecondaryDataTypesResponseDTO | WidgetCatergoriesPresetsDTO;

/**
 * 
 * @export
 * @interface WidgetResponseDTO
 */
export interface WidgetResponseDTO {
    /**
     * The name of the test
     * @type {string}
     * @memberof WidgetResponseDTO
     */
    'name': string;
    /**
     * Description of the test
     * @type {string}
     * @memberof WidgetResponseDTO
     */
    'description': string;
    /**
     * The type of chart
     * @type {string}
     * @memberof WidgetResponseDTO
     */
    'chartType': string;
    /**
     * Primary data type
     * @type {string}
     * @memberof WidgetResponseDTO
     */
    'primaryDataType': string;
    /**
     * Secondary data type
     * @type {string}
     * @memberof WidgetResponseDTO
     */
    'secondaryDataType': string;
    /**
     * Time configuration for the data
     * @type {string}
     * @memberof WidgetResponseDTO
     */
    'timeConfig': string;
    /**
     * Item counter
     * @type {number}
     * @memberof WidgetResponseDTO
     */
    'itemCounter': number;
    /**
     * Period configuration
     * @type {string}
     * @memberof WidgetResponseDTO
     */
    'periodConfig': string;
    /**
     * Unique ID of the test
     * @type {number}
     * @memberof WidgetResponseDTO
     */
    'id': number;
}

/**
 * AdvancedDashboardApi - axios parameter creator
 * @export
 */
export const AdvancedDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateWidgetOnAdvanceDashboardDTO} createWidgetOnAdvanceDashboardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardAddWidget: async (id: string, createWidgetOnAdvanceDashboardDTO: CreateWidgetOnAdvanceDashboardDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advancedDashboardAddWidget', 'id', id)
            // verify required parameter 'createWidgetOnAdvanceDashboardDTO' is not null or undefined
            assertParamExists('advancedDashboardAddWidget', 'createWidgetOnAdvanceDashboardDTO', createWidgetOnAdvanceDashboardDTO)
            const localVarPath = `/advanced-dashboard/{id}/widget`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWidgetOnAdvanceDashboardDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAdvancedDashboardDto} createAdvancedDashboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardCreate: async (createAdvancedDashboardDto: CreateAdvancedDashboardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdvancedDashboardDto' is not null or undefined
            assertParamExists('advancedDashboardCreate', 'createAdvancedDashboardDto', createAdvancedDashboardDto)
            const localVarPath = `/advanced-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAdvancedDashboardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advancedDashboardDelete', 'id', id)
            const localVarPath = `/advanced-dashboard/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardDeleteWidget: async (id: string, widgetId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advancedDashboardDeleteWidget', 'id', id)
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('advancedDashboardDeleteWidget', 'widgetId', widgetId)
            const localVarPath = `/advanced-dashboard/{id}/widget/{widgetId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/advanced-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardGetOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advancedDashboardGetOneById', 'id', id)
            const localVarPath = `/advanced-dashboard/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} widgetId widget ID
         * @param {string} dashboardId Dashboard UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardGetWidgetData: async (widgetId: number, dashboardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('advancedDashboardGetWidgetData', 'widgetId', widgetId)
            // verify required parameter 'dashboardId' is not null or undefined
            assertParamExists('advancedDashboardGetWidgetData', 'dashboardId', dashboardId)
            const localVarPath = `/advanced-dashboard/widget/{dashboardId}/data/{widgetId}`
                .replace(`{${"widgetId"}}`, encodeURIComponent(String(widgetId)))
                .replace(`{${"dashboardId"}}`, encodeURIComponent(String(dashboardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdvancedDashboardDto} updateAdvancedDashboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardUpdate: async (id: string, updateAdvancedDashboardDto: UpdateAdvancedDashboardDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advancedDashboardUpdate', 'id', id)
            // verify required parameter 'updateAdvancedDashboardDto' is not null or undefined
            assertParamExists('advancedDashboardUpdate', 'updateAdvancedDashboardDto', updateAdvancedDashboardDto)
            const localVarPath = `/advanced-dashboard/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAdvancedDashboardDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateWidgetOrderDTO} updateWidgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardUpdateWidgetOrder: async (id: string, updateWidgetOrderDTO: UpdateWidgetOrderDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('advancedDashboardUpdateWidgetOrder', 'id', id)
            // verify required parameter 'updateWidgetOrderDTO' is not null or undefined
            assertParamExists('advancedDashboardUpdateWidgetOrder', 'updateWidgetOrderDTO', updateWidgetOrderDTO)
            const localVarPath = `/advanced-dashboard/{id}/update-widget-order`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWidgetOrderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdvancedDashboardApi - functional programming interface
 * @export
 */
export const AdvancedDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdvancedDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateWidgetOnAdvanceDashboardDTO} createWidgetOnAdvanceDashboardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardAddWidget(id: string, createWidgetOnAdvanceDashboardDTO: CreateWidgetOnAdvanceDashboardDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardAddWidget(id, createWidgetOnAdvanceDashboardDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateAdvancedDashboardDto} createAdvancedDashboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardCreate(createAdvancedDashboardDto: CreateAdvancedDashboardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardCreate(createAdvancedDashboardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardDeleteWidget(id: string, widgetId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardDeleteWidget(id, widgetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardGetOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardGetOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} widgetId widget ID
         * @param {string} dashboardId Dashboard UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardGetWidgetData(widgetId: number, dashboardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetDataDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardGetWidgetData(widgetId, dashboardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdvancedDashboardDto} updateAdvancedDashboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardUpdate(id: string, updateAdvancedDashboardDto: UpdateAdvancedDashboardDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardUpdate(id, updateAdvancedDashboardDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateWidgetOrderDTO} updateWidgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async advancedDashboardUpdateWidgetOrder(id: string, updateWidgetOrderDTO: UpdateWidgetOrderDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.advancedDashboardUpdateWidgetOrder(id, updateWidgetOrderDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdvancedDashboardApi - factory interface
 * @export
 */
export const AdvancedDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdvancedDashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {CreateWidgetOnAdvanceDashboardDTO} createWidgetOnAdvanceDashboardDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardAddWidget(id: string, createWidgetOnAdvanceDashboardDTO: CreateWidgetOnAdvanceDashboardDTO, options?: any): AxiosPromise<object> {
            return localVarFp.advancedDashboardAddWidget(id, createWidgetOnAdvanceDashboardDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateAdvancedDashboardDto} createAdvancedDashboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardCreate(createAdvancedDashboardDto: CreateAdvancedDashboardDto, options?: any): AxiosPromise<object> {
            return localVarFp.advancedDashboardCreate(createAdvancedDashboardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.advancedDashboardDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} widgetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardDeleteWidget(id: string, widgetId: number, options?: any): AxiosPromise<void> {
            return localVarFp.advancedDashboardDeleteWidget(id, widgetId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardGetAll(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.advancedDashboardGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardGetOneById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.advancedDashboardGetOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} widgetId widget ID
         * @param {string} dashboardId Dashboard UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardGetWidgetData(widgetId: number, dashboardId: string, options?: any): AxiosPromise<WidgetDataDTO> {
            return localVarFp.advancedDashboardGetWidgetData(widgetId, dashboardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAdvancedDashboardDto} updateAdvancedDashboardDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardUpdate(id: string, updateAdvancedDashboardDto: UpdateAdvancedDashboardDto, options?: any): AxiosPromise<object> {
            return localVarFp.advancedDashboardUpdate(id, updateAdvancedDashboardDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateWidgetOrderDTO} updateWidgetOrderDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        advancedDashboardUpdateWidgetOrder(id: string, updateWidgetOrderDTO: UpdateWidgetOrderDTO, options?: any): AxiosPromise<void> {
            return localVarFp.advancedDashboardUpdateWidgetOrder(id, updateWidgetOrderDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for advancedDashboardAddWidget operation in AdvancedDashboardApi.
 * @export
 * @interface AdvancedDashboardApiAdvancedDashboardAddWidgetRequest
 */
export interface AdvancedDashboardApiAdvancedDashboardAddWidgetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvancedDashboardApiAdvancedDashboardAddWidget
     */
    readonly id: string

    /**
     * 
     * @type {CreateWidgetOnAdvanceDashboardDTO}
     * @memberof AdvancedDashboardApiAdvancedDashboardAddWidget
     */
    readonly createWidgetOnAdvanceDashboardDTO: CreateWidgetOnAdvanceDashboardDTO
}

/**
 * Request parameters for advancedDashboardCreate operation in AdvancedDashboardApi.
 * @export
 * @interface AdvancedDashboardApiAdvancedDashboardCreateRequest
 */
export interface AdvancedDashboardApiAdvancedDashboardCreateRequest {
    /**
     * 
     * @type {CreateAdvancedDashboardDto}
     * @memberof AdvancedDashboardApiAdvancedDashboardCreate
     */
    readonly createAdvancedDashboardDto: CreateAdvancedDashboardDto
}

/**
 * Request parameters for advancedDashboardDelete operation in AdvancedDashboardApi.
 * @export
 * @interface AdvancedDashboardApiAdvancedDashboardDeleteRequest
 */
export interface AdvancedDashboardApiAdvancedDashboardDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvancedDashboardApiAdvancedDashboardDelete
     */
    readonly id: string
}

/**
 * Request parameters for advancedDashboardDeleteWidget operation in AdvancedDashboardApi.
 * @export
 * @interface AdvancedDashboardApiAdvancedDashboardDeleteWidgetRequest
 */
export interface AdvancedDashboardApiAdvancedDashboardDeleteWidgetRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvancedDashboardApiAdvancedDashboardDeleteWidget
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof AdvancedDashboardApiAdvancedDashboardDeleteWidget
     */
    readonly widgetId: number
}

/**
 * Request parameters for advancedDashboardGetOneById operation in AdvancedDashboardApi.
 * @export
 * @interface AdvancedDashboardApiAdvancedDashboardGetOneByIdRequest
 */
export interface AdvancedDashboardApiAdvancedDashboardGetOneByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvancedDashboardApiAdvancedDashboardGetOneById
     */
    readonly id: string
}

/**
 * Request parameters for advancedDashboardGetWidgetData operation in AdvancedDashboardApi.
 * @export
 * @interface AdvancedDashboardApiAdvancedDashboardGetWidgetDataRequest
 */
export interface AdvancedDashboardApiAdvancedDashboardGetWidgetDataRequest {
    /**
     * widget ID
     * @type {number}
     * @memberof AdvancedDashboardApiAdvancedDashboardGetWidgetData
     */
    readonly widgetId: number

    /**
     * Dashboard UUID
     * @type {string}
     * @memberof AdvancedDashboardApiAdvancedDashboardGetWidgetData
     */
    readonly dashboardId: string
}

/**
 * Request parameters for advancedDashboardUpdate operation in AdvancedDashboardApi.
 * @export
 * @interface AdvancedDashboardApiAdvancedDashboardUpdateRequest
 */
export interface AdvancedDashboardApiAdvancedDashboardUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvancedDashboardApiAdvancedDashboardUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateAdvancedDashboardDto}
     * @memberof AdvancedDashboardApiAdvancedDashboardUpdate
     */
    readonly updateAdvancedDashboardDto: UpdateAdvancedDashboardDto
}

/**
 * Request parameters for advancedDashboardUpdateWidgetOrder operation in AdvancedDashboardApi.
 * @export
 * @interface AdvancedDashboardApiAdvancedDashboardUpdateWidgetOrderRequest
 */
export interface AdvancedDashboardApiAdvancedDashboardUpdateWidgetOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof AdvancedDashboardApiAdvancedDashboardUpdateWidgetOrder
     */
    readonly id: string

    /**
     * 
     * @type {UpdateWidgetOrderDTO}
     * @memberof AdvancedDashboardApiAdvancedDashboardUpdateWidgetOrder
     */
    readonly updateWidgetOrderDTO: UpdateWidgetOrderDTO
}

/**
 * AdvancedDashboardApi - object-oriented interface
 * @export
 * @class AdvancedDashboardApi
 * @extends {BaseAPI}
 */
export class AdvancedDashboardApi extends BaseAPI {
    /**
     * 
     * @param {AdvancedDashboardApiAdvancedDashboardAddWidgetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardAddWidget(requestParameters: AdvancedDashboardApiAdvancedDashboardAddWidgetRequest, options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardAddWidget(requestParameters.id, requestParameters.createWidgetOnAdvanceDashboardDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdvancedDashboardApiAdvancedDashboardCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardCreate(requestParameters: AdvancedDashboardApiAdvancedDashboardCreateRequest, options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardCreate(requestParameters.createAdvancedDashboardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdvancedDashboardApiAdvancedDashboardDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardDelete(requestParameters: AdvancedDashboardApiAdvancedDashboardDeleteRequest, options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdvancedDashboardApiAdvancedDashboardDeleteWidgetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardDeleteWidget(requestParameters: AdvancedDashboardApiAdvancedDashboardDeleteWidgetRequest, options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardDeleteWidget(requestParameters.id, requestParameters.widgetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardGetAll(options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdvancedDashboardApiAdvancedDashboardGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardGetOneById(requestParameters: AdvancedDashboardApiAdvancedDashboardGetOneByIdRequest, options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardGetOneById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdvancedDashboardApiAdvancedDashboardGetWidgetDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardGetWidgetData(requestParameters: AdvancedDashboardApiAdvancedDashboardGetWidgetDataRequest, options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardGetWidgetData(requestParameters.widgetId, requestParameters.dashboardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdvancedDashboardApiAdvancedDashboardUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardUpdate(requestParameters: AdvancedDashboardApiAdvancedDashboardUpdateRequest, options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardUpdate(requestParameters.id, requestParameters.updateAdvancedDashboardDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdvancedDashboardApiAdvancedDashboardUpdateWidgetOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdvancedDashboardApi
     */
    public advancedDashboardUpdateWidgetOrder(requestParameters: AdvancedDashboardApiAdvancedDashboardUpdateWidgetOrderRequest, options?: AxiosRequestConfig) {
        return AdvancedDashboardApiFp(this.configuration).advancedDashboardUpdateWidgetOrder(requestParameters.id, requestParameters.updateWidgetOrderDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AssignmentsApi - axios parameter creator
 * @export
 */
export const AssignmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAssignmentsDto} createAssignmentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsCreate: async (createAssignmentsDto: CreateAssignmentsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAssignmentsDto' is not null or undefined
            assertParamExists('assignmentsCreate', 'createAssignmentsDto', createAssignmentsDto)
            const localVarPath = `/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssignmentsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentsDelete', 'id', id)
            const localVarPath = `/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/assignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsGetOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentsGetOneById', 'id', id)
            const localVarPath = `/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsUpdate: async (id: string, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentsUpdate', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('assignmentsUpdate', 'body', body)
            const localVarPath = `/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssignmentsApi - functional programming interface
 * @export
 */
export const AssignmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssignmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAssignmentsDto} createAssignmentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentsCreate(createAssignmentsDto: CreateAssignmentsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentsCreate(createAssignmentsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentsDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentsGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentsGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentsGetOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentsGetOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentsUpdate(id: string, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentsUpdate(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssignmentsApi - factory interface
 * @export
 */
export const AssignmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssignmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateAssignmentsDto} createAssignmentsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsCreate(createAssignmentsDto: CreateAssignmentsDto, options?: any): AxiosPromise<object> {
            return localVarFp.assignmentsCreate(createAssignmentsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.assignmentsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsGetAll(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.assignmentsGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsGetOneById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.assignmentsGetOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentsUpdate(id: string, body: object, options?: any): AxiosPromise<object> {
            return localVarFp.assignmentsUpdate(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for assignmentsCreate operation in AssignmentsApi.
 * @export
 * @interface AssignmentsApiAssignmentsCreateRequest
 */
export interface AssignmentsApiAssignmentsCreateRequest {
    /**
     * 
     * @type {CreateAssignmentsDto}
     * @memberof AssignmentsApiAssignmentsCreate
     */
    readonly createAssignmentsDto: CreateAssignmentsDto
}

/**
 * Request parameters for assignmentsDelete operation in AssignmentsApi.
 * @export
 * @interface AssignmentsApiAssignmentsDeleteRequest
 */
export interface AssignmentsApiAssignmentsDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignmentsApiAssignmentsDelete
     */
    readonly id: string
}

/**
 * Request parameters for assignmentsGetOneById operation in AssignmentsApi.
 * @export
 * @interface AssignmentsApiAssignmentsGetOneByIdRequest
 */
export interface AssignmentsApiAssignmentsGetOneByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignmentsApiAssignmentsGetOneById
     */
    readonly id: string
}

/**
 * Request parameters for assignmentsUpdate operation in AssignmentsApi.
 * @export
 * @interface AssignmentsApiAssignmentsUpdateRequest
 */
export interface AssignmentsApiAssignmentsUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignmentsApiAssignmentsUpdate
     */
    readonly id: string

    /**
     * 
     * @type {object}
     * @memberof AssignmentsApiAssignmentsUpdate
     */
    readonly body: object
}

/**
 * AssignmentsApi - object-oriented interface
 * @export
 * @class AssignmentsApi
 * @extends {BaseAPI}
 */
export class AssignmentsApi extends BaseAPI {
    /**
     * 
     * @param {AssignmentsApiAssignmentsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public assignmentsCreate(requestParameters: AssignmentsApiAssignmentsCreateRequest, options?: AxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).assignmentsCreate(requestParameters.createAssignmentsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssignmentsApiAssignmentsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public assignmentsDelete(requestParameters: AssignmentsApiAssignmentsDeleteRequest, options?: AxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).assignmentsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public assignmentsGetAll(options?: AxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).assignmentsGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssignmentsApiAssignmentsGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public assignmentsGetOneById(requestParameters: AssignmentsApiAssignmentsGetOneByIdRequest, options?: AxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).assignmentsGetOneById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AssignmentsApiAssignmentsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentsApi
     */
    public assignmentsUpdate(requestParameters: AssignmentsApiAssignmentsUpdateRequest, options?: AxiosRequestConfig) {
        return AssignmentsApiFp(this.configuration).assignmentsUpdate(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ActiveUserDTO} activeUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authActiveUser: async (activeUserDTO: ActiveUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeUserDTO' is not null or undefined
            assertParamExists('authActiveUser', 'activeUserDTO', activeUserDTO)
            const localVarPath = `/auth/active-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authArchiveUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authArchiveUser', 'id', id)
            const localVarPath = `/auth/archive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthChangePasswordBodyDTO} authChangePasswordBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authChangePassword: async (authChangePasswordBodyDTO: AuthChangePasswordBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authChangePasswordBodyDTO' is not null or undefined
            assertParamExists('authChangePassword', 'authChangePasswordBodyDTO', authChangePasswordBodyDTO)
            const localVarPath = `/auth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authChangePasswordBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordCreateDTO} forgotPasswordCreateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCreate: async (forgotPasswordCreateDTO: ForgotPasswordCreateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordCreateDTO' is not null or undefined
            assertParamExists('authCreate', 'forgotPasswordCreateDTO', forgotPasswordCreateDTO)
            const localVarPath = `/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordCreateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TwoFactorBodyDTO} twoFactorBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginTwoFactor: async (twoFactorBodyDTO: TwoFactorBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'twoFactorBodyDTO' is not null or undefined
            assertParamExists('authLoginTwoFactor', 'twoFactorBodyDTO', twoFactorBodyDTO)
            const localVarPath = `/auth/two-factor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPasswordTokenDTO} resetPasswordTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword: async (token: string, resetPasswordTokenDTO: ResetPasswordTokenDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('authResetPassword', 'token', token)
            // verify required parameter 'resetPasswordTokenDTO' is not null or undefined
            assertParamExists('authResetPassword', 'resetPasswordTokenDTO', resetPasswordTokenDTO)
            const localVarPath = `/auth/reset-password/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordTokenDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUnarchiveUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUnarchiveUser', 'id', id)
            const localVarPath = `/auth/unarchive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UnblockUserDTO} unblockUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUnblockUser: async (unblockUserDTO: UnblockUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unblockUserDTO' is not null or undefined
            assertParamExists('authUnblockUser', 'unblockUserDTO', unblockUserDTO)
            const localVarPath = `/auth/unblock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unblockUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authVerifyToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ActiveUserDTO} activeUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authActiveUser(activeUserDTO: ActiveUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authActiveUser(activeUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authArchiveUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authArchiveUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthChangePasswordBodyDTO} authChangePasswordBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authChangePassword(authChangePasswordBodyDTO: AuthChangePasswordBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authChangePassword(authChangePasswordBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgotPasswordCreateDTO} forgotPasswordCreateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authCreate(forgotPasswordCreateDTO: ForgotPasswordCreateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authCreate(forgotPasswordCreateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TwoFactorBodyDTO} twoFactorBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginTwoFactor(twoFactorBodyDTO: TwoFactorBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginTwoFactor(twoFactorBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPasswordTokenDTO} resetPasswordTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authResetPassword(token: string, resetPasswordTokenDTO: ResetPasswordTokenDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authResetPassword(token, resetPasswordTokenDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUnarchiveUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUnarchiveUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UnblockUserDTO} unblockUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUnblockUser(unblockUserDTO: UnblockUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUnblockUser(unblockUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authVerifyToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authVerifyToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {ActiveUserDTO} activeUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authActiveUser(activeUserDTO: ActiveUserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.authActiveUser(activeUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authArchiveUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.authArchiveUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthChangePasswordBodyDTO} authChangePasswordBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authChangePassword(authChangePasswordBodyDTO: AuthChangePasswordBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.authChangePassword(authChangePasswordBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordCreateDTO} forgotPasswordCreateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCreate(forgotPasswordCreateDTO: ForgotPasswordCreateDTO, options?: any): AxiosPromise<void> {
            return localVarFp.authCreate(forgotPasswordCreateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(loginDto: LoginDto, options?: any): AxiosPromise<LoginResponseDTO> {
            return localVarFp.authLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TwoFactorBodyDTO} twoFactorBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginTwoFactor(twoFactorBodyDTO: TwoFactorBodyDTO, options?: any): AxiosPromise<LoginResponseDTO> {
            return localVarFp.authLoginTwoFactor(twoFactorBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {ResetPasswordTokenDTO} resetPasswordTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authResetPassword(token: string, resetPasswordTokenDTO: ResetPasswordTokenDTO, options?: any): AxiosPromise<void> {
            return localVarFp.authResetPassword(token, resetPasswordTokenDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUnarchiveUser(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.authUnarchiveUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UnblockUserDTO} unblockUserDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUnblockUser(unblockUserDTO: UnblockUserDTO, options?: any): AxiosPromise<void> {
            return localVarFp.authUnblockUser(unblockUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authVerifyToken(options?: any): AxiosPromise<VerifyResponseDTO> {
            return localVarFp.authVerifyToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authActiveUser operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthActiveUserRequest
 */
export interface AuthenticationApiAuthActiveUserRequest {
    /**
     * 
     * @type {ActiveUserDTO}
     * @memberof AuthenticationApiAuthActiveUser
     */
    readonly activeUserDTO: ActiveUserDTO
}

/**
 * Request parameters for authArchiveUser operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthArchiveUserRequest
 */
export interface AuthenticationApiAuthArchiveUserRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof AuthenticationApiAuthArchiveUser
     */
    readonly id: string
}

/**
 * Request parameters for authChangePassword operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthChangePasswordRequest
 */
export interface AuthenticationApiAuthChangePasswordRequest {
    /**
     * 
     * @type {AuthChangePasswordBodyDTO}
     * @memberof AuthenticationApiAuthChangePassword
     */
    readonly authChangePasswordBodyDTO: AuthChangePasswordBodyDTO
}

/**
 * Request parameters for authCreate operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthCreateRequest
 */
export interface AuthenticationApiAuthCreateRequest {
    /**
     * 
     * @type {ForgotPasswordCreateDTO}
     * @memberof AuthenticationApiAuthCreate
     */
    readonly forgotPasswordCreateDTO: ForgotPasswordCreateDTO
}

/**
 * Request parameters for authLogin operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthLoginRequest
 */
export interface AuthenticationApiAuthLoginRequest {
    /**
     * 
     * @type {LoginDto}
     * @memberof AuthenticationApiAuthLogin
     */
    readonly loginDto: LoginDto
}

/**
 * Request parameters for authLoginTwoFactor operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthLoginTwoFactorRequest
 */
export interface AuthenticationApiAuthLoginTwoFactorRequest {
    /**
     * 
     * @type {TwoFactorBodyDTO}
     * @memberof AuthenticationApiAuthLoginTwoFactor
     */
    readonly twoFactorBodyDTO: TwoFactorBodyDTO
}

/**
 * Request parameters for authResetPassword operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthResetPasswordRequest
 */
export interface AuthenticationApiAuthResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationApiAuthResetPassword
     */
    readonly token: string

    /**
     * 
     * @type {ResetPasswordTokenDTO}
     * @memberof AuthenticationApiAuthResetPassword
     */
    readonly resetPasswordTokenDTO: ResetPasswordTokenDTO
}

/**
 * Request parameters for authUnarchiveUser operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthUnarchiveUserRequest
 */
export interface AuthenticationApiAuthUnarchiveUserRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof AuthenticationApiAuthUnarchiveUser
     */
    readonly id: string
}

/**
 * Request parameters for authUnblockUser operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthUnblockUserRequest
 */
export interface AuthenticationApiAuthUnblockUserRequest {
    /**
     * 
     * @type {UnblockUserDTO}
     * @memberof AuthenticationApiAuthUnblockUser
     */
    readonly unblockUserDTO: UnblockUserDTO
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {AuthenticationApiAuthActiveUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authActiveUser(requestParameters: AuthenticationApiAuthActiveUserRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authActiveUser(requestParameters.activeUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthArchiveUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authArchiveUser(requestParameters: AuthenticationApiAuthArchiveUserRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authArchiveUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authChangePassword(requestParameters: AuthenticationApiAuthChangePasswordRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authChangePassword(requestParameters.authChangePasswordBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authCreate(requestParameters: AuthenticationApiAuthCreateRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authCreate(requestParameters.forgotPasswordCreateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authLogin(requestParameters: AuthenticationApiAuthLoginRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authLogin(requestParameters.loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthLoginTwoFactorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authLoginTwoFactor(requestParameters: AuthenticationApiAuthLoginTwoFactorRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authLoginTwoFactor(requestParameters.twoFactorBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authResetPassword(requestParameters: AuthenticationApiAuthResetPasswordRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authResetPassword(requestParameters.token, requestParameters.resetPasswordTokenDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthUnarchiveUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authUnarchiveUser(requestParameters: AuthenticationApiAuthUnarchiveUserRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authUnarchiveUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthUnblockUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authUnblockUser(requestParameters: AuthenticationApiAuthUnblockUserRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authUnblockUser(requestParameters.unblockUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authVerifyToken(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authVerifyToken(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AvailabilityApi - axios parameter creator
 * @export
 */
export const AvailabilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AvailabilityDTO} availabilityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabiltyUserAvailability: async (availabilityDTO: AvailabilityDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'availabilityDTO' is not null or undefined
            assertParamExists('availabiltyUserAvailability', 'availabilityDTO', availabilityDTO)
            const localVarPath = `/availability/get-availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(availabilityDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvailabilityApi - functional programming interface
 * @export
 */
export const AvailabilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvailabilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AvailabilityDTO} availabilityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availabiltyUserAvailability(availabilityDTO: AvailabilityDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availabiltyUserAvailability(availabilityDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvailabilityApi - factory interface
 * @export
 */
export const AvailabilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvailabilityApiFp(configuration)
    return {
        /**
         * 
         * @param {AvailabilityDTO} availabilityDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabiltyUserAvailability(availabilityDTO: AvailabilityDTO, options?: any): AxiosPromise<void> {
            return localVarFp.availabiltyUserAvailability(availabilityDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for availabiltyUserAvailability operation in AvailabilityApi.
 * @export
 * @interface AvailabilityApiAvailabiltyUserAvailabilityRequest
 */
export interface AvailabilityApiAvailabiltyUserAvailabilityRequest {
    /**
     * 
     * @type {AvailabilityDTO}
     * @memberof AvailabilityApiAvailabiltyUserAvailability
     */
    readonly availabilityDTO: AvailabilityDTO
}

/**
 * AvailabilityApi - object-oriented interface
 * @export
 * @class AvailabilityApi
 * @extends {BaseAPI}
 */
export class AvailabilityApi extends BaseAPI {
    /**
     * 
     * @param {AvailabilityApiAvailabiltyUserAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvailabilityApi
     */
    public availabiltyUserAvailability(requestParameters: AvailabilityApiAvailabiltyUserAvailabilityRequest, options?: AxiosRequestConfig) {
        return AvailabilityApiFp(this.configuration).availabiltyUserAvailability(requestParameters.availabilityDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarEventIterationApi - axios parameter creator
 * @export
 */
export const CalendarEventIterationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationCreate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar-event-iteration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventIterationDelete', 'id', id)
            const localVarPath = `/calendar-event-iteration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar-event-iteration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationGetOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventIterationGetOneById', 'id', id)
            const localVarPath = `/calendar-event-iteration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationSetCompleteCalendarIteration: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventIterationSetCompleteCalendarIteration', 'id', id)
            const localVarPath = `/calendar-event-iteration/clockOut/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationUpdate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventIterationUpdate', 'id', id)
            const localVarPath = `/calendar-event-iteration/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarEventIterationApi - functional programming interface
 * @export
 */
export const CalendarEventIterationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarEventIterationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventIterationCreate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventIterationCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventIterationDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventIterationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventIterationGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventIterationGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventIterationGetOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventIterationGetOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventIterationSetCompleteCalendarIteration(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventIterationSetCompleteCalendarIteration(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventIterationUpdate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventIterationUpdate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarEventIterationApi - factory interface
 * @export
 */
export const CalendarEventIterationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarEventIterationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationCreate(options?: any): AxiosPromise<object> {
            return localVarFp.calendarEventIterationCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.calendarEventIterationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationGetAll(options?: any): AxiosPromise<Array<object>> {
            return localVarFp.calendarEventIterationGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationGetOneById(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.calendarEventIterationGetOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationSetCompleteCalendarIteration(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.calendarEventIterationSetCompleteCalendarIteration(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventIterationUpdate(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.calendarEventIterationUpdate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calendarEventIterationDelete operation in CalendarEventIterationApi.
 * @export
 * @interface CalendarEventIterationApiCalendarEventIterationDeleteRequest
 */
export interface CalendarEventIterationApiCalendarEventIterationDeleteRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventIterationApiCalendarEventIterationDelete
     */
    readonly id: string
}

/**
 * Request parameters for calendarEventIterationGetOneById operation in CalendarEventIterationApi.
 * @export
 * @interface CalendarEventIterationApiCalendarEventIterationGetOneByIdRequest
 */
export interface CalendarEventIterationApiCalendarEventIterationGetOneByIdRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventIterationApiCalendarEventIterationGetOneById
     */
    readonly id: string
}

/**
 * Request parameters for calendarEventIterationSetCompleteCalendarIteration operation in CalendarEventIterationApi.
 * @export
 * @interface CalendarEventIterationApiCalendarEventIterationSetCompleteCalendarIterationRequest
 */
export interface CalendarEventIterationApiCalendarEventIterationSetCompleteCalendarIterationRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventIterationApiCalendarEventIterationSetCompleteCalendarIteration
     */
    readonly id: string
}

/**
 * Request parameters for calendarEventIterationUpdate operation in CalendarEventIterationApi.
 * @export
 * @interface CalendarEventIterationApiCalendarEventIterationUpdateRequest
 */
export interface CalendarEventIterationApiCalendarEventIterationUpdateRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventIterationApiCalendarEventIterationUpdate
     */
    readonly id: string
}

/**
 * CalendarEventIterationApi - object-oriented interface
 * @export
 * @class CalendarEventIterationApi
 * @extends {BaseAPI}
 */
export class CalendarEventIterationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventIterationApi
     */
    public calendarEventIterationCreate(options?: AxiosRequestConfig) {
        return CalendarEventIterationApiFp(this.configuration).calendarEventIterationCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventIterationApiCalendarEventIterationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventIterationApi
     */
    public calendarEventIterationDelete(requestParameters: CalendarEventIterationApiCalendarEventIterationDeleteRequest, options?: AxiosRequestConfig) {
        return CalendarEventIterationApiFp(this.configuration).calendarEventIterationDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventIterationApi
     */
    public calendarEventIterationGetAll(options?: AxiosRequestConfig) {
        return CalendarEventIterationApiFp(this.configuration).calendarEventIterationGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventIterationApiCalendarEventIterationGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventIterationApi
     */
    public calendarEventIterationGetOneById(requestParameters: CalendarEventIterationApiCalendarEventIterationGetOneByIdRequest, options?: AxiosRequestConfig) {
        return CalendarEventIterationApiFp(this.configuration).calendarEventIterationGetOneById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventIterationApiCalendarEventIterationSetCompleteCalendarIterationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventIterationApi
     */
    public calendarEventIterationSetCompleteCalendarIteration(requestParameters: CalendarEventIterationApiCalendarEventIterationSetCompleteCalendarIterationRequest, options?: AxiosRequestConfig) {
        return CalendarEventIterationApiFp(this.configuration).calendarEventIterationSetCompleteCalendarIteration(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventIterationApiCalendarEventIterationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventIterationApi
     */
    public calendarEventIterationUpdate(requestParameters: CalendarEventIterationApiCalendarEventIterationUpdateRequest, options?: AxiosRequestConfig) {
        return CalendarEventIterationApiFp(this.configuration).calendarEventIterationUpdate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarEventsApi - axios parameter creator
 * @export
 */
export const CalendarEventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AssignationBodyDTO} assignationBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsAssigneTechnicianToTask: async (assignationBodyDTO: AssignationBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignationBodyDTO' is not null or undefined
            assertParamExists('calendarEventsAssigneTechnicianToTask', 'assignationBodyDTO', assignationBodyDTO)
            const localVarPath = `/calendarEvents/assignation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignationBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCalendarEventsDTO} createCalendarEventsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsCreate: async (createCalendarEventsDTO: CreateCalendarEventsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCalendarEventsDTO' is not null or undefined
            assertParamExists('calendarEventsCreate', 'createCalendarEventsDTO', createCalendarEventsDTO)
            const localVarPath = `/calendarEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCalendarEventsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendarEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindAllPaginate: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('calendarEventsFindAllPaginate', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('calendarEventsFindAllPaginate', 'items', items)
            const localVarPath = `/calendarEvents/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} projectId Project UUID
         * @param {string} date Date
         * @param {number} serviceTimeId Service Time ID
         * @param {string} calendarEventDay calendarEventDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByDateAndServiceTime: async (projectId: string, date: string, serviceTimeId: number, calendarEventDay: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('calendarEventsFindByDateAndServiceTime', 'projectId', projectId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('calendarEventsFindByDateAndServiceTime', 'date', date)
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('calendarEventsFindByDateAndServiceTime', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'calendarEventDay' is not null or undefined
            assertParamExists('calendarEventsFindByDateAndServiceTime', 'calendarEventDay', calendarEventDay)
            const localVarPath = `/calendarEvents/project/detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (serviceTimeId !== undefined) {
                localVarQueryParameter['serviceTimeId'] = serviceTimeId;
            }

            if (calendarEventDay !== undefined) {
                localVarQueryParameter['calendarEventDay'] = calendarEventDay;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} day Calendar Event Date
         * @param {number} [timeZone] User Time Zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByMonth: async (day: string, timeZone?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('calendarEventsFindByMonth', 'day', day)
            const localVarPath = `/calendarEvents/byMonth/{day}`
                .replace(`{${"day"}}`, encodeURIComponent(String(day)))
                .replace(`{${"timeZone"}}`, encodeURIComponent(String(timeZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByTechnician: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendarEvents/technician`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} day Calendar Event Date
         * @param {number} [timeZone] User Time Zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByTechnicianAndDate: async (day: string, timeZone?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('calendarEventsFindByTechnicianAndDate', 'day', day)
            const localVarPath = `/calendarEvents/technicianDate/{day}/{timeZone}`
                .replace(`{${"day"}}`, encodeURIComponent(String(day)))
                .replace(`{${"timeZone"}}`, encodeURIComponent(String(timeZone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByTechnicianDashboard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendarEvents/technicianDashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByTechnicianId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventsFindByTechnicianId', 'id', id)
            const localVarPath = `/calendarEvents/per-technician/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindContactInformationByCalendarEvent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventsFindContactInformationByCalendarEvent', 'id', id)
            const localVarPath = `/calendarEvents/{id}/contact-information`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindDotDaysByTechnician: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendarEvents/technicianDots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindInProgressByTechnicianDashboard: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendarEvents/technician-inprogress-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventsFindOne', 'id', id)
            const localVarPath = `/calendarEvents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'project' | 'lead' | 'serviceTime'} searchBy 
         * @param {string} searchById 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsGetCalendarViewInformation: async (searchBy: 'project' | 'lead' | 'serviceTime', searchById: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchBy' is not null or undefined
            assertParamExists('calendarEventsGetCalendarViewInformation', 'searchBy', searchBy)
            // verify required parameter 'searchById' is not null or undefined
            assertParamExists('calendarEventsGetCalendarViewInformation', 'searchById', searchById)
            const localVarPath = `/calendarEvents/calendar-view/filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchBy !== undefined) {
                localVarQueryParameter['searchBy'] = searchBy;
            }

            if (searchById !== undefined) {
                localVarQueryParameter['searchById'] = searchById;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventsRemove', 'id', id)
            const localVarPath = `/calendarEvents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} query Search query for calendar events by title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsSearch: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('calendarEventsSearch', 'query', query)
            const localVarPath = `/calendarEvents/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsSetTravelTimeArrived: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventsSetTravelTimeArrived', 'id', id)
            const localVarPath = `/calendarEvents/arrived/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsSetTravelTimeOnRoad: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calendarEventsSetTravelTimeOnRoad', 'id', id)
            const localVarPath = `/calendarEvents/on-road/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarEventsApi - functional programming interface
 * @export
 */
export const CalendarEventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarEventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AssignationBodyDTO} assignationBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsAssigneTechnicianToTask(assignationBodyDTO: AssignationBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsAssigneTechnicianToTask(assignationBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCalendarEventsDTO} createCalendarEventsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsCreate(createCalendarEventsDTO: CreateCalendarEventsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsCreate(createCalendarEventsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindAllPaginate(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindAllPaginate(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} projectId Project UUID
         * @param {string} date Date
         * @param {number} serviceTimeId Service Time ID
         * @param {string} calendarEventDay calendarEventDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindByDateAndServiceTime(projectId: string, date: string, serviceTimeId: number, calendarEventDay: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsProjectDetailResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindByDateAndServiceTime(projectId, date, serviceTimeId, calendarEventDay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} day Calendar Event Date
         * @param {number} [timeZone] User Time Zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindByMonth(day: string, timeZone?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindByMonth(day, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindByTechnician(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindByTechnician(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} day Calendar Event Date
         * @param {number} [timeZone] User Time Zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindByTechnicianAndDate(day: string, timeZone?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindByTechnicianAndDate(day, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindByTechnicianDashboard(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindByTechnicianDashboard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindByTechnicianId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindByTechnicianId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindContactInformationByCalendarEvent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindContactInformationByCalendarEvent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindDotDaysByTechnician(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindDotDaysByTechnician(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindInProgressByTechnicianDashboard(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindInProgressByTechnicianDashboard(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'project' | 'lead' | 'serviceTime'} searchBy 
         * @param {string} searchById 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsGetCalendarViewInformation(searchBy: 'project' | 'lead' | 'serviceTime', searchById: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsGetCalendarViewInformation(searchBy, searchById, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} query Search query for calendar events by title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsSearch(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsSearch(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsSetTravelTimeArrived(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsSetTravelTimeArrived(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarEventsSetTravelTimeOnRoad(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarEventsSetTravelTimeOnRoad(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarEventsApi - factory interface
 * @export
 */
export const CalendarEventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarEventsApiFp(configuration)
    return {
        /**
         * 
         * @param {AssignationBodyDTO} assignationBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsAssigneTechnicianToTask(assignationBodyDTO: AssignationBodyDTO, options?: any): AxiosPromise<CalendarEventsResponseDTO> {
            return localVarFp.calendarEventsAssigneTechnicianToTask(assignationBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCalendarEventsDTO} createCalendarEventsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsCreate(createCalendarEventsDTO: CreateCalendarEventsDTO, options?: any): AxiosPromise<CalendarEventsResponseDTO> {
            return localVarFp.calendarEventsCreate(createCalendarEventsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindAll(options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindAllPaginate(page: number, items: number, options?: any): AxiosPromise<CalendarEventsListDTO> {
            return localVarFp.calendarEventsFindAllPaginate(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} projectId Project UUID
         * @param {string} date Date
         * @param {number} serviceTimeId Service Time ID
         * @param {string} calendarEventDay calendarEventDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByDateAndServiceTime(projectId: string, date: string, serviceTimeId: number, calendarEventDay: string, options?: any): AxiosPromise<CalendarEventsProjectDetailResponseDTO> {
            return localVarFp.calendarEventsFindByDateAndServiceTime(projectId, date, serviceTimeId, calendarEventDay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} day Calendar Event Date
         * @param {number} [timeZone] User Time Zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByMonth(day: string, timeZone?: number, options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindByMonth(day, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByTechnician(options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindByTechnician(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} day Calendar Event Date
         * @param {number} [timeZone] User Time Zone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByTechnicianAndDate(day: string, timeZone?: number, options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindByTechnicianAndDate(day, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByTechnicianDashboard(options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindByTechnicianDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindByTechnicianId(id: string, options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindByTechnicianId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindContactInformationByCalendarEvent(id: string, options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindContactInformationByCalendarEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindDotDaysByTechnician(options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindDotDaysByTechnician(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindInProgressByTechnicianDashboard(options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsFindInProgressByTechnicianDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsFindOne(id: string, options?: any): AxiosPromise<CalendarEventsResponseDTO> {
            return localVarFp.calendarEventsFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'project' | 'lead' | 'serviceTime'} searchBy 
         * @param {string} searchById 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsGetCalendarViewInformation(searchBy: 'project' | 'lead' | 'serviceTime', searchById: string, options?: any): AxiosPromise<void> {
            return localVarFp.calendarEventsGetCalendarViewInformation(searchBy, searchById, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.calendarEventsRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} query Search query for calendar events by title
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsSearch(query: string, options?: any): AxiosPromise<Array<CalendarEventsResponseDTO>> {
            return localVarFp.calendarEventsSearch(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsSetTravelTimeArrived(id: string, options?: any): AxiosPromise<CalendarEventsResponseDTO> {
            return localVarFp.calendarEventsSetTravelTimeArrived(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Calendar Event UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarEventsSetTravelTimeOnRoad(id: string, options?: any): AxiosPromise<CalendarEventsResponseDTO> {
            return localVarFp.calendarEventsSetTravelTimeOnRoad(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calendarEventsAssigneTechnicianToTask operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsAssigneTechnicianToTaskRequest
 */
export interface CalendarEventsApiCalendarEventsAssigneTechnicianToTaskRequest {
    /**
     * 
     * @type {AssignationBodyDTO}
     * @memberof CalendarEventsApiCalendarEventsAssigneTechnicianToTask
     */
    readonly assignationBodyDTO: AssignationBodyDTO
}

/**
 * Request parameters for calendarEventsCreate operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsCreateRequest
 */
export interface CalendarEventsApiCalendarEventsCreateRequest {
    /**
     * 
     * @type {CreateCalendarEventsDTO}
     * @memberof CalendarEventsApiCalendarEventsCreate
     */
    readonly createCalendarEventsDTO: CreateCalendarEventsDTO
}

/**
 * Request parameters for calendarEventsFindAllPaginate operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsFindAllPaginateRequest
 */
export interface CalendarEventsApiCalendarEventsFindAllPaginateRequest {
    /**
     * 
     * @type {number}
     * @memberof CalendarEventsApiCalendarEventsFindAllPaginate
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof CalendarEventsApiCalendarEventsFindAllPaginate
     */
    readonly items: number
}

/**
 * Request parameters for calendarEventsFindByDateAndServiceTime operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsFindByDateAndServiceTimeRequest
 */
export interface CalendarEventsApiCalendarEventsFindByDateAndServiceTimeRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsFindByDateAndServiceTime
     */
    readonly projectId: string

    /**
     * Date
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsFindByDateAndServiceTime
     */
    readonly date: string

    /**
     * Service Time ID
     * @type {number}
     * @memberof CalendarEventsApiCalendarEventsFindByDateAndServiceTime
     */
    readonly serviceTimeId: number

    /**
     * calendarEventDay
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsFindByDateAndServiceTime
     */
    readonly calendarEventDay: string
}

/**
 * Request parameters for calendarEventsFindByMonth operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsFindByMonthRequest
 */
export interface CalendarEventsApiCalendarEventsFindByMonthRequest {
    /**
     * Calendar Event Date
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsFindByMonth
     */
    readonly day: string

    /**
     * User Time Zone
     * @type {number}
     * @memberof CalendarEventsApiCalendarEventsFindByMonth
     */
    readonly timeZone?: number
}

/**
 * Request parameters for calendarEventsFindByTechnicianAndDate operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsFindByTechnicianAndDateRequest
 */
export interface CalendarEventsApiCalendarEventsFindByTechnicianAndDateRequest {
    /**
     * Calendar Event Date
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsFindByTechnicianAndDate
     */
    readonly day: string

    /**
     * User Time Zone
     * @type {number}
     * @memberof CalendarEventsApiCalendarEventsFindByTechnicianAndDate
     */
    readonly timeZone?: number
}

/**
 * Request parameters for calendarEventsFindByTechnicianId operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsFindByTechnicianIdRequest
 */
export interface CalendarEventsApiCalendarEventsFindByTechnicianIdRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsFindByTechnicianId
     */
    readonly id: string
}

/**
 * Request parameters for calendarEventsFindContactInformationByCalendarEvent operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsFindContactInformationByCalendarEventRequest
 */
export interface CalendarEventsApiCalendarEventsFindContactInformationByCalendarEventRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsFindContactInformationByCalendarEvent
     */
    readonly id: string
}

/**
 * Request parameters for calendarEventsFindOne operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsFindOneRequest
 */
export interface CalendarEventsApiCalendarEventsFindOneRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsFindOne
     */
    readonly id: string
}

/**
 * Request parameters for calendarEventsGetCalendarViewInformation operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsGetCalendarViewInformationRequest
 */
export interface CalendarEventsApiCalendarEventsGetCalendarViewInformationRequest {
    /**
     * 
     * @type {'project' | 'lead' | 'serviceTime'}
     * @memberof CalendarEventsApiCalendarEventsGetCalendarViewInformation
     */
    readonly searchBy: 'project' | 'lead' | 'serviceTime'

    /**
     * 
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsGetCalendarViewInformation
     */
    readonly searchById: string
}

/**
 * Request parameters for calendarEventsRemove operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsRemoveRequest
 */
export interface CalendarEventsApiCalendarEventsRemoveRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsRemove
     */
    readonly id: string
}

/**
 * Request parameters for calendarEventsSearch operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsSearchRequest
 */
export interface CalendarEventsApiCalendarEventsSearchRequest {
    /**
     * Search query for calendar events by title
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsSearch
     */
    readonly query: string
}

/**
 * Request parameters for calendarEventsSetTravelTimeArrived operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsSetTravelTimeArrivedRequest
 */
export interface CalendarEventsApiCalendarEventsSetTravelTimeArrivedRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsSetTravelTimeArrived
     */
    readonly id: string
}

/**
 * Request parameters for calendarEventsSetTravelTimeOnRoad operation in CalendarEventsApi.
 * @export
 * @interface CalendarEventsApiCalendarEventsSetTravelTimeOnRoadRequest
 */
export interface CalendarEventsApiCalendarEventsSetTravelTimeOnRoadRequest {
    /**
     * Calendar Event UUID
     * @type {string}
     * @memberof CalendarEventsApiCalendarEventsSetTravelTimeOnRoad
     */
    readonly id: string
}

/**
 * CalendarEventsApi - object-oriented interface
 * @export
 * @class CalendarEventsApi
 * @extends {BaseAPI}
 */
export class CalendarEventsApi extends BaseAPI {
    /**
     * 
     * @param {CalendarEventsApiCalendarEventsAssigneTechnicianToTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsAssigneTechnicianToTask(requestParameters: CalendarEventsApiCalendarEventsAssigneTechnicianToTaskRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsAssigneTechnicianToTask(requestParameters.assignationBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsCreate(requestParameters: CalendarEventsApiCalendarEventsCreateRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsCreate(requestParameters.createCalendarEventsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindAll(options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsFindAllPaginateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindAllPaginate(requestParameters: CalendarEventsApiCalendarEventsFindAllPaginateRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindAllPaginate(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsFindByDateAndServiceTimeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindByDateAndServiceTime(requestParameters: CalendarEventsApiCalendarEventsFindByDateAndServiceTimeRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindByDateAndServiceTime(requestParameters.projectId, requestParameters.date, requestParameters.serviceTimeId, requestParameters.calendarEventDay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsFindByMonthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindByMonth(requestParameters: CalendarEventsApiCalendarEventsFindByMonthRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindByMonth(requestParameters.day, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindByTechnician(options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindByTechnician(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsFindByTechnicianAndDateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindByTechnicianAndDate(requestParameters: CalendarEventsApiCalendarEventsFindByTechnicianAndDateRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindByTechnicianAndDate(requestParameters.day, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindByTechnicianDashboard(options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindByTechnicianDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsFindByTechnicianIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindByTechnicianId(requestParameters: CalendarEventsApiCalendarEventsFindByTechnicianIdRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindByTechnicianId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsFindContactInformationByCalendarEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindContactInformationByCalendarEvent(requestParameters: CalendarEventsApiCalendarEventsFindContactInformationByCalendarEventRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindContactInformationByCalendarEvent(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindDotDaysByTechnician(options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindDotDaysByTechnician(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindInProgressByTechnicianDashboard(options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindInProgressByTechnicianDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsFindOne(requestParameters: CalendarEventsApiCalendarEventsFindOneRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsGetCalendarViewInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsGetCalendarViewInformation(requestParameters: CalendarEventsApiCalendarEventsGetCalendarViewInformationRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsGetCalendarViewInformation(requestParameters.searchBy, requestParameters.searchById, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsRemove(requestParameters: CalendarEventsApiCalendarEventsRemoveRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsSearch(requestParameters: CalendarEventsApiCalendarEventsSearchRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsSearch(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsSetTravelTimeArrivedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsSetTravelTimeArrived(requestParameters: CalendarEventsApiCalendarEventsSetTravelTimeArrivedRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsSetTravelTimeArrived(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarEventsApiCalendarEventsSetTravelTimeOnRoadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarEventsApi
     */
    public calendarEventsSetTravelTimeOnRoad(requestParameters: CalendarEventsApiCalendarEventsSetTravelTimeOnRoadRequest, options?: AxiosRequestConfig) {
        return CalendarEventsApiFp(this.configuration).calendarEventsSetTravelTimeOnRoad(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCategoryDTO} createCategoryDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCreate: async (createCategoryDTO: CreateCategoryDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCategoryDTO' is not null or undefined
            assertParamExists('categoryCreate', 'createCategoryDTO', createCategoryDTO)
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCategoryDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryDelete', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetAllPaginated: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('categoryGetAllPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('categoryGetAllPaginated', 'items', items)
            const localVarPath = `/categories/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryGetOneById', 'id', id)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDTO} updateCategoryDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryUpdate: async (id: string, updateCategoryDTO: UpdateCategoryDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoryUpdate', 'id', id)
            // verify required parameter 'updateCategoryDTO' is not null or undefined
            assertParamExists('categoryUpdate', 'updateCategoryDTO', updateCategoryDTO)
            const localVarPath = `/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCategoryDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCategoryDTO} createCategoryDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryCreate(createCategoryDTO: CreateCategoryDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryCreate(createCategoryDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryGetAllPaginated(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryPaginatedResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryGetAllPaginated(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryGetOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryGetOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDTO} updateCategoryDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryUpdate(id: string, updateCategoryDTO: UpdateCategoryDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoryResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryUpdate(id, updateCategoryDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCategoryDTO} createCategoryDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryCreate(createCategoryDTO: CreateCategoryDTO, options?: any): AxiosPromise<CategoryResponseDTO> {
            return localVarFp.categoryCreate(createCategoryDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.categoryDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetAll(options?: any): AxiosPromise<Array<CategoryResponseDTO>> {
            return localVarFp.categoryGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetAllPaginated(page: number, items: number, options?: any): AxiosPromise<CategoryPaginatedResponseDTO> {
            return localVarFp.categoryGetAllPaginated(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryGetOneById(id: string, options?: any): AxiosPromise<CategoryResponseDTO> {
            return localVarFp.categoryGetOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateCategoryDTO} updateCategoryDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryUpdate(id: string, updateCategoryDTO: UpdateCategoryDTO, options?: any): AxiosPromise<CategoryResponseDTO> {
            return localVarFp.categoryUpdate(id, updateCategoryDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for categoryCreate operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoryCreateRequest
 */
export interface CategoryApiCategoryCreateRequest {
    /**
     * 
     * @type {CreateCategoryDTO}
     * @memberof CategoryApiCategoryCreate
     */
    readonly createCategoryDTO: CreateCategoryDTO
}

/**
 * Request parameters for categoryDelete operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoryDeleteRequest
 */
export interface CategoryApiCategoryDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryApiCategoryDelete
     */
    readonly id: string
}

/**
 * Request parameters for categoryGetAllPaginated operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoryGetAllPaginatedRequest
 */
export interface CategoryApiCategoryGetAllPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof CategoryApiCategoryGetAllPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof CategoryApiCategoryGetAllPaginated
     */
    readonly items: number
}

/**
 * Request parameters for categoryGetOneById operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoryGetOneByIdRequest
 */
export interface CategoryApiCategoryGetOneByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryApiCategoryGetOneById
     */
    readonly id: string
}

/**
 * Request parameters for categoryUpdate operation in CategoryApi.
 * @export
 * @interface CategoryApiCategoryUpdateRequest
 */
export interface CategoryApiCategoryUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoryApiCategoryUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCategoryDTO}
     * @memberof CategoryApiCategoryUpdate
     */
    readonly updateCategoryDTO: UpdateCategoryDTO
}

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @param {CategoryApiCategoryCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryCreate(requestParameters: CategoryApiCategoryCreateRequest, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryCreate(requestParameters.createCategoryDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryApiCategoryDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryDelete(requestParameters: CategoryApiCategoryDeleteRequest, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryGetAll(options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryApiCategoryGetAllPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryGetAllPaginated(requestParameters: CategoryApiCategoryGetAllPaginatedRequest, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryGetAllPaginated(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryApiCategoryGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryGetOneById(requestParameters: CategoryApiCategoryGetOneByIdRequest, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryGetOneById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoryApiCategoryUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public categoryUpdate(requestParameters: CategoryApiCategoryUpdateRequest, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).categoryUpdate(requestParameters.id, requestParameters.updateCategoryDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistApi - axios parameter creator
 * @export
 */
export const ChecklistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateChecklistDTO} createChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistCreate: async (createChecklistDTO: CreateChecklistDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChecklistDTO' is not null or undefined
            assertParamExists('checklistCreate', 'createChecklistDTO', createChecklistDTO)
            const localVarPath = `/checklists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChecklistDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checklistDelete', 'id', id)
            const localVarPath = `/checklists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistGetChecklistById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checklistGetChecklistById', 'id', id)
            const localVarPath = `/checklists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistGetChecklists: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/checklists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateChecklistDTO} updateChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistUpdate: async (id: string, updateChecklistDTO: UpdateChecklistDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checklistUpdate', 'id', id)
            // verify required parameter 'updateChecklistDTO' is not null or undefined
            assertParamExists('checklistUpdate', 'updateChecklistDTO', updateChecklistDTO)
            const localVarPath = `/checklists/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChecklistDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistApi - functional programming interface
 * @export
 */
export const ChecklistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateChecklistDTO} createChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistCreate(createChecklistDTO: CreateChecklistDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistCreate(createChecklistDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistGetChecklistById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistGetChecklistById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistGetChecklists(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistGetChecklists(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateChecklistDTO} updateChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistUpdate(id: string, updateChecklistDTO: UpdateChecklistDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistUpdate(id, updateChecklistDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistApi - factory interface
 * @export
 */
export const ChecklistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateChecklistDTO} createChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistCreate(createChecklistDTO: CreateChecklistDTO, options?: any): AxiosPromise<void> {
            return localVarFp.checklistCreate(createChecklistDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.checklistDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistGetChecklistById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.checklistGetChecklistById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistGetChecklists(options?: any): AxiosPromise<void> {
            return localVarFp.checklistGetChecklists(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateChecklistDTO} updateChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistUpdate(id: string, updateChecklistDTO: UpdateChecklistDTO, options?: any): AxiosPromise<void> {
            return localVarFp.checklistUpdate(id, updateChecklistDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checklistCreate operation in ChecklistApi.
 * @export
 * @interface ChecklistApiChecklistCreateRequest
 */
export interface ChecklistApiChecklistCreateRequest {
    /**
     * 
     * @type {CreateChecklistDTO}
     * @memberof ChecklistApiChecklistCreate
     */
    readonly createChecklistDTO: CreateChecklistDTO
}

/**
 * Request parameters for checklistDelete operation in ChecklistApi.
 * @export
 * @interface ChecklistApiChecklistDeleteRequest
 */
export interface ChecklistApiChecklistDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ChecklistApiChecklistDelete
     */
    readonly id: string
}

/**
 * Request parameters for checklistGetChecklistById operation in ChecklistApi.
 * @export
 * @interface ChecklistApiChecklistGetChecklistByIdRequest
 */
export interface ChecklistApiChecklistGetChecklistByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ChecklistApiChecklistGetChecklistById
     */
    readonly id: string
}

/**
 * Request parameters for checklistUpdate operation in ChecklistApi.
 * @export
 * @interface ChecklistApiChecklistUpdateRequest
 */
export interface ChecklistApiChecklistUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ChecklistApiChecklistUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateChecklistDTO}
     * @memberof ChecklistApiChecklistUpdate
     */
    readonly updateChecklistDTO: UpdateChecklistDTO
}

/**
 * ChecklistApi - object-oriented interface
 * @export
 * @class ChecklistApi
 * @extends {BaseAPI}
 */
export class ChecklistApi extends BaseAPI {
    /**
     * 
     * @param {ChecklistApiChecklistCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistCreate(requestParameters: ChecklistApiChecklistCreateRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistCreate(requestParameters.createChecklistDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChecklistApiChecklistDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistDelete(requestParameters: ChecklistApiChecklistDeleteRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChecklistApiChecklistGetChecklistByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistGetChecklistById(requestParameters: ChecklistApiChecklistGetChecklistByIdRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistGetChecklistById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistGetChecklists(options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistGetChecklists(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChecklistApiChecklistUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistUpdate(requestParameters: ChecklistApiChecklistUpdateRequest, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistUpdate(requestParameters.id, requestParameters.updateChecklistDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAddWatcher: async (userId: string, clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('clientAddWatcher', 'userId', userId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientAddWatcher', 'clientId', clientId)
            const localVarPath = `/clients/watcher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateClientDTO} createClientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCreate: async (createClientDTO: CreateClientDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientDTO' is not null or undefined
            assertParamExists('clientCreate', 'createClientDTO', createClientDTO)
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDeleteWatcher: async (userId: string, clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('clientDeleteWatcher', 'userId', userId)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientDeleteWatcher', 'clientId', clientId)
            const localVarPath = `/clients/delete-watcher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [status] 
         * @param {Array<string>} [type] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFindAll: async (status?: Array<string>, type?: Array<string>, archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {Array<string>} [status] 
         * @param {Array<string>} [type] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFindAllPaginate: async (page: number, items: number, status?: Array<string>, type?: Array<string>, archived?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('clientFindAllPaginate', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('clientFindAllPaginate', 'items', items)
            const localVarPath = `/clients/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (archived !== undefined) {
                localVarQueryParameter['archived'] = archived;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientFindOne', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFindWatcherPerClient: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientFindWatcherPerClient', 'id', id)
            const localVarPath = `/clients/client-watchers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientRemove', 'id', id)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientResendOnboarding: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientResendOnboarding', 'id', id)
            const localVarPath = `/clients/resend-onboarding/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} query Search query for clients by name or business name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSearch: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('clientSearch', 'query', query)
            const localVarPath = `/clients/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {UpdateClientDTO} updateClientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUpdate: async (id: string, updateClientDTO: UpdateClientDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientUpdate', 'id', id)
            // verify required parameter 'updateClientDTO' is not null or undefined
            assertParamExists('clientUpdate', 'updateClientDTO', updateClientDTO)
            const localVarPath = `/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateClientDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAddWatcher(userId: string, clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateClientWatcherResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAddWatcher(userId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateClientDTO} createClientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCreate(createClientDTO: CreateClientDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCreate(createClientDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientDeleteWatcher(userId: string, clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientDeleteWatcher(userId, clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [status] 
         * @param {Array<string>} [type] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientFindAll(status?: Array<string>, type?: Array<string>, archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientFindAll(status, type, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {Array<string>} [status] 
         * @param {Array<string>} [type] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientFindAllPaginate(page: number, items: number, status?: Array<string>, type?: Array<string>, archived?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientFindAllPaginate(page, items, status, type, archived, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientFindWatcherPerClient(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientWatcherDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientFindWatcherPerClient(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRemove(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientResendOnboarding(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientResendOnboarding(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} query Search query for clients by name or business name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientSearch(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientSearch(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {UpdateClientDTO} updateClientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientUpdate(id: string, updateClientDTO: UpdateClientDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientUpdate(id, updateClientDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAddWatcher(userId: string, clientId: string, options?: any): AxiosPromise<CreateClientWatcherResponseDTO> {
            return localVarFp.clientAddWatcher(userId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateClientDTO} createClientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCreate(createClientDTO: CreateClientDTO, options?: any): AxiosPromise<ClientResponseDTO> {
            return localVarFp.clientCreate(createClientDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientDeleteWatcher(userId: string, clientId: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientDeleteWatcher(userId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [status] 
         * @param {Array<string>} [type] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFindAll(status?: Array<string>, type?: Array<string>, archived?: boolean, options?: any): AxiosPromise<Array<ClientResponseDTO>> {
            return localVarFp.clientFindAll(status, type, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {Array<string>} [status] 
         * @param {Array<string>} [type] 
         * @param {boolean} [archived] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFindAllPaginate(page: number, items: number, status?: Array<string>, type?: Array<string>, archived?: boolean, options?: any): AxiosPromise<ClientListDTO> {
            return localVarFp.clientFindAllPaginate(page, items, status, type, archived, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFindOne(id: string, options?: any): AxiosPromise<ClientResponseDTO> {
            return localVarFp.clientFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientFindWatcherPerClient(id: string, options?: any): AxiosPromise<Array<ClientWatcherDTO>> {
            return localVarFp.clientFindWatcherPerClient(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRemove(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientResendOnboarding(id: string, options?: any): AxiosPromise<ClientResponseDTO> {
            return localVarFp.clientResendOnboarding(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} query Search query for clients by name or business name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSearch(query: string, options?: any): AxiosPromise<Array<ClientResponseDTO>> {
            return localVarFp.clientSearch(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Client UUID
         * @param {UpdateClientDTO} updateClientDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientUpdate(id: string, updateClientDTO: UpdateClientDTO, options?: any): AxiosPromise<ClientResponseDTO> {
            return localVarFp.clientUpdate(id, updateClientDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for clientAddWatcher operation in ClientsApi.
 * @export
 * @interface ClientsApiClientAddWatcherRequest
 */
export interface ClientsApiClientAddWatcherRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsApiClientAddWatcher
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof ClientsApiClientAddWatcher
     */
    readonly clientId: string
}

/**
 * Request parameters for clientCreate operation in ClientsApi.
 * @export
 * @interface ClientsApiClientCreateRequest
 */
export interface ClientsApiClientCreateRequest {
    /**
     * 
     * @type {CreateClientDTO}
     * @memberof ClientsApiClientCreate
     */
    readonly createClientDTO: CreateClientDTO
}

/**
 * Request parameters for clientDeleteWatcher operation in ClientsApi.
 * @export
 * @interface ClientsApiClientDeleteWatcherRequest
 */
export interface ClientsApiClientDeleteWatcherRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsApiClientDeleteWatcher
     */
    readonly userId: string

    /**
     * 
     * @type {string}
     * @memberof ClientsApiClientDeleteWatcher
     */
    readonly clientId: string
}

/**
 * Request parameters for clientFindAll operation in ClientsApi.
 * @export
 * @interface ClientsApiClientFindAllRequest
 */
export interface ClientsApiClientFindAllRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientsApiClientFindAll
     */
    readonly status?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClientsApiClientFindAll
     */
    readonly type?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ClientsApiClientFindAll
     */
    readonly archived?: boolean
}

/**
 * Request parameters for clientFindAllPaginate operation in ClientsApi.
 * @export
 * @interface ClientsApiClientFindAllPaginateRequest
 */
export interface ClientsApiClientFindAllPaginateRequest {
    /**
     * 
     * @type {number}
     * @memberof ClientsApiClientFindAllPaginate
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof ClientsApiClientFindAllPaginate
     */
    readonly items: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ClientsApiClientFindAllPaginate
     */
    readonly status?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ClientsApiClientFindAllPaginate
     */
    readonly type?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ClientsApiClientFindAllPaginate
     */
    readonly archived?: boolean
}

/**
 * Request parameters for clientFindOne operation in ClientsApi.
 * @export
 * @interface ClientsApiClientFindOneRequest
 */
export interface ClientsApiClientFindOneRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsApiClientFindOne
     */
    readonly id: string
}

/**
 * Request parameters for clientFindWatcherPerClient operation in ClientsApi.
 * @export
 * @interface ClientsApiClientFindWatcherPerClientRequest
 */
export interface ClientsApiClientFindWatcherPerClientRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsApiClientFindWatcherPerClient
     */
    readonly id: string
}

/**
 * Request parameters for clientRemove operation in ClientsApi.
 * @export
 * @interface ClientsApiClientRemoveRequest
 */
export interface ClientsApiClientRemoveRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsApiClientRemove
     */
    readonly id: string
}

/**
 * Request parameters for clientResendOnboarding operation in ClientsApi.
 * @export
 * @interface ClientsApiClientResendOnboardingRequest
 */
export interface ClientsApiClientResendOnboardingRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsApiClientResendOnboarding
     */
    readonly id: string
}

/**
 * Request parameters for clientSearch operation in ClientsApi.
 * @export
 * @interface ClientsApiClientSearchRequest
 */
export interface ClientsApiClientSearchRequest {
    /**
     * Search query for clients by name or business name
     * @type {string}
     * @memberof ClientsApiClientSearch
     */
    readonly query: string
}

/**
 * Request parameters for clientUpdate operation in ClientsApi.
 * @export
 * @interface ClientsApiClientUpdateRequest
 */
export interface ClientsApiClientUpdateRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsApiClientUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateClientDTO}
     * @memberof ClientsApiClientUpdate
     */
    readonly updateClientDTO: UpdateClientDTO
}

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @param {ClientsApiClientAddWatcherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientAddWatcher(requestParameters: ClientsApiClientAddWatcherRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientAddWatcher(requestParameters.userId, requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientCreate(requestParameters: ClientsApiClientCreateRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientCreate(requestParameters.createClientDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientDeleteWatcherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientDeleteWatcher(requestParameters: ClientsApiClientDeleteWatcherRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientDeleteWatcher(requestParameters.userId, requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientFindAll(requestParameters: ClientsApiClientFindAllRequest = {}, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientFindAll(requestParameters.status, requestParameters.type, requestParameters.archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientFindAllPaginateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientFindAllPaginate(requestParameters: ClientsApiClientFindAllPaginateRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientFindAllPaginate(requestParameters.page, requestParameters.items, requestParameters.status, requestParameters.type, requestParameters.archived, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientFindOne(requestParameters: ClientsApiClientFindOneRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientFindWatcherPerClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientFindWatcherPerClient(requestParameters: ClientsApiClientFindWatcherPerClientRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientFindWatcherPerClient(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientRemove(requestParameters: ClientsApiClientRemoveRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientRemove(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientResendOnboardingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientResendOnboarding(requestParameters: ClientsApiClientResendOnboardingRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientResendOnboarding(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientSearch(requestParameters: ClientsApiClientSearchRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientSearch(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsApiClientUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public clientUpdate(requestParameters: ClientsApiClientUpdateRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).clientUpdate(requestParameters.id, requestParameters.updateClientDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsAddressesApi - axios parameter creator
 * @export
 */
export const ClientsAddressesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressFindAll: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('addressFindAll', 'clientId', clientId)
            const localVarPath = `/clients/{clientId}/addresses`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteAddressBodyDTO} deleteAddressBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressRemove: async (clientId: string, deleteAddressBodyDTO: DeleteAddressBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('addressRemove', 'clientId', clientId)
            // verify required parameter 'deleteAddressBodyDTO' is not null or undefined
            assertParamExists('addressRemove', 'deleteAddressBodyDTO', deleteAddressBodyDTO)
            const localVarPath = `/clients/{clientId}/addresses`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteAddressBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertAddressDTO} upsertAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressUpsert: async (clientId: string, upsertAddressDTO: UpsertAddressDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('addressUpsert', 'clientId', clientId)
            // verify required parameter 'upsertAddressDTO' is not null or undefined
            assertParamExists('addressUpsert', 'upsertAddressDTO', upsertAddressDTO)
            const localVarPath = `/clients/{clientId}/addresses`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAddressDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsAddressesApi - functional programming interface
 * @export
 */
export const ClientsAddressesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsAddressesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressFindAll(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressFindAll(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteAddressBodyDTO} deleteAddressBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressRemove(clientId: string, deleteAddressBodyDTO: DeleteAddressBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressRemove(clientId, deleteAddressBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertAddressDTO} upsertAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressUpsert(clientId: string, upsertAddressDTO: UpsertAddressDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AddressResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressUpsert(clientId, upsertAddressDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsAddressesApi - factory interface
 * @export
 */
export const ClientsAddressesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsAddressesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressFindAll(clientId: string, options?: any): AxiosPromise<Array<AddressResponseDTO>> {
            return localVarFp.addressFindAll(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteAddressBodyDTO} deleteAddressBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressRemove(clientId: string, deleteAddressBodyDTO: DeleteAddressBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.addressRemove(clientId, deleteAddressBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertAddressDTO} upsertAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressUpsert(clientId: string, upsertAddressDTO: UpsertAddressDTO, options?: any): AxiosPromise<Array<AddressResponseDTO>> {
            return localVarFp.addressUpsert(clientId, upsertAddressDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addressFindAll operation in ClientsAddressesApi.
 * @export
 * @interface ClientsAddressesApiAddressFindAllRequest
 */
export interface ClientsAddressesApiAddressFindAllRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsAddressesApiAddressFindAll
     */
    readonly clientId: string
}

/**
 * Request parameters for addressRemove operation in ClientsAddressesApi.
 * @export
 * @interface ClientsAddressesApiAddressRemoveRequest
 */
export interface ClientsAddressesApiAddressRemoveRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsAddressesApiAddressRemove
     */
    readonly clientId: string

    /**
     * 
     * @type {DeleteAddressBodyDTO}
     * @memberof ClientsAddressesApiAddressRemove
     */
    readonly deleteAddressBodyDTO: DeleteAddressBodyDTO
}

/**
 * Request parameters for addressUpsert operation in ClientsAddressesApi.
 * @export
 * @interface ClientsAddressesApiAddressUpsertRequest
 */
export interface ClientsAddressesApiAddressUpsertRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsAddressesApiAddressUpsert
     */
    readonly clientId: string

    /**
     * 
     * @type {UpsertAddressDTO}
     * @memberof ClientsAddressesApiAddressUpsert
     */
    readonly upsertAddressDTO: UpsertAddressDTO
}

/**
 * ClientsAddressesApi - object-oriented interface
 * @export
 * @class ClientsAddressesApi
 * @extends {BaseAPI}
 */
export class ClientsAddressesApi extends BaseAPI {
    /**
     * 
     * @param {ClientsAddressesApiAddressFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAddressesApi
     */
    public addressFindAll(requestParameters: ClientsAddressesApiAddressFindAllRequest, options?: AxiosRequestConfig) {
        return ClientsAddressesApiFp(this.configuration).addressFindAll(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAddressesApiAddressRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAddressesApi
     */
    public addressRemove(requestParameters: ClientsAddressesApiAddressRemoveRequest, options?: AxiosRequestConfig) {
        return ClientsAddressesApiFp(this.configuration).addressRemove(requestParameters.clientId, requestParameters.deleteAddressBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAddressesApiAddressUpsertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAddressesApi
     */
    public addressUpsert(requestParameters: ClientsAddressesApiAddressUpsertRequest, options?: AxiosRequestConfig) {
        return ClientsAddressesApiFp(this.configuration).addressUpsert(requestParameters.clientId, requestParameters.upsertAddressDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsAuthApi - axios parameter creator
 * @export
 */
export const ClientsAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CompleteClientOnboardingDTO} completeClientOnboardingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthCompleteClientOnboarding: async (completeClientOnboardingDTO: CompleteClientOnboardingDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeClientOnboardingDTO' is not null or undefined
            assertParamExists('clientAuthCompleteClientOnboarding', 'completeClientOnboardingDTO', completeClientOnboardingDTO)
            const localVarPath = `/clients-auth/onboarding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeClientOnboardingDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetAppLink: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('clientAuthGetAppLink', 'email', email)
            const localVarPath = `/clients-auth/app-link/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetAppLinkWithCompany: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientAuthGetAppLinkWithCompany', 'id', id)
            const localVarPath = `/clients-auth/app-link-with-company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetClientCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients-auth/client-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetClientsPerCompany: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientAuthGetClientsPerCompany', 'id', id)
            const localVarPath = `/clients-auth/clients-per-company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientImpersonateDTO} clientImpersonateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthImpersonateClientForSuperUser: async (clientImpersonateDTO: ClientImpersonateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientImpersonateDTO' is not null or undefined
            assertParamExists('clientAuthImpersonateClientForSuperUser', 'clientImpersonateDTO', clientImpersonateDTO)
            const localVarPath = `/clients-auth/impersonate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientImpersonateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsAuthApi - functional programming interface
 * @export
 */
export const ClientsAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CompleteClientOnboardingDTO} completeClientOnboardingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthCompleteClientOnboarding(completeClientOnboardingDTO: CompleteClientOnboardingDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthCompleteClientOnboarding(completeClientOnboardingDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthGetAppLink(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthGetAppLink(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthGetAppLinkWithCompany(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthGetAppLinkWithCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthGetClientCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthGetClientCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthGetClientsPerCompany(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthGetClientsPerCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClientImpersonateDTO} clientImpersonateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAuthImpersonateClientForSuperUser(clientImpersonateDTO: ClientImpersonateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAuthImpersonateClientForSuperUser(clientImpersonateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsAuthApi - factory interface
 * @export
 */
export const ClientsAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsAuthApiFp(configuration)
    return {
        /**
         * 
         * @param {CompleteClientOnboardingDTO} completeClientOnboardingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthCompleteClientOnboarding(completeClientOnboardingDTO: CompleteClientOnboardingDTO, options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthCompleteClientOnboarding(completeClientOnboardingDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetAppLink(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthGetAppLink(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetAppLinkWithCompany(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthGetAppLinkWithCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetClientCompanies(options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthGetClientCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthGetClientsPerCompany(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthGetClientsPerCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientImpersonateDTO} clientImpersonateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAuthImpersonateClientForSuperUser(clientImpersonateDTO: ClientImpersonateDTO, options?: any): AxiosPromise<void> {
            return localVarFp.clientAuthImpersonateClientForSuperUser(clientImpersonateDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for clientAuthCompleteClientOnboarding operation in ClientsAuthApi.
 * @export
 * @interface ClientsAuthApiClientAuthCompleteClientOnboardingRequest
 */
export interface ClientsAuthApiClientAuthCompleteClientOnboardingRequest {
    /**
     * 
     * @type {CompleteClientOnboardingDTO}
     * @memberof ClientsAuthApiClientAuthCompleteClientOnboarding
     */
    readonly completeClientOnboardingDTO: CompleteClientOnboardingDTO
}

/**
 * Request parameters for clientAuthGetAppLink operation in ClientsAuthApi.
 * @export
 * @interface ClientsAuthApiClientAuthGetAppLinkRequest
 */
export interface ClientsAuthApiClientAuthGetAppLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof ClientsAuthApiClientAuthGetAppLink
     */
    readonly email: string
}

/**
 * Request parameters for clientAuthGetAppLinkWithCompany operation in ClientsAuthApi.
 * @export
 * @interface ClientsAuthApiClientAuthGetAppLinkWithCompanyRequest
 */
export interface ClientsAuthApiClientAuthGetAppLinkWithCompanyRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof ClientsAuthApiClientAuthGetAppLinkWithCompany
     */
    readonly id: string
}

/**
 * Request parameters for clientAuthGetClientsPerCompany operation in ClientsAuthApi.
 * @export
 * @interface ClientsAuthApiClientAuthGetClientsPerCompanyRequest
 */
export interface ClientsAuthApiClientAuthGetClientsPerCompanyRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof ClientsAuthApiClientAuthGetClientsPerCompany
     */
    readonly id: string
}

/**
 * Request parameters for clientAuthImpersonateClientForSuperUser operation in ClientsAuthApi.
 * @export
 * @interface ClientsAuthApiClientAuthImpersonateClientForSuperUserRequest
 */
export interface ClientsAuthApiClientAuthImpersonateClientForSuperUserRequest {
    /**
     * 
     * @type {ClientImpersonateDTO}
     * @memberof ClientsAuthApiClientAuthImpersonateClientForSuperUser
     */
    readonly clientImpersonateDTO: ClientImpersonateDTO
}

/**
 * ClientsAuthApi - object-oriented interface
 * @export
 * @class ClientsAuthApi
 * @extends {BaseAPI}
 */
export class ClientsAuthApi extends BaseAPI {
    /**
     * 
     * @param {ClientsAuthApiClientAuthCompleteClientOnboardingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAuthApi
     */
    public clientAuthCompleteClientOnboarding(requestParameters: ClientsAuthApiClientAuthCompleteClientOnboardingRequest, options?: AxiosRequestConfig) {
        return ClientsAuthApiFp(this.configuration).clientAuthCompleteClientOnboarding(requestParameters.completeClientOnboardingDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAuthApiClientAuthGetAppLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAuthApi
     */
    public clientAuthGetAppLink(requestParameters: ClientsAuthApiClientAuthGetAppLinkRequest, options?: AxiosRequestConfig) {
        return ClientsAuthApiFp(this.configuration).clientAuthGetAppLink(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAuthApiClientAuthGetAppLinkWithCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAuthApi
     */
    public clientAuthGetAppLinkWithCompany(requestParameters: ClientsAuthApiClientAuthGetAppLinkWithCompanyRequest, options?: AxiosRequestConfig) {
        return ClientsAuthApiFp(this.configuration).clientAuthGetAppLinkWithCompany(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAuthApi
     */
    public clientAuthGetClientCompanies(options?: AxiosRequestConfig) {
        return ClientsAuthApiFp(this.configuration).clientAuthGetClientCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAuthApiClientAuthGetClientsPerCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAuthApi
     */
    public clientAuthGetClientsPerCompany(requestParameters: ClientsAuthApiClientAuthGetClientsPerCompanyRequest, options?: AxiosRequestConfig) {
        return ClientsAuthApiFp(this.configuration).clientAuthGetClientsPerCompany(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsAuthApiClientAuthImpersonateClientForSuperUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsAuthApi
     */
    public clientAuthImpersonateClientForSuperUser(requestParameters: ClientsAuthApiClientAuthImpersonateClientForSuperUserRequest, options?: AxiosRequestConfig) {
        return ClientsAuthApiFp(this.configuration).clientAuthImpersonateClientForSuperUser(requestParameters.clientImpersonateDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsCommunicationMethodsApi - axios parameter creator
 * @export
 */
export const ClientsCommunicationMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationMethodFindAll: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('communicationMethodFindAll', 'clientId', clientId)
            const localVarPath = `/clients/{clientId}/communication-method`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteCommunicationMethodBodyDTO} deleteCommunicationMethodBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationMethodSoftRemove: async (clientId: string, deleteCommunicationMethodBodyDTO: DeleteCommunicationMethodBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('communicationMethodSoftRemove', 'clientId', clientId)
            // verify required parameter 'deleteCommunicationMethodBodyDTO' is not null or undefined
            assertParamExists('communicationMethodSoftRemove', 'deleteCommunicationMethodBodyDTO', deleteCommunicationMethodBodyDTO)
            const localVarPath = `/clients/{clientId}/communication-method`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteCommunicationMethodBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertCommunicationMethodDTO} upsertCommunicationMethodDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationMethodUpsert: async (clientId: string, upsertCommunicationMethodDTO: UpsertCommunicationMethodDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('communicationMethodUpsert', 'clientId', clientId)
            // verify required parameter 'upsertCommunicationMethodDTO' is not null or undefined
            assertParamExists('communicationMethodUpsert', 'upsertCommunicationMethodDTO', upsertCommunicationMethodDTO)
            const localVarPath = `/clients/{clientId}/communication-method`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertCommunicationMethodDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsCommunicationMethodsApi - functional programming interface
 * @export
 */
export const ClientsCommunicationMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsCommunicationMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationMethodFindAll(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommunicationMethodResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicationMethodFindAll(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteCommunicationMethodBodyDTO} deleteCommunicationMethodBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationMethodSoftRemove(clientId: string, deleteCommunicationMethodBodyDTO: DeleteCommunicationMethodBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicationMethodSoftRemove(clientId, deleteCommunicationMethodBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertCommunicationMethodDTO} upsertCommunicationMethodDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async communicationMethodUpsert(clientId: string, upsertCommunicationMethodDTO: UpsertCommunicationMethodDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommunicationMethodResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.communicationMethodUpsert(clientId, upsertCommunicationMethodDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsCommunicationMethodsApi - factory interface
 * @export
 */
export const ClientsCommunicationMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsCommunicationMethodsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationMethodFindAll(clientId: string, options?: any): AxiosPromise<Array<CommunicationMethodResponseDTO>> {
            return localVarFp.communicationMethodFindAll(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteCommunicationMethodBodyDTO} deleteCommunicationMethodBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationMethodSoftRemove(clientId: string, deleteCommunicationMethodBodyDTO: DeleteCommunicationMethodBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.communicationMethodSoftRemove(clientId, deleteCommunicationMethodBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertCommunicationMethodDTO} upsertCommunicationMethodDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        communicationMethodUpsert(clientId: string, upsertCommunicationMethodDTO: UpsertCommunicationMethodDTO, options?: any): AxiosPromise<Array<CommunicationMethodResponseDTO>> {
            return localVarFp.communicationMethodUpsert(clientId, upsertCommunicationMethodDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for communicationMethodFindAll operation in ClientsCommunicationMethodsApi.
 * @export
 * @interface ClientsCommunicationMethodsApiCommunicationMethodFindAllRequest
 */
export interface ClientsCommunicationMethodsApiCommunicationMethodFindAllRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsCommunicationMethodsApiCommunicationMethodFindAll
     */
    readonly clientId: string
}

/**
 * Request parameters for communicationMethodSoftRemove operation in ClientsCommunicationMethodsApi.
 * @export
 * @interface ClientsCommunicationMethodsApiCommunicationMethodSoftRemoveRequest
 */
export interface ClientsCommunicationMethodsApiCommunicationMethodSoftRemoveRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsCommunicationMethodsApiCommunicationMethodSoftRemove
     */
    readonly clientId: string

    /**
     * 
     * @type {DeleteCommunicationMethodBodyDTO}
     * @memberof ClientsCommunicationMethodsApiCommunicationMethodSoftRemove
     */
    readonly deleteCommunicationMethodBodyDTO: DeleteCommunicationMethodBodyDTO
}

/**
 * Request parameters for communicationMethodUpsert operation in ClientsCommunicationMethodsApi.
 * @export
 * @interface ClientsCommunicationMethodsApiCommunicationMethodUpsertRequest
 */
export interface ClientsCommunicationMethodsApiCommunicationMethodUpsertRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsCommunicationMethodsApiCommunicationMethodUpsert
     */
    readonly clientId: string

    /**
     * 
     * @type {UpsertCommunicationMethodDTO}
     * @memberof ClientsCommunicationMethodsApiCommunicationMethodUpsert
     */
    readonly upsertCommunicationMethodDTO: UpsertCommunicationMethodDTO
}

/**
 * ClientsCommunicationMethodsApi - object-oriented interface
 * @export
 * @class ClientsCommunicationMethodsApi
 * @extends {BaseAPI}
 */
export class ClientsCommunicationMethodsApi extends BaseAPI {
    /**
     * 
     * @param {ClientsCommunicationMethodsApiCommunicationMethodFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsCommunicationMethodsApi
     */
    public communicationMethodFindAll(requestParameters: ClientsCommunicationMethodsApiCommunicationMethodFindAllRequest, options?: AxiosRequestConfig) {
        return ClientsCommunicationMethodsApiFp(this.configuration).communicationMethodFindAll(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsCommunicationMethodsApiCommunicationMethodSoftRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsCommunicationMethodsApi
     */
    public communicationMethodSoftRemove(requestParameters: ClientsCommunicationMethodsApiCommunicationMethodSoftRemoveRequest, options?: AxiosRequestConfig) {
        return ClientsCommunicationMethodsApiFp(this.configuration).communicationMethodSoftRemove(requestParameters.clientId, requestParameters.deleteCommunicationMethodBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsCommunicationMethodsApiCommunicationMethodUpsertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsCommunicationMethodsApi
     */
    public communicationMethodUpsert(requestParameters: ClientsCommunicationMethodsApiCommunicationMethodUpsertRequest, options?: AxiosRequestConfig) {
        return ClientsCommunicationMethodsApiFp(this.configuration).communicationMethodUpsert(requestParameters.clientId, requestParameters.upsertCommunicationMethodDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsContactsApi - axios parameter creator
 * @export
 */
export const ClientsContactsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFindAll: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('contactFindAll', 'clientId', clientId)
            const localVarPath = `/clients/{clientId}/contacts`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteContactBodyDTO} deleteContactBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSoftRemove: async (clientId: string, deleteContactBodyDTO: DeleteContactBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('contactSoftRemove', 'clientId', clientId)
            // verify required parameter 'deleteContactBodyDTO' is not null or undefined
            assertParamExists('contactSoftRemove', 'deleteContactBodyDTO', deleteContactBodyDTO)
            const localVarPath = `/clients/{clientId}/contacts`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteContactBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertContactDTO} upsertContactDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUpsert: async (clientId: string, upsertContactDTO: UpsertContactDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('contactUpsert', 'clientId', clientId)
            // verify required parameter 'upsertContactDTO' is not null or undefined
            assertParamExists('contactUpsert', 'upsertContactDTO', upsertContactDTO)
            const localVarPath = `/clients/{clientId}/contacts`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertContactDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsContactsApi - functional programming interface
 * @export
 */
export const ClientsContactsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsContactsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactFindAll(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactFindAll(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteContactBodyDTO} deleteContactBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactSoftRemove(clientId: string, deleteContactBodyDTO: DeleteContactBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactSoftRemove(clientId, deleteContactBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertContactDTO} upsertContactDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactUpsert(clientId: string, upsertContactDTO: UpsertContactDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactUpsert(clientId, upsertContactDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsContactsApi - factory interface
 * @export
 */
export const ClientsContactsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsContactsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFindAll(clientId: string, options?: any): AxiosPromise<Array<ContactResponseDTO>> {
            return localVarFp.contactFindAll(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {DeleteContactBodyDTO} deleteContactBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSoftRemove(clientId: string, deleteContactBodyDTO: DeleteContactBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.contactSoftRemove(clientId, deleteContactBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {UpsertContactDTO} upsertContactDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUpsert(clientId: string, upsertContactDTO: UpsertContactDTO, options?: any): AxiosPromise<Array<ContactResponseDTO>> {
            return localVarFp.contactUpsert(clientId, upsertContactDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for contactFindAll operation in ClientsContactsApi.
 * @export
 * @interface ClientsContactsApiContactFindAllRequest
 */
export interface ClientsContactsApiContactFindAllRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsContactsApiContactFindAll
     */
    readonly clientId: string
}

/**
 * Request parameters for contactSoftRemove operation in ClientsContactsApi.
 * @export
 * @interface ClientsContactsApiContactSoftRemoveRequest
 */
export interface ClientsContactsApiContactSoftRemoveRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsContactsApiContactSoftRemove
     */
    readonly clientId: string

    /**
     * 
     * @type {DeleteContactBodyDTO}
     * @memberof ClientsContactsApiContactSoftRemove
     */
    readonly deleteContactBodyDTO: DeleteContactBodyDTO
}

/**
 * Request parameters for contactUpsert operation in ClientsContactsApi.
 * @export
 * @interface ClientsContactsApiContactUpsertRequest
 */
export interface ClientsContactsApiContactUpsertRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof ClientsContactsApiContactUpsert
     */
    readonly clientId: string

    /**
     * 
     * @type {UpsertContactDTO}
     * @memberof ClientsContactsApiContactUpsert
     */
    readonly upsertContactDTO: UpsertContactDTO
}

/**
 * ClientsContactsApi - object-oriented interface
 * @export
 * @class ClientsContactsApi
 * @extends {BaseAPI}
 */
export class ClientsContactsApi extends BaseAPI {
    /**
     * 
     * @param {ClientsContactsApiContactFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsContactsApi
     */
    public contactFindAll(requestParameters: ClientsContactsApiContactFindAllRequest, options?: AxiosRequestConfig) {
        return ClientsContactsApiFp(this.configuration).contactFindAll(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsContactsApiContactSoftRemoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsContactsApi
     */
    public contactSoftRemove(requestParameters: ClientsContactsApiContactSoftRemoveRequest, options?: AxiosRequestConfig) {
        return ClientsContactsApiFp(this.configuration).contactSoftRemove(requestParameters.clientId, requestParameters.deleteContactBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsContactsApiContactUpsertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsContactsApi
     */
    public contactUpsert(requestParameters: ClientsContactsApiContactUpsertRequest, options?: AxiosRequestConfig) {
        return ClientsContactsApiFp(this.configuration).contactUpsert(requestParameters.clientId, requestParameters.upsertContactDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsPortalApi - axios parameter creator
 * @export
 */
export const ClientsPortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} projectId Project UUID
         * @param {string} date Date
         * @param {number} serviceTimeId Service Time ID
         * @param {string} calendarEventDay calendarEventDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalFindByDateAndServiceTime: async (projectId: string, date: string, serviceTimeId: number, calendarEventDay: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('clientPortalFindByDateAndServiceTime', 'projectId', projectId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('clientPortalFindByDateAndServiceTime', 'date', date)
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('clientPortalFindByDateAndServiceTime', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'calendarEventDay' is not null or undefined
            assertParamExists('clientPortalFindByDateAndServiceTime', 'calendarEventDay', calendarEventDay)
            const localVarPath = `/clients-portal/project-service-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (serviceTimeId !== undefined) {
                localVarQueryParameter['serviceTimeId'] = serviceTimeId;
            }

            if (calendarEventDay !== undefined) {
                localVarQueryParameter['calendarEventDay'] = calendarEventDay;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateScheduleDTO} generateScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGenerateSchedule: async (generateScheduleDTO: GenerateScheduleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateScheduleDTO' is not null or undefined
            assertParamExists('clientPortalGenerateSchedule', 'generateScheduleDTO', generateScheduleDTO)
            const localVarPath = `/clients-portal/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateScheduleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetAppLink: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients-portal/information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetPaymentLinkFromInvoice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientPortalGetPaymentLinkFromInvoice', 'id', id)
            const localVarPath = `/clients-portal/invoices-payment-link/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetProjectById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientPortalGetProjectById', 'id', id)
            const localVarPath = `/clients-portal/project-detail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetProjectsOfClient: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients-portal/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetProposalsofClient: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients-portal/proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetinvoicesFilteredofClientPerProject: async (year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients-portal/invoices-filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetinvoicesofClient: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/clients-portal/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetinvoicesofClientPerProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientPortalGetinvoicesofClientPerProject', 'id', id)
            const localVarPath = `/clients-portal/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertContactDTO} upsertContactDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalUpsert: async (upsertContactDTO: UpsertContactDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertContactDTO' is not null or undefined
            assertParamExists('clientPortalUpsert', 'upsertContactDTO', upsertContactDTO)
            const localVarPath = `/clients-portal/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertContactDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertAddressDTO} upsertAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalUpsertAddress: async (upsertAddressDTO: UpsertAddressDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertAddressDTO' is not null or undefined
            assertParamExists('clientPortalUpsertAddress', 'upsertAddressDTO', upsertAddressDTO)
            const localVarPath = `/clients-portal/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertAddressDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsPortalApi - functional programming interface
 * @export
 */
export const ClientsPortalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsPortalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} projectId Project UUID
         * @param {string} date Date
         * @param {number} serviceTimeId Service Time ID
         * @param {string} calendarEventDay calendarEventDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalFindByDateAndServiceTime(projectId: string, date: string, serviceTimeId: number, calendarEventDay: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventsProjectDetailResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalFindByDateAndServiceTime(projectId, date, serviceTimeId, calendarEventDay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenerateScheduleDTO} generateScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGenerateSchedule(generateScheduleDTO: GenerateScheduleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGenerateSchedule(generateScheduleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGetAppLink(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGetAppLink(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGetPaymentLinkFromInvoice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGetPaymentLinkFromInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGetProjectById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGetProjectById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGetProjectsOfClient(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGetProjectsOfClient(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGetProposalsofClient(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGetProposalsofClient(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGetinvoicesFilteredofClientPerProject(year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGetinvoicesFilteredofClientPerProject(year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGetinvoicesofClient(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGetinvoicesofClient(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalGetinvoicesofClientPerProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalGetinvoicesofClientPerProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertContactDTO} upsertContactDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalUpsert(upsertContactDTO: UpsertContactDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalUpsert(upsertContactDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertAddressDTO} upsertAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientPortalUpsertAddress(upsertAddressDTO: UpsertAddressDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientPortalUpsertAddress(upsertAddressDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsPortalApi - factory interface
 * @export
 */
export const ClientsPortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsPortalApiFp(configuration)
    return {
        /**
         * 
         * @param {string} projectId Project UUID
         * @param {string} date Date
         * @param {number} serviceTimeId Service Time ID
         * @param {string} calendarEventDay calendarEventDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalFindByDateAndServiceTime(projectId: string, date: string, serviceTimeId: number, calendarEventDay: string, options?: any): AxiosPromise<CalendarEventsProjectDetailResponseDTO> {
            return localVarFp.clientPortalFindByDateAndServiceTime(projectId, date, serviceTimeId, calendarEventDay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateScheduleDTO} generateScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGenerateSchedule(generateScheduleDTO: GenerateScheduleDTO, options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalGenerateSchedule(generateScheduleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetAppLink(options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalGetAppLink(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetPaymentLinkFromInvoice(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalGetPaymentLinkFromInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetProjectById(id: string, options?: any): AxiosPromise<ProjectResponseDTO> {
            return localVarFp.clientPortalGetProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetProjectsOfClient(options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalGetProjectsOfClient(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetProposalsofClient(options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalGetProposalsofClient(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetinvoicesFilteredofClientPerProject(year?: number, month?: number, options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalGetinvoicesFilteredofClientPerProject(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetinvoicesofClient(options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalGetinvoicesofClient(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalGetinvoicesofClientPerProject(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalGetinvoicesofClientPerProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertContactDTO} upsertContactDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalUpsert(upsertContactDTO: UpsertContactDTO, options?: any): AxiosPromise<Array<ContactResponseDTO>> {
            return localVarFp.clientPortalUpsert(upsertContactDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertAddressDTO} upsertAddressDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientPortalUpsertAddress(upsertAddressDTO: UpsertAddressDTO, options?: any): AxiosPromise<void> {
            return localVarFp.clientPortalUpsertAddress(upsertAddressDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for clientPortalFindByDateAndServiceTime operation in ClientsPortalApi.
 * @export
 * @interface ClientsPortalApiClientPortalFindByDateAndServiceTimeRequest
 */
export interface ClientsPortalApiClientPortalFindByDateAndServiceTimeRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ClientsPortalApiClientPortalFindByDateAndServiceTime
     */
    readonly projectId: string

    /**
     * Date
     * @type {string}
     * @memberof ClientsPortalApiClientPortalFindByDateAndServiceTime
     */
    readonly date: string

    /**
     * Service Time ID
     * @type {number}
     * @memberof ClientsPortalApiClientPortalFindByDateAndServiceTime
     */
    readonly serviceTimeId: number

    /**
     * calendarEventDay
     * @type {string}
     * @memberof ClientsPortalApiClientPortalFindByDateAndServiceTime
     */
    readonly calendarEventDay: string
}

/**
 * Request parameters for clientPortalGenerateSchedule operation in ClientsPortalApi.
 * @export
 * @interface ClientsPortalApiClientPortalGenerateScheduleRequest
 */
export interface ClientsPortalApiClientPortalGenerateScheduleRequest {
    /**
     * 
     * @type {GenerateScheduleDTO}
     * @memberof ClientsPortalApiClientPortalGenerateSchedule
     */
    readonly generateScheduleDTO: GenerateScheduleDTO
}

/**
 * Request parameters for clientPortalGetPaymentLinkFromInvoice operation in ClientsPortalApi.
 * @export
 * @interface ClientsPortalApiClientPortalGetPaymentLinkFromInvoiceRequest
 */
export interface ClientsPortalApiClientPortalGetPaymentLinkFromInvoiceRequest {
    /**
     * Invoice UUID
     * @type {string}
     * @memberof ClientsPortalApiClientPortalGetPaymentLinkFromInvoice
     */
    readonly id: string
}

/**
 * Request parameters for clientPortalGetProjectById operation in ClientsPortalApi.
 * @export
 * @interface ClientsPortalApiClientPortalGetProjectByIdRequest
 */
export interface ClientsPortalApiClientPortalGetProjectByIdRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ClientsPortalApiClientPortalGetProjectById
     */
    readonly id: string
}

/**
 * Request parameters for clientPortalGetinvoicesFilteredofClientPerProject operation in ClientsPortalApi.
 * @export
 * @interface ClientsPortalApiClientPortalGetinvoicesFilteredofClientPerProjectRequest
 */
export interface ClientsPortalApiClientPortalGetinvoicesFilteredofClientPerProjectRequest {
    /**
     * 
     * @type {number}
     * @memberof ClientsPortalApiClientPortalGetinvoicesFilteredofClientPerProject
     */
    readonly year?: number

    /**
     * 
     * @type {number}
     * @memberof ClientsPortalApiClientPortalGetinvoicesFilteredofClientPerProject
     */
    readonly month?: number
}

/**
 * Request parameters for clientPortalGetinvoicesofClientPerProject operation in ClientsPortalApi.
 * @export
 * @interface ClientsPortalApiClientPortalGetinvoicesofClientPerProjectRequest
 */
export interface ClientsPortalApiClientPortalGetinvoicesofClientPerProjectRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ClientsPortalApiClientPortalGetinvoicesofClientPerProject
     */
    readonly id: string
}

/**
 * Request parameters for clientPortalUpsert operation in ClientsPortalApi.
 * @export
 * @interface ClientsPortalApiClientPortalUpsertRequest
 */
export interface ClientsPortalApiClientPortalUpsertRequest {
    /**
     * 
     * @type {UpsertContactDTO}
     * @memberof ClientsPortalApiClientPortalUpsert
     */
    readonly upsertContactDTO: UpsertContactDTO
}

/**
 * Request parameters for clientPortalUpsertAddress operation in ClientsPortalApi.
 * @export
 * @interface ClientsPortalApiClientPortalUpsertAddressRequest
 */
export interface ClientsPortalApiClientPortalUpsertAddressRequest {
    /**
     * 
     * @type {UpsertAddressDTO}
     * @memberof ClientsPortalApiClientPortalUpsertAddress
     */
    readonly upsertAddressDTO: UpsertAddressDTO
}

/**
 * ClientsPortalApi - object-oriented interface
 * @export
 * @class ClientsPortalApi
 * @extends {BaseAPI}
 */
export class ClientsPortalApi extends BaseAPI {
    /**
     * 
     * @param {ClientsPortalApiClientPortalFindByDateAndServiceTimeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalFindByDateAndServiceTime(requestParameters: ClientsPortalApiClientPortalFindByDateAndServiceTimeRequest, options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalFindByDateAndServiceTime(requestParameters.projectId, requestParameters.date, requestParameters.serviceTimeId, requestParameters.calendarEventDay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsPortalApiClientPortalGenerateScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGenerateSchedule(requestParameters: ClientsPortalApiClientPortalGenerateScheduleRequest, options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGenerateSchedule(requestParameters.generateScheduleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGetAppLink(options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGetAppLink(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsPortalApiClientPortalGetPaymentLinkFromInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGetPaymentLinkFromInvoice(requestParameters: ClientsPortalApiClientPortalGetPaymentLinkFromInvoiceRequest, options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGetPaymentLinkFromInvoice(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project by id
     * @param {ClientsPortalApiClientPortalGetProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGetProjectById(requestParameters: ClientsPortalApiClientPortalGetProjectByIdRequest, options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGetProjectById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGetProjectsOfClient(options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGetProjectsOfClient(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGetProposalsofClient(options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGetProposalsofClient(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsPortalApiClientPortalGetinvoicesFilteredofClientPerProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGetinvoicesFilteredofClientPerProject(requestParameters: ClientsPortalApiClientPortalGetinvoicesFilteredofClientPerProjectRequest = {}, options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGetinvoicesFilteredofClientPerProject(requestParameters.year, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGetinvoicesofClient(options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGetinvoicesofClient(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsPortalApiClientPortalGetinvoicesofClientPerProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalGetinvoicesofClientPerProject(requestParameters: ClientsPortalApiClientPortalGetinvoicesofClientPerProjectRequest, options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalGetinvoicesofClientPerProject(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsPortalApiClientPortalUpsertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalUpsert(requestParameters: ClientsPortalApiClientPortalUpsertRequest, options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalUpsert(requestParameters.upsertContactDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientsPortalApiClientPortalUpsertAddressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsPortalApi
     */
    public clientPortalUpsertAddress(requestParameters: ClientsPortalApiClientPortalUpsertAddressRequest, options?: AxiosRequestConfig) {
        return ClientsPortalApiFp(this.configuration).clientPortalUpsertAddress(requestParameters.upsertAddressDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyArchiveCompany: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyArchiveCompany', 'id', id)
            const localVarPath = `/company/archive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDeleteById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyDeleteById', 'id', id)
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Tax UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDeleteTaxById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyDeleteTaxById', 'id', id)
            const localVarPath = `/company/tax/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAllInactive: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/inactive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetCurrentCompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyGetOneById', 'id', id)
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyReactivateById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyReactivateById', 'id', id)
            const localVarPath = `/company/{id}/reactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUnarchiveCompany: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyUnarchiveCompany', 'id', id)
            const localVarPath = `/company/unarchive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCurrentCompanyDTO} updateCurrentCompanyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateCurrentCompany: async (updateCurrentCompanyDTO: UpdateCurrentCompanyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCurrentCompanyDTO' is not null or undefined
            assertParamExists('companyUpdateCurrentCompany', 'updateCurrentCompanyDTO', updateCurrentCompanyDTO)
            const localVarPath = `/company/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCurrentCompanyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {UpdateCompanyDTO} updateCompanyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateOneById: async (id: string, updateCompanyDTO: UpdateCompanyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyUpdateOneById', 'id', id)
            // verify required parameter 'updateCompanyDTO' is not null or undefined
            assertParamExists('companyUpdateOneById', 'updateCompanyDTO', updateCompanyDTO)
            const localVarPath = `/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyArchiveCompany(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyArchiveCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDeleteById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDeleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Tax UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyDeleteTaxById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyDeleteTaxById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetAllInactive(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGetAllInactive(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetCurrentCompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGetCurrentCompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGetOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyReactivateById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyReactivateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUnarchiveCompany(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUnarchiveCompany(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCurrentCompanyDTO} updateCurrentCompanyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdateCurrentCompany(updateCurrentCompanyDTO: UpdateCurrentCompanyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdateCurrentCompany(updateCurrentCompanyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {UpdateCompanyDTO} updateCompanyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdateOneById(id: string, updateCompanyDTO: UpdateCompanyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdateOneById(id, updateCompanyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyArchiveCompany(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.companyArchiveCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDeleteById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companyDeleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Tax UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyDeleteTaxById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companyDeleteTaxById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAll(options?: any): AxiosPromise<Array<CompanyResponseDTO>> {
            return localVarFp.companyGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAllInactive(options?: any): AxiosPromise<Array<CompanyResponseDTO>> {
            return localVarFp.companyGetAllInactive(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetCurrentCompany(options?: any): AxiosPromise<CompanyResponseDTO> {
            return localVarFp.companyGetCurrentCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetOneById(id: string, options?: any): AxiosPromise<CompanyResponseDTO> {
            return localVarFp.companyGetOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyReactivateById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companyReactivateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUnarchiveCompany(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.companyUnarchiveCompany(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCurrentCompanyDTO} updateCurrentCompanyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateCurrentCompany(updateCurrentCompanyDTO: UpdateCurrentCompanyDTO, options?: any): AxiosPromise<CompanyResponseDTO> {
            return localVarFp.companyUpdateCurrentCompany(updateCurrentCompanyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Company UUID
         * @param {UpdateCompanyDTO} updateCompanyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateOneById(id: string, updateCompanyDTO: UpdateCompanyDTO, options?: any): AxiosPromise<CompanyResponseDTO> {
            return localVarFp.companyUpdateOneById(id, updateCompanyDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyArchiveCompany operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyArchiveCompanyRequest
 */
export interface CompanyApiCompanyArchiveCompanyRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof CompanyApiCompanyArchiveCompany
     */
    readonly id: string
}

/**
 * Request parameters for companyDeleteById operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyDeleteByIdRequest
 */
export interface CompanyApiCompanyDeleteByIdRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof CompanyApiCompanyDeleteById
     */
    readonly id: string
}

/**
 * Request parameters for companyDeleteTaxById operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyDeleteTaxByIdRequest
 */
export interface CompanyApiCompanyDeleteTaxByIdRequest {
    /**
     * Tax UUID
     * @type {string}
     * @memberof CompanyApiCompanyDeleteTaxById
     */
    readonly id: string
}

/**
 * Request parameters for companyGetOneById operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyGetOneByIdRequest
 */
export interface CompanyApiCompanyGetOneByIdRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof CompanyApiCompanyGetOneById
     */
    readonly id: string
}

/**
 * Request parameters for companyReactivateById operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyReactivateByIdRequest
 */
export interface CompanyApiCompanyReactivateByIdRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof CompanyApiCompanyReactivateById
     */
    readonly id: string
}

/**
 * Request parameters for companyUnarchiveCompany operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyUnarchiveCompanyRequest
 */
export interface CompanyApiCompanyUnarchiveCompanyRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof CompanyApiCompanyUnarchiveCompany
     */
    readonly id: string
}

/**
 * Request parameters for companyUpdateCurrentCompany operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyUpdateCurrentCompanyRequest
 */
export interface CompanyApiCompanyUpdateCurrentCompanyRequest {
    /**
     * 
     * @type {UpdateCurrentCompanyDTO}
     * @memberof CompanyApiCompanyUpdateCurrentCompany
     */
    readonly updateCurrentCompanyDTO: UpdateCurrentCompanyDTO
}

/**
 * Request parameters for companyUpdateOneById operation in CompanyApi.
 * @export
 * @interface CompanyApiCompanyUpdateOneByIdRequest
 */
export interface CompanyApiCompanyUpdateOneByIdRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof CompanyApiCompanyUpdateOneById
     */
    readonly id: string

    /**
     * 
     * @type {UpdateCompanyDTO}
     * @memberof CompanyApiCompanyUpdateOneById
     */
    readonly updateCompanyDTO: UpdateCompanyDTO
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {CompanyApiCompanyArchiveCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyArchiveCompany(requestParameters: CompanyApiCompanyArchiveCompanyRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyArchiveCompany(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyDeleteByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDeleteById(requestParameters: CompanyApiCompanyDeleteByIdRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyDeleteById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyDeleteTaxByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyDeleteTaxById(requestParameters: CompanyApiCompanyDeleteTaxByIdRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyDeleteTaxById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetAll(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetAllInactive(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGetAllInactive(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetCurrentCompany(options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGetCurrentCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetOneById(requestParameters: CompanyApiCompanyGetOneByIdRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGetOneById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyReactivateByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyReactivateById(requestParameters: CompanyApiCompanyReactivateByIdRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyReactivateById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyUnarchiveCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUnarchiveCompany(requestParameters: CompanyApiCompanyUnarchiveCompanyRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUnarchiveCompany(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyUpdateCurrentCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdateCurrentCompany(requestParameters: CompanyApiCompanyUpdateCurrentCompanyRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdateCurrentCompany(requestParameters.updateCurrentCompanyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompanyUpdateOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdateOneById(requestParameters: CompanyApiCompanyUpdateOneByIdRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdateOneById(requestParameters.id, requestParameters.updateCompanyDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyInvitationApi - axios parameter creator
 * @export
 */
export const CompanyInvitationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {AcceptCompanyInvitationDTO} acceptCompanyInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationAcceptInvitation: async (token: string, acceptCompanyInvitationDTO: AcceptCompanyInvitationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('companyInvitationAcceptInvitation', 'token', token)
            // verify required parameter 'acceptCompanyInvitationDTO' is not null or undefined
            assertParamExists('companyInvitationAcceptInvitation', 'acceptCompanyInvitationDTO', acceptCompanyInvitationDTO)
            const localVarPath = `/company/invitation/accept-invitation/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptCompanyInvitationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCompanyInvitationDTO} createCompanyInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationCreate: async (createCompanyInvitationDTO: CreateCompanyInvitationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyInvitationDTO' is not null or undefined
            assertParamExists('companyInvitationCreate', 'createCompanyInvitationDTO', createCompanyInvitationDTO)
            const localVarPath = `/company/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyInvitationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Company invitation UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationDeleteInvitation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyInvitationDeleteInvitation', 'id', id)
            const localVarPath = `/company/invitation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationGetAllPaginated: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('companyInvitationGetAllPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('companyInvitationGetAllPaginated', 'items', items)
            const localVarPath = `/company/invitation/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationIsValidInvitation: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('companyInvitationIsValidInvitation', 'token', token)
            const localVarPath = `/company/invitation/is-valid/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {ResendCompanyInvitationDTO} [resendCompanyInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationResendInvitation: async (email: string, resendCompanyInvitationDTO?: ResendCompanyInvitationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('companyInvitationResendInvitation', 'email', email)
            const localVarPath = `/company/invitation/resend-invitation/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendCompanyInvitationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyInvitationApi - functional programming interface
 * @export
 */
export const CompanyInvitationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyInvitationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {AcceptCompanyInvitationDTO} acceptCompanyInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInvitationAcceptInvitation(token: string, acceptCompanyInvitationDTO: AcceptCompanyInvitationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptCompanyInvitationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInvitationAcceptInvitation(token, acceptCompanyInvitationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateCompanyInvitationDTO} createCompanyInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInvitationCreate(createCompanyInvitationDTO: CreateCompanyInvitationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInvitationCreate(createCompanyInvitationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Company invitation UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInvitationDeleteInvitation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInvitationDeleteInvitation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInvitationGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyInvitationResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInvitationGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInvitationGetAllPaginated(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInvitationPaginatedResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInvitationGetAllPaginated(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInvitationIsValidInvitation(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInvitationIsValidInvitation(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {ResendCompanyInvitationDTO} [resendCompanyInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInvitationResendInvitation(email: string, resendCompanyInvitationDTO?: ResendCompanyInvitationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInvitationResendInvitation(email, resendCompanyInvitationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyInvitationApi - factory interface
 * @export
 */
export const CompanyInvitationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyInvitationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {AcceptCompanyInvitationDTO} acceptCompanyInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationAcceptInvitation(token: string, acceptCompanyInvitationDTO: AcceptCompanyInvitationDTO, options?: any): AxiosPromise<AcceptCompanyInvitationResponseDTO> {
            return localVarFp.companyInvitationAcceptInvitation(token, acceptCompanyInvitationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateCompanyInvitationDTO} createCompanyInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationCreate(createCompanyInvitationDTO: CreateCompanyInvitationDTO, options?: any): AxiosPromise<void> {
            return localVarFp.companyInvitationCreate(createCompanyInvitationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Company invitation UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationDeleteInvitation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companyInvitationDeleteInvitation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationGetAll(options?: any): AxiosPromise<Array<CompanyInvitationResponseDTO>> {
            return localVarFp.companyInvitationGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationGetAllPaginated(page: number, items: number, options?: any): AxiosPromise<CompanyInvitationPaginatedResponseDTO> {
            return localVarFp.companyInvitationGetAllPaginated(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationIsValidInvitation(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.companyInvitationIsValidInvitation(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {ResendCompanyInvitationDTO} [resendCompanyInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInvitationResendInvitation(email: string, resendCompanyInvitationDTO?: ResendCompanyInvitationDTO, options?: any): AxiosPromise<void> {
            return localVarFp.companyInvitationResendInvitation(email, resendCompanyInvitationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companyInvitationAcceptInvitation operation in CompanyInvitationApi.
 * @export
 * @interface CompanyInvitationApiCompanyInvitationAcceptInvitationRequest
 */
export interface CompanyInvitationApiCompanyInvitationAcceptInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitationApiCompanyInvitationAcceptInvitation
     */
    readonly token: string

    /**
     * 
     * @type {AcceptCompanyInvitationDTO}
     * @memberof CompanyInvitationApiCompanyInvitationAcceptInvitation
     */
    readonly acceptCompanyInvitationDTO: AcceptCompanyInvitationDTO
}

/**
 * Request parameters for companyInvitationCreate operation in CompanyInvitationApi.
 * @export
 * @interface CompanyInvitationApiCompanyInvitationCreateRequest
 */
export interface CompanyInvitationApiCompanyInvitationCreateRequest {
    /**
     * 
     * @type {CreateCompanyInvitationDTO}
     * @memberof CompanyInvitationApiCompanyInvitationCreate
     */
    readonly createCompanyInvitationDTO: CreateCompanyInvitationDTO
}

/**
 * Request parameters for companyInvitationDeleteInvitation operation in CompanyInvitationApi.
 * @export
 * @interface CompanyInvitationApiCompanyInvitationDeleteInvitationRequest
 */
export interface CompanyInvitationApiCompanyInvitationDeleteInvitationRequest {
    /**
     * Company invitation UUID
     * @type {string}
     * @memberof CompanyInvitationApiCompanyInvitationDeleteInvitation
     */
    readonly id: string
}

/**
 * Request parameters for companyInvitationGetAllPaginated operation in CompanyInvitationApi.
 * @export
 * @interface CompanyInvitationApiCompanyInvitationGetAllPaginatedRequest
 */
export interface CompanyInvitationApiCompanyInvitationGetAllPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyInvitationApiCompanyInvitationGetAllPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof CompanyInvitationApiCompanyInvitationGetAllPaginated
     */
    readonly items: number
}

/**
 * Request parameters for companyInvitationIsValidInvitation operation in CompanyInvitationApi.
 * @export
 * @interface CompanyInvitationApiCompanyInvitationIsValidInvitationRequest
 */
export interface CompanyInvitationApiCompanyInvitationIsValidInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitationApiCompanyInvitationIsValidInvitation
     */
    readonly token: string
}

/**
 * Request parameters for companyInvitationResendInvitation operation in CompanyInvitationApi.
 * @export
 * @interface CompanyInvitationApiCompanyInvitationResendInvitationRequest
 */
export interface CompanyInvitationApiCompanyInvitationResendInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyInvitationApiCompanyInvitationResendInvitation
     */
    readonly email: string

    /**
     * 
     * @type {ResendCompanyInvitationDTO}
     * @memberof CompanyInvitationApiCompanyInvitationResendInvitation
     */
    readonly resendCompanyInvitationDTO?: ResendCompanyInvitationDTO
}

/**
 * CompanyInvitationApi - object-oriented interface
 * @export
 * @class CompanyInvitationApi
 * @extends {BaseAPI}
 */
export class CompanyInvitationApi extends BaseAPI {
    /**
     * 
     * @param {CompanyInvitationApiCompanyInvitationAcceptInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInvitationApi
     */
    public companyInvitationAcceptInvitation(requestParameters: CompanyInvitationApiCompanyInvitationAcceptInvitationRequest, options?: AxiosRequestConfig) {
        return CompanyInvitationApiFp(this.configuration).companyInvitationAcceptInvitation(requestParameters.token, requestParameters.acceptCompanyInvitationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyInvitationApiCompanyInvitationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInvitationApi
     */
    public companyInvitationCreate(requestParameters: CompanyInvitationApiCompanyInvitationCreateRequest, options?: AxiosRequestConfig) {
        return CompanyInvitationApiFp(this.configuration).companyInvitationCreate(requestParameters.createCompanyInvitationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyInvitationApiCompanyInvitationDeleteInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInvitationApi
     */
    public companyInvitationDeleteInvitation(requestParameters: CompanyInvitationApiCompanyInvitationDeleteInvitationRequest, options?: AxiosRequestConfig) {
        return CompanyInvitationApiFp(this.configuration).companyInvitationDeleteInvitation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInvitationApi
     */
    public companyInvitationGetAll(options?: AxiosRequestConfig) {
        return CompanyInvitationApiFp(this.configuration).companyInvitationGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyInvitationApiCompanyInvitationGetAllPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInvitationApi
     */
    public companyInvitationGetAllPaginated(requestParameters: CompanyInvitationApiCompanyInvitationGetAllPaginatedRequest, options?: AxiosRequestConfig) {
        return CompanyInvitationApiFp(this.configuration).companyInvitationGetAllPaginated(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyInvitationApiCompanyInvitationIsValidInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInvitationApi
     */
    public companyInvitationIsValidInvitation(requestParameters: CompanyInvitationApiCompanyInvitationIsValidInvitationRequest, options?: AxiosRequestConfig) {
        return CompanyInvitationApiFp(this.configuration).companyInvitationIsValidInvitation(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyInvitationApiCompanyInvitationResendInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInvitationApi
     */
    public companyInvitationResendInvitation(requestParameters: CompanyInvitationApiCompanyInvitationResendInvitationRequest, options?: AxiosRequestConfig) {
        return CompanyInvitationApiFp(this.configuration).companyInvitationResendInvitation(requestParameters.email, requestParameters.resendCompanyInvitationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationGetPasswordComplexityRules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration/password-complexity-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationGetPasswordComplexityRules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationPasswordComplexityDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationGetPasswordComplexityRules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationGetPasswordComplexityRules(options?: any): AxiosPromise<ConfigurationPasswordComplexityDTO> {
            return localVarFp.configurationGetPasswordComplexityRules(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public configurationGetPasswordComplexityRules(options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).configurationGetPasswordComplexityRules(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the invoices counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetInvoicesDashboardInformation: async (fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the lead counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetLeadDashboardInformation: async (fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the project counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetProjectDashboardInformation: async (fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the resources counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetResourcesDashboardInformation: async (fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the time counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetTimeDashboardInformation: async (fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the counters and data values for all categories in dashboard for a specific company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardUpdateCompanyDashboardValues: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/company-dashboard-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update the counters and data values for all categories in dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardUpdateDashboardValues: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dashboard/dashboard-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the invoices counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetInvoicesDashboardInformation(fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetInvoicesDashboardInformation(fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the lead counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetLeadDashboardInformation(fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetLeadDashboardInformation(fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the project counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetProjectDashboardInformation(fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetProjectDashboardInformation(fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the resources counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetResourcesDashboardInformation(fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetResourcesDashboardInformation(fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the time counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardGetTimeDashboardInformation(fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardGetTimeDashboardInformation(fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the counters and data values for all categories in dashboard for a specific company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardUpdateCompanyDashboardValues(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardUpdateCompanyDashboardValues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update the counters and data values for all categories in dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardUpdateDashboardValues(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardUpdateDashboardValues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the invoices counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetInvoicesDashboardInformation(fromDate?: string, toDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dashboardGetInvoicesDashboardInformation(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the lead counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetLeadDashboardInformation(fromDate?: string, toDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dashboardGetLeadDashboardInformation(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the project counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetProjectDashboardInformation(fromDate?: string, toDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dashboardGetProjectDashboardInformation(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the resources counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetResourcesDashboardInformation(fromDate?: string, toDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dashboardGetResourcesDashboardInformation(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the time counters for the dashboard
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardGetTimeDashboardInformation(fromDate?: string, toDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dashboardGetTimeDashboardInformation(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the counters and data values for all categories in dashboard for a specific company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardUpdateCompanyDashboardValues(options?: any): AxiosPromise<void> {
            return localVarFp.dashboardUpdateCompanyDashboardValues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update the counters and data values for all categories in dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardUpdateDashboardValues(options?: any): AxiosPromise<void> {
            return localVarFp.dashboardUpdateDashboardValues(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dashboardGetInvoicesDashboardInformation operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardGetInvoicesDashboardInformationRequest
 */
export interface DashboardApiDashboardGetInvoicesDashboardInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetInvoicesDashboardInformation
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetInvoicesDashboardInformation
     */
    readonly toDate?: string
}

/**
 * Request parameters for dashboardGetLeadDashboardInformation operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardGetLeadDashboardInformationRequest
 */
export interface DashboardApiDashboardGetLeadDashboardInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetLeadDashboardInformation
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetLeadDashboardInformation
     */
    readonly toDate?: string
}

/**
 * Request parameters for dashboardGetProjectDashboardInformation operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardGetProjectDashboardInformationRequest
 */
export interface DashboardApiDashboardGetProjectDashboardInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetProjectDashboardInformation
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetProjectDashboardInformation
     */
    readonly toDate?: string
}

/**
 * Request parameters for dashboardGetResourcesDashboardInformation operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardGetResourcesDashboardInformationRequest
 */
export interface DashboardApiDashboardGetResourcesDashboardInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetResourcesDashboardInformation
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetResourcesDashboardInformation
     */
    readonly toDate?: string
}

/**
 * Request parameters for dashboardGetTimeDashboardInformation operation in DashboardApi.
 * @export
 * @interface DashboardApiDashboardGetTimeDashboardInformationRequest
 */
export interface DashboardApiDashboardGetTimeDashboardInformationRequest {
    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetTimeDashboardInformation
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof DashboardApiDashboardGetTimeDashboardInformation
     */
    readonly toDate?: string
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Get the invoices counters for the dashboard
     * @param {DashboardApiDashboardGetInvoicesDashboardInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetInvoicesDashboardInformation(requestParameters: DashboardApiDashboardGetInvoicesDashboardInformationRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetInvoicesDashboardInformation(requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the lead counters for the dashboard
     * @param {DashboardApiDashboardGetLeadDashboardInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetLeadDashboardInformation(requestParameters: DashboardApiDashboardGetLeadDashboardInformationRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetLeadDashboardInformation(requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the project counters for the dashboard
     * @param {DashboardApiDashboardGetProjectDashboardInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetProjectDashboardInformation(requestParameters: DashboardApiDashboardGetProjectDashboardInformationRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetProjectDashboardInformation(requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the resources counters for the dashboard
     * @param {DashboardApiDashboardGetResourcesDashboardInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetResourcesDashboardInformation(requestParameters: DashboardApiDashboardGetResourcesDashboardInformationRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetResourcesDashboardInformation(requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the time counters for the dashboard
     * @param {DashboardApiDashboardGetTimeDashboardInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardGetTimeDashboardInformation(requestParameters: DashboardApiDashboardGetTimeDashboardInformationRequest = {}, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardGetTimeDashboardInformation(requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the counters and data values for all categories in dashboard for a specific company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardUpdateCompanyDashboardValues(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardUpdateCompanyDashboardValues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update the counters and data values for all categories in dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public dashboardUpdateDashboardValues(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).dashboardUpdateDashboardValues(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appGetHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHello(options?: any): AxiosPromise<void> {
            return localVarFp.appGetHello(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appGetHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appGetHello(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinancialApi - axios parameter creator
 * @export
 */
export const FinancialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialConnectFinancialAccount: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('financialConnectFinancialAccount', 'body', body)
            const localVarPath = `/financial/connect-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialConnectFinancialAccountConfirmation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialCreateCustomer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial/create-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialCreateInvoice: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial/create-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialDisconnectFinancialAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial/disconnect-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialGetAccountInformation: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('financialGetAccountInformation', 'body', body)
            const localVarPath = `/financial/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialGetCustomers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial/get-customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialGetFinancialIntegrations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialMatchCustomer: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('financialMatchCustomer', 'body', body)
            const localVarPath = `/financial/match-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialQuickBooksInvoiceWebhook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial/quickbooks/invoice-webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialRefreshToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialSynchCustomer: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('financialSynchCustomer', 'body', body)
            const localVarPath = `/financial/synch-customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialSynchFinancialAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financial/synch-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialUpdateWebhookSecret: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('financialUpdateWebhookSecret', 'body', body)
            const localVarPath = `/financial/update-webhook-secret`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinancialApi - functional programming interface
 * @export
 */
export const FinancialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinancialApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialConnectFinancialAccount(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialConnectFinancialAccount(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialConnectFinancialAccountConfirmation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialConnectFinancialAccountConfirmation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialCreateCustomer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialCreateCustomer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialCreateInvoice(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialCreateInvoice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialDisconnectFinancialAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialDisconnectFinancialAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialGetAccountInformation(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialGetAccountInformation(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialGetCustomers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialGetCustomers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialGetFinancialIntegrations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialGetFinancialIntegrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialMatchCustomer(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialMatchCustomer(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialQuickBooksInvoiceWebhook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialQuickBooksInvoiceWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialRefreshToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialRefreshToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialSynchCustomer(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialSynchCustomer(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialSynchFinancialAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialSynchFinancialAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financialUpdateWebhookSecret(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.financialUpdateWebhookSecret(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FinancialApi - factory interface
 * @export
 */
export const FinancialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinancialApiFp(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialConnectFinancialAccount(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.financialConnectFinancialAccount(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialConnectFinancialAccountConfirmation(options?: any): AxiosPromise<void> {
            return localVarFp.financialConnectFinancialAccountConfirmation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialCreateCustomer(options?: any): AxiosPromise<void> {
            return localVarFp.financialCreateCustomer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialCreateInvoice(options?: any): AxiosPromise<void> {
            return localVarFp.financialCreateInvoice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialDisconnectFinancialAccount(options?: any): AxiosPromise<void> {
            return localVarFp.financialDisconnectFinancialAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialGetAccountInformation(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.financialGetAccountInformation(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialGetCustomers(options?: any): AxiosPromise<void> {
            return localVarFp.financialGetCustomers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialGetFinancialIntegrations(options?: any): AxiosPromise<void> {
            return localVarFp.financialGetFinancialIntegrations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialMatchCustomer(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.financialMatchCustomer(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialQuickBooksInvoiceWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.financialQuickBooksInvoiceWebhook(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialRefreshToken(options?: any): AxiosPromise<void> {
            return localVarFp.financialRefreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialSynchCustomer(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.financialSynchCustomer(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialSynchFinancialAccount(options?: any): AxiosPromise<void> {
            return localVarFp.financialSynchFinancialAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financialUpdateWebhookSecret(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.financialUpdateWebhookSecret(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for financialConnectFinancialAccount operation in FinancialApi.
 * @export
 * @interface FinancialApiFinancialConnectFinancialAccountRequest
 */
export interface FinancialApiFinancialConnectFinancialAccountRequest {
    /**
     * 
     * @type {object}
     * @memberof FinancialApiFinancialConnectFinancialAccount
     */
    readonly body: object
}

/**
 * Request parameters for financialGetAccountInformation operation in FinancialApi.
 * @export
 * @interface FinancialApiFinancialGetAccountInformationRequest
 */
export interface FinancialApiFinancialGetAccountInformationRequest {
    /**
     * 
     * @type {object}
     * @memberof FinancialApiFinancialGetAccountInformation
     */
    readonly body: object
}

/**
 * Request parameters for financialMatchCustomer operation in FinancialApi.
 * @export
 * @interface FinancialApiFinancialMatchCustomerRequest
 */
export interface FinancialApiFinancialMatchCustomerRequest {
    /**
     * 
     * @type {object}
     * @memberof FinancialApiFinancialMatchCustomer
     */
    readonly body: object
}

/**
 * Request parameters for financialSynchCustomer operation in FinancialApi.
 * @export
 * @interface FinancialApiFinancialSynchCustomerRequest
 */
export interface FinancialApiFinancialSynchCustomerRequest {
    /**
     * 
     * @type {object}
     * @memberof FinancialApiFinancialSynchCustomer
     */
    readonly body: object
}

/**
 * Request parameters for financialUpdateWebhookSecret operation in FinancialApi.
 * @export
 * @interface FinancialApiFinancialUpdateWebhookSecretRequest
 */
export interface FinancialApiFinancialUpdateWebhookSecretRequest {
    /**
     * 
     * @type {object}
     * @memberof FinancialApiFinancialUpdateWebhookSecret
     */
    readonly body: object
}

/**
 * FinancialApi - object-oriented interface
 * @export
 * @class FinancialApi
 * @extends {BaseAPI}
 */
export class FinancialApi extends BaseAPI {
    /**
     * 
     * @param {FinancialApiFinancialConnectFinancialAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialConnectFinancialAccount(requestParameters: FinancialApiFinancialConnectFinancialAccountRequest, options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialConnectFinancialAccount(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialConnectFinancialAccountConfirmation(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialConnectFinancialAccountConfirmation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialCreateCustomer(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialCreateCustomer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialCreateInvoice(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialCreateInvoice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialDisconnectFinancialAccount(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialDisconnectFinancialAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinancialApiFinancialGetAccountInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialGetAccountInformation(requestParameters: FinancialApiFinancialGetAccountInformationRequest, options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialGetAccountInformation(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialGetCustomers(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialGetCustomers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialGetFinancialIntegrations(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialGetFinancialIntegrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinancialApiFinancialMatchCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialMatchCustomer(requestParameters: FinancialApiFinancialMatchCustomerRequest, options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialMatchCustomer(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialQuickBooksInvoiceWebhook(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialQuickBooksInvoiceWebhook(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialRefreshToken(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialRefreshToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinancialApiFinancialSynchCustomerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialSynchCustomer(requestParameters: FinancialApiFinancialSynchCustomerRequest, options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialSynchCustomer(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialSynchFinancialAccount(options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialSynchFinancialAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinancialApiFinancialUpdateWebhookSecretRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinancialApi
     */
    public financialUpdateWebhookSecret(requestParameters: FinancialApiFinancialUpdateWebhookSecretRequest, options?: AxiosRequestConfig) {
        return FinancialApiFp(this.configuration).financialUpdateWebhookSecret(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImpersonateApi - axios parameter creator
 * @export
 */
export const ImpersonateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateGetEmployesToImpersonate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('impersonateGetEmployesToImpersonate', 'id', id)
            const localVarPath = `/impersonate/employees/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateGetTokenForImpersonate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('impersonateGetTokenForImpersonate', 'id', id)
            const localVarPath = `/impersonate/impersonate-employee/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImpersonateApi - functional programming interface
 * @export
 */
export const ImpersonateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImpersonateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonateGetEmployesToImpersonate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.impersonateGetEmployesToImpersonate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async impersonateGetTokenForImpersonate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.impersonateGetTokenForImpersonate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImpersonateApi - factory interface
 * @export
 */
export const ImpersonateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImpersonateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id Company UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateGetEmployesToImpersonate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.impersonateGetEmployesToImpersonate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        impersonateGetTokenForImpersonate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.impersonateGetTokenForImpersonate(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for impersonateGetEmployesToImpersonate operation in ImpersonateApi.
 * @export
 * @interface ImpersonateApiImpersonateGetEmployesToImpersonateRequest
 */
export interface ImpersonateApiImpersonateGetEmployesToImpersonateRequest {
    /**
     * Company UUID
     * @type {string}
     * @memberof ImpersonateApiImpersonateGetEmployesToImpersonate
     */
    readonly id: string
}

/**
 * Request parameters for impersonateGetTokenForImpersonate operation in ImpersonateApi.
 * @export
 * @interface ImpersonateApiImpersonateGetTokenForImpersonateRequest
 */
export interface ImpersonateApiImpersonateGetTokenForImpersonateRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof ImpersonateApiImpersonateGetTokenForImpersonate
     */
    readonly id: string
}

/**
 * ImpersonateApi - object-oriented interface
 * @export
 * @class ImpersonateApi
 * @extends {BaseAPI}
 */
export class ImpersonateApi extends BaseAPI {
    /**
     * 
     * @param {ImpersonateApiImpersonateGetEmployesToImpersonateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonateApi
     */
    public impersonateGetEmployesToImpersonate(requestParameters: ImpersonateApiImpersonateGetEmployesToImpersonateRequest, options?: AxiosRequestConfig) {
        return ImpersonateApiFp(this.configuration).impersonateGetEmployesToImpersonate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImpersonateApiImpersonateGetTokenForImpersonateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonateApi
     */
    public impersonateGetTokenForImpersonate(requestParameters: ImpersonateApiImpersonateGetTokenForImpersonateRequest, options?: AxiosRequestConfig) {
        return ImpersonateApiFp(this.configuration).impersonateGetTokenForImpersonate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvitationApi - axios parameter creator
 * @export
 */
export const InvitationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {AcceptInvitationBodyDTO} acceptInvitationBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationAcceptsInvitation: async (token: string, acceptInvitationBodyDTO: AcceptInvitationBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('invitationAcceptsInvitation', 'token', token)
            // verify required parameter 'acceptInvitationBodyDTO' is not null or undefined
            assertParamExists('invitationAcceptsInvitation', 'acceptInvitationBodyDTO', acceptInvitationBodyDTO)
            const localVarPath = `/invitation/accept-invitation/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptInvitationBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateInvitationDTO} createInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationCreate: async (createInvitationDTO: CreateInvitationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInvitationDTO' is not null or undefined
            assertParamExists('invitationCreate', 'createInvitationDTO', createInvitationDTO)
            const localVarPath = `/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInvitationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationDeleteInvitation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invitationDeleteInvitation', 'id', id)
            const localVarPath = `/invitation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationIsValidInvitation: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('invitationIsValidInvitation', 'token', token)
            const localVarPath = `/invitation/is-valid/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationListInvitations: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('invitationListInvitations', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('invitationListInvitations', 'items', items)
            const localVarPath = `/invitation/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {ResendInvitationDTO} [resendInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationResendInvitation: async (email: string, resendInvitationDTO?: ResendInvitationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('invitationResendInvitation', 'email', email)
            const localVarPath = `/invitation/resend-invitation/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendInvitationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitationApi - functional programming interface
 * @export
 */
export const InvitationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {AcceptInvitationBodyDTO} acceptInvitationBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationAcceptsInvitation(token: string, acceptInvitationBodyDTO: AcceptInvitationBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptInvitationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationAcceptsInvitation(token, acceptInvitationBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateInvitationDTO} createInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationCreate(createInvitationDTO: CreateInvitationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationCreate(createInvitationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationDeleteInvitation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationDeleteInvitation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationIsValidInvitation(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationIsValidInvitation(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationListInvitations(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationsPaginationResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationListInvitations(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {ResendInvitationDTO} [resendInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationResendInvitation(email: string, resendInvitationDTO?: ResendInvitationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationResendInvitation(email, resendInvitationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvitationApi - factory interface
 * @export
 */
export const InvitationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {AcceptInvitationBodyDTO} acceptInvitationBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationAcceptsInvitation(token: string, acceptInvitationBodyDTO: AcceptInvitationBodyDTO, options?: any): AxiosPromise<AcceptInvitationResponseDTO> {
            return localVarFp.invitationAcceptsInvitation(token, acceptInvitationBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateInvitationDTO} createInvitationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationCreate(createInvitationDTO: CreateInvitationDTO, options?: any): AxiosPromise<void> {
            return localVarFp.invitationCreate(createInvitationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationDeleteInvitation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.invitationDeleteInvitation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationGetAll(options?: any): AxiosPromise<void> {
            return localVarFp.invitationGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationIsValidInvitation(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.invitationIsValidInvitation(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationListInvitations(page: number, items: number, options?: any): AxiosPromise<InvitationsPaginationResponseDTO> {
            return localVarFp.invitationListInvitations(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {ResendInvitationDTO} [resendInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationResendInvitation(email: string, resendInvitationDTO?: ResendInvitationDTO, options?: any): AxiosPromise<void> {
            return localVarFp.invitationResendInvitation(email, resendInvitationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for invitationAcceptsInvitation operation in InvitationApi.
 * @export
 * @interface InvitationApiInvitationAcceptsInvitationRequest
 */
export interface InvitationApiInvitationAcceptsInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitationApiInvitationAcceptsInvitation
     */
    readonly token: string

    /**
     * 
     * @type {AcceptInvitationBodyDTO}
     * @memberof InvitationApiInvitationAcceptsInvitation
     */
    readonly acceptInvitationBodyDTO: AcceptInvitationBodyDTO
}

/**
 * Request parameters for invitationCreate operation in InvitationApi.
 * @export
 * @interface InvitationApiInvitationCreateRequest
 */
export interface InvitationApiInvitationCreateRequest {
    /**
     * 
     * @type {CreateInvitationDTO}
     * @memberof InvitationApiInvitationCreate
     */
    readonly createInvitationDTO: CreateInvitationDTO
}

/**
 * Request parameters for invitationDeleteInvitation operation in InvitationApi.
 * @export
 * @interface InvitationApiInvitationDeleteInvitationRequest
 */
export interface InvitationApiInvitationDeleteInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitationApiInvitationDeleteInvitation
     */
    readonly id: string
}

/**
 * Request parameters for invitationIsValidInvitation operation in InvitationApi.
 * @export
 * @interface InvitationApiInvitationIsValidInvitationRequest
 */
export interface InvitationApiInvitationIsValidInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitationApiInvitationIsValidInvitation
     */
    readonly token: string
}

/**
 * Request parameters for invitationListInvitations operation in InvitationApi.
 * @export
 * @interface InvitationApiInvitationListInvitationsRequest
 */
export interface InvitationApiInvitationListInvitationsRequest {
    /**
     * 
     * @type {number}
     * @memberof InvitationApiInvitationListInvitations
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof InvitationApiInvitationListInvitations
     */
    readonly items: number
}

/**
 * Request parameters for invitationResendInvitation operation in InvitationApi.
 * @export
 * @interface InvitationApiInvitationResendInvitationRequest
 */
export interface InvitationApiInvitationResendInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitationApiInvitationResendInvitation
     */
    readonly email: string

    /**
     * 
     * @type {ResendInvitationDTO}
     * @memberof InvitationApiInvitationResendInvitation
     */
    readonly resendInvitationDTO?: ResendInvitationDTO
}

/**
 * InvitationApi - object-oriented interface
 * @export
 * @class InvitationApi
 * @extends {BaseAPI}
 */
export class InvitationApi extends BaseAPI {
    /**
     * 
     * @param {InvitationApiInvitationAcceptsInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public invitationAcceptsInvitation(requestParameters: InvitationApiInvitationAcceptsInvitationRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).invitationAcceptsInvitation(requestParameters.token, requestParameters.acceptInvitationBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitationApiInvitationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public invitationCreate(requestParameters: InvitationApiInvitationCreateRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).invitationCreate(requestParameters.createInvitationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitationApiInvitationDeleteInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public invitationDeleteInvitation(requestParameters: InvitationApiInvitationDeleteInvitationRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).invitationDeleteInvitation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public invitationGetAll(options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).invitationGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitationApiInvitationIsValidInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public invitationIsValidInvitation(requestParameters: InvitationApiInvitationIsValidInvitationRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).invitationIsValidInvitation(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitationApiInvitationListInvitationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public invitationListInvitations(requestParameters: InvitationApiInvitationListInvitationsRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).invitationListInvitations(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvitationApiInvitationResendInvitationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitationApi
     */
    public invitationResendInvitation(requestParameters: InvitationApiInvitationResendInvitationRequest, options?: AxiosRequestConfig) {
        return InvitationApiFp(this.configuration).invitationResendInvitation(requestParameters.email, requestParameters.resendInvitationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceManagementApi - axios parameter creator
 * @export
 */
export const InvoiceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get invoice by id
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGetInvoiceById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoiceGetInvoiceById', 'id', id)
            const localVarPath = `/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get invoice by token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGetInvoiceByToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('invoiceGetInvoiceByToken', 'token', token)
            const localVarPath = `/invoices/view/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all invoices with filters
         * @param {string} projectId Project UUID
         * @param {'PENDING' | 'OVERDUE' | 'PAID' | 'CANCELLED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGetInvoiceList: async (projectId: string, status?: 'PENDING' | 'OVERDUE' | 'PAID' | 'CANCELLED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('invoiceGetInvoiceList', 'projectId', projectId)
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGetinvoicesFilteredofClientPerProject: async (clientId: string, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('invoiceGetinvoicesFilteredofClientPerProject', 'clientId', clientId)
            const localVarPath = `/invoices/client-invoices-filtered/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend invoice to customer
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceResendInvoice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoiceResendInvoice', 'id', id)
            const localVarPath = `/invoices/{id}/resend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update invoice status
         * @param {string} id Invoice UUID
         * @param {UpdateInvoiceStatusDTO} updateInvoiceStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceUpdateInvoiceStatus: async (id: string, updateInvoiceStatusDTO: UpdateInvoiceStatusDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('invoiceUpdateInvoiceStatus', 'id', id)
            // verify required parameter 'updateInvoiceStatusDTO' is not null or undefined
            assertParamExists('invoiceUpdateInvoiceStatus', 'updateInvoiceStatusDTO', updateInvoiceStatusDTO)
            const localVarPath = `/invoices/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoiceStatusDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceManagementApi - functional programming interface
 * @export
 */
export const InvoiceManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get invoice by id
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceGetInvoiceById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceByIdResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceGetInvoiceById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get invoice by token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceGetInvoiceByToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceByIdResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceGetInvoiceByToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all invoices with filters
         * @param {string} projectId Project UUID
         * @param {'PENDING' | 'OVERDUE' | 'PAID' | 'CANCELLED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceGetInvoiceList(projectId: string, status?: 'PENDING' | 'OVERDUE' | 'PAID' | 'CANCELLED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvoiceListResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceGetInvoiceList(projectId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceGetinvoicesFilteredofClientPerProject(clientId: string, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceGetinvoicesFilteredofClientPerProject(clientId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend invoice to customer
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceResendInvoice(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceResendInvoice(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update invoice status
         * @param {string} id Invoice UUID
         * @param {UpdateInvoiceStatusDTO} updateInvoiceStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoiceUpdateInvoiceStatus(id: string, updateInvoiceStatusDTO: UpdateInvoiceStatusDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoiceUpdateInvoiceStatus(id, updateInvoiceStatusDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceManagementApi - factory interface
 * @export
 */
export const InvoiceManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceManagementApiFp(configuration)
    return {
        /**
         * 
         * @summary Get invoice by id
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGetInvoiceById(id: string, options?: any): AxiosPromise<InvoiceByIdResponseDTO> {
            return localVarFp.invoiceGetInvoiceById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get invoice by token
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGetInvoiceByToken(token: string, options?: any): AxiosPromise<InvoiceByIdResponseDTO> {
            return localVarFp.invoiceGetInvoiceByToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all invoices with filters
         * @param {string} projectId Project UUID
         * @param {'PENDING' | 'OVERDUE' | 'PAID' | 'CANCELLED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGetInvoiceList(projectId: string, status?: 'PENDING' | 'OVERDUE' | 'PAID' | 'CANCELLED', options?: any): AxiosPromise<Array<InvoiceListResponseDTO>> {
            return localVarFp.invoiceGetInvoiceList(projectId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId Client UUID
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceGetinvoicesFilteredofClientPerProject(clientId: string, year?: number, month?: number, options?: any): AxiosPromise<void> {
            return localVarFp.invoiceGetinvoicesFilteredofClientPerProject(clientId, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend invoice to customer
         * @param {string} id Invoice UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceResendInvoice(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.invoiceResendInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update invoice status
         * @param {string} id Invoice UUID
         * @param {UpdateInvoiceStatusDTO} updateInvoiceStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoiceUpdateInvoiceStatus(id: string, updateInvoiceStatusDTO: UpdateInvoiceStatusDTO, options?: any): AxiosPromise<void> {
            return localVarFp.invoiceUpdateInvoiceStatus(id, updateInvoiceStatusDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for invoiceGetInvoiceById operation in InvoiceManagementApi.
 * @export
 * @interface InvoiceManagementApiInvoiceGetInvoiceByIdRequest
 */
export interface InvoiceManagementApiInvoiceGetInvoiceByIdRequest {
    /**
     * Invoice UUID
     * @type {string}
     * @memberof InvoiceManagementApiInvoiceGetInvoiceById
     */
    readonly id: string
}

/**
 * Request parameters for invoiceGetInvoiceByToken operation in InvoiceManagementApi.
 * @export
 * @interface InvoiceManagementApiInvoiceGetInvoiceByTokenRequest
 */
export interface InvoiceManagementApiInvoiceGetInvoiceByTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof InvoiceManagementApiInvoiceGetInvoiceByToken
     */
    readonly token: string
}

/**
 * Request parameters for invoiceGetInvoiceList operation in InvoiceManagementApi.
 * @export
 * @interface InvoiceManagementApiInvoiceGetInvoiceListRequest
 */
export interface InvoiceManagementApiInvoiceGetInvoiceListRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof InvoiceManagementApiInvoiceGetInvoiceList
     */
    readonly projectId: string

    /**
     * 
     * @type {'PENDING' | 'OVERDUE' | 'PAID' | 'CANCELLED'}
     * @memberof InvoiceManagementApiInvoiceGetInvoiceList
     */
    readonly status?: 'PENDING' | 'OVERDUE' | 'PAID' | 'CANCELLED'
}

/**
 * Request parameters for invoiceGetinvoicesFilteredofClientPerProject operation in InvoiceManagementApi.
 * @export
 * @interface InvoiceManagementApiInvoiceGetinvoicesFilteredofClientPerProjectRequest
 */
export interface InvoiceManagementApiInvoiceGetinvoicesFilteredofClientPerProjectRequest {
    /**
     * Client UUID
     * @type {string}
     * @memberof InvoiceManagementApiInvoiceGetinvoicesFilteredofClientPerProject
     */
    readonly clientId: string

    /**
     * 
     * @type {number}
     * @memberof InvoiceManagementApiInvoiceGetinvoicesFilteredofClientPerProject
     */
    readonly year?: number

    /**
     * 
     * @type {number}
     * @memberof InvoiceManagementApiInvoiceGetinvoicesFilteredofClientPerProject
     */
    readonly month?: number
}

/**
 * Request parameters for invoiceResendInvoice operation in InvoiceManagementApi.
 * @export
 * @interface InvoiceManagementApiInvoiceResendInvoiceRequest
 */
export interface InvoiceManagementApiInvoiceResendInvoiceRequest {
    /**
     * Invoice UUID
     * @type {string}
     * @memberof InvoiceManagementApiInvoiceResendInvoice
     */
    readonly id: string
}

/**
 * Request parameters for invoiceUpdateInvoiceStatus operation in InvoiceManagementApi.
 * @export
 * @interface InvoiceManagementApiInvoiceUpdateInvoiceStatusRequest
 */
export interface InvoiceManagementApiInvoiceUpdateInvoiceStatusRequest {
    /**
     * Invoice UUID
     * @type {string}
     * @memberof InvoiceManagementApiInvoiceUpdateInvoiceStatus
     */
    readonly id: string

    /**
     * 
     * @type {UpdateInvoiceStatusDTO}
     * @memberof InvoiceManagementApiInvoiceUpdateInvoiceStatus
     */
    readonly updateInvoiceStatusDTO: UpdateInvoiceStatusDTO
}

/**
 * InvoiceManagementApi - object-oriented interface
 * @export
 * @class InvoiceManagementApi
 * @extends {BaseAPI}
 */
export class InvoiceManagementApi extends BaseAPI {
    /**
     * 
     * @summary Get invoice by id
     * @param {InvoiceManagementApiInvoiceGetInvoiceByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceManagementApi
     */
    public invoiceGetInvoiceById(requestParameters: InvoiceManagementApiInvoiceGetInvoiceByIdRequest, options?: AxiosRequestConfig) {
        return InvoiceManagementApiFp(this.configuration).invoiceGetInvoiceById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get invoice by token
     * @param {InvoiceManagementApiInvoiceGetInvoiceByTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceManagementApi
     */
    public invoiceGetInvoiceByToken(requestParameters: InvoiceManagementApiInvoiceGetInvoiceByTokenRequest, options?: AxiosRequestConfig) {
        return InvoiceManagementApiFp(this.configuration).invoiceGetInvoiceByToken(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all invoices with filters
     * @param {InvoiceManagementApiInvoiceGetInvoiceListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceManagementApi
     */
    public invoiceGetInvoiceList(requestParameters: InvoiceManagementApiInvoiceGetInvoiceListRequest, options?: AxiosRequestConfig) {
        return InvoiceManagementApiFp(this.configuration).invoiceGetInvoiceList(requestParameters.projectId, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InvoiceManagementApiInvoiceGetinvoicesFilteredofClientPerProjectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceManagementApi
     */
    public invoiceGetinvoicesFilteredofClientPerProject(requestParameters: InvoiceManagementApiInvoiceGetinvoicesFilteredofClientPerProjectRequest, options?: AxiosRequestConfig) {
        return InvoiceManagementApiFp(this.configuration).invoiceGetinvoicesFilteredofClientPerProject(requestParameters.clientId, requestParameters.year, requestParameters.month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend invoice to customer
     * @param {InvoiceManagementApiInvoiceResendInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceManagementApi
     */
    public invoiceResendInvoice(requestParameters: InvoiceManagementApiInvoiceResendInvoiceRequest, options?: AxiosRequestConfig) {
        return InvoiceManagementApiFp(this.configuration).invoiceResendInvoice(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update invoice status
     * @param {InvoiceManagementApiInvoiceUpdateInvoiceStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceManagementApi
     */
    public invoiceUpdateInvoiceStatus(requestParameters: InvoiceManagementApiInvoiceUpdateInvoiceStatusRequest, options?: AxiosRequestConfig) {
        return InvoiceManagementApiFp(this.configuration).invoiceUpdateInvoiceStatus(requestParameters.id, requestParameters.updateInvoiceStatusDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadIndividualTasksApi - axios parameter creator
 * @export
 */
export const LeadIndividualTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadIndividualTaskCreate: async (serviceTimeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadIndividualTaskCreate', 'serviceTimeId', serviceTimeId)
            const localVarPath = `/service-times/{serviceTimeId}/individual-tasks`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} individualTaskId Individual Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadIndividualTaskDelete: async (serviceTimeId: number, individualTaskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadIndividualTaskDelete', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'individualTaskId' is not null or undefined
            assertParamExists('leadIndividualTaskDelete', 'individualTaskId', individualTaskId)
            const localVarPath = `/service-times/{serviceTimeId}/individual-tasks/{individualTaskId}`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)))
                .replace(`{${"individualTaskId"}}`, encodeURIComponent(String(individualTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} individualTaskId Individual Task ID
         * @param {UpdateLeadIndividualTaskDto} updateLeadIndividualTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadIndividualTaskUpdate: async (serviceTimeId: number, individualTaskId: number, updateLeadIndividualTaskDto: UpdateLeadIndividualTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadIndividualTaskUpdate', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'individualTaskId' is not null or undefined
            assertParamExists('leadIndividualTaskUpdate', 'individualTaskId', individualTaskId)
            // verify required parameter 'updateLeadIndividualTaskDto' is not null or undefined
            assertParamExists('leadIndividualTaskUpdate', 'updateLeadIndividualTaskDto', updateLeadIndividualTaskDto)
            const localVarPath = `/service-times/{serviceTimeId}/individual-tasks/{individualTaskId}`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)))
                .replace(`{${"individualTaskId"}}`, encodeURIComponent(String(individualTaskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLeadIndividualTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {UpdateLeadIndividualTaskTimesDto} updateLeadIndividualTaskTimesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadIndividualTaskUpdateTimes: async (serviceTimeId: number, updateLeadIndividualTaskTimesDto: UpdateLeadIndividualTaskTimesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadIndividualTaskUpdateTimes', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'updateLeadIndividualTaskTimesDto' is not null or undefined
            assertParamExists('leadIndividualTaskUpdateTimes', 'updateLeadIndividualTaskTimesDto', updateLeadIndividualTaskTimesDto)
            const localVarPath = `/service-times/{serviceTimeId}/individual-tasks`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLeadIndividualTaskTimesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadIndividualTasksApi - functional programming interface
 * @export
 */
export const LeadIndividualTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadIndividualTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadIndividualTaskCreate(serviceTimeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadIndividualTaskResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadIndividualTaskCreate(serviceTimeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} individualTaskId Individual Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadIndividualTaskDelete(serviceTimeId: number, individualTaskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadIndividualTaskDelete(serviceTimeId, individualTaskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} individualTaskId Individual Task ID
         * @param {UpdateLeadIndividualTaskDto} updateLeadIndividualTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadIndividualTaskUpdate(serviceTimeId: number, individualTaskId: number, updateLeadIndividualTaskDto: UpdateLeadIndividualTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadIndividualTaskUpdate(serviceTimeId, individualTaskId, updateLeadIndividualTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {UpdateLeadIndividualTaskTimesDto} updateLeadIndividualTaskTimesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadIndividualTaskUpdateTimes(serviceTimeId: number, updateLeadIndividualTaskTimesDto: UpdateLeadIndividualTaskTimesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadIndividualTaskUpdateTimes(serviceTimeId, updateLeadIndividualTaskTimesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadIndividualTasksApi - factory interface
 * @export
 */
export const LeadIndividualTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadIndividualTasksApiFp(configuration)
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadIndividualTaskCreate(serviceTimeId: number, options?: any): AxiosPromise<LeadIndividualTaskResponseDto> {
            return localVarFp.leadIndividualTaskCreate(serviceTimeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} individualTaskId Individual Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadIndividualTaskDelete(serviceTimeId: number, individualTaskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.leadIndividualTaskDelete(serviceTimeId, individualTaskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} individualTaskId Individual Task ID
         * @param {UpdateLeadIndividualTaskDto} updateLeadIndividualTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadIndividualTaskUpdate(serviceTimeId: number, individualTaskId: number, updateLeadIndividualTaskDto: UpdateLeadIndividualTaskDto, options?: any): AxiosPromise<void> {
            return localVarFp.leadIndividualTaskUpdate(serviceTimeId, individualTaskId, updateLeadIndividualTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {UpdateLeadIndividualTaskTimesDto} updateLeadIndividualTaskTimesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadIndividualTaskUpdateTimes(serviceTimeId: number, updateLeadIndividualTaskTimesDto: UpdateLeadIndividualTaskTimesDto, options?: any): AxiosPromise<void> {
            return localVarFp.leadIndividualTaskUpdateTimes(serviceTimeId, updateLeadIndividualTaskTimesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for leadIndividualTaskCreate operation in LeadIndividualTasksApi.
 * @export
 * @interface LeadIndividualTasksApiLeadIndividualTaskCreateRequest
 */
export interface LeadIndividualTasksApiLeadIndividualTaskCreateRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadIndividualTasksApiLeadIndividualTaskCreate
     */
    readonly serviceTimeId: number
}

/**
 * Request parameters for leadIndividualTaskDelete operation in LeadIndividualTasksApi.
 * @export
 * @interface LeadIndividualTasksApiLeadIndividualTaskDeleteRequest
 */
export interface LeadIndividualTasksApiLeadIndividualTaskDeleteRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadIndividualTasksApiLeadIndividualTaskDelete
     */
    readonly serviceTimeId: number

    /**
     * Individual Task ID
     * @type {number}
     * @memberof LeadIndividualTasksApiLeadIndividualTaskDelete
     */
    readonly individualTaskId: number
}

/**
 * Request parameters for leadIndividualTaskUpdate operation in LeadIndividualTasksApi.
 * @export
 * @interface LeadIndividualTasksApiLeadIndividualTaskUpdateRequest
 */
export interface LeadIndividualTasksApiLeadIndividualTaskUpdateRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadIndividualTasksApiLeadIndividualTaskUpdate
     */
    readonly serviceTimeId: number

    /**
     * Individual Task ID
     * @type {number}
     * @memberof LeadIndividualTasksApiLeadIndividualTaskUpdate
     */
    readonly individualTaskId: number

    /**
     * 
     * @type {UpdateLeadIndividualTaskDto}
     * @memberof LeadIndividualTasksApiLeadIndividualTaskUpdate
     */
    readonly updateLeadIndividualTaskDto: UpdateLeadIndividualTaskDto
}

/**
 * Request parameters for leadIndividualTaskUpdateTimes operation in LeadIndividualTasksApi.
 * @export
 * @interface LeadIndividualTasksApiLeadIndividualTaskUpdateTimesRequest
 */
export interface LeadIndividualTasksApiLeadIndividualTaskUpdateTimesRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadIndividualTasksApiLeadIndividualTaskUpdateTimes
     */
    readonly serviceTimeId: number

    /**
     * 
     * @type {UpdateLeadIndividualTaskTimesDto}
     * @memberof LeadIndividualTasksApiLeadIndividualTaskUpdateTimes
     */
    readonly updateLeadIndividualTaskTimesDto: UpdateLeadIndividualTaskTimesDto
}

/**
 * LeadIndividualTasksApi - object-oriented interface
 * @export
 * @class LeadIndividualTasksApi
 * @extends {BaseAPI}
 */
export class LeadIndividualTasksApi extends BaseAPI {
    /**
     * 
     * @param {LeadIndividualTasksApiLeadIndividualTaskCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadIndividualTasksApi
     */
    public leadIndividualTaskCreate(requestParameters: LeadIndividualTasksApiLeadIndividualTaskCreateRequest, options?: AxiosRequestConfig) {
        return LeadIndividualTasksApiFp(this.configuration).leadIndividualTaskCreate(requestParameters.serviceTimeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadIndividualTasksApiLeadIndividualTaskDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadIndividualTasksApi
     */
    public leadIndividualTaskDelete(requestParameters: LeadIndividualTasksApiLeadIndividualTaskDeleteRequest, options?: AxiosRequestConfig) {
        return LeadIndividualTasksApiFp(this.configuration).leadIndividualTaskDelete(requestParameters.serviceTimeId, requestParameters.individualTaskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadIndividualTasksApiLeadIndividualTaskUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadIndividualTasksApi
     */
    public leadIndividualTaskUpdate(requestParameters: LeadIndividualTasksApiLeadIndividualTaskUpdateRequest, options?: AxiosRequestConfig) {
        return LeadIndividualTasksApiFp(this.configuration).leadIndividualTaskUpdate(requestParameters.serviceTimeId, requestParameters.individualTaskId, requestParameters.updateLeadIndividualTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadIndividualTasksApiLeadIndividualTaskUpdateTimesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadIndividualTasksApi
     */
    public leadIndividualTaskUpdateTimes(requestParameters: LeadIndividualTasksApiLeadIndividualTaskUpdateTimesRequest, options?: AxiosRequestConfig) {
        return LeadIndividualTasksApiFp(this.configuration).leadIndividualTaskUpdateTimes(requestParameters.serviceTimeId, requestParameters.updateLeadIndividualTaskTimesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadManagementApi - axios parameter creator
 * @export
 */
export const LeadManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLeadGeneralInfoDto} createLeadGeneralInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadCreate: async (createLeadGeneralInfoDto: CreateLeadGeneralInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLeadGeneralInfoDto' is not null or undefined
            assertParamExists('leadCreate', 'createLeadGeneralInfoDto', createLeadGeneralInfoDto)
            const localVarPath = `/leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeadGeneralInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFindAllLeadForACompany: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFindAllPaginate: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('leadFindAllPaginate', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('leadFindAllPaginate', 'items', items)
            const localVarPath = `/leads/test/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadFindOne', 'id', id)
            const localVarPath = `/leads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadGetLeadsPaginated: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('leadGetLeadsPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('leadGetLeadsPaginated', 'items', items)
            const localVarPath = `/leads/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadGetLeadsPaginatedForListing: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('leadGetLeadsPaginatedForListing', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('leadGetLeadsPaginatedForListing', 'items', items)
            const localVarPath = `/leads/paginate-listing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {UpdateLeadGeneralInfoDto} updateLeadGeneralInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadUpdate: async (id: string, updateLeadGeneralInfoDto: UpdateLeadGeneralInfoDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadUpdate', 'id', id)
            // verify required parameter 'updateLeadGeneralInfoDto' is not null or undefined
            assertParamExists('leadUpdate', 'updateLeadGeneralInfoDto', updateLeadGeneralInfoDto)
            const localVarPath = `/leads/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLeadGeneralInfoDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadValidateLead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadValidateLead', 'id', id)
            const localVarPath = `/leads/validate-lead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadManagementApi - functional programming interface
 * @export
 */
export const LeadManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLeadGeneralInfoDto} createLeadGeneralInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadCreate(createLeadGeneralInfoDto: CreateLeadGeneralInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadGeneralInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadCreate(createLeadGeneralInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadFindAllLeadForACompany(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadGeneralInfoResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadFindAllLeadForACompany(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadFindAllPaginate(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadPaginationResponse2DTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadFindAllPaginate(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadGeneralInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadGetLeadsPaginated(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadPaginationResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadGetLeadsPaginated(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadGetLeadsPaginatedForListing(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadPaginationResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadGetLeadsPaginatedForListing(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {UpdateLeadGeneralInfoDto} updateLeadGeneralInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadUpdate(id: string, updateLeadGeneralInfoDto: UpdateLeadGeneralInfoDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadGeneralInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadUpdate(id, updateLeadGeneralInfoDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadValidateLead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadGeneralInfoResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadValidateLead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadManagementApi - factory interface
 * @export
 */
export const LeadManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLeadGeneralInfoDto} createLeadGeneralInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadCreate(createLeadGeneralInfoDto: CreateLeadGeneralInfoDto, options?: any): AxiosPromise<LeadGeneralInfoResponseDTO> {
            return localVarFp.leadCreate(createLeadGeneralInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFindAllLeadForACompany(options?: any): AxiosPromise<Array<LeadGeneralInfoResponseDTO>> {
            return localVarFp.leadFindAllLeadForACompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFindAllPaginate(page: number, items: number, options?: any): AxiosPromise<LeadPaginationResponse2DTO> {
            return localVarFp.leadFindAllPaginate(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadFindOne(id: string, options?: any): AxiosPromise<LeadGeneralInfoResponseDTO> {
            return localVarFp.leadFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadGetLeadsPaginated(page: number, items: number, options?: any): AxiosPromise<Array<LeadPaginationResponseDTO>> {
            return localVarFp.leadGetLeadsPaginated(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadGetLeadsPaginatedForListing(page: number, items: number, options?: any): AxiosPromise<Array<LeadPaginationResponseDTO>> {
            return localVarFp.leadGetLeadsPaginatedForListing(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {UpdateLeadGeneralInfoDto} updateLeadGeneralInfoDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadUpdate(id: string, updateLeadGeneralInfoDto: UpdateLeadGeneralInfoDto, options?: any): AxiosPromise<LeadGeneralInfoResponseDTO> {
            return localVarFp.leadUpdate(id, updateLeadGeneralInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadValidateLead(id: string, options?: any): AxiosPromise<LeadGeneralInfoResponseDTO> {
            return localVarFp.leadValidateLead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for leadCreate operation in LeadManagementApi.
 * @export
 * @interface LeadManagementApiLeadCreateRequest
 */
export interface LeadManagementApiLeadCreateRequest {
    /**
     * 
     * @type {CreateLeadGeneralInfoDto}
     * @memberof LeadManagementApiLeadCreate
     */
    readonly createLeadGeneralInfoDto: CreateLeadGeneralInfoDto
}

/**
 * Request parameters for leadFindAllPaginate operation in LeadManagementApi.
 * @export
 * @interface LeadManagementApiLeadFindAllPaginateRequest
 */
export interface LeadManagementApiLeadFindAllPaginateRequest {
    /**
     * 
     * @type {number}
     * @memberof LeadManagementApiLeadFindAllPaginate
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof LeadManagementApiLeadFindAllPaginate
     */
    readonly items: number
}

/**
 * Request parameters for leadFindOne operation in LeadManagementApi.
 * @export
 * @interface LeadManagementApiLeadFindOneRequest
 */
export interface LeadManagementApiLeadFindOneRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadManagementApiLeadFindOne
     */
    readonly id: string
}

/**
 * Request parameters for leadGetLeadsPaginated operation in LeadManagementApi.
 * @export
 * @interface LeadManagementApiLeadGetLeadsPaginatedRequest
 */
export interface LeadManagementApiLeadGetLeadsPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof LeadManagementApiLeadGetLeadsPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof LeadManagementApiLeadGetLeadsPaginated
     */
    readonly items: number
}

/**
 * Request parameters for leadGetLeadsPaginatedForListing operation in LeadManagementApi.
 * @export
 * @interface LeadManagementApiLeadGetLeadsPaginatedForListingRequest
 */
export interface LeadManagementApiLeadGetLeadsPaginatedForListingRequest {
    /**
     * 
     * @type {number}
     * @memberof LeadManagementApiLeadGetLeadsPaginatedForListing
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof LeadManagementApiLeadGetLeadsPaginatedForListing
     */
    readonly items: number
}

/**
 * Request parameters for leadUpdate operation in LeadManagementApi.
 * @export
 * @interface LeadManagementApiLeadUpdateRequest
 */
export interface LeadManagementApiLeadUpdateRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadManagementApiLeadUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateLeadGeneralInfoDto}
     * @memberof LeadManagementApiLeadUpdate
     */
    readonly updateLeadGeneralInfoDto: UpdateLeadGeneralInfoDto
}

/**
 * Request parameters for leadValidateLead operation in LeadManagementApi.
 * @export
 * @interface LeadManagementApiLeadValidateLeadRequest
 */
export interface LeadManagementApiLeadValidateLeadRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadManagementApiLeadValidateLead
     */
    readonly id: string
}

/**
 * LeadManagementApi - object-oriented interface
 * @export
 * @class LeadManagementApi
 * @extends {BaseAPI}
 */
export class LeadManagementApi extends BaseAPI {
    /**
     * 
     * @param {LeadManagementApiLeadCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadManagementApi
     */
    public leadCreate(requestParameters: LeadManagementApiLeadCreateRequest, options?: AxiosRequestConfig) {
        return LeadManagementApiFp(this.configuration).leadCreate(requestParameters.createLeadGeneralInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadManagementApi
     */
    public leadFindAllLeadForACompany(options?: AxiosRequestConfig) {
        return LeadManagementApiFp(this.configuration).leadFindAllLeadForACompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadManagementApiLeadFindAllPaginateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadManagementApi
     */
    public leadFindAllPaginate(requestParameters: LeadManagementApiLeadFindAllPaginateRequest, options?: AxiosRequestConfig) {
        return LeadManagementApiFp(this.configuration).leadFindAllPaginate(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadManagementApiLeadFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadManagementApi
     */
    public leadFindOne(requestParameters: LeadManagementApiLeadFindOneRequest, options?: AxiosRequestConfig) {
        return LeadManagementApiFp(this.configuration).leadFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadManagementApiLeadGetLeadsPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadManagementApi
     */
    public leadGetLeadsPaginated(requestParameters: LeadManagementApiLeadGetLeadsPaginatedRequest, options?: AxiosRequestConfig) {
        return LeadManagementApiFp(this.configuration).leadGetLeadsPaginated(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadManagementApiLeadGetLeadsPaginatedForListingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadManagementApi
     */
    public leadGetLeadsPaginatedForListing(requestParameters: LeadManagementApiLeadGetLeadsPaginatedForListingRequest, options?: AxiosRequestConfig) {
        return LeadManagementApiFp(this.configuration).leadGetLeadsPaginatedForListing(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadManagementApiLeadUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadManagementApi
     */
    public leadUpdate(requestParameters: LeadManagementApiLeadUpdateRequest, options?: AxiosRequestConfig) {
        return LeadManagementApiFp(this.configuration).leadUpdate(requestParameters.id, requestParameters.updateLeadGeneralInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadManagementApiLeadValidateLeadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadManagementApi
     */
    public leadValidateLead(requestParameters: LeadManagementApiLeadValidateLeadRequest, options?: AxiosRequestConfig) {
        return LeadManagementApiFp(this.configuration).leadValidateLead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadServiceApi - axios parameter creator
 * @export
 */
export const LeadServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {CreateLeadServiceDto} createLeadServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceCreate: async (serviceTimeId: number, createLeadServiceDto: CreateLeadServiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceCreate', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'createLeadServiceDto' is not null or undefined
            assertParamExists('leadServiceCreate', 'createLeadServiceDto', createLeadServiceDto)
            const localVarPath = `/service-times/{serviceTimeId}/services`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeadServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceDelete: async (serviceTimeId: number, serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceDelete', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('leadServiceDelete', 'serviceId', serviceId)
            const localVarPath = `/service-times/{serviceTimeId}/services/{serviceId}`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {UpdateLeadServiceDto} updateLeadServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceUpdate: async (serviceTimeId: number, serviceId: number, updateLeadServiceDto: UpdateLeadServiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceUpdate', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('leadServiceUpdate', 'serviceId', serviceId)
            // verify required parameter 'updateLeadServiceDto' is not null or undefined
            assertParamExists('leadServiceUpdate', 'updateLeadServiceDto', updateLeadServiceDto)
            const localVarPath = `/service-times/{serviceTimeId}/services/{serviceId}`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLeadServiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadServiceApi - functional programming interface
 * @export
 */
export const LeadServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {CreateLeadServiceDto} createLeadServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceCreate(serviceTimeId: number, createLeadServiceDto: CreateLeadServiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadServiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceCreate(serviceTimeId, createLeadServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceDelete(serviceTimeId: number, serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceDelete(serviceTimeId, serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {UpdateLeadServiceDto} updateLeadServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceUpdate(serviceTimeId: number, serviceId: number, updateLeadServiceDto: UpdateLeadServiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceUpdate(serviceTimeId, serviceId, updateLeadServiceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadServiceApi - factory interface
 * @export
 */
export const LeadServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadServiceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {CreateLeadServiceDto} createLeadServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceCreate(serviceTimeId: number, createLeadServiceDto: CreateLeadServiceDto, options?: any): AxiosPromise<LeadServiceResponseDto> {
            return localVarFp.leadServiceCreate(serviceTimeId, createLeadServiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceDelete(serviceTimeId: number, serviceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.leadServiceDelete(serviceTimeId, serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {UpdateLeadServiceDto} updateLeadServiceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceUpdate(serviceTimeId: number, serviceId: number, updateLeadServiceDto: UpdateLeadServiceDto, options?: any): AxiosPromise<void> {
            return localVarFp.leadServiceUpdate(serviceTimeId, serviceId, updateLeadServiceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for leadServiceCreate operation in LeadServiceApi.
 * @export
 * @interface LeadServiceApiLeadServiceCreateRequest
 */
export interface LeadServiceApiLeadServiceCreateRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceApiLeadServiceCreate
     */
    readonly serviceTimeId: number

    /**
     * 
     * @type {CreateLeadServiceDto}
     * @memberof LeadServiceApiLeadServiceCreate
     */
    readonly createLeadServiceDto: CreateLeadServiceDto
}

/**
 * Request parameters for leadServiceDelete operation in LeadServiceApi.
 * @export
 * @interface LeadServiceApiLeadServiceDeleteRequest
 */
export interface LeadServiceApiLeadServiceDeleteRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceApiLeadServiceDelete
     */
    readonly serviceTimeId: number

    /**
     * Service ID
     * @type {number}
     * @memberof LeadServiceApiLeadServiceDelete
     */
    readonly serviceId: number
}

/**
 * Request parameters for leadServiceUpdate operation in LeadServiceApi.
 * @export
 * @interface LeadServiceApiLeadServiceUpdateRequest
 */
export interface LeadServiceApiLeadServiceUpdateRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceApiLeadServiceUpdate
     */
    readonly serviceTimeId: number

    /**
     * Service ID
     * @type {number}
     * @memberof LeadServiceApiLeadServiceUpdate
     */
    readonly serviceId: number

    /**
     * 
     * @type {UpdateLeadServiceDto}
     * @memberof LeadServiceApiLeadServiceUpdate
     */
    readonly updateLeadServiceDto: UpdateLeadServiceDto
}

/**
 * LeadServiceApi - object-oriented interface
 * @export
 * @class LeadServiceApi
 * @extends {BaseAPI}
 */
export class LeadServiceApi extends BaseAPI {
    /**
     * 
     * @param {LeadServiceApiLeadServiceCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceApi
     */
    public leadServiceCreate(requestParameters: LeadServiceApiLeadServiceCreateRequest, options?: AxiosRequestConfig) {
        return LeadServiceApiFp(this.configuration).leadServiceCreate(requestParameters.serviceTimeId, requestParameters.createLeadServiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceApiLeadServiceDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceApi
     */
    public leadServiceDelete(requestParameters: LeadServiceApiLeadServiceDeleteRequest, options?: AxiosRequestConfig) {
        return LeadServiceApiFp(this.configuration).leadServiceDelete(requestParameters.serviceTimeId, requestParameters.serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceApiLeadServiceUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceApi
     */
    public leadServiceUpdate(requestParameters: LeadServiceApiLeadServiceUpdateRequest, options?: AxiosRequestConfig) {
        return LeadServiceApiFp(this.configuration).leadServiceUpdate(requestParameters.serviceTimeId, requestParameters.serviceId, requestParameters.updateLeadServiceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadServiceTaskApi - axios parameter creator
 * @export
 */
export const LeadServiceTaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {CreateLeadServiceTaskDto} createLeadServiceTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTaskCreate: async (serviceTimeId: number, serviceId: number, createLeadServiceTaskDto: CreateLeadServiceTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceTaskCreate', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('leadServiceTaskCreate', 'serviceId', serviceId)
            // verify required parameter 'createLeadServiceTaskDto' is not null or undefined
            assertParamExists('leadServiceTaskCreate', 'createLeadServiceTaskDto', createLeadServiceTaskDto)
            const localVarPath = `/service-times/{serviceTimeId}/services/{serviceId}/tasks`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeadServiceTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTaskDelete: async (serviceTimeId: number, serviceId: number, taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceTaskDelete', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('leadServiceTaskDelete', 'serviceId', serviceId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('leadServiceTaskDelete', 'taskId', taskId)
            const localVarPath = `/service-times/{serviceTimeId}/services/{serviceId}/tasks/{taskId}`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTaskGetTaskById: async (serviceTimeId: number, serviceId: number, taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceTaskGetTaskById', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('leadServiceTaskGetTaskById', 'serviceId', serviceId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('leadServiceTaskGetTaskById', 'taskId', taskId)
            const localVarPath = `/service-times/{serviceTimeId}/services/{serviceId}/tasks/{taskId}`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTaskGetTasks: async (serviceTimeId: number, serviceId: number, taskId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceTaskGetTasks', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('leadServiceTaskGetTasks', 'serviceId', serviceId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('leadServiceTaskGetTasks', 'taskId', taskId)
            const localVarPath = `/service-times/{serviceTimeId}/services/{serviceId}/tasks`
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)))
                .replace(`{${"serviceId"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadServiceTaskApi - functional programming interface
 * @export
 */
export const LeadServiceTaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadServiceTaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {CreateLeadServiceTaskDto} createLeadServiceTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTaskCreate(serviceTimeId: number, serviceId: number, createLeadServiceTaskDto: CreateLeadServiceTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadServiceResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTaskCreate(serviceTimeId, serviceId, createLeadServiceTaskDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTaskDelete(serviceTimeId: number, serviceId: number, taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTaskDelete(serviceTimeId, serviceId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTaskGetTaskById(serviceTimeId: number, serviceId: number, taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTaskGetTaskById(serviceTimeId, serviceId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTaskGetTasks(serviceTimeId: number, serviceId: number, taskId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTaskGetTasks(serviceTimeId, serviceId, taskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadServiceTaskApi - factory interface
 * @export
 */
export const LeadServiceTaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadServiceTaskApiFp(configuration)
    return {
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {CreateLeadServiceTaskDto} createLeadServiceTaskDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTaskCreate(serviceTimeId: number, serviceId: number, createLeadServiceTaskDto: CreateLeadServiceTaskDto, options?: any): AxiosPromise<LeadServiceResponseDto> {
            return localVarFp.leadServiceTaskCreate(serviceTimeId, serviceId, createLeadServiceTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTaskDelete(serviceTimeId: number, serviceId: number, taskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.leadServiceTaskDelete(serviceTimeId, serviceId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTaskGetTaskById(serviceTimeId: number, serviceId: number, taskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.leadServiceTaskGetTaskById(serviceTimeId, serviceId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceTimeId Service Time ID
         * @param {number} serviceId Service ID
         * @param {number} taskId Task ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTaskGetTasks(serviceTimeId: number, serviceId: number, taskId: number, options?: any): AxiosPromise<void> {
            return localVarFp.leadServiceTaskGetTasks(serviceTimeId, serviceId, taskId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for leadServiceTaskCreate operation in LeadServiceTaskApi.
 * @export
 * @interface LeadServiceTaskApiLeadServiceTaskCreateRequest
 */
export interface LeadServiceTaskApiLeadServiceTaskCreateRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskCreate
     */
    readonly serviceTimeId: number

    /**
     * Service ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskCreate
     */
    readonly serviceId: number

    /**
     * 
     * @type {CreateLeadServiceTaskDto}
     * @memberof LeadServiceTaskApiLeadServiceTaskCreate
     */
    readonly createLeadServiceTaskDto: CreateLeadServiceTaskDto
}

/**
 * Request parameters for leadServiceTaskDelete operation in LeadServiceTaskApi.
 * @export
 * @interface LeadServiceTaskApiLeadServiceTaskDeleteRequest
 */
export interface LeadServiceTaskApiLeadServiceTaskDeleteRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskDelete
     */
    readonly serviceTimeId: number

    /**
     * Service ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskDelete
     */
    readonly serviceId: number

    /**
     * Task ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskDelete
     */
    readonly taskId: number
}

/**
 * Request parameters for leadServiceTaskGetTaskById operation in LeadServiceTaskApi.
 * @export
 * @interface LeadServiceTaskApiLeadServiceTaskGetTaskByIdRequest
 */
export interface LeadServiceTaskApiLeadServiceTaskGetTaskByIdRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskGetTaskById
     */
    readonly serviceTimeId: number

    /**
     * Service ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskGetTaskById
     */
    readonly serviceId: number

    /**
     * Task ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskGetTaskById
     */
    readonly taskId: number
}

/**
 * Request parameters for leadServiceTaskGetTasks operation in LeadServiceTaskApi.
 * @export
 * @interface LeadServiceTaskApiLeadServiceTaskGetTasksRequest
 */
export interface LeadServiceTaskApiLeadServiceTaskGetTasksRequest {
    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskGetTasks
     */
    readonly serviceTimeId: number

    /**
     * Service ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskGetTasks
     */
    readonly serviceId: number

    /**
     * Task ID
     * @type {number}
     * @memberof LeadServiceTaskApiLeadServiceTaskGetTasks
     */
    readonly taskId: number
}

/**
 * LeadServiceTaskApi - object-oriented interface
 * @export
 * @class LeadServiceTaskApi
 * @extends {BaseAPI}
 */
export class LeadServiceTaskApi extends BaseAPI {
    /**
     * 
     * @param {LeadServiceTaskApiLeadServiceTaskCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTaskApi
     */
    public leadServiceTaskCreate(requestParameters: LeadServiceTaskApiLeadServiceTaskCreateRequest, options?: AxiosRequestConfig) {
        return LeadServiceTaskApiFp(this.configuration).leadServiceTaskCreate(requestParameters.serviceTimeId, requestParameters.serviceId, requestParameters.createLeadServiceTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTaskApiLeadServiceTaskDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTaskApi
     */
    public leadServiceTaskDelete(requestParameters: LeadServiceTaskApiLeadServiceTaskDeleteRequest, options?: AxiosRequestConfig) {
        return LeadServiceTaskApiFp(this.configuration).leadServiceTaskDelete(requestParameters.serviceTimeId, requestParameters.serviceId, requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTaskApiLeadServiceTaskGetTaskByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTaskApi
     */
    public leadServiceTaskGetTaskById(requestParameters: LeadServiceTaskApiLeadServiceTaskGetTaskByIdRequest, options?: AxiosRequestConfig) {
        return LeadServiceTaskApiFp(this.configuration).leadServiceTaskGetTaskById(requestParameters.serviceTimeId, requestParameters.serviceId, requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTaskApiLeadServiceTaskGetTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTaskApi
     */
    public leadServiceTaskGetTasks(requestParameters: LeadServiceTaskApiLeadServiceTaskGetTasksRequest, options?: AxiosRequestConfig) {
        return LeadServiceTaskApiFp(this.configuration).leadServiceTaskGetTasks(requestParameters.serviceTimeId, requestParameters.serviceId, requestParameters.taskId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadServiceTimesApi - axios parameter creator
 * @export
 */
export const LeadServiceTimesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id Lead UUID
         * @param {CreateLeadServiceTimeDto} createLeadServiceTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeCreate: async (id: string, createLeadServiceTimeDto: CreateLeadServiceTimeDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadServiceTimeCreate', 'id', id)
            // verify required parameter 'createLeadServiceTimeDto' is not null or undefined
            assertParamExists('leadServiceTimeCreate', 'createLeadServiceTimeDto', createLeadServiceTimeDto)
            const localVarPath = `/leads/{id}/service-times`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLeadServiceTimeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeDelete: async (id: string, serviceTimeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadServiceTimeDelete', 'id', id)
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceTimeDelete', 'serviceTimeId', serviceTimeId)
            const localVarPath = `/leads/{id}/service-times/{serviceTimeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeFind: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadServiceTimeFind', 'id', id)
            const localVarPath = `/leads/{id}/service-times`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeFindOne: async (id: string, serviceTimeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadServiceTimeFindOne', 'id', id)
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceTimeFindOne', 'serviceTimeId', serviceTimeId)
            const localVarPath = `/leads/{id}/service-times/{serviceTimeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeGetCalendarEvents: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadServiceTimeGetCalendarEvents', 'id', id)
            const localVarPath = `/leads/{id}/service-times/calendar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeGetCalendarViewInformation: async (id: string, serviceTimeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadServiceTimeGetCalendarViewInformation', 'id', id)
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceTimeGetCalendarViewInformation', 'serviceTimeId', serviceTimeId)
            const localVarPath = `/leads/{id}/service-times/{serviceTimeId}/calendar-view`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeUpdate: async (id: string, serviceTimeId: number, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('leadServiceTimeUpdate', 'id', id)
            // verify required parameter 'serviceTimeId' is not null or undefined
            assertParamExists('leadServiceTimeUpdate', 'serviceTimeId', serviceTimeId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('leadServiceTimeUpdate', 'body', body)
            const localVarPath = `/leads/{id}/service-times/{serviceTimeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"serviceTimeId"}}`, encodeURIComponent(String(serviceTimeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadServiceTimesApi - functional programming interface
 * @export
 */
export const LeadServiceTimesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadServiceTimesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id Lead UUID
         * @param {CreateLeadServiceTimeDto} createLeadServiceTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTimeCreate(id: string, createLeadServiceTimeDto: CreateLeadServiceTimeDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadServiceTimeIdResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTimeCreate(id, createLeadServiceTimeDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTimeDelete(id: string, serviceTimeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTimeDelete(id, serviceTimeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTimeFind(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadServiceTimeResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTimeFind(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTimeFindOne(id: string, serviceTimeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadServiceTimeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTimeFindOne(id, serviceTimeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTimeGetCalendarEvents(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarObjectDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTimeGetCalendarEvents(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTimeGetCalendarViewInformation(id: string, serviceTimeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LeadServiceTimeResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTimeGetCalendarViewInformation(id, serviceTimeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadServiceTimeUpdate(id: string, serviceTimeId: number, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadServiceTimeIdResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadServiceTimeUpdate(id, serviceTimeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadServiceTimesApi - factory interface
 * @export
 */
export const LeadServiceTimesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadServiceTimesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id Lead UUID
         * @param {CreateLeadServiceTimeDto} createLeadServiceTimeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeCreate(id: string, createLeadServiceTimeDto: CreateLeadServiceTimeDto, options?: any): AxiosPromise<LeadServiceTimeIdResponseDTO> {
            return localVarFp.leadServiceTimeCreate(id, createLeadServiceTimeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeDelete(id: string, serviceTimeId: number, options?: any): AxiosPromise<void> {
            return localVarFp.leadServiceTimeDelete(id, serviceTimeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeFind(id: string, options?: any): AxiosPromise<Array<LeadServiceTimeResponseDTO>> {
            return localVarFp.leadServiceTimeFind(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeFindOne(id: string, serviceTimeId: number, options?: any): AxiosPromise<LeadServiceTimeResponseDTO> {
            return localVarFp.leadServiceTimeFindOne(id, serviceTimeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeGetCalendarEvents(id: string, options?: any): AxiosPromise<Array<CalendarObjectDTO>> {
            return localVarFp.leadServiceTimeGetCalendarEvents(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeGetCalendarViewInformation(id: string, serviceTimeId: number, options?: any): AxiosPromise<Array<LeadServiceTimeResponseDTO>> {
            return localVarFp.leadServiceTimeGetCalendarViewInformation(id, serviceTimeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Lead UUID
         * @param {number} serviceTimeId Service Time ID
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadServiceTimeUpdate(id: string, serviceTimeId: number, body: object, options?: any): AxiosPromise<LeadServiceTimeIdResponseDTO> {
            return localVarFp.leadServiceTimeUpdate(id, serviceTimeId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for leadServiceTimeCreate operation in LeadServiceTimesApi.
 * @export
 * @interface LeadServiceTimesApiLeadServiceTimeCreateRequest
 */
export interface LeadServiceTimesApiLeadServiceTimeCreateRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadServiceTimesApiLeadServiceTimeCreate
     */
    readonly id: string

    /**
     * 
     * @type {CreateLeadServiceTimeDto}
     * @memberof LeadServiceTimesApiLeadServiceTimeCreate
     */
    readonly createLeadServiceTimeDto: CreateLeadServiceTimeDto
}

/**
 * Request parameters for leadServiceTimeDelete operation in LeadServiceTimesApi.
 * @export
 * @interface LeadServiceTimesApiLeadServiceTimeDeleteRequest
 */
export interface LeadServiceTimesApiLeadServiceTimeDeleteRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadServiceTimesApiLeadServiceTimeDelete
     */
    readonly id: string

    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceTimesApiLeadServiceTimeDelete
     */
    readonly serviceTimeId: number
}

/**
 * Request parameters for leadServiceTimeFind operation in LeadServiceTimesApi.
 * @export
 * @interface LeadServiceTimesApiLeadServiceTimeFindRequest
 */
export interface LeadServiceTimesApiLeadServiceTimeFindRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadServiceTimesApiLeadServiceTimeFind
     */
    readonly id: string
}

/**
 * Request parameters for leadServiceTimeFindOne operation in LeadServiceTimesApi.
 * @export
 * @interface LeadServiceTimesApiLeadServiceTimeFindOneRequest
 */
export interface LeadServiceTimesApiLeadServiceTimeFindOneRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadServiceTimesApiLeadServiceTimeFindOne
     */
    readonly id: string

    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceTimesApiLeadServiceTimeFindOne
     */
    readonly serviceTimeId: number
}

/**
 * Request parameters for leadServiceTimeGetCalendarEvents operation in LeadServiceTimesApi.
 * @export
 * @interface LeadServiceTimesApiLeadServiceTimeGetCalendarEventsRequest
 */
export interface LeadServiceTimesApiLeadServiceTimeGetCalendarEventsRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadServiceTimesApiLeadServiceTimeGetCalendarEvents
     */
    readonly id: string
}

/**
 * Request parameters for leadServiceTimeGetCalendarViewInformation operation in LeadServiceTimesApi.
 * @export
 * @interface LeadServiceTimesApiLeadServiceTimeGetCalendarViewInformationRequest
 */
export interface LeadServiceTimesApiLeadServiceTimeGetCalendarViewInformationRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadServiceTimesApiLeadServiceTimeGetCalendarViewInformation
     */
    readonly id: string

    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceTimesApiLeadServiceTimeGetCalendarViewInformation
     */
    readonly serviceTimeId: number
}

/**
 * Request parameters for leadServiceTimeUpdate operation in LeadServiceTimesApi.
 * @export
 * @interface LeadServiceTimesApiLeadServiceTimeUpdateRequest
 */
export interface LeadServiceTimesApiLeadServiceTimeUpdateRequest {
    /**
     * Lead UUID
     * @type {string}
     * @memberof LeadServiceTimesApiLeadServiceTimeUpdate
     */
    readonly id: string

    /**
     * Service Time ID
     * @type {number}
     * @memberof LeadServiceTimesApiLeadServiceTimeUpdate
     */
    readonly serviceTimeId: number

    /**
     * 
     * @type {object}
     * @memberof LeadServiceTimesApiLeadServiceTimeUpdate
     */
    readonly body: object
}

/**
 * LeadServiceTimesApi - object-oriented interface
 * @export
 * @class LeadServiceTimesApi
 * @extends {BaseAPI}
 */
export class LeadServiceTimesApi extends BaseAPI {
    /**
     * 
     * @param {LeadServiceTimesApiLeadServiceTimeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTimesApi
     */
    public leadServiceTimeCreate(requestParameters: LeadServiceTimesApiLeadServiceTimeCreateRequest, options?: AxiosRequestConfig) {
        return LeadServiceTimesApiFp(this.configuration).leadServiceTimeCreate(requestParameters.id, requestParameters.createLeadServiceTimeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTimesApiLeadServiceTimeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTimesApi
     */
    public leadServiceTimeDelete(requestParameters: LeadServiceTimesApiLeadServiceTimeDeleteRequest, options?: AxiosRequestConfig) {
        return LeadServiceTimesApiFp(this.configuration).leadServiceTimeDelete(requestParameters.id, requestParameters.serviceTimeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTimesApiLeadServiceTimeFindRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTimesApi
     */
    public leadServiceTimeFind(requestParameters: LeadServiceTimesApiLeadServiceTimeFindRequest, options?: AxiosRequestConfig) {
        return LeadServiceTimesApiFp(this.configuration).leadServiceTimeFind(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTimesApiLeadServiceTimeFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTimesApi
     */
    public leadServiceTimeFindOne(requestParameters: LeadServiceTimesApiLeadServiceTimeFindOneRequest, options?: AxiosRequestConfig) {
        return LeadServiceTimesApiFp(this.configuration).leadServiceTimeFindOne(requestParameters.id, requestParameters.serviceTimeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTimesApiLeadServiceTimeGetCalendarEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTimesApi
     */
    public leadServiceTimeGetCalendarEvents(requestParameters: LeadServiceTimesApiLeadServiceTimeGetCalendarEventsRequest, options?: AxiosRequestConfig) {
        return LeadServiceTimesApiFp(this.configuration).leadServiceTimeGetCalendarEvents(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTimesApiLeadServiceTimeGetCalendarViewInformationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTimesApi
     */
    public leadServiceTimeGetCalendarViewInformation(requestParameters: LeadServiceTimesApiLeadServiceTimeGetCalendarViewInformationRequest, options?: AxiosRequestConfig) {
        return LeadServiceTimesApiFp(this.configuration).leadServiceTimeGetCalendarViewInformation(requestParameters.id, requestParameters.serviceTimeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LeadServiceTimesApiLeadServiceTimeUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadServiceTimesApi
     */
    public leadServiceTimeUpdate(requestParameters: LeadServiceTimesApiLeadServiceTimeUpdateRequest, options?: AxiosRequestConfig) {
        return LeadServiceTimesApiFp(this.configuration).leadServiceTimeUpdate(requestParameters.id, requestParameters.serviceTimeId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationHistoryApi - axios parameter creator
 * @export
 */
export const LocationHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LocationHistoryCreationDTO} locationHistoryCreationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationHistoryCreate: async (locationHistoryCreationDTO: LocationHistoryCreationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationHistoryCreationDTO' is not null or undefined
            assertParamExists('locationHistoryCreate', 'locationHistoryCreationDTO', locationHistoryCreationDTO)
            const localVarPath = `/location-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationHistoryCreationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userId] User ID
         * @param {'Arrived' | 'Completed' | 'Clock Out' | 'On Road' | 'Start task'} [action] Action
         * @param {string} [projectId] Project ID
         * @param {string} [calendarEventId] Calendar Event ID
         * @param {number} [taskIterationId] Task Iteration ID
         * @param {string} [date] Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationHistoryFind: async (userId?: string, action?: 'Arrived' | 'Completed' | 'Clock Out' | 'On Road' | 'Start task', projectId?: string, calendarEventId?: string, taskIterationId?: number, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/location-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (calendarEventId !== undefined) {
                localVarQueryParameter['calendarEventId'] = calendarEventId;
            }

            if (taskIterationId !== undefined) {
                localVarQueryParameter['taskIterationId'] = taskIterationId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationHistoryApi - functional programming interface
 * @export
 */
export const LocationHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LocationHistoryCreationDTO} locationHistoryCreationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationHistoryCreate(locationHistoryCreationDTO: LocationHistoryCreationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationHistoryCreate(locationHistoryCreationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userId] User ID
         * @param {'Arrived' | 'Completed' | 'Clock Out' | 'On Road' | 'Start task'} [action] Action
         * @param {string} [projectId] Project ID
         * @param {string} [calendarEventId] Calendar Event ID
         * @param {number} [taskIterationId] Task Iteration ID
         * @param {string} [date] Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationHistoryFind(userId?: string, action?: 'Arrived' | 'Completed' | 'Clock Out' | 'On Road' | 'Start task', projectId?: string, calendarEventId?: string, taskIterationId?: number, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationHistoryFind(userId, action, projectId, calendarEventId, taskIterationId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationHistoryApi - factory interface
 * @export
 */
export const LocationHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationHistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {LocationHistoryCreationDTO} locationHistoryCreationDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationHistoryCreate(locationHistoryCreationDTO: LocationHistoryCreationDTO, options?: any): AxiosPromise<void> {
            return localVarFp.locationHistoryCreate(locationHistoryCreationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userId] User ID
         * @param {'Arrived' | 'Completed' | 'Clock Out' | 'On Road' | 'Start task'} [action] Action
         * @param {string} [projectId] Project ID
         * @param {string} [calendarEventId] Calendar Event ID
         * @param {number} [taskIterationId] Task Iteration ID
         * @param {string} [date] Date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationHistoryFind(userId?: string, action?: 'Arrived' | 'Completed' | 'Clock Out' | 'On Road' | 'Start task', projectId?: string, calendarEventId?: string, taskIterationId?: number, date?: string, options?: any): AxiosPromise<void> {
            return localVarFp.locationHistoryFind(userId, action, projectId, calendarEventId, taskIterationId, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for locationHistoryCreate operation in LocationHistoryApi.
 * @export
 * @interface LocationHistoryApiLocationHistoryCreateRequest
 */
export interface LocationHistoryApiLocationHistoryCreateRequest {
    /**
     * 
     * @type {LocationHistoryCreationDTO}
     * @memberof LocationHistoryApiLocationHistoryCreate
     */
    readonly locationHistoryCreationDTO: LocationHistoryCreationDTO
}

/**
 * Request parameters for locationHistoryFind operation in LocationHistoryApi.
 * @export
 * @interface LocationHistoryApiLocationHistoryFindRequest
 */
export interface LocationHistoryApiLocationHistoryFindRequest {
    /**
     * User ID
     * @type {string}
     * @memberof LocationHistoryApiLocationHistoryFind
     */
    readonly userId?: string

    /**
     * Action
     * @type {'Arrived' | 'Completed' | 'Clock Out' | 'On Road' | 'Start task'}
     * @memberof LocationHistoryApiLocationHistoryFind
     */
    readonly action?: 'Arrived' | 'Completed' | 'Clock Out' | 'On Road' | 'Start task'

    /**
     * Project ID
     * @type {string}
     * @memberof LocationHistoryApiLocationHistoryFind
     */
    readonly projectId?: string

    /**
     * Calendar Event ID
     * @type {string}
     * @memberof LocationHistoryApiLocationHistoryFind
     */
    readonly calendarEventId?: string

    /**
     * Task Iteration ID
     * @type {number}
     * @memberof LocationHistoryApiLocationHistoryFind
     */
    readonly taskIterationId?: number

    /**
     * Date
     * @type {string}
     * @memberof LocationHistoryApiLocationHistoryFind
     */
    readonly date?: string
}

/**
 * LocationHistoryApi - object-oriented interface
 * @export
 * @class LocationHistoryApi
 * @extends {BaseAPI}
 */
export class LocationHistoryApi extends BaseAPI {
    /**
     * 
     * @param {LocationHistoryApiLocationHistoryCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationHistoryApi
     */
    public locationHistoryCreate(requestParameters: LocationHistoryApiLocationHistoryCreateRequest, options?: AxiosRequestConfig) {
        return LocationHistoryApiFp(this.configuration).locationHistoryCreate(requestParameters.locationHistoryCreationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationHistoryApiLocationHistoryFindRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationHistoryApi
     */
    public locationHistoryFind(requestParameters: LocationHistoryApiLocationHistoryFindRequest = {}, options?: AxiosRequestConfig) {
        return LocationHistoryApiFp(this.configuration).locationHistoryFind(requestParameters.userId, requestParameters.action, requestParameters.projectId, requestParameters.calendarEventId, requestParameters.taskIterationId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MFAApi - axios parameter creator
 * @export
 */
export const MFAApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TwoFactorCreateBodyDTO} twoFactorCreateBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorAssignTwoFactor: async (twoFactorCreateBodyDTO: TwoFactorCreateBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'twoFactorCreateBodyDTO' is not null or undefined
            assertParamExists('twoFactorAssignTwoFactor', 'twoFactorCreateBodyDTO', twoFactorCreateBodyDTO)
            const localVarPath = `/two-factor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorCreateBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TwoFactorConfirmBodyDTO} twoFactorConfirmBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorConfirmTwoFactor: async (twoFactorConfirmBodyDTO: TwoFactorConfirmBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'twoFactorConfirmBodyDTO' is not null or undefined
            assertParamExists('twoFactorConfirmTwoFactor', 'twoFactorConfirmBodyDTO', twoFactorConfirmBodyDTO)
            const localVarPath = `/two-factor/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorConfirmBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorSendCode: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/two-factor/send-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TwoFactorUpdateBodyDTO} twoFactorUpdateBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorUpdateTwoFactor: async (twoFactorUpdateBodyDTO: TwoFactorUpdateBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'twoFactorUpdateBodyDTO' is not null or undefined
            assertParamExists('twoFactorUpdateTwoFactor', 'twoFactorUpdateBodyDTO', twoFactorUpdateBodyDTO)
            const localVarPath = `/two-factor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorUpdateBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MFAApi - functional programming interface
 * @export
 */
export const MFAApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MFAApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TwoFactorCreateBodyDTO} twoFactorCreateBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twoFactorAssignTwoFactor(twoFactorCreateBodyDTO: TwoFactorCreateBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twoFactorAssignTwoFactor(twoFactorCreateBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TwoFactorConfirmBodyDTO} twoFactorConfirmBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twoFactorConfirmTwoFactor(twoFactorConfirmBodyDTO: TwoFactorConfirmBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twoFactorConfirmTwoFactor(twoFactorConfirmBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twoFactorSendCode(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twoFactorSendCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TwoFactorUpdateBodyDTO} twoFactorUpdateBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async twoFactorUpdateTwoFactor(twoFactorUpdateBodyDTO: TwoFactorUpdateBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.twoFactorUpdateTwoFactor(twoFactorUpdateBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MFAApi - factory interface
 * @export
 */
export const MFAApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MFAApiFp(configuration)
    return {
        /**
         * 
         * @param {TwoFactorCreateBodyDTO} twoFactorCreateBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorAssignTwoFactor(twoFactorCreateBodyDTO: TwoFactorCreateBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.twoFactorAssignTwoFactor(twoFactorCreateBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TwoFactorConfirmBodyDTO} twoFactorConfirmBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorConfirmTwoFactor(twoFactorConfirmBodyDTO: TwoFactorConfirmBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.twoFactorConfirmTwoFactor(twoFactorConfirmBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorSendCode(options?: any): AxiosPromise<void> {
            return localVarFp.twoFactorSendCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TwoFactorUpdateBodyDTO} twoFactorUpdateBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        twoFactorUpdateTwoFactor(twoFactorUpdateBodyDTO: TwoFactorUpdateBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.twoFactorUpdateTwoFactor(twoFactorUpdateBodyDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for twoFactorAssignTwoFactor operation in MFAApi.
 * @export
 * @interface MFAApiTwoFactorAssignTwoFactorRequest
 */
export interface MFAApiTwoFactorAssignTwoFactorRequest {
    /**
     * 
     * @type {TwoFactorCreateBodyDTO}
     * @memberof MFAApiTwoFactorAssignTwoFactor
     */
    readonly twoFactorCreateBodyDTO: TwoFactorCreateBodyDTO
}

/**
 * Request parameters for twoFactorConfirmTwoFactor operation in MFAApi.
 * @export
 * @interface MFAApiTwoFactorConfirmTwoFactorRequest
 */
export interface MFAApiTwoFactorConfirmTwoFactorRequest {
    /**
     * 
     * @type {TwoFactorConfirmBodyDTO}
     * @memberof MFAApiTwoFactorConfirmTwoFactor
     */
    readonly twoFactorConfirmBodyDTO: TwoFactorConfirmBodyDTO
}

/**
 * Request parameters for twoFactorUpdateTwoFactor operation in MFAApi.
 * @export
 * @interface MFAApiTwoFactorUpdateTwoFactorRequest
 */
export interface MFAApiTwoFactorUpdateTwoFactorRequest {
    /**
     * 
     * @type {TwoFactorUpdateBodyDTO}
     * @memberof MFAApiTwoFactorUpdateTwoFactor
     */
    readonly twoFactorUpdateBodyDTO: TwoFactorUpdateBodyDTO
}

/**
 * MFAApi - object-oriented interface
 * @export
 * @class MFAApi
 * @extends {BaseAPI}
 */
export class MFAApi extends BaseAPI {
    /**
     * 
     * @param {MFAApiTwoFactorAssignTwoFactorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MFAApi
     */
    public twoFactorAssignTwoFactor(requestParameters: MFAApiTwoFactorAssignTwoFactorRequest, options?: AxiosRequestConfig) {
        return MFAApiFp(this.configuration).twoFactorAssignTwoFactor(requestParameters.twoFactorCreateBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MFAApiTwoFactorConfirmTwoFactorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MFAApi
     */
    public twoFactorConfirmTwoFactor(requestParameters: MFAApiTwoFactorConfirmTwoFactorRequest, options?: AxiosRequestConfig) {
        return MFAApiFp(this.configuration).twoFactorConfirmTwoFactor(requestParameters.twoFactorConfirmBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MFAApi
     */
    public twoFactorSendCode(options?: AxiosRequestConfig) {
        return MFAApiFp(this.configuration).twoFactorSendCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MFAApiTwoFactorUpdateTwoFactorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MFAApi
     */
    public twoFactorUpdateTwoFactor(requestParameters: MFAApiTwoFactorUpdateTwoFactorRequest, options?: AxiosRequestConfig) {
        return MFAApiFp(this.configuration).twoFactorUpdateTwoFactor(requestParameters.twoFactorUpdateBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaterialApi - axios parameter creator
 * @export
 */
export const MaterialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateMaterialDTO} createMaterialDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialCreate: async (createMaterialDTO: CreateMaterialDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMaterialDTO' is not null or undefined
            assertParamExists('materialCreate', 'createMaterialDTO', createMaterialDTO)
            const localVarPath = `/materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMaterialDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialDelete', 'id', id)
            const localVarPath = `/materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/materials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialFindAllPaginate: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('materialFindAllPaginate', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('materialFindAllPaginate', 'items', items)
            const localVarPath = `/materials/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialGetOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialGetOneById', 'id', id)
            const localVarPath = `/materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {CreateMaterialDTO} createMaterialDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialUpdate: async (id: string, createMaterialDTO: CreateMaterialDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('materialUpdate', 'id', id)
            // verify required parameter 'createMaterialDTO' is not null or undefined
            assertParamExists('materialUpdate', 'createMaterialDTO', createMaterialDTO)
            const localVarPath = `/materials/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMaterialDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaterialApi - functional programming interface
 * @export
 */
export const MaterialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaterialApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateMaterialDTO} createMaterialDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialCreate(createMaterialDTO: CreateMaterialDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialCreate(createMaterialDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MaterialResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialFindAllPaginate(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialFindAllPaginate(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialGetOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialGetOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {CreateMaterialDTO} createMaterialDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async materialUpdate(id: string, createMaterialDTO: CreateMaterialDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.materialUpdate(id, createMaterialDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaterialApi - factory interface
 * @export
 */
export const MaterialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaterialApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateMaterialDTO} createMaterialDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialCreate(createMaterialDTO: CreateMaterialDTO, options?: any): AxiosPromise<MaterialResponseDTO> {
            return localVarFp.materialCreate(createMaterialDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.materialDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialFindAll(options?: any): AxiosPromise<Array<MaterialResponseDTO>> {
            return localVarFp.materialFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialFindAllPaginate(page: number, items: number, options?: any): AxiosPromise<MaterialListDTO> {
            return localVarFp.materialFindAllPaginate(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialGetOneById(id: string, options?: any): AxiosPromise<MaterialResponseDTO> {
            return localVarFp.materialGetOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Material UUID
         * @param {CreateMaterialDTO} createMaterialDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        materialUpdate(id: string, createMaterialDTO: CreateMaterialDTO, options?: any): AxiosPromise<MaterialResponseDTO> {
            return localVarFp.materialUpdate(id, createMaterialDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for materialCreate operation in MaterialApi.
 * @export
 * @interface MaterialApiMaterialCreateRequest
 */
export interface MaterialApiMaterialCreateRequest {
    /**
     * 
     * @type {CreateMaterialDTO}
     * @memberof MaterialApiMaterialCreate
     */
    readonly createMaterialDTO: CreateMaterialDTO
}

/**
 * Request parameters for materialDelete operation in MaterialApi.
 * @export
 * @interface MaterialApiMaterialDeleteRequest
 */
export interface MaterialApiMaterialDeleteRequest {
    /**
     * Material UUID
     * @type {string}
     * @memberof MaterialApiMaterialDelete
     */
    readonly id: string
}

/**
 * Request parameters for materialFindAllPaginate operation in MaterialApi.
 * @export
 * @interface MaterialApiMaterialFindAllPaginateRequest
 */
export interface MaterialApiMaterialFindAllPaginateRequest {
    /**
     * 
     * @type {number}
     * @memberof MaterialApiMaterialFindAllPaginate
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof MaterialApiMaterialFindAllPaginate
     */
    readonly items: number
}

/**
 * Request parameters for materialGetOneById operation in MaterialApi.
 * @export
 * @interface MaterialApiMaterialGetOneByIdRequest
 */
export interface MaterialApiMaterialGetOneByIdRequest {
    /**
     * Material UUID
     * @type {string}
     * @memberof MaterialApiMaterialGetOneById
     */
    readonly id: string
}

/**
 * Request parameters for materialUpdate operation in MaterialApi.
 * @export
 * @interface MaterialApiMaterialUpdateRequest
 */
export interface MaterialApiMaterialUpdateRequest {
    /**
     * Material UUID
     * @type {string}
     * @memberof MaterialApiMaterialUpdate
     */
    readonly id: string

    /**
     * 
     * @type {CreateMaterialDTO}
     * @memberof MaterialApiMaterialUpdate
     */
    readonly createMaterialDTO: CreateMaterialDTO
}

/**
 * MaterialApi - object-oriented interface
 * @export
 * @class MaterialApi
 * @extends {BaseAPI}
 */
export class MaterialApi extends BaseAPI {
    /**
     * 
     * @param {MaterialApiMaterialCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public materialCreate(requestParameters: MaterialApiMaterialCreateRequest, options?: AxiosRequestConfig) {
        return MaterialApiFp(this.configuration).materialCreate(requestParameters.createMaterialDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaterialApiMaterialDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public materialDelete(requestParameters: MaterialApiMaterialDeleteRequest, options?: AxiosRequestConfig) {
        return MaterialApiFp(this.configuration).materialDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public materialFindAll(options?: AxiosRequestConfig) {
        return MaterialApiFp(this.configuration).materialFindAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaterialApiMaterialFindAllPaginateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public materialFindAllPaginate(requestParameters: MaterialApiMaterialFindAllPaginateRequest, options?: AxiosRequestConfig) {
        return MaterialApiFp(this.configuration).materialFindAllPaginate(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaterialApiMaterialGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public materialGetOneById(requestParameters: MaterialApiMaterialGetOneByIdRequest, options?: AxiosRequestConfig) {
        return MaterialApiFp(this.configuration).materialGetOneById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaterialApiMaterialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaterialApi
     */
    public materialUpdate(requestParameters: MaterialApiMaterialUpdateRequest, options?: AxiosRequestConfig) {
        return MaterialApiFp(this.configuration).materialUpdate(requestParameters.id, requestParameters.createMaterialDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeasureApi - axios parameter creator
 * @export
 */
export const MeasureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        measureFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/measures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasureApi - functional programming interface
 * @export
 */
export const MeasureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async measureFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeasureResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.measureFindAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasureApi - factory interface
 * @export
 */
export const MeasureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasureApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        measureFindAll(options?: any): AxiosPromise<MeasureResponseDTO> {
            return localVarFp.measureFindAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasureApi - object-oriented interface
 * @export
 * @class MeasureApi
 * @extends {BaseAPI}
 */
export class MeasureApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasureApi
     */
    public measureFindAll(options?: AxiosRequestConfig) {
        return MeasureApiFp(this.configuration).measureFindAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateNoteDTO} createNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteCreate: async (createNoteDTO: CreateNoteDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNoteDTO' is not null or undefined
            assertParamExists('noteCreate', 'createNoteDTO', createNoteDTO)
            const localVarPath = `/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoteDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entity 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteGetAllByEntity: async (entity: string, entityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('noteGetAllByEntity', 'entity', entity)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('noteGetAllByEntity', 'entityId', entityId)
            const localVarPath = `/notes/{entity}/{entityId}`
                .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateNoteDTO} createNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteCreate(createNoteDTO: CreateNoteDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteCreate(createNoteDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entity 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteGetAllByEntity(entity: string, entityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteGetAllByEntity(entity, entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateNoteDTO} createNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteCreate(createNoteDTO: CreateNoteDTO, options?: any): AxiosPromise<NoteResponseDTO> {
            return localVarFp.noteCreate(createNoteDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteGetAll(options?: any): AxiosPromise<Array<NoteResponseDTO>> {
            return localVarFp.noteGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entity 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteGetAllByEntity(entity: string, entityId: string, options?: any): AxiosPromise<NoteResponseDTO> {
            return localVarFp.noteGetAllByEntity(entity, entityId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for noteCreate operation in NoteApi.
 * @export
 * @interface NoteApiNoteCreateRequest
 */
export interface NoteApiNoteCreateRequest {
    /**
     * 
     * @type {CreateNoteDTO}
     * @memberof NoteApiNoteCreate
     */
    readonly createNoteDTO: CreateNoteDTO
}

/**
 * Request parameters for noteGetAllByEntity operation in NoteApi.
 * @export
 * @interface NoteApiNoteGetAllByEntityRequest
 */
export interface NoteApiNoteGetAllByEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof NoteApiNoteGetAllByEntity
     */
    readonly entity: string

    /**
     * 
     * @type {string}
     * @memberof NoteApiNoteGetAllByEntity
     */
    readonly entityId: string
}

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
    /**
     * 
     * @param {NoteApiNoteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteCreate(requestParameters: NoteApiNoteCreateRequest, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteCreate(requestParameters.createNoteDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteGetAll(options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NoteApiNoteGetAllByEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteGetAllByEntity(requestParameters: NoteApiNoteGetAllByEntityRequest, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteGetAllByEntity(requestParameters.entity, requestParameters.entityId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileCancelRequest: async (requestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('profileCancelRequest', 'requestId', requestId)
            const localVarPath = `/profile/request/cancel/{requestId}`
                .replace(`{${"requestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AvailableTimeRequestedDTO} availableTimeRequestedDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileChangeCurrentUserAvilableTimeRequest: async (availableTimeRequestedDTO: AvailableTimeRequestedDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'availableTimeRequestedDTO' is not null or undefined
            assertParamExists('profileChangeCurrentUserAvilableTimeRequest', 'availableTimeRequestedDTO', availableTimeRequestedDTO)
            const localVarPath = `/profile/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(availableTimeRequestedDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePasswordBodyDTO} changePasswordBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileChangePassword: async (changePasswordBodyDTO: ChangePasswordBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordBodyDTO' is not null or undefined
            assertParamExists('profileChangePassword', 'changePasswordBodyDTO', changePasswordBodyDTO)
            const localVarPath = `/profile/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {'Pending' | 'Approved' | 'Rejected' | 'Canceled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetAllAvailableTimeRequest: async (userName?: string, status?: 'Pending' | 'Approved' | 'Rejected' | 'Canceled', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/get-all/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userName !== undefined) {
                localVarQueryParameter['userName'] = userName;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetAllCurrentUserAvailableTimeRequest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/request/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetAllProfilesPaginated: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('profileGetAllProfilesPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('profileGetAllProfilesPaginated', 'items', items)
            const localVarPath = `/profile/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetProfileById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileGetProfileById', 'id', id)
            const localVarPath = `/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCurrentProfileDto} updateCurrentProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateProfile: async (updateCurrentProfileDto: UpdateCurrentProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCurrentProfileDto' is not null or undefined
            assertParamExists('profileUpdateProfile', 'updateCurrentProfileDto', updateCurrentProfileDto)
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCurrentProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRequestStatusDTO} updateRequestStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateRequestStatus: async (updateRequestStatusDTO: UpdateRequestStatusDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRequestStatusDTO' is not null or undefined
            assertParamExists('profileUpdateRequestStatus', 'updateRequestStatusDTO', updateRequestStatusDTO)
            const localVarPath = `/profile/request/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRequestStatusDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserTimezoneDTO} userTimezoneDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateTimezoneForCurrentUser: async (userTimezoneDTO: UserTimezoneDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userTimezoneDTO' is not null or undefined
            assertParamExists('profileUpdateTimezoneForCurrentUser', 'userTimezoneDTO', userTimezoneDTO)
            const localVarPath = `/profile/timezone/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTimezoneDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserTimezoneIanaDTO} userTimezoneIanaDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateTimezoneIanaForCurrentUser: async (userTimezoneIanaDTO: UserTimezoneIanaDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userTimezoneIanaDTO' is not null or undefined
            assertParamExists('profileUpdateTimezoneIanaForCurrentUser', 'userTimezoneIanaDTO', userTimezoneIanaDTO)
            const localVarPath = `/profile/timezoneiana/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userTimezoneIanaDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {UpdateUserProfileDto} updateUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateUserProfile: async (id: string, updateUserProfileDto: UpdateUserProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('profileUpdateUserProfile', 'id', id)
            // verify required parameter 'updateUserProfileDto' is not null or undefined
            assertParamExists('profileUpdateUserProfile', 'updateUserProfileDto', updateUserProfileDto)
            const localVarPath = `/profile/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileCancelRequest(requestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableTimeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileCancelRequest(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AvailableTimeRequestedDTO} availableTimeRequestedDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileChangeCurrentUserAvilableTimeRequest(availableTimeRequestedDTO: AvailableTimeRequestedDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableTimeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileChangeCurrentUserAvilableTimeRequest(availableTimeRequestedDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangePasswordBodyDTO} changePasswordBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileChangePassword(changePasswordBodyDTO: ChangePasswordBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileChangePassword(changePasswordBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {'Pending' | 'Approved' | 'Rejected' | 'Canceled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetAllAvailableTimeRequest(userName?: string, status?: 'Pending' | 'Approved' | 'Rejected' | 'Canceled', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableTimeResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetAllAvailableTimeRequest(userName, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetAllCurrentUserAvailableTimeRequest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableTimeResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetAllCurrentUserAvailableTimeRequest(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetAllProfilesPaginated(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetAllProfilesPaginated(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileGetProfileById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileGetProfileById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCurrentProfileDto} updateCurrentProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateProfile(updateCurrentProfileDto: UpdateCurrentProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateProfile(updateCurrentProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateRequestStatusDTO} updateRequestStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateRequestStatus(updateRequestStatusDTO: UpdateRequestStatusDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailableTimeResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateRequestStatus(updateRequestStatusDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserTimezoneDTO} userTimezoneDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateTimezoneForCurrentUser(userTimezoneDTO: UserTimezoneDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateTimezoneForCurrentUser(userTimezoneDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserTimezoneIanaDTO} userTimezoneIanaDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateTimezoneIanaForCurrentUser(userTimezoneIanaDTO: UserTimezoneIanaDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateTimezoneIanaForCurrentUser(userTimezoneIanaDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {UpdateUserProfileDto} updateUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileUpdateUserProfile(id: string, updateUserProfileDto: UpdateUserProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileUpdateUserProfile(id, updateUserProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileCancelRequest(requestId: string, options?: any): AxiosPromise<AvailableTimeResponseDTO> {
            return localVarFp.profileCancelRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AvailableTimeRequestedDTO} availableTimeRequestedDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileChangeCurrentUserAvilableTimeRequest(availableTimeRequestedDTO: AvailableTimeRequestedDTO, options?: any): AxiosPromise<AvailableTimeResponseDTO> {
            return localVarFp.profileChangeCurrentUserAvilableTimeRequest(availableTimeRequestedDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangePasswordBodyDTO} changePasswordBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileChangePassword(changePasswordBodyDTO: ChangePasswordBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.profileChangePassword(changePasswordBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userName] 
         * @param {'Pending' | 'Approved' | 'Rejected' | 'Canceled'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetAllAvailableTimeRequest(userName?: string, status?: 'Pending' | 'Approved' | 'Rejected' | 'Canceled', options?: any): AxiosPromise<Array<AvailableTimeResponseDTO>> {
            return localVarFp.profileGetAllAvailableTimeRequest(userName, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetAllCurrentUserAvailableTimeRequest(options?: any): AxiosPromise<Array<AvailableTimeResponseDTO>> {
            return localVarFp.profileGetAllCurrentUserAvailableTimeRequest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetAllProfilesPaginated(page: number, items: number, options?: any): AxiosPromise<object> {
            return localVarFp.profileGetAllProfilesPaginated(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetProfile(options?: any): AxiosPromise<ProfileResponseDTO> {
            return localVarFp.profileGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileGetProfileById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.profileGetProfileById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCurrentProfileDto} updateCurrentProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateProfile(updateCurrentProfileDto: UpdateCurrentProfileDto, options?: any): AxiosPromise<ProfileResponseDTO> {
            return localVarFp.profileUpdateProfile(updateCurrentProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRequestStatusDTO} updateRequestStatusDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateRequestStatus(updateRequestStatusDTO: UpdateRequestStatusDTO, options?: any): AxiosPromise<AvailableTimeResponseDTO> {
            return localVarFp.profileUpdateRequestStatus(updateRequestStatusDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserTimezoneDTO} userTimezoneDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateTimezoneForCurrentUser(userTimezoneDTO: UserTimezoneDTO, options?: any): AxiosPromise<void> {
            return localVarFp.profileUpdateTimezoneForCurrentUser(userTimezoneDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserTimezoneIanaDTO} userTimezoneIanaDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateTimezoneIanaForCurrentUser(userTimezoneIanaDTO: UserTimezoneIanaDTO, options?: any): AxiosPromise<void> {
            return localVarFp.profileUpdateTimezoneIanaForCurrentUser(userTimezoneIanaDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {UpdateUserProfileDto} updateUserProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileUpdateUserProfile(id: string, updateUserProfileDto: UpdateUserProfileDto, options?: any): AxiosPromise<ProfileResponseDTO> {
            return localVarFp.profileUpdateUserProfile(id, updateUserProfileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for profileCancelRequest operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileCancelRequestRequest
 */
export interface ProfileApiProfileCancelRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileCancelRequest
     */
    readonly requestId: string
}

/**
 * Request parameters for profileChangeCurrentUserAvilableTimeRequest operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileChangeCurrentUserAvilableTimeRequestRequest
 */
export interface ProfileApiProfileChangeCurrentUserAvilableTimeRequestRequest {
    /**
     * 
     * @type {AvailableTimeRequestedDTO}
     * @memberof ProfileApiProfileChangeCurrentUserAvilableTimeRequest
     */
    readonly availableTimeRequestedDTO: AvailableTimeRequestedDTO
}

/**
 * Request parameters for profileChangePassword operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileChangePasswordRequest
 */
export interface ProfileApiProfileChangePasswordRequest {
    /**
     * 
     * @type {ChangePasswordBodyDTO}
     * @memberof ProfileApiProfileChangePassword
     */
    readonly changePasswordBodyDTO: ChangePasswordBodyDTO
}

/**
 * Request parameters for profileGetAllAvailableTimeRequest operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileGetAllAvailableTimeRequestRequest
 */
export interface ProfileApiProfileGetAllAvailableTimeRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiProfileGetAllAvailableTimeRequest
     */
    readonly userName?: string

    /**
     * 
     * @type {'Pending' | 'Approved' | 'Rejected' | 'Canceled'}
     * @memberof ProfileApiProfileGetAllAvailableTimeRequest
     */
    readonly status?: 'Pending' | 'Approved' | 'Rejected' | 'Canceled'
}

/**
 * Request parameters for profileGetAllProfilesPaginated operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileGetAllProfilesPaginatedRequest
 */
export interface ProfileApiProfileGetAllProfilesPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof ProfileApiProfileGetAllProfilesPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof ProfileApiProfileGetAllProfilesPaginated
     */
    readonly items: number
}

/**
 * Request parameters for profileGetProfileById operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileGetProfileByIdRequest
 */
export interface ProfileApiProfileGetProfileByIdRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof ProfileApiProfileGetProfileById
     */
    readonly id: string
}

/**
 * Request parameters for profileUpdateProfile operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileUpdateProfileRequest
 */
export interface ProfileApiProfileUpdateProfileRequest {
    /**
     * 
     * @type {UpdateCurrentProfileDto}
     * @memberof ProfileApiProfileUpdateProfile
     */
    readonly updateCurrentProfileDto: UpdateCurrentProfileDto
}

/**
 * Request parameters for profileUpdateRequestStatus operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileUpdateRequestStatusRequest
 */
export interface ProfileApiProfileUpdateRequestStatusRequest {
    /**
     * 
     * @type {UpdateRequestStatusDTO}
     * @memberof ProfileApiProfileUpdateRequestStatus
     */
    readonly updateRequestStatusDTO: UpdateRequestStatusDTO
}

/**
 * Request parameters for profileUpdateTimezoneForCurrentUser operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileUpdateTimezoneForCurrentUserRequest
 */
export interface ProfileApiProfileUpdateTimezoneForCurrentUserRequest {
    /**
     * 
     * @type {UserTimezoneDTO}
     * @memberof ProfileApiProfileUpdateTimezoneForCurrentUser
     */
    readonly userTimezoneDTO: UserTimezoneDTO
}

/**
 * Request parameters for profileUpdateTimezoneIanaForCurrentUser operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileUpdateTimezoneIanaForCurrentUserRequest
 */
export interface ProfileApiProfileUpdateTimezoneIanaForCurrentUserRequest {
    /**
     * 
     * @type {UserTimezoneIanaDTO}
     * @memberof ProfileApiProfileUpdateTimezoneIanaForCurrentUser
     */
    readonly userTimezoneIanaDTO: UserTimezoneIanaDTO
}

/**
 * Request parameters for profileUpdateUserProfile operation in ProfileApi.
 * @export
 * @interface ProfileApiProfileUpdateUserProfileRequest
 */
export interface ProfileApiProfileUpdateUserProfileRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof ProfileApiProfileUpdateUserProfile
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserProfileDto}
     * @memberof ProfileApiProfileUpdateUserProfile
     */
    readonly updateUserProfileDto: UpdateUserProfileDto
}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @param {ProfileApiProfileCancelRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileCancelRequest(requestParameters: ProfileApiProfileCancelRequestRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileCancelRequest(requestParameters.requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileChangeCurrentUserAvilableTimeRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileChangeCurrentUserAvilableTimeRequest(requestParameters: ProfileApiProfileChangeCurrentUserAvilableTimeRequestRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileChangeCurrentUserAvilableTimeRequest(requestParameters.availableTimeRequestedDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileChangePasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileChangePassword(requestParameters: ProfileApiProfileChangePasswordRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileChangePassword(requestParameters.changePasswordBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileGetAllAvailableTimeRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGetAllAvailableTimeRequest(requestParameters: ProfileApiProfileGetAllAvailableTimeRequestRequest = {}, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileGetAllAvailableTimeRequest(requestParameters.userName, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGetAllCurrentUserAvailableTimeRequest(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileGetAllCurrentUserAvailableTimeRequest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileGetAllProfilesPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGetAllProfilesPaginated(requestParameters: ProfileApiProfileGetAllProfilesPaginatedRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileGetAllProfilesPaginated(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGetProfile(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileGetProfileByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileGetProfileById(requestParameters: ProfileApiProfileGetProfileByIdRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileGetProfileById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileUpdateProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateProfile(requestParameters: ProfileApiProfileUpdateProfileRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileUpdateProfile(requestParameters.updateCurrentProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileUpdateRequestStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateRequestStatus(requestParameters: ProfileApiProfileUpdateRequestStatusRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileUpdateRequestStatus(requestParameters.updateRequestStatusDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileUpdateTimezoneForCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateTimezoneForCurrentUser(requestParameters: ProfileApiProfileUpdateTimezoneForCurrentUserRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileUpdateTimezoneForCurrentUser(requestParameters.userTimezoneDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileUpdateTimezoneIanaForCurrentUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateTimezoneIanaForCurrentUser(requestParameters: ProfileApiProfileUpdateTimezoneIanaForCurrentUserRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileUpdateTimezoneIanaForCurrentUser(requestParameters.userTimezoneIanaDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiProfileUpdateUserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileUpdateUserProfile(requestParameters: ProfileApiProfileUpdateUserProfileRequest, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileUpdateUserProfile(requestParameters.id, requestParameters.updateUserProfileDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectManagementApi - axios parameter creator
 * @export
 */
export const ProjectManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProjectAssignResourcesDTO} projectAssignResourcesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectAssignResources: async (projectAssignResourcesDTO: ProjectAssignResourcesDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectAssignResourcesDTO' is not null or undefined
            assertParamExists('projectAssignResources', 'projectAssignResourcesDTO', projectAssignResourcesDTO)
            const localVarPath = `/projects/assign-resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectAssignResourcesDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ProjectEnforceGeofencingDTO} projectEnforceGeofencingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectChangeProjectEnforceGeofencing: async (id: string, projectEnforceGeofencingDTO: ProjectEnforceGeofencingDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectChangeProjectEnforceGeofencing', 'id', id)
            // verify required parameter 'projectEnforceGeofencingDTO' is not null or undefined
            assertParamExists('projectChangeProjectEnforceGeofencing', 'projectEnforceGeofencingDTO', projectEnforceGeofencingDTO)
            const localVarPath = `/projects/enforceGeofencing/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectEnforceGeofencingDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ChangeLeadNameDTO} changeLeadNameDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectChangeProjectName: async (id: string, changeLeadNameDTO: ChangeLeadNameDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectChangeProjectName', 'id', id)
            // verify required parameter 'changeLeadNameDTO' is not null or undefined
            assertParamExists('projectChangeProjectName', 'changeLeadNameDTO', changeLeadNameDTO)
            const localVarPath = `/projects/name/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeLeadNameDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {CreateMessageInProjectDTO} createMessageInProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateClientMessageInProposal: async (id: string, createMessageInProjectDTO: CreateMessageInProjectDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectCreateClientMessageInProposal', 'id', id)
            // verify required parameter 'createMessageInProjectDTO' is not null or undefined
            assertParamExists('projectCreateClientMessageInProposal', 'createMessageInProjectDTO', createMessageInProjectDTO)
            const localVarPath = `/projects/clients-portal/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageInProjectDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {CreateMessageInProjectDTO} createMessageInProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateMessageInProposal: async (id: string, createMessageInProjectDTO: CreateMessageInProjectDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectCreateMessageInProposal', 'id', id)
            // verify required parameter 'createMessageInProjectDTO' is not null or undefined
            assertParamExists('projectCreateMessageInProposal', 'createMessageInProjectDTO', createMessageInProjectDTO)
            const localVarPath = `/projects/operator-message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageInProjectDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ProjectNoteDTO} projectNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProjectNote: async (id: string, projectNoteDTO: ProjectNoteDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectCreateProjectNote', 'id', id)
            // verify required parameter 'projectNoteDTO' is not null or undefined
            assertParamExists('projectCreateProjectNote', 'projectNoteDTO', projectNoteDTO)
            const localVarPath = `/projects/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectNoteDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project Note UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectNote: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectDeleteProjectNote', 'id', id)
            const localVarPath = `/projects/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenerateScheduleDTO} generateScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGenerateSchedule: async (generateScheduleDTO: GenerateScheduleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateScheduleDTO' is not null or undefined
            assertParamExists('projectGenerateSchedule', 'generateScheduleDTO', generateScheduleDTO)
            const localVarPath = `/projects/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateScheduleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectAssignments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectGetProjectAssignments', 'id', id)
            const localVarPath = `/projects/assignments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectGetProjectById', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectsNotes: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectGetProjectsNotes', 'id', id)
            const localVarPath = `/projects/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get projects paginated
         * @param {number} page 
         * @param {number} items 
         * @param {'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled' | 'On Hold' | 'Re-schedule' | 'Cancellation Requested'} [status] 
         * @param {string} [client] 
         * @param {string} [type] 
         * @param {string} [assignUrgency] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectsPaginated: async (page: number, items: number, status?: 'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled' | 'On Hold' | 'Re-schedule' | 'Cancellation Requested', client?: string, type?: string, assignUrgency?: string, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('projectGetProjectsPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('projectGetProjectsPaginated', 'items', items)
            const localVarPath = `/projects/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (assignUrgency !== undefined) {
                localVarQueryParameter['assignUrgency'] = assignUrgency;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProposalChatById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectGetProposalChatById', 'id', id)
            const localVarPath = `/projects/chat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectResourcesDTO} projectResourcesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetResources: async (projectResourcesDTO: ProjectResourcesDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectResourcesDTO' is not null or undefined
            assertParamExists('projectGetResources', 'projectResourcesDTO', projectResourcesDTO)
            const localVarPath = `/projects/resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectResourcesDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProjectUnassignmentParamsDTO} projectUnassignmentParamsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUnassignResources: async (projectUnassignmentParamsDTO: ProjectUnassignmentParamsDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUnassignmentParamsDTO' is not null or undefined
            assertParamExists('projectUnassignResources', 'projectUnassignmentParamsDTO', projectUnassignmentParamsDTO)
            const localVarPath = `/projects/unassign-resources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectUnassignmentParamsDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project Note UUID
         * @param {UpdateProjectNoteDTO} updateProjectNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectNote: async (id: string, updateProjectNoteDTO: UpdateProjectNoteDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectUpdateProjectNote', 'id', id)
            // verify required parameter 'updateProjectNoteDTO' is not null or undefined
            assertParamExists('projectUpdateProjectNote', 'updateProjectNoteDTO', updateProjectNoteDTO)
            const localVarPath = `/projects/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectNoteDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project status
         * @param {string} id Project UUID
         * @param {ProjectUpdateProjectStatusRequest} projectUpdateProjectStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectStatus: async (id: string, projectUpdateProjectStatusRequest: ProjectUpdateProjectStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectUpdateProjectStatus', 'id', id)
            // verify required parameter 'projectUpdateProjectStatusRequest' is not null or undefined
            assertParamExists('projectUpdateProjectStatus', 'projectUpdateProjectStatusRequest', projectUpdateProjectStatusRequest)
            const localVarPath = `/projects/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectUpdateProjectStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectViewInProgressProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/calendar-view/filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectViewOneInProgressProjects: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('projectViewOneInProgressProjects', 'id', id)
            const localVarPath = `/projects/calendar-view/one-filtered/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectManagementApi - functional programming interface
 * @export
 */
export const ProjectManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProjectAssignResourcesDTO} projectAssignResourcesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectAssignResources(projectAssignResourcesDTO: ProjectAssignResourcesDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectAssignResources(projectAssignResourcesDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ProjectEnforceGeofencingDTO} projectEnforceGeofencingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectChangeProjectEnforceGeofencing(id: string, projectEnforceGeofencingDTO: ProjectEnforceGeofencingDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectChangeProjectEnforceGeofencing(id, projectEnforceGeofencingDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ChangeLeadNameDTO} changeLeadNameDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectChangeProjectName(id: string, changeLeadNameDTO: ChangeLeadNameDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectChangeProjectName(id, changeLeadNameDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {CreateMessageInProjectDTO} createMessageInProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreateClientMessageInProposal(id: string, createMessageInProjectDTO: CreateMessageInProjectDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreateClientMessageInProposal(id, createMessageInProjectDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {CreateMessageInProjectDTO} createMessageInProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreateMessageInProposal(id: string, createMessageInProjectDTO: CreateMessageInProjectDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreateMessageInProposal(id, createMessageInProjectDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ProjectNoteDTO} projectNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreateProjectNote(id: string, projectNoteDTO: ProjectNoteDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectNotesResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreateProjectNote(id, projectNoteDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project Note UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectNote(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectNotesResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectNote(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenerateScheduleDTO} generateScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGenerateSchedule(generateScheduleDTO: GenerateScheduleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGenerateSchedule(generateScheduleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectAssignments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectAssignments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectsNotes(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectNotesResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectsNotes(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get projects paginated
         * @param {number} page 
         * @param {number} items 
         * @param {'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled' | 'On Hold' | 'Re-schedule' | 'Cancellation Requested'} [status] 
         * @param {string} [client] 
         * @param {string} [type] 
         * @param {string} [assignUrgency] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectsPaginated(page: number, items: number, status?: 'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled' | 'On Hold' | 'Re-schedule' | 'Cancellation Requested', client?: string, type?: string, assignUrgency?: string, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectPaginationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectsPaginated(page, items, status, client, type, assignUrgency, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProposalChatById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProposalChatById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectResourcesDTO} projectResourcesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetResources(projectResourcesDTO: ProjectResourcesDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetResources(projectResourcesDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProjectUnassignmentParamsDTO} projectUnassignmentParamsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUnassignResources(projectUnassignmentParamsDTO: ProjectUnassignmentParamsDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUnassignResources(projectUnassignmentParamsDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project Note UUID
         * @param {UpdateProjectNoteDTO} updateProjectNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectNote(id: string, updateProjectNoteDTO: UpdateProjectNoteDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectNote(id, updateProjectNoteDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update project status
         * @param {string} id Project UUID
         * @param {ProjectUpdateProjectStatusRequest} projectUpdateProjectStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectStatus(id: string, projectUpdateProjectStatusRequest: ProjectUpdateProjectStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectStatus(id, projectUpdateProjectStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectViewInProgressProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResponseCalendarViewDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectViewInProgressProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectViewOneInProgressProjects(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResponseCalendarViewDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectViewOneInProgressProjects(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectManagementApi - factory interface
 * @export
 */
export const ProjectManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {ProjectAssignResourcesDTO} projectAssignResourcesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectAssignResources(projectAssignResourcesDTO: ProjectAssignResourcesDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectAssignResources(projectAssignResourcesDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ProjectEnforceGeofencingDTO} projectEnforceGeofencingDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectChangeProjectEnforceGeofencing(id: string, projectEnforceGeofencingDTO: ProjectEnforceGeofencingDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectChangeProjectEnforceGeofencing(id, projectEnforceGeofencingDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ChangeLeadNameDTO} changeLeadNameDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectChangeProjectName(id: string, changeLeadNameDTO: ChangeLeadNameDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectChangeProjectName(id, changeLeadNameDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {CreateMessageInProjectDTO} createMessageInProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateClientMessageInProposal(id: string, createMessageInProjectDTO: CreateMessageInProjectDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectCreateClientMessageInProposal(id, createMessageInProjectDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {CreateMessageInProjectDTO} createMessageInProjectDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateMessageInProposal(id: string, createMessageInProjectDTO: CreateMessageInProjectDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectCreateMessageInProposal(id, createMessageInProjectDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {ProjectNoteDTO} projectNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProjectNote(id: string, projectNoteDTO: ProjectNoteDTO, options?: any): AxiosPromise<ProjectNotesResponseDTO> {
            return localVarFp.projectCreateProjectNote(id, projectNoteDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project Note UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectNote(id: string, options?: any): AxiosPromise<Array<ProjectNotesResponseDTO>> {
            return localVarFp.projectDeleteProjectNote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenerateScheduleDTO} generateScheduleDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGenerateSchedule(generateScheduleDTO: GenerateScheduleDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectGenerateSchedule(generateScheduleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectAssignments(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.projectGetProjectAssignments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project by id
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectById(id: string, options?: any): AxiosPromise<ProjectResponseDTO> {
            return localVarFp.projectGetProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectsNotes(id: string, options?: any): AxiosPromise<Array<ProjectNotesResponseDTO>> {
            return localVarFp.projectGetProjectsNotes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get projects paginated
         * @param {number} page 
         * @param {number} items 
         * @param {'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled' | 'On Hold' | 'Re-schedule' | 'Cancellation Requested'} [status] 
         * @param {string} [client] 
         * @param {string} [type] 
         * @param {string} [assignUrgency] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectsPaginated(page: number, items: number, status?: 'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled' | 'On Hold' | 'Re-schedule' | 'Cancellation Requested', client?: string, type?: string, assignUrgency?: string, searchText?: string, options?: any): AxiosPromise<ProjectPaginationResponse> {
            return localVarFp.projectGetProjectsPaginated(page, items, status, client, type, assignUrgency, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProposalChatById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.projectGetProposalChatById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectResourcesDTO} projectResourcesDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetResources(projectResourcesDTO: ProjectResourcesDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectGetResources(projectResourcesDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProjectUnassignmentParamsDTO} projectUnassignmentParamsDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUnassignResources(projectUnassignmentParamsDTO: ProjectUnassignmentParamsDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectUnassignResources(projectUnassignmentParamsDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project Note UUID
         * @param {UpdateProjectNoteDTO} updateProjectNoteDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectNote(id: string, updateProjectNoteDTO: UpdateProjectNoteDTO, options?: any): AxiosPromise<void> {
            return localVarFp.projectUpdateProjectNote(id, updateProjectNoteDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project status
         * @param {string} id Project UUID
         * @param {ProjectUpdateProjectStatusRequest} projectUpdateProjectStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectStatus(id: string, projectUpdateProjectStatusRequest: ProjectUpdateProjectStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.projectUpdateProjectStatus(id, projectUpdateProjectStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectViewInProgressProjects(options?: any): AxiosPromise<Array<ProjectResponseCalendarViewDTO>> {
            return localVarFp.projectViewInProgressProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Project UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectViewOneInProgressProjects(id: string, options?: any): AxiosPromise<Array<ProjectResponseCalendarViewDTO>> {
            return localVarFp.projectViewOneInProgressProjects(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for projectAssignResources operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectAssignResourcesRequest
 */
export interface ProjectManagementApiProjectAssignResourcesRequest {
    /**
     * 
     * @type {ProjectAssignResourcesDTO}
     * @memberof ProjectManagementApiProjectAssignResources
     */
    readonly projectAssignResourcesDTO: ProjectAssignResourcesDTO
}

/**
 * Request parameters for projectChangeProjectEnforceGeofencing operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectChangeProjectEnforceGeofencingRequest
 */
export interface ProjectManagementApiProjectChangeProjectEnforceGeofencingRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectChangeProjectEnforceGeofencing
     */
    readonly id: string

    /**
     * 
     * @type {ProjectEnforceGeofencingDTO}
     * @memberof ProjectManagementApiProjectChangeProjectEnforceGeofencing
     */
    readonly projectEnforceGeofencingDTO: ProjectEnforceGeofencingDTO
}

/**
 * Request parameters for projectChangeProjectName operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectChangeProjectNameRequest
 */
export interface ProjectManagementApiProjectChangeProjectNameRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectChangeProjectName
     */
    readonly id: string

    /**
     * 
     * @type {ChangeLeadNameDTO}
     * @memberof ProjectManagementApiProjectChangeProjectName
     */
    readonly changeLeadNameDTO: ChangeLeadNameDTO
}

/**
 * Request parameters for projectCreateClientMessageInProposal operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectCreateClientMessageInProposalRequest
 */
export interface ProjectManagementApiProjectCreateClientMessageInProposalRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectCreateClientMessageInProposal
     */
    readonly id: string

    /**
     * 
     * @type {CreateMessageInProjectDTO}
     * @memberof ProjectManagementApiProjectCreateClientMessageInProposal
     */
    readonly createMessageInProjectDTO: CreateMessageInProjectDTO
}

/**
 * Request parameters for projectCreateMessageInProposal operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectCreateMessageInProposalRequest
 */
export interface ProjectManagementApiProjectCreateMessageInProposalRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectCreateMessageInProposal
     */
    readonly id: string

    /**
     * 
     * @type {CreateMessageInProjectDTO}
     * @memberof ProjectManagementApiProjectCreateMessageInProposal
     */
    readonly createMessageInProjectDTO: CreateMessageInProjectDTO
}

/**
 * Request parameters for projectCreateProjectNote operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectCreateProjectNoteRequest
 */
export interface ProjectManagementApiProjectCreateProjectNoteRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectCreateProjectNote
     */
    readonly id: string

    /**
     * 
     * @type {ProjectNoteDTO}
     * @memberof ProjectManagementApiProjectCreateProjectNote
     */
    readonly projectNoteDTO: ProjectNoteDTO
}

/**
 * Request parameters for projectDeleteProjectNote operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectDeleteProjectNoteRequest
 */
export interface ProjectManagementApiProjectDeleteProjectNoteRequest {
    /**
     * Project Note UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectDeleteProjectNote
     */
    readonly id: string
}

/**
 * Request parameters for projectGenerateSchedule operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectGenerateScheduleRequest
 */
export interface ProjectManagementApiProjectGenerateScheduleRequest {
    /**
     * 
     * @type {GenerateScheduleDTO}
     * @memberof ProjectManagementApiProjectGenerateSchedule
     */
    readonly generateScheduleDTO: GenerateScheduleDTO
}

/**
 * Request parameters for projectGetProjectAssignments operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectGetProjectAssignmentsRequest
 */
export interface ProjectManagementApiProjectGetProjectAssignmentsRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectGetProjectAssignments
     */
    readonly id: string
}

/**
 * Request parameters for projectGetProjectById operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectGetProjectByIdRequest
 */
export interface ProjectManagementApiProjectGetProjectByIdRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectGetProjectById
     */
    readonly id: string
}

/**
 * Request parameters for projectGetProjectsNotes operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectGetProjectsNotesRequest
 */
export interface ProjectManagementApiProjectGetProjectsNotesRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectGetProjectsNotes
     */
    readonly id: string
}

/**
 * Request parameters for projectGetProjectsPaginated operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectGetProjectsPaginatedRequest
 */
export interface ProjectManagementApiProjectGetProjectsPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectManagementApiProjectGetProjectsPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof ProjectManagementApiProjectGetProjectsPaginated
     */
    readonly items: number

    /**
     * 
     * @type {'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled' | 'On Hold' | 'Re-schedule' | 'Cancellation Requested'}
     * @memberof ProjectManagementApiProjectGetProjectsPaginated
     */
    readonly status?: 'Scheduled' | 'In Progress' | 'Completed' | 'Cancelled' | 'On Hold' | 'Re-schedule' | 'Cancellation Requested'

    /**
     * 
     * @type {string}
     * @memberof ProjectManagementApiProjectGetProjectsPaginated
     */
    readonly client?: string

    /**
     * 
     * @type {string}
     * @memberof ProjectManagementApiProjectGetProjectsPaginated
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof ProjectManagementApiProjectGetProjectsPaginated
     */
    readonly assignUrgency?: string

    /**
     * 
     * @type {string}
     * @memberof ProjectManagementApiProjectGetProjectsPaginated
     */
    readonly searchText?: string
}

/**
 * Request parameters for projectGetProposalChatById operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectGetProposalChatByIdRequest
 */
export interface ProjectManagementApiProjectGetProposalChatByIdRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectGetProposalChatById
     */
    readonly id: string
}

/**
 * Request parameters for projectGetResources operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectGetResourcesRequest
 */
export interface ProjectManagementApiProjectGetResourcesRequest {
    /**
     * 
     * @type {ProjectResourcesDTO}
     * @memberof ProjectManagementApiProjectGetResources
     */
    readonly projectResourcesDTO: ProjectResourcesDTO
}

/**
 * Request parameters for projectUnassignResources operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectUnassignResourcesRequest
 */
export interface ProjectManagementApiProjectUnassignResourcesRequest {
    /**
     * 
     * @type {ProjectUnassignmentParamsDTO}
     * @memberof ProjectManagementApiProjectUnassignResources
     */
    readonly projectUnassignmentParamsDTO: ProjectUnassignmentParamsDTO
}

/**
 * Request parameters for projectUpdateProjectNote operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectUpdateProjectNoteRequest
 */
export interface ProjectManagementApiProjectUpdateProjectNoteRequest {
    /**
     * Project Note UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectUpdateProjectNote
     */
    readonly id: string

    /**
     * 
     * @type {UpdateProjectNoteDTO}
     * @memberof ProjectManagementApiProjectUpdateProjectNote
     */
    readonly updateProjectNoteDTO: UpdateProjectNoteDTO
}

/**
 * Request parameters for projectUpdateProjectStatus operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectUpdateProjectStatusRequest
 */
export interface ProjectManagementApiProjectUpdateProjectStatusRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectUpdateProjectStatus
     */
    readonly id: string

    /**
     * 
     * @type {ProjectUpdateProjectStatusRequest}
     * @memberof ProjectManagementApiProjectUpdateProjectStatus
     */
    readonly projectUpdateProjectStatusRequest: ProjectUpdateProjectStatusRequest
}

/**
 * Request parameters for projectViewOneInProgressProjects operation in ProjectManagementApi.
 * @export
 * @interface ProjectManagementApiProjectViewOneInProgressProjectsRequest
 */
export interface ProjectManagementApiProjectViewOneInProgressProjectsRequest {
    /**
     * Project UUID
     * @type {string}
     * @memberof ProjectManagementApiProjectViewOneInProgressProjects
     */
    readonly id: string
}

/**
 * ProjectManagementApi - object-oriented interface
 * @export
 * @class ProjectManagementApi
 * @extends {BaseAPI}
 */
export class ProjectManagementApi extends BaseAPI {
    /**
     * 
     * @param {ProjectManagementApiProjectAssignResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectAssignResources(requestParameters: ProjectManagementApiProjectAssignResourcesRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectAssignResources(requestParameters.projectAssignResourcesDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectChangeProjectEnforceGeofencingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectChangeProjectEnforceGeofencing(requestParameters: ProjectManagementApiProjectChangeProjectEnforceGeofencingRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectChangeProjectEnforceGeofencing(requestParameters.id, requestParameters.projectEnforceGeofencingDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectChangeProjectNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectChangeProjectName(requestParameters: ProjectManagementApiProjectChangeProjectNameRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectChangeProjectName(requestParameters.id, requestParameters.changeLeadNameDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectCreateClientMessageInProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectCreateClientMessageInProposal(requestParameters: ProjectManagementApiProjectCreateClientMessageInProposalRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectCreateClientMessageInProposal(requestParameters.id, requestParameters.createMessageInProjectDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectCreateMessageInProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectCreateMessageInProposal(requestParameters: ProjectManagementApiProjectCreateMessageInProposalRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectCreateMessageInProposal(requestParameters.id, requestParameters.createMessageInProjectDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectCreateProjectNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectCreateProjectNote(requestParameters: ProjectManagementApiProjectCreateProjectNoteRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectCreateProjectNote(requestParameters.id, requestParameters.projectNoteDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectDeleteProjectNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectDeleteProjectNote(requestParameters: ProjectManagementApiProjectDeleteProjectNoteRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectDeleteProjectNote(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectGenerateScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectGenerateSchedule(requestParameters: ProjectManagementApiProjectGenerateScheduleRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectGenerateSchedule(requestParameters.generateScheduleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectGetProjectAssignmentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectGetProjectAssignments(requestParameters: ProjectManagementApiProjectGetProjectAssignmentsRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectGetProjectAssignments(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project by id
     * @param {ProjectManagementApiProjectGetProjectByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectGetProjectById(requestParameters: ProjectManagementApiProjectGetProjectByIdRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectGetProjectById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectGetProjectsNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectGetProjectsNotes(requestParameters: ProjectManagementApiProjectGetProjectsNotesRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectGetProjectsNotes(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get projects paginated
     * @param {ProjectManagementApiProjectGetProjectsPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectGetProjectsPaginated(requestParameters: ProjectManagementApiProjectGetProjectsPaginatedRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectGetProjectsPaginated(requestParameters.page, requestParameters.items, requestParameters.status, requestParameters.client, requestParameters.type, requestParameters.assignUrgency, requestParameters.searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectGetProposalChatByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectGetProposalChatById(requestParameters: ProjectManagementApiProjectGetProposalChatByIdRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectGetProposalChatById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectGetResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectGetResources(requestParameters: ProjectManagementApiProjectGetResourcesRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectGetResources(requestParameters.projectResourcesDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectUnassignResourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectUnassignResources(requestParameters: ProjectManagementApiProjectUnassignResourcesRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectUnassignResources(requestParameters.projectUnassignmentParamsDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectUpdateProjectNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectUpdateProjectNote(requestParameters: ProjectManagementApiProjectUpdateProjectNoteRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectUpdateProjectNote(requestParameters.id, requestParameters.updateProjectNoteDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project status
     * @param {ProjectManagementApiProjectUpdateProjectStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectUpdateProjectStatus(requestParameters: ProjectManagementApiProjectUpdateProjectStatusRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectUpdateProjectStatus(requestParameters.id, requestParameters.projectUpdateProjectStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectViewInProgressProjects(options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectViewInProgressProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProjectManagementApiProjectViewOneInProgressProjectsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectManagementApi
     */
    public projectViewOneInProgressProjects(requestParameters: ProjectManagementApiProjectViewOneInProgressProjectsRequest, options?: AxiosRequestConfig) {
        return ProjectManagementApiFp(this.configuration).projectViewOneInProgressProjects(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProposalApi - axios parameter creator
 * @export
 */
export const ProposalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {WatchersIdsArrayDTO} watchersIdsArrayDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalAddNewWatcher: async (id: string, watchersIdsArrayDTO: WatchersIdsArrayDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalAddNewWatcher', 'id', id)
            // verify required parameter 'watchersIdsArrayDTO' is not null or undefined
            assertParamExists('proposalAddNewWatcher', 'watchersIdsArrayDTO', watchersIdsArrayDTO)
            const localVarPath = `/proposal/watchers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(watchersIdsArrayDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalBackupProposal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalBackupProposal', 'id', id)
            const localVarPath = `/proposal/backup-proposal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalCreateClientMessageInProposal: async (token: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('proposalCreateClientMessageInProposal', 'token', token)
            // verify required parameter 'createMessageInProposalDTO' is not null or undefined
            assertParamExists('proposalCreateClientMessageInProposal', 'createMessageInProposalDTO', createMessageInProposalDTO)
            const localVarPath = `/proposal/client-message/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageInProposalDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalCreateMessageInProposal: async (id: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalCreateMessageInProposal', 'id', id)
            // verify required parameter 'createMessageInProposalDTO' is not null or undefined
            assertParamExists('proposalCreateMessageInProposal', 'createMessageInProposalDTO', createMessageInProposalDTO)
            const localVarPath = `/proposal/operator-message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageInProposalDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProposalCreateProposalRequest} proposalCreateProposalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalCreateProposal: async (proposalCreateProposalRequest: ProposalCreateProposalRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'proposalCreateProposalRequest' is not null or undefined
            assertParamExists('proposalCreateProposal', 'proposalCreateProposalRequest', proposalCreateProposalRequest)
            const localVarPath = `/proposal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proposalCreateProposalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {ProposalCounterOfferDTO} proposalCounterOfferDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalCreateProposalCounterOffer: async (token: string, proposalCounterOfferDTO: ProposalCounterOfferDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('proposalCreateProposalCounterOffer', 'token', token)
            // verify required parameter 'proposalCounterOfferDTO' is not null or undefined
            assertParamExists('proposalCreateProposalCounterOffer', 'proposalCounterOfferDTO', proposalCounterOfferDTO)
            const localVarPath = `/proposal/counter-offer/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proposalCounterOfferDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetPaginatedProposalsPerCompany: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('proposalGetPaginatedProposalsPerCompany', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('proposalGetPaginatedProposalsPerCompany', 'items', items)
            const localVarPath = `/proposal/company-paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalGetProposalById', 'id', id)
            const localVarPath = `/proposal/company/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalByLeadId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalGetProposalByLeadId', 'id', id)
            const localVarPath = `/proposal/lead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalByToken: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('proposalGetProposalByToken', 'token', token)
            const localVarPath = `/proposal/view-proposal/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalChatById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalGetProposalChatById', 'id', id)
            const localVarPath = `/proposal/chat/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalCounterOffers: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalGetProposalCounterOffers', 'id', id)
            const localVarPath = `/proposal/counter-offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalsByLeadId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalGetProposalsByLeadId', 'id', id)
            const localVarPath = `/proposal/lead-multiple/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalsPerCompany: async (filter?: string, value?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/proposal/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalRecallProposal: async (id: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalRecallProposal', 'id', id)
            // verify required parameter 'createMessageInProposalDTO' is not null or undefined
            assertParamExists('proposalRecallProposal', 'createMessageInProposalDTO', createMessageInProposalDTO)
            const localVarPath = `/proposal/recall/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageInProposalDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalResendProposal: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalResendProposal', 'id', id)
            const localVarPath = `/proposal/resend-proposal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'accept' | 'reject'} status 
         * @param {string} id proposal id
         * @param {DecisionMessageDTO} decisionMessageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalSetCounterOfferDecisionStatus: async (status: 'accept' | 'reject', id: string, decisionMessageDTO: DecisionMessageDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('proposalSetCounterOfferDecisionStatus', 'status', status)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalSetCounterOfferDecisionStatus', 'id', id)
            // verify required parameter 'decisionMessageDTO' is not null or undefined
            assertParamExists('proposalSetCounterOfferDecisionStatus', 'decisionMessageDTO', decisionMessageDTO)
            const localVarPath = `/proposal/counter-offer-decision/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(decisionMessageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {string} status 
         * @param {DecisionMessageDTO} decisionMessageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalSetDecisionStatus: async (token: string, status: string, decisionMessageDTO: DecisionMessageDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('proposalSetDecisionStatus', 'token', token)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('proposalSetDecisionStatus', 'status', status)
            // verify required parameter 'decisionMessageDTO' is not null or undefined
            assertParamExists('proposalSetDecisionStatus', 'decisionMessageDTO', decisionMessageDTO)
            const localVarPath = `/proposal/decision/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(decisionMessageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalSoftDeleteProposalById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalSoftDeleteProposalById', 'id', id)
            const localVarPath = `/proposal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProposalBodyDTO} updateProposalBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalUpdate: async (id: string, updateProposalBodyDTO: UpdateProposalBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalUpdate', 'id', id)
            // verify required parameter 'updateProposalBodyDTO' is not null or undefined
            assertParamExists('proposalUpdate', 'updateProposalBodyDTO', updateProposalBodyDTO)
            const localVarPath = `/proposal/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProposalBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalUpdatePricePerVisit: async (id: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalUpdatePricePerVisit', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('proposalUpdatePricePerVisit', 'requestBody', requestBody)
            const localVarPath = `/proposal/price-per-visit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProposalApi - functional programming interface
 * @export
 */
export const ProposalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProposalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {WatchersIdsArrayDTO} watchersIdsArrayDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalAddNewWatcher(id: string, watchersIdsArrayDTO: WatchersIdsArrayDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalAddNewWatcher(id, watchersIdsArrayDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalBackupProposal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalBackupProposal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalCreateClientMessageInProposal(token: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalCreateClientMessageInProposal(token, createMessageInProposalDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalCreateMessageInProposal(id: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalCreateMessageInProposal(id, createMessageInProposalDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProposalCreateProposalRequest} proposalCreateProposalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalCreateProposal(proposalCreateProposalRequest: ProposalCreateProposalRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalCreateProposal(proposalCreateProposalRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {ProposalCounterOfferDTO} proposalCounterOfferDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalCreateProposalCounterOffer(token: string, proposalCounterOfferDTO: ProposalCounterOfferDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalCreateProposalCounterOffer(token, proposalCounterOfferDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalGetPaginatedProposalsPerCompany(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalGetPaginatedProposalsPerCompany(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalGetProposalById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalGetProposalById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalGetProposalByLeadId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalGetProposalByLeadId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalGetProposalByToken(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProposalByTokenDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalGetProposalByToken(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalGetProposalChatById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalGetProposalChatById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalGetProposalCounterOffers(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalGetProposalCounterOffers(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalGetProposalsByLeadId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalGetProposalsByLeadId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalGetProposalsPerCompany(filter?: string, value?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalGetProposalsPerCompany(filter, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalRecallProposal(id: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalRecallProposal(id, createMessageInProposalDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalResendProposal(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalResendProposal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'accept' | 'reject'} status 
         * @param {string} id proposal id
         * @param {DecisionMessageDTO} decisionMessageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalSetCounterOfferDecisionStatus(status: 'accept' | 'reject', id: string, decisionMessageDTO: DecisionMessageDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalSetCounterOfferDecisionStatus(status, id, decisionMessageDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {string} status 
         * @param {DecisionMessageDTO} decisionMessageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalSetDecisionStatus(token: string, status: string, decisionMessageDTO: DecisionMessageDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalSetDecisionStatus(token, status, decisionMessageDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalSoftDeleteProposalById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalSoftDeleteProposalById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProposalBodyDTO} updateProposalBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalUpdate(id: string, updateProposalBodyDTO: UpdateProposalBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalUpdate(id, updateProposalBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalUpdatePricePerVisit(id: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalUpdatePricePerVisit(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProposalApi - factory interface
 * @export
 */
export const ProposalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProposalApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {WatchersIdsArrayDTO} watchersIdsArrayDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalAddNewWatcher(id: string, watchersIdsArrayDTO: WatchersIdsArrayDTO, options?: any): AxiosPromise<void> {
            return localVarFp.proposalAddNewWatcher(id, watchersIdsArrayDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalBackupProposal(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalBackupProposal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalCreateClientMessageInProposal(token: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options?: any): AxiosPromise<void> {
            return localVarFp.proposalCreateClientMessageInProposal(token, createMessageInProposalDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalCreateMessageInProposal(id: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options?: any): AxiosPromise<void> {
            return localVarFp.proposalCreateMessageInProposal(id, createMessageInProposalDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProposalCreateProposalRequest} proposalCreateProposalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalCreateProposal(proposalCreateProposalRequest: ProposalCreateProposalRequest, options?: any): AxiosPromise<void> {
            return localVarFp.proposalCreateProposal(proposalCreateProposalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {ProposalCounterOfferDTO} proposalCounterOfferDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalCreateProposalCounterOffer(token: string, proposalCounterOfferDTO: ProposalCounterOfferDTO, options?: any): AxiosPromise<void> {
            return localVarFp.proposalCreateProposalCounterOffer(token, proposalCounterOfferDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetPaginatedProposalsPerCompany(page: number, items: number, options?: any): AxiosPromise<void> {
            return localVarFp.proposalGetPaginatedProposalsPerCompany(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalGetProposalById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalByLeadId(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalGetProposalByLeadId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalByToken(token: string, options?: any): AxiosPromise<GetProposalByTokenDTO> {
            return localVarFp.proposalGetProposalByToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalChatById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalGetProposalChatById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalCounterOffers(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalGetProposalCounterOffers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalsByLeadId(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalGetProposalsByLeadId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {string} [value] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalGetProposalsPerCompany(filter?: string, value?: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalGetProposalsPerCompany(filter, value, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateMessageInProposalDTO} createMessageInProposalDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalRecallProposal(id: string, createMessageInProposalDTO: CreateMessageInProposalDTO, options?: any): AxiosPromise<void> {
            return localVarFp.proposalRecallProposal(id, createMessageInProposalDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalResendProposal(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalResendProposal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'accept' | 'reject'} status 
         * @param {string} id proposal id
         * @param {DecisionMessageDTO} decisionMessageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalSetCounterOfferDecisionStatus(status: 'accept' | 'reject', id: string, decisionMessageDTO: DecisionMessageDTO, options?: any): AxiosPromise<void> {
            return localVarFp.proposalSetCounterOfferDecisionStatus(status, id, decisionMessageDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {string} status 
         * @param {DecisionMessageDTO} decisionMessageDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalSetDecisionStatus(token: string, status: string, decisionMessageDTO: DecisionMessageDTO, options?: any): AxiosPromise<void> {
            return localVarFp.proposalSetDecisionStatus(token, status, decisionMessageDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalSoftDeleteProposalById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposalSoftDeleteProposalById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateProposalBodyDTO} updateProposalBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalUpdate(id: string, updateProposalBodyDTO: UpdateProposalBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.proposalUpdate(id, updateProposalBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalUpdatePricePerVisit(id: string, requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.proposalUpdatePricePerVisit(id, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for proposalAddNewWatcher operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalAddNewWatcherRequest
 */
export interface ProposalApiProposalAddNewWatcherRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalAddNewWatcher
     */
    readonly id: string

    /**
     * 
     * @type {WatchersIdsArrayDTO}
     * @memberof ProposalApiProposalAddNewWatcher
     */
    readonly watchersIdsArrayDTO: WatchersIdsArrayDTO
}

/**
 * Request parameters for proposalBackupProposal operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalBackupProposalRequest
 */
export interface ProposalApiProposalBackupProposalRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalBackupProposal
     */
    readonly id: string
}

/**
 * Request parameters for proposalCreateClientMessageInProposal operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalCreateClientMessageInProposalRequest
 */
export interface ProposalApiProposalCreateClientMessageInProposalRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalCreateClientMessageInProposal
     */
    readonly token: string

    /**
     * 
     * @type {CreateMessageInProposalDTO}
     * @memberof ProposalApiProposalCreateClientMessageInProposal
     */
    readonly createMessageInProposalDTO: CreateMessageInProposalDTO
}

/**
 * Request parameters for proposalCreateMessageInProposal operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalCreateMessageInProposalRequest
 */
export interface ProposalApiProposalCreateMessageInProposalRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalCreateMessageInProposal
     */
    readonly id: string

    /**
     * 
     * @type {CreateMessageInProposalDTO}
     * @memberof ProposalApiProposalCreateMessageInProposal
     */
    readonly createMessageInProposalDTO: CreateMessageInProposalDTO
}

/**
 * Request parameters for proposalCreateProposal operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalCreateProposalRequest
 */
export interface ProposalApiProposalCreateProposalRequest {
    /**
     * 
     * @type {ProposalCreateProposalRequest}
     * @memberof ProposalApiProposalCreateProposal
     */
    readonly proposalCreateProposalRequest: ProposalCreateProposalRequest
}

/**
 * Request parameters for proposalCreateProposalCounterOffer operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalCreateProposalCounterOfferRequest
 */
export interface ProposalApiProposalCreateProposalCounterOfferRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalCreateProposalCounterOffer
     */
    readonly token: string

    /**
     * 
     * @type {ProposalCounterOfferDTO}
     * @memberof ProposalApiProposalCreateProposalCounterOffer
     */
    readonly proposalCounterOfferDTO: ProposalCounterOfferDTO
}

/**
 * Request parameters for proposalGetPaginatedProposalsPerCompany operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalGetPaginatedProposalsPerCompanyRequest
 */
export interface ProposalApiProposalGetPaginatedProposalsPerCompanyRequest {
    /**
     * 
     * @type {number}
     * @memberof ProposalApiProposalGetPaginatedProposalsPerCompany
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof ProposalApiProposalGetPaginatedProposalsPerCompany
     */
    readonly items: number
}

/**
 * Request parameters for proposalGetProposalById operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalGetProposalByIdRequest
 */
export interface ProposalApiProposalGetProposalByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalGetProposalById
     */
    readonly id: string
}

/**
 * Request parameters for proposalGetProposalByLeadId operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalGetProposalByLeadIdRequest
 */
export interface ProposalApiProposalGetProposalByLeadIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalGetProposalByLeadId
     */
    readonly id: string
}

/**
 * Request parameters for proposalGetProposalByToken operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalGetProposalByTokenRequest
 */
export interface ProposalApiProposalGetProposalByTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalGetProposalByToken
     */
    readonly token: string
}

/**
 * Request parameters for proposalGetProposalChatById operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalGetProposalChatByIdRequest
 */
export interface ProposalApiProposalGetProposalChatByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalGetProposalChatById
     */
    readonly id: string
}

/**
 * Request parameters for proposalGetProposalCounterOffers operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalGetProposalCounterOffersRequest
 */
export interface ProposalApiProposalGetProposalCounterOffersRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalGetProposalCounterOffers
     */
    readonly id: string
}

/**
 * Request parameters for proposalGetProposalsByLeadId operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalGetProposalsByLeadIdRequest
 */
export interface ProposalApiProposalGetProposalsByLeadIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalGetProposalsByLeadId
     */
    readonly id: string
}

/**
 * Request parameters for proposalGetProposalsPerCompany operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalGetProposalsPerCompanyRequest
 */
export interface ProposalApiProposalGetProposalsPerCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalGetProposalsPerCompany
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalGetProposalsPerCompany
     */
    readonly value?: string
}

/**
 * Request parameters for proposalRecallProposal operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalRecallProposalRequest
 */
export interface ProposalApiProposalRecallProposalRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalRecallProposal
     */
    readonly id: string

    /**
     * 
     * @type {CreateMessageInProposalDTO}
     * @memberof ProposalApiProposalRecallProposal
     */
    readonly createMessageInProposalDTO: CreateMessageInProposalDTO
}

/**
 * Request parameters for proposalResendProposal operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalResendProposalRequest
 */
export interface ProposalApiProposalResendProposalRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalResendProposal
     */
    readonly id: string
}

/**
 * Request parameters for proposalSetCounterOfferDecisionStatus operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalSetCounterOfferDecisionStatusRequest
 */
export interface ProposalApiProposalSetCounterOfferDecisionStatusRequest {
    /**
     * 
     * @type {'accept' | 'reject'}
     * @memberof ProposalApiProposalSetCounterOfferDecisionStatus
     */
    readonly status: 'accept' | 'reject'

    /**
     * proposal id
     * @type {string}
     * @memberof ProposalApiProposalSetCounterOfferDecisionStatus
     */
    readonly id: string

    /**
     * 
     * @type {DecisionMessageDTO}
     * @memberof ProposalApiProposalSetCounterOfferDecisionStatus
     */
    readonly decisionMessageDTO: DecisionMessageDTO
}

/**
 * Request parameters for proposalSetDecisionStatus operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalSetDecisionStatusRequest
 */
export interface ProposalApiProposalSetDecisionStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalSetDecisionStatus
     */
    readonly token: string

    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalSetDecisionStatus
     */
    readonly status: string

    /**
     * 
     * @type {DecisionMessageDTO}
     * @memberof ProposalApiProposalSetDecisionStatus
     */
    readonly decisionMessageDTO: DecisionMessageDTO
}

/**
 * Request parameters for proposalSoftDeleteProposalById operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalSoftDeleteProposalByIdRequest
 */
export interface ProposalApiProposalSoftDeleteProposalByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalSoftDeleteProposalById
     */
    readonly id: string
}

/**
 * Request parameters for proposalUpdate operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalUpdateRequest
 */
export interface ProposalApiProposalUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateProposalBodyDTO}
     * @memberof ProposalApiProposalUpdate
     */
    readonly updateProposalBodyDTO: UpdateProposalBodyDTO
}

/**
 * Request parameters for proposalUpdatePricePerVisit operation in ProposalApi.
 * @export
 * @interface ProposalApiProposalUpdatePricePerVisitRequest
 */
export interface ProposalApiProposalUpdatePricePerVisitRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalApiProposalUpdatePricePerVisit
     */
    readonly id: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ProposalApiProposalUpdatePricePerVisit
     */
    readonly requestBody: Array<string>
}

/**
 * ProposalApi - object-oriented interface
 * @export
 * @class ProposalApi
 * @extends {BaseAPI}
 */
export class ProposalApi extends BaseAPI {
    /**
     * 
     * @param {ProposalApiProposalAddNewWatcherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalAddNewWatcher(requestParameters: ProposalApiProposalAddNewWatcherRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalAddNewWatcher(requestParameters.id, requestParameters.watchersIdsArrayDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalBackupProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalBackupProposal(requestParameters: ProposalApiProposalBackupProposalRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalBackupProposal(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalCreateClientMessageInProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalCreateClientMessageInProposal(requestParameters: ProposalApiProposalCreateClientMessageInProposalRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalCreateClientMessageInProposal(requestParameters.token, requestParameters.createMessageInProposalDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalCreateMessageInProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalCreateMessageInProposal(requestParameters: ProposalApiProposalCreateMessageInProposalRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalCreateMessageInProposal(requestParameters.id, requestParameters.createMessageInProposalDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalCreateProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalCreateProposal(requestParameters: ProposalApiProposalCreateProposalRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalCreateProposal(requestParameters.proposalCreateProposalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalCreateProposalCounterOfferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalCreateProposalCounterOffer(requestParameters: ProposalApiProposalCreateProposalCounterOfferRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalCreateProposalCounterOffer(requestParameters.token, requestParameters.proposalCounterOfferDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalGetPaginatedProposalsPerCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalGetPaginatedProposalsPerCompany(requestParameters: ProposalApiProposalGetPaginatedProposalsPerCompanyRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalGetPaginatedProposalsPerCompany(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalGetProposalByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalGetProposalById(requestParameters: ProposalApiProposalGetProposalByIdRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalGetProposalById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalGetProposalByLeadIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalGetProposalByLeadId(requestParameters: ProposalApiProposalGetProposalByLeadIdRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalGetProposalByLeadId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalGetProposalByTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalGetProposalByToken(requestParameters: ProposalApiProposalGetProposalByTokenRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalGetProposalByToken(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalGetProposalChatByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalGetProposalChatById(requestParameters: ProposalApiProposalGetProposalChatByIdRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalGetProposalChatById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalGetProposalCounterOffersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalGetProposalCounterOffers(requestParameters: ProposalApiProposalGetProposalCounterOffersRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalGetProposalCounterOffers(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalGetProposalsByLeadIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalGetProposalsByLeadId(requestParameters: ProposalApiProposalGetProposalsByLeadIdRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalGetProposalsByLeadId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalGetProposalsPerCompanyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalGetProposalsPerCompany(requestParameters: ProposalApiProposalGetProposalsPerCompanyRequest = {}, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalGetProposalsPerCompany(requestParameters.filter, requestParameters.value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalRecallProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalRecallProposal(requestParameters: ProposalApiProposalRecallProposalRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalRecallProposal(requestParameters.id, requestParameters.createMessageInProposalDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalResendProposalRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalResendProposal(requestParameters: ProposalApiProposalResendProposalRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalResendProposal(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalSetCounterOfferDecisionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalSetCounterOfferDecisionStatus(requestParameters: ProposalApiProposalSetCounterOfferDecisionStatusRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalSetCounterOfferDecisionStatus(requestParameters.status, requestParameters.id, requestParameters.decisionMessageDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalSetDecisionStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalSetDecisionStatus(requestParameters: ProposalApiProposalSetDecisionStatusRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalSetDecisionStatus(requestParameters.token, requestParameters.status, requestParameters.decisionMessageDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalSoftDeleteProposalByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalSoftDeleteProposalById(requestParameters: ProposalApiProposalSoftDeleteProposalByIdRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalSoftDeleteProposalById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalUpdate(requestParameters: ProposalApiProposalUpdateRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalUpdate(requestParameters.id, requestParameters.updateProposalBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalApiProposalUpdatePricePerVisitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalApi
     */
    public proposalUpdatePricePerVisit(requestParameters: ProposalApiProposalUpdatePricePerVisitRequest, options?: AxiosRequestConfig) {
        return ProposalApiFp(this.configuration).proposalUpdatePricePerVisit(requestParameters.id, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProposalMessagesTemplateApi - axios parameter creator
 * @export
 */
export const ProposalMessagesTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateProposalMessagesTemplateDTO} createProposalMessagesTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateCreateProposalTemplate: async (createProposalMessagesTemplateDTO: CreateProposalMessagesTemplateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProposalMessagesTemplateDTO' is not null or undefined
            assertParamExists('proposalMessagesTemplateCreateProposalTemplate', 'createProposalMessagesTemplateDTO', createProposalMessagesTemplateDTO)
            const localVarPath = `/proposal-messages-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProposalMessagesTemplateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateDeleteProposalTemplate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalMessagesTemplateDeleteProposalTemplate', 'id', id)
            const localVarPath = `/proposal-messages-template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateGetMessageTemplate: async (title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/proposal-messages-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateGetMessagesTemplatesPaginate: async (page: number, items: number, title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('proposalMessagesTemplateGetMessagesTemplatesPaginate', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('proposalMessagesTemplateGetMessagesTemplatesPaginate', 'items', items)
            const localVarPath = `/proposal-messages-template/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateGetProposalMessageTemplateById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalMessagesTemplateGetProposalMessageTemplateById', 'id', id)
            const localVarPath = `/proposal-messages-template/getById/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProposalMessagesTemplateDTO} updateProposalMessagesTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateUpdate: async (id: number, updateProposalMessagesTemplateDTO: UpdateProposalMessagesTemplateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('proposalMessagesTemplateUpdate', 'id', id)
            // verify required parameter 'updateProposalMessagesTemplateDTO' is not null or undefined
            assertParamExists('proposalMessagesTemplateUpdate', 'updateProposalMessagesTemplateDTO', updateProposalMessagesTemplateDTO)
            const localVarPath = `/proposal-messages-template/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProposalMessagesTemplateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProposalMessagesTemplateApi - functional programming interface
 * @export
 */
export const ProposalMessagesTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProposalMessagesTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateProposalMessagesTemplateDTO} createProposalMessagesTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalMessagesTemplateCreateProposalTemplate(createProposalMessagesTemplateDTO: CreateProposalMessagesTemplateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposalTemplateMessageResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalMessagesTemplateCreateProposalTemplate(createProposalMessagesTemplateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalMessagesTemplateDeleteProposalTemplate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalMessagesTemplateDeleteProposalTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalMessagesTemplateGetMessageTemplate(title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposalTemplateMessageResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalMessagesTemplateGetMessageTemplate(title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalMessagesTemplateGetMessagesTemplatesPaginate(page: number, items: number, title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposalTemplateMessagesPaginatedResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalMessagesTemplateGetMessagesTemplatesPaginate(page, items, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalMessagesTemplateGetProposalMessageTemplateById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposalTemplateMessageResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalMessagesTemplateGetProposalMessageTemplateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProposalMessagesTemplateDTO} updateProposalMessagesTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalMessagesTemplateUpdate(id: number, updateProposalMessagesTemplateDTO: UpdateProposalMessagesTemplateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposalTemplateMessageResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposalMessagesTemplateUpdate(id, updateProposalMessagesTemplateDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProposalMessagesTemplateApi - factory interface
 * @export
 */
export const ProposalMessagesTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProposalMessagesTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateProposalMessagesTemplateDTO} createProposalMessagesTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateCreateProposalTemplate(createProposalMessagesTemplateDTO: CreateProposalMessagesTemplateDTO, options?: any): AxiosPromise<ProposalTemplateMessageResponseDTO> {
            return localVarFp.proposalMessagesTemplateCreateProposalTemplate(createProposalMessagesTemplateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateDeleteProposalTemplate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.proposalMessagesTemplateDeleteProposalTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateGetMessageTemplate(title?: string, options?: any): AxiosPromise<ProposalTemplateMessageResponseDTO> {
            return localVarFp.proposalMessagesTemplateGetMessageTemplate(title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateGetMessagesTemplatesPaginate(page: number, items: number, title?: string, options?: any): AxiosPromise<ProposalTemplateMessagesPaginatedResponseDTO> {
            return localVarFp.proposalMessagesTemplateGetMessagesTemplatesPaginate(page, items, title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateGetProposalMessageTemplateById(id: number, options?: any): AxiosPromise<ProposalTemplateMessageResponseDTO> {
            return localVarFp.proposalMessagesTemplateGetProposalMessageTemplateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProposalMessagesTemplateDTO} updateProposalMessagesTemplateDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalMessagesTemplateUpdate(id: number, updateProposalMessagesTemplateDTO: UpdateProposalMessagesTemplateDTO, options?: any): AxiosPromise<ProposalTemplateMessageResponseDTO> {
            return localVarFp.proposalMessagesTemplateUpdate(id, updateProposalMessagesTemplateDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for proposalMessagesTemplateCreateProposalTemplate operation in ProposalMessagesTemplateApi.
 * @export
 * @interface ProposalMessagesTemplateApiProposalMessagesTemplateCreateProposalTemplateRequest
 */
export interface ProposalMessagesTemplateApiProposalMessagesTemplateCreateProposalTemplateRequest {
    /**
     * 
     * @type {CreateProposalMessagesTemplateDTO}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateCreateProposalTemplate
     */
    readonly createProposalMessagesTemplateDTO: CreateProposalMessagesTemplateDTO
}

/**
 * Request parameters for proposalMessagesTemplateDeleteProposalTemplate operation in ProposalMessagesTemplateApi.
 * @export
 * @interface ProposalMessagesTemplateApiProposalMessagesTemplateDeleteProposalTemplateRequest
 */
export interface ProposalMessagesTemplateApiProposalMessagesTemplateDeleteProposalTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateDeleteProposalTemplate
     */
    readonly id: number
}

/**
 * Request parameters for proposalMessagesTemplateGetMessageTemplate operation in ProposalMessagesTemplateApi.
 * @export
 * @interface ProposalMessagesTemplateApiProposalMessagesTemplateGetMessageTemplateRequest
 */
export interface ProposalMessagesTemplateApiProposalMessagesTemplateGetMessageTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateGetMessageTemplate
     */
    readonly title?: string
}

/**
 * Request parameters for proposalMessagesTemplateGetMessagesTemplatesPaginate operation in ProposalMessagesTemplateApi.
 * @export
 * @interface ProposalMessagesTemplateApiProposalMessagesTemplateGetMessagesTemplatesPaginateRequest
 */
export interface ProposalMessagesTemplateApiProposalMessagesTemplateGetMessagesTemplatesPaginateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateGetMessagesTemplatesPaginate
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateGetMessagesTemplatesPaginate
     */
    readonly items: number

    /**
     * 
     * @type {string}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateGetMessagesTemplatesPaginate
     */
    readonly title?: string
}

/**
 * Request parameters for proposalMessagesTemplateGetProposalMessageTemplateById operation in ProposalMessagesTemplateApi.
 * @export
 * @interface ProposalMessagesTemplateApiProposalMessagesTemplateGetProposalMessageTemplateByIdRequest
 */
export interface ProposalMessagesTemplateApiProposalMessagesTemplateGetProposalMessageTemplateByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateGetProposalMessageTemplateById
     */
    readonly id: number
}

/**
 * Request parameters for proposalMessagesTemplateUpdate operation in ProposalMessagesTemplateApi.
 * @export
 * @interface ProposalMessagesTemplateApiProposalMessagesTemplateUpdateRequest
 */
export interface ProposalMessagesTemplateApiProposalMessagesTemplateUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateProposalMessagesTemplateDTO}
     * @memberof ProposalMessagesTemplateApiProposalMessagesTemplateUpdate
     */
    readonly updateProposalMessagesTemplateDTO: UpdateProposalMessagesTemplateDTO
}

/**
 * ProposalMessagesTemplateApi - object-oriented interface
 * @export
 * @class ProposalMessagesTemplateApi
 * @extends {BaseAPI}
 */
export class ProposalMessagesTemplateApi extends BaseAPI {
    /**
     * 
     * @param {ProposalMessagesTemplateApiProposalMessagesTemplateCreateProposalTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalMessagesTemplateApi
     */
    public proposalMessagesTemplateCreateProposalTemplate(requestParameters: ProposalMessagesTemplateApiProposalMessagesTemplateCreateProposalTemplateRequest, options?: AxiosRequestConfig) {
        return ProposalMessagesTemplateApiFp(this.configuration).proposalMessagesTemplateCreateProposalTemplate(requestParameters.createProposalMessagesTemplateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalMessagesTemplateApiProposalMessagesTemplateDeleteProposalTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalMessagesTemplateApi
     */
    public proposalMessagesTemplateDeleteProposalTemplate(requestParameters: ProposalMessagesTemplateApiProposalMessagesTemplateDeleteProposalTemplateRequest, options?: AxiosRequestConfig) {
        return ProposalMessagesTemplateApiFp(this.configuration).proposalMessagesTemplateDeleteProposalTemplate(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalMessagesTemplateApiProposalMessagesTemplateGetMessageTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalMessagesTemplateApi
     */
    public proposalMessagesTemplateGetMessageTemplate(requestParameters: ProposalMessagesTemplateApiProposalMessagesTemplateGetMessageTemplateRequest = {}, options?: AxiosRequestConfig) {
        return ProposalMessagesTemplateApiFp(this.configuration).proposalMessagesTemplateGetMessageTemplate(requestParameters.title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalMessagesTemplateApiProposalMessagesTemplateGetMessagesTemplatesPaginateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalMessagesTemplateApi
     */
    public proposalMessagesTemplateGetMessagesTemplatesPaginate(requestParameters: ProposalMessagesTemplateApiProposalMessagesTemplateGetMessagesTemplatesPaginateRequest, options?: AxiosRequestConfig) {
        return ProposalMessagesTemplateApiFp(this.configuration).proposalMessagesTemplateGetMessagesTemplatesPaginate(requestParameters.page, requestParameters.items, requestParameters.title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalMessagesTemplateApiProposalMessagesTemplateGetProposalMessageTemplateByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalMessagesTemplateApi
     */
    public proposalMessagesTemplateGetProposalMessageTemplateById(requestParameters: ProposalMessagesTemplateApiProposalMessagesTemplateGetProposalMessageTemplateByIdRequest, options?: AxiosRequestConfig) {
        return ProposalMessagesTemplateApiFp(this.configuration).proposalMessagesTemplateGetProposalMessageTemplateById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProposalMessagesTemplateApiProposalMessagesTemplateUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalMessagesTemplateApi
     */
    public proposalMessagesTemplateUpdate(requestParameters: ProposalMessagesTemplateApiProposalMessagesTemplateUpdateRequest, options?: AxiosRequestConfig) {
        return ProposalMessagesTemplateApiFp(this.configuration).proposalMessagesTemplateUpdate(requestParameters.id, requestParameters.updateProposalMessagesTemplateDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RolesAndPermissionsApi - axios parameter creator
 * @export
 */
export const RolesAndPermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsGetPermissions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateRolDTO} createRolDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesCreate: async (createRolDTO: CreateRolDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRolDTO' is not null or undefined
            assertParamExists('rolesCreate', 'createRolDTO', createRolDTO)
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRolDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesDelete', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGetRolById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesGetRolById', 'id', id)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGetRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RolesEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGetRolesByUserType: async (type: RolesEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('rolesGetRolesByUserType', 'type', type)
            const localVarPath = `/roles/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRolDTO} updateRolDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesUpdateRole: async (id: string, updateRolDTO: UpdateRolDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rolesUpdateRole', 'id', id)
            // verify required parameter 'updateRolDTO' is not null or undefined
            assertParamExists('rolesUpdateRole', 'updateRolDTO', updateRolDTO)
            const localVarPath = `/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRolDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesAndPermissionsApi - functional programming interface
 * @export
 */
export const RolesAndPermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RolesAndPermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async permissionsGetPermissions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PermissionResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.permissionsGetPermissions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateRolDTO} createRolDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesCreate(createRolDTO: CreateRolDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesCreate(createRolDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesGetRolById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesGetRolById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesGetRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RolResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesGetRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RolesEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesGetRolesByUserType(type: RolesEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesGetRolesByUserType(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRolDTO} updateRolDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rolesUpdateRole(id: string, updateRolDTO: UpdateRolDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RolResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rolesUpdateRole(id, updateRolDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RolesAndPermissionsApi - factory interface
 * @export
 */
export const RolesAndPermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RolesAndPermissionsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        permissionsGetPermissions(options?: any): AxiosPromise<Array<PermissionResponseDTO>> {
            return localVarFp.permissionsGetPermissions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateRolDTO} createRolDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesCreate(createRolDTO: CreateRolDTO, options?: any): AxiosPromise<RolResponseDTO> {
            return localVarFp.rolesCreate(createRolDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.rolesDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGetRolById(id: string, options?: any): AxiosPromise<RolResponseDTO> {
            return localVarFp.rolesGetRolById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGetRoles(options?: any): AxiosPromise<Array<RolResponseDTO>> {
            return localVarFp.rolesGetRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RolesEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesGetRolesByUserType(type: RolesEnum, options?: any): AxiosPromise<RolResponseDTO> {
            return localVarFp.rolesGetRolesByUserType(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateRolDTO} updateRolDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rolesUpdateRole(id: string, updateRolDTO: UpdateRolDTO, options?: any): AxiosPromise<RolResponseDTO> {
            return localVarFp.rolesUpdateRole(id, updateRolDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for rolesCreate operation in RolesAndPermissionsApi.
 * @export
 * @interface RolesAndPermissionsApiRolesCreateRequest
 */
export interface RolesAndPermissionsApiRolesCreateRequest {
    /**
     * 
     * @type {CreateRolDTO}
     * @memberof RolesAndPermissionsApiRolesCreate
     */
    readonly createRolDTO: CreateRolDTO
}

/**
 * Request parameters for rolesDelete operation in RolesAndPermissionsApi.
 * @export
 * @interface RolesAndPermissionsApiRolesDeleteRequest
 */
export interface RolesAndPermissionsApiRolesDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof RolesAndPermissionsApiRolesDelete
     */
    readonly id: string
}

/**
 * Request parameters for rolesGetRolById operation in RolesAndPermissionsApi.
 * @export
 * @interface RolesAndPermissionsApiRolesGetRolByIdRequest
 */
export interface RolesAndPermissionsApiRolesGetRolByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof RolesAndPermissionsApiRolesGetRolById
     */
    readonly id: string
}

/**
 * Request parameters for rolesGetRolesByUserType operation in RolesAndPermissionsApi.
 * @export
 * @interface RolesAndPermissionsApiRolesGetRolesByUserTypeRequest
 */
export interface RolesAndPermissionsApiRolesGetRolesByUserTypeRequest {
    /**
     * 
     * @type {RolesEnum}
     * @memberof RolesAndPermissionsApiRolesGetRolesByUserType
     */
    readonly type: RolesEnum
}

/**
 * Request parameters for rolesUpdateRole operation in RolesAndPermissionsApi.
 * @export
 * @interface RolesAndPermissionsApiRolesUpdateRoleRequest
 */
export interface RolesAndPermissionsApiRolesUpdateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof RolesAndPermissionsApiRolesUpdateRole
     */
    readonly id: string

    /**
     * 
     * @type {UpdateRolDTO}
     * @memberof RolesAndPermissionsApiRolesUpdateRole
     */
    readonly updateRolDTO: UpdateRolDTO
}

/**
 * RolesAndPermissionsApi - object-oriented interface
 * @export
 * @class RolesAndPermissionsApi
 * @extends {BaseAPI}
 */
export class RolesAndPermissionsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public permissionsGetPermissions(options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).permissionsGetPermissions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesAndPermissionsApiRolesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public rolesCreate(requestParameters: RolesAndPermissionsApiRolesCreateRequest, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).rolesCreate(requestParameters.createRolDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesAndPermissionsApiRolesDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public rolesDelete(requestParameters: RolesAndPermissionsApiRolesDeleteRequest, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).rolesDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesAndPermissionsApiRolesGetRolByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public rolesGetRolById(requestParameters: RolesAndPermissionsApiRolesGetRolByIdRequest, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).rolesGetRolById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public rolesGetRoles(options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).rolesGetRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesAndPermissionsApiRolesGetRolesByUserTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public rolesGetRolesByUserType(requestParameters: RolesAndPermissionsApiRolesGetRolesByUserTypeRequest, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).rolesGetRolesByUserType(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RolesAndPermissionsApiRolesUpdateRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesAndPermissionsApi
     */
    public rolesUpdateRole(requestParameters: RolesAndPermissionsApiRolesUpdateRoleRequest, options?: AxiosRequestConfig) {
        return RolesAndPermissionsApiFp(this.configuration).rolesUpdateRole(requestParameters.id, requestParameters.updateRolDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetAll: async (q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('searchGetAll', 'q', q)
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchGetAll(q: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchGetAll(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} q 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchGetAll(q: string, options?: any): AxiosPromise<SearchResponseDTO> {
            return localVarFp.searchGetAll(q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for searchGetAll operation in SearchApi.
 * @export
 * @interface SearchApiSearchGetAllRequest
 */
export interface SearchApiSearchGetAllRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchApiSearchGetAll
     */
    readonly q: string
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {SearchApiSearchGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public searchGetAll(requestParameters: SearchApiSearchGetAllRequest, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).searchGetAll(requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceEndpointsApi - axios parameter creator
 * @export
 */
export const ServiceEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateServiceDTO} createServiceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceCreate: async (createServiceDTO: CreateServiceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createServiceDTO' is not null or undefined
            assertParamExists('serviceCreate', 'createServiceDTO', createServiceDTO)
            const localVarPath = `/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('serviceDelete', 'id', id)
            const localVarPath = `/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceGetAllPaginated: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('serviceGetAllPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('serviceGetAllPaginated', 'items', items)
            const localVarPath = `/services/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [relations] Comma-separated list of relations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceGetOneById: async (id: string, relations?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('serviceGetOneById', 'id', id)
            const localVarPath = `/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (relations !== undefined) {
                localVarQueryParameter['relations'] = relations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceDTO} updateServiceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceUpdate: async (id: string, updateServiceDTO: UpdateServiceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('serviceUpdate', 'id', id)
            // verify required parameter 'updateServiceDTO' is not null or undefined
            assertParamExists('serviceUpdate', 'updateServiceDTO', updateServiceDTO)
            const localVarPath = `/services/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceEndpointsApi - functional programming interface
 * @export
 */
export const ServiceEndpointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceEndpointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateServiceDTO} createServiceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceCreate(createServiceDTO: CreateServiceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceCreate(createServiceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceGetAllPaginated(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePaginatedResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceGetAllPaginated(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [relations] Comma-separated list of relations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceGetOneById(id: string, relations?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceGetOneById200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceGetOneById(id, relations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceDTO} updateServiceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceUpdate(id: string, updateServiceDTO: UpdateServiceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceUpdate(id, updateServiceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceEndpointsApi - factory interface
 * @export
 */
export const ServiceEndpointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceEndpointsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateServiceDTO} createServiceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceCreate(createServiceDTO: CreateServiceDTO, options?: any): AxiosPromise<ServiceResponseDTO> {
            return localVarFp.serviceCreate(createServiceDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.serviceDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceGetAll(options?: any): AxiosPromise<Array<ServiceResponseDTO>> {
            return localVarFp.serviceGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceGetAllPaginated(page: number, items: number, options?: any): AxiosPromise<ServicePaginatedResponseDTO> {
            return localVarFp.serviceGetAllPaginated(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [relations] Comma-separated list of relations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceGetOneById(id: string, relations?: string, options?: any): AxiosPromise<ServiceGetOneById200Response> {
            return localVarFp.serviceGetOneById(id, relations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceDTO} updateServiceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceUpdate(id: string, updateServiceDTO: UpdateServiceDTO, options?: any): AxiosPromise<ServiceResponseDTO> {
            return localVarFp.serviceUpdate(id, updateServiceDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for serviceCreate operation in ServiceEndpointsApi.
 * @export
 * @interface ServiceEndpointsApiServiceCreateRequest
 */
export interface ServiceEndpointsApiServiceCreateRequest {
    /**
     * 
     * @type {CreateServiceDTO}
     * @memberof ServiceEndpointsApiServiceCreate
     */
    readonly createServiceDTO: CreateServiceDTO
}

/**
 * Request parameters for serviceDelete operation in ServiceEndpointsApi.
 * @export
 * @interface ServiceEndpointsApiServiceDeleteRequest
 */
export interface ServiceEndpointsApiServiceDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceEndpointsApiServiceDelete
     */
    readonly id: string
}

/**
 * Request parameters for serviceGetAllPaginated operation in ServiceEndpointsApi.
 * @export
 * @interface ServiceEndpointsApiServiceGetAllPaginatedRequest
 */
export interface ServiceEndpointsApiServiceGetAllPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof ServiceEndpointsApiServiceGetAllPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof ServiceEndpointsApiServiceGetAllPaginated
     */
    readonly items: number
}

/**
 * Request parameters for serviceGetOneById operation in ServiceEndpointsApi.
 * @export
 * @interface ServiceEndpointsApiServiceGetOneByIdRequest
 */
export interface ServiceEndpointsApiServiceGetOneByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceEndpointsApiServiceGetOneById
     */
    readonly id: string

    /**
     * Comma-separated list of relations.
     * @type {string}
     * @memberof ServiceEndpointsApiServiceGetOneById
     */
    readonly relations?: string
}

/**
 * Request parameters for serviceUpdate operation in ServiceEndpointsApi.
 * @export
 * @interface ServiceEndpointsApiServiceUpdateRequest
 */
export interface ServiceEndpointsApiServiceUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceEndpointsApiServiceUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateServiceDTO}
     * @memberof ServiceEndpointsApiServiceUpdate
     */
    readonly updateServiceDTO: UpdateServiceDTO
}

/**
 * ServiceEndpointsApi - object-oriented interface
 * @export
 * @class ServiceEndpointsApi
 * @extends {BaseAPI}
 */
export class ServiceEndpointsApi extends BaseAPI {
    /**
     * 
     * @param {ServiceEndpointsApiServiceCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceEndpointsApi
     */
    public serviceCreate(requestParameters: ServiceEndpointsApiServiceCreateRequest, options?: AxiosRequestConfig) {
        return ServiceEndpointsApiFp(this.configuration).serviceCreate(requestParameters.createServiceDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ServiceEndpointsApiServiceDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceEndpointsApi
     */
    public serviceDelete(requestParameters: ServiceEndpointsApiServiceDeleteRequest, options?: AxiosRequestConfig) {
        return ServiceEndpointsApiFp(this.configuration).serviceDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceEndpointsApi
     */
    public serviceGetAll(options?: AxiosRequestConfig) {
        return ServiceEndpointsApiFp(this.configuration).serviceGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ServiceEndpointsApiServiceGetAllPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceEndpointsApi
     */
    public serviceGetAllPaginated(requestParameters: ServiceEndpointsApiServiceGetAllPaginatedRequest, options?: AxiosRequestConfig) {
        return ServiceEndpointsApiFp(this.configuration).serviceGetAllPaginated(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ServiceEndpointsApiServiceGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceEndpointsApi
     */
    public serviceGetOneById(requestParameters: ServiceEndpointsApiServiceGetOneByIdRequest, options?: AxiosRequestConfig) {
        return ServiceEndpointsApiFp(this.configuration).serviceGetOneById(requestParameters.id, requestParameters.relations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ServiceEndpointsApiServiceUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceEndpointsApi
     */
    public serviceUpdate(requestParameters: ServiceEndpointsApiServiceUpdateRequest, options?: AxiosRequestConfig) {
        return ServiceEndpointsApiFp(this.configuration).serviceUpdate(requestParameters.id, requestParameters.updateServiceDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SkillApi - axios parameter creator
 * @export
 */
export const SkillApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSkillDTO} createSkillDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillCreate: async (createSkillDTO: CreateSkillDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSkillDTO' is not null or undefined
            assertParamExists('skillCreate', 'createSkillDTO', createSkillDTO)
            const localVarPath = `/skills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSkillDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('skillDelete', 'id', id)
            const localVarPath = `/skills/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/skills`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillGetAllPaginated: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('skillGetAllPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('skillGetAllPaginated', 'items', items)
            const localVarPath = `/skills/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillGetOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('skillGetOneById', 'id', id)
            const localVarPath = `/skills/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillGetOneByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('skillGetOneByName', 'name', name)
            const localVarPath = `/skills/name/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSkillDTO} updateSkillDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillUpdate: async (id: string, updateSkillDTO: UpdateSkillDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('skillUpdate', 'id', id)
            // verify required parameter 'updateSkillDTO' is not null or undefined
            assertParamExists('skillUpdate', 'updateSkillDTO', updateSkillDTO)
            const localVarPath = `/skills/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSkillDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SkillApi - functional programming interface
 * @export
 */
export const SkillApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SkillApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSkillDTO} createSkillDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skillCreate(createSkillDTO: CreateSkillDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skillCreate(createSkillDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skillDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skillDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skillGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SkillResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skillGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skillGetAllPaginated(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillPaginatedResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skillGetAllPaginated(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skillGetOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skillGetOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skillGetOneByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skillGetOneByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSkillDTO} updateSkillDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skillUpdate(id: string, updateSkillDTO: UpdateSkillDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skillUpdate(id, updateSkillDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SkillApi - factory interface
 * @export
 */
export const SkillApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SkillApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSkillDTO} createSkillDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillCreate(createSkillDTO: CreateSkillDTO, options?: any): AxiosPromise<SkillResponseDTO> {
            return localVarFp.skillCreate(createSkillDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.skillDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillGetAll(options?: any): AxiosPromise<Array<SkillResponseDTO>> {
            return localVarFp.skillGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillGetAllPaginated(page: number, items: number, options?: any): AxiosPromise<SkillPaginatedResponseDTO> {
            return localVarFp.skillGetAllPaginated(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillGetOneById(id: string, options?: any): AxiosPromise<SkillResponseDTO> {
            return localVarFp.skillGetOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillGetOneByName(name: string, options?: any): AxiosPromise<SkillResponseDTO> {
            return localVarFp.skillGetOneByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateSkillDTO} updateSkillDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skillUpdate(id: string, updateSkillDTO: UpdateSkillDTO, options?: any): AxiosPromise<SkillResponseDTO> {
            return localVarFp.skillUpdate(id, updateSkillDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for skillCreate operation in SkillApi.
 * @export
 * @interface SkillApiSkillCreateRequest
 */
export interface SkillApiSkillCreateRequest {
    /**
     * 
     * @type {CreateSkillDTO}
     * @memberof SkillApiSkillCreate
     */
    readonly createSkillDTO: CreateSkillDTO
}

/**
 * Request parameters for skillDelete operation in SkillApi.
 * @export
 * @interface SkillApiSkillDeleteRequest
 */
export interface SkillApiSkillDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof SkillApiSkillDelete
     */
    readonly id: string
}

/**
 * Request parameters for skillGetAllPaginated operation in SkillApi.
 * @export
 * @interface SkillApiSkillGetAllPaginatedRequest
 */
export interface SkillApiSkillGetAllPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof SkillApiSkillGetAllPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof SkillApiSkillGetAllPaginated
     */
    readonly items: number
}

/**
 * Request parameters for skillGetOneById operation in SkillApi.
 * @export
 * @interface SkillApiSkillGetOneByIdRequest
 */
export interface SkillApiSkillGetOneByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof SkillApiSkillGetOneById
     */
    readonly id: string
}

/**
 * Request parameters for skillGetOneByName operation in SkillApi.
 * @export
 * @interface SkillApiSkillGetOneByNameRequest
 */
export interface SkillApiSkillGetOneByNameRequest {
    /**
     * 
     * @type {string}
     * @memberof SkillApiSkillGetOneByName
     */
    readonly name: string
}

/**
 * Request parameters for skillUpdate operation in SkillApi.
 * @export
 * @interface SkillApiSkillUpdateRequest
 */
export interface SkillApiSkillUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SkillApiSkillUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateSkillDTO}
     * @memberof SkillApiSkillUpdate
     */
    readonly updateSkillDTO: UpdateSkillDTO
}

/**
 * SkillApi - object-oriented interface
 * @export
 * @class SkillApi
 * @extends {BaseAPI}
 */
export class SkillApi extends BaseAPI {
    /**
     * 
     * @param {SkillApiSkillCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public skillCreate(requestParameters: SkillApiSkillCreateRequest, options?: AxiosRequestConfig) {
        return SkillApiFp(this.configuration).skillCreate(requestParameters.createSkillDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SkillApiSkillDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public skillDelete(requestParameters: SkillApiSkillDeleteRequest, options?: AxiosRequestConfig) {
        return SkillApiFp(this.configuration).skillDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public skillGetAll(options?: AxiosRequestConfig) {
        return SkillApiFp(this.configuration).skillGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SkillApiSkillGetAllPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public skillGetAllPaginated(requestParameters: SkillApiSkillGetAllPaginatedRequest, options?: AxiosRequestConfig) {
        return SkillApiFp(this.configuration).skillGetAllPaginated(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SkillApiSkillGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public skillGetOneById(requestParameters: SkillApiSkillGetOneByIdRequest, options?: AxiosRequestConfig) {
        return SkillApiFp(this.configuration).skillGetOneById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SkillApiSkillGetOneByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public skillGetOneByName(requestParameters: SkillApiSkillGetOneByNameRequest, options?: AxiosRequestConfig) {
        return SkillApiFp(this.configuration).skillGetOneByName(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SkillApiSkillUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SkillApi
     */
    public skillUpdate(requestParameters: SkillApiSkillUpdateRequest, options?: AxiosRequestConfig) {
        return SkillApiFp(this.configuration).skillUpdate(requestParameters.id, requestParameters.updateSkillDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CopyFileBodyDTO} copyFileBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageCopyFile: async (copyFileBodyDTO: CopyFileBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'copyFileBodyDTO' is not null or undefined
            assertParamExists('storageCopyFile', 'copyFileBodyDTO', copyFileBodyDTO)
            const localVarPath = `/storage/copy-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(copyFileBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteFileBodyDTO} deleteFileBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDeleteFile: async (deleteFileBodyDTO: DeleteFileBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFileBodyDTO' is not null or undefined
            assertParamExists('storageDeleteFile', 'deleteFileBodyDTO', deleteFileBodyDTO)
            const localVarPath = `/storage/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFileBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteFilesBodyDTO} deleteFilesBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDeleteFiles: async (deleteFilesBodyDTO: DeleteFilesBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteFilesBodyDTO' is not null or undefined
            assertParamExists('storageDeleteFiles', 'deleteFilesBodyDTO', deleteFilesBodyDTO)
            const localVarPath = `/storage/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteFilesBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetAllCompanyFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/company-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} relatedEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetAllEntityFilesById: async (entityId: string, relatedEntity: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('storageGetAllEntityFilesById', 'entityId', entityId)
            // verify required parameter 'relatedEntity' is not null or undefined
            assertParamExists('storageGetAllEntityFilesById', 'relatedEntity', relatedEntity)
            const localVarPath = `/storage/entity-files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (relatedEntity !== undefined) {
                localVarQueryParameter['relatedEntity'] = relatedEntity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetCompanyLogo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/company-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetProfilePhotos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/profile-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetProfilePhotosFromProfile: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('storageGetProfilePhotosFromProfile', 'id', id)
            const localVarPath = `/storage/profile-photo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateFileDTO} updateFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUpdateFile: async (updateFileDTO: UpdateFileDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFileDTO' is not null or undefined
            assertParamExists('storageUpdateFile', 'updateFileDTO', updateFileDTO)
            const localVarPath = `/storage/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadCompanyBrouchure: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/upload/company-brouchure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadCompanyLogo: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/upload/company-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadCompanyTerms: async (files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/upload/company-terms-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadDocuments: async (files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/upload/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serviceCatalogId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadServiceCatalogDocuments: async (serviceCatalogId?: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/upload/service-catalog/{serviceCatalogId}`
                .replace(`{${"serviceCatalogId"}}`, encodeURIComponent(String(serviceCatalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [taskId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadTaskDocuments: async (taskId?: string, files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/upload/task-documents/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId Task ID
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadTaskIterationFile: async (taskId: number, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('storageUploadTaskIterationFile', 'taskId', taskId)
            const localVarPath = `/storage/upload/task-iteration-file/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadUserPhoto: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/upload/user-profile-photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CopyFileBodyDTO} copyFileBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageCopyFile(copyFileBodyDTO: CopyFileBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageCopyFile(copyFileBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteFileBodyDTO} deleteFileBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageDeleteFile(deleteFileBodyDTO: DeleteFileBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageDeleteFile(deleteFileBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteFilesBodyDTO} deleteFilesBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageDeleteFiles(deleteFilesBodyDTO: DeleteFilesBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageDeleteFiles(deleteFilesBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageGetAllCompanyFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageGetAllCompanyFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} relatedEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageGetAllEntityFilesById(entityId: string, relatedEntity: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageGetAllEntityFilesById(entityId, relatedEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageGetCompanyLogo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageGetCompanyLogo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageGetProfilePhotos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageGetProfilePhotos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageGetProfilePhotosFromProfile(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageGetProfilePhotosFromProfile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateFileDTO} updateFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUpdateFile(updateFileDTO: UpdateFileDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUpdateFile(updateFileDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadCompanyBrouchure(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadCompanyBrouchure(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadCompanyLogo(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadCompanyLogo(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadCompanyTerms(files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadCompanyTerms(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadDocuments(files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadDocuments(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [serviceCatalogId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadServiceCatalogDocuments(serviceCatalogId?: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadServiceCatalogDocuments(serviceCatalogId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [taskId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadTaskDocuments(taskId?: string, files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadTaskDocuments(taskId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId Task ID
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadTaskIterationFile(taskId: number, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadTaskIterationFile(taskId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storageUploadUserPhoto(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storageUploadUserPhoto(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageApiFp(configuration)
    return {
        /**
         * 
         * @param {CopyFileBodyDTO} copyFileBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageCopyFile(copyFileBodyDTO: CopyFileBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.storageCopyFile(copyFileBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteFileBodyDTO} deleteFileBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDeleteFile(deleteFileBodyDTO: DeleteFileBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.storageDeleteFile(deleteFileBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteFilesBodyDTO} deleteFilesBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageDeleteFiles(deleteFilesBodyDTO: DeleteFilesBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.storageDeleteFiles(deleteFilesBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetAllCompanyFiles(options?: any): AxiosPromise<void> {
            return localVarFp.storageGetAllCompanyFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} entityId 
         * @param {string} relatedEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetAllEntityFilesById(entityId: string, relatedEntity: string, options?: any): AxiosPromise<void> {
            return localVarFp.storageGetAllEntityFilesById(entityId, relatedEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetCompanyLogo(options?: any): AxiosPromise<void> {
            return localVarFp.storageGetCompanyLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetProfilePhotos(options?: any): AxiosPromise<void> {
            return localVarFp.storageGetProfilePhotos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageGetProfilePhotosFromProfile(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.storageGetProfilePhotosFromProfile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateFileDTO} updateFileDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUpdateFile(updateFileDTO: UpdateFileDTO, options?: any): AxiosPromise<void> {
            return localVarFp.storageUpdateFile(updateFileDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadCompanyBrouchure(file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.storageUploadCompanyBrouchure(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadCompanyLogo(file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.storageUploadCompanyLogo(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadCompanyTerms(files?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.storageUploadCompanyTerms(files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadDocuments(files?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.storageUploadDocuments(files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [serviceCatalogId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadServiceCatalogDocuments(serviceCatalogId?: string, files?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.storageUploadServiceCatalogDocuments(serviceCatalogId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [taskId] 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadTaskDocuments(taskId?: string, files?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.storageUploadTaskDocuments(taskId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId Task ID
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadTaskIterationFile(taskId: number, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.storageUploadTaskIterationFile(taskId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storageUploadUserPhoto(file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.storageUploadUserPhoto(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for storageCopyFile operation in StorageApi.
 * @export
 * @interface StorageApiStorageCopyFileRequest
 */
export interface StorageApiStorageCopyFileRequest {
    /**
     * 
     * @type {CopyFileBodyDTO}
     * @memberof StorageApiStorageCopyFile
     */
    readonly copyFileBodyDTO: CopyFileBodyDTO
}

/**
 * Request parameters for storageDeleteFile operation in StorageApi.
 * @export
 * @interface StorageApiStorageDeleteFileRequest
 */
export interface StorageApiStorageDeleteFileRequest {
    /**
     * 
     * @type {DeleteFileBodyDTO}
     * @memberof StorageApiStorageDeleteFile
     */
    readonly deleteFileBodyDTO: DeleteFileBodyDTO
}

/**
 * Request parameters for storageDeleteFiles operation in StorageApi.
 * @export
 * @interface StorageApiStorageDeleteFilesRequest
 */
export interface StorageApiStorageDeleteFilesRequest {
    /**
     * 
     * @type {DeleteFilesBodyDTO}
     * @memberof StorageApiStorageDeleteFiles
     */
    readonly deleteFilesBodyDTO: DeleteFilesBodyDTO
}

/**
 * Request parameters for storageGetAllEntityFilesById operation in StorageApi.
 * @export
 * @interface StorageApiStorageGetAllEntityFilesByIdRequest
 */
export interface StorageApiStorageGetAllEntityFilesByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof StorageApiStorageGetAllEntityFilesById
     */
    readonly entityId: string

    /**
     * 
     * @type {string}
     * @memberof StorageApiStorageGetAllEntityFilesById
     */
    readonly relatedEntity: string
}

/**
 * Request parameters for storageGetProfilePhotosFromProfile operation in StorageApi.
 * @export
 * @interface StorageApiStorageGetProfilePhotosFromProfileRequest
 */
export interface StorageApiStorageGetProfilePhotosFromProfileRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof StorageApiStorageGetProfilePhotosFromProfile
     */
    readonly id: string
}

/**
 * Request parameters for storageUpdateFile operation in StorageApi.
 * @export
 * @interface StorageApiStorageUpdateFileRequest
 */
export interface StorageApiStorageUpdateFileRequest {
    /**
     * 
     * @type {UpdateFileDTO}
     * @memberof StorageApiStorageUpdateFile
     */
    readonly updateFileDTO: UpdateFileDTO
}

/**
 * Request parameters for storageUploadCompanyBrouchure operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadCompanyBrouchureRequest
 */
export interface StorageApiStorageUploadCompanyBrouchureRequest {
    /**
     * 
     * @type {File}
     * @memberof StorageApiStorageUploadCompanyBrouchure
     */
    readonly file?: File
}

/**
 * Request parameters for storageUploadCompanyLogo operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadCompanyLogoRequest
 */
export interface StorageApiStorageUploadCompanyLogoRequest {
    /**
     * 
     * @type {File}
     * @memberof StorageApiStorageUploadCompanyLogo
     */
    readonly file?: File
}

/**
 * Request parameters for storageUploadCompanyTerms operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadCompanyTermsRequest
 */
export interface StorageApiStorageUploadCompanyTermsRequest {
    /**
     * 
     * @type {Array<File>}
     * @memberof StorageApiStorageUploadCompanyTerms
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for storageUploadDocuments operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadDocumentsRequest
 */
export interface StorageApiStorageUploadDocumentsRequest {
    /**
     * 
     * @type {Array<File>}
     * @memberof StorageApiStorageUploadDocuments
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for storageUploadServiceCatalogDocuments operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadServiceCatalogDocumentsRequest
 */
export interface StorageApiStorageUploadServiceCatalogDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof StorageApiStorageUploadServiceCatalogDocuments
     */
    readonly serviceCatalogId?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof StorageApiStorageUploadServiceCatalogDocuments
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for storageUploadTaskDocuments operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadTaskDocumentsRequest
 */
export interface StorageApiStorageUploadTaskDocumentsRequest {
    /**
     * 
     * @type {string}
     * @memberof StorageApiStorageUploadTaskDocuments
     */
    readonly taskId?: string

    /**
     * 
     * @type {Array<File>}
     * @memberof StorageApiStorageUploadTaskDocuments
     */
    readonly files?: Array<File>
}

/**
 * Request parameters for storageUploadTaskIterationFile operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadTaskIterationFileRequest
 */
export interface StorageApiStorageUploadTaskIterationFileRequest {
    /**
     * Task ID
     * @type {number}
     * @memberof StorageApiStorageUploadTaskIterationFile
     */
    readonly taskId: number

    /**
     * 
     * @type {File}
     * @memberof StorageApiStorageUploadTaskIterationFile
     */
    readonly file?: File
}

/**
 * Request parameters for storageUploadUserPhoto operation in StorageApi.
 * @export
 * @interface StorageApiStorageUploadUserPhotoRequest
 */
export interface StorageApiStorageUploadUserPhotoRequest {
    /**
     * 
     * @type {File}
     * @memberof StorageApiStorageUploadUserPhoto
     */
    readonly file?: File
}

/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     * 
     * @param {StorageApiStorageCopyFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageCopyFile(requestParameters: StorageApiStorageCopyFileRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageCopyFile(requestParameters.copyFileBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageDeleteFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageDeleteFile(requestParameters: StorageApiStorageDeleteFileRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageDeleteFile(requestParameters.deleteFileBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageDeleteFilesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageDeleteFiles(requestParameters: StorageApiStorageDeleteFilesRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageDeleteFiles(requestParameters.deleteFilesBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageGetAllCompanyFiles(options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageGetAllCompanyFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageGetAllEntityFilesByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageGetAllEntityFilesById(requestParameters: StorageApiStorageGetAllEntityFilesByIdRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageGetAllEntityFilesById(requestParameters.entityId, requestParameters.relatedEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageGetCompanyLogo(options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageGetCompanyLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageGetProfilePhotos(options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageGetProfilePhotos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageGetProfilePhotosFromProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageGetProfilePhotosFromProfile(requestParameters: StorageApiStorageGetProfilePhotosFromProfileRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageGetProfilePhotosFromProfile(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUpdateFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUpdateFile(requestParameters: StorageApiStorageUpdateFileRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUpdateFile(requestParameters.updateFileDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUploadCompanyBrouchureRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadCompanyBrouchure(requestParameters: StorageApiStorageUploadCompanyBrouchureRequest = {}, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadCompanyBrouchure(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUploadCompanyLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadCompanyLogo(requestParameters: StorageApiStorageUploadCompanyLogoRequest = {}, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadCompanyLogo(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUploadCompanyTermsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadCompanyTerms(requestParameters: StorageApiStorageUploadCompanyTermsRequest = {}, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadCompanyTerms(requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUploadDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadDocuments(requestParameters: StorageApiStorageUploadDocumentsRequest = {}, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadDocuments(requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUploadServiceCatalogDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadServiceCatalogDocuments(requestParameters: StorageApiStorageUploadServiceCatalogDocumentsRequest = {}, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadServiceCatalogDocuments(requestParameters.serviceCatalogId, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUploadTaskDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadTaskDocuments(requestParameters: StorageApiStorageUploadTaskDocumentsRequest = {}, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadTaskDocuments(requestParameters.taskId, requestParameters.files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUploadTaskIterationFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadTaskIterationFile(requestParameters: StorageApiStorageUploadTaskIterationFileRequest, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadTaskIterationFile(requestParameters.taskId, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StorageApiStorageUploadUserPhotoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    public storageUploadUserPhoto(requestParameters: StorageApiStorageUploadUserPhotoRequest = {}, options?: AxiosRequestConfig) {
        return StorageApiFp(this.configuration).storageUploadUserPhoto(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateTaskDTO} createTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCreate: async (createTaskDTO: CreateTaskDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskDTO' is not null or undefined
            assertParamExists('taskCreate', 'createTaskDTO', createTaskDTO)
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskDelete', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskGetAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskGetAllPaginated: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('taskGetAllPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('taskGetAllPaginated', 'items', items)
            const localVarPath = `/tasks/paginate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskGetOneById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskGetOneById', 'id', id)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTaskDTO} updateTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskUpdate: async (id: string, updateTaskDTO: UpdateTaskDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('taskUpdate', 'id', id)
            // verify required parameter 'updateTaskDTO' is not null or undefined
            assertParamExists('taskUpdate', 'updateTaskDTO', updateTaskDTO)
            const localVarPath = `/tasks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateTaskDTO} createTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskCreate(createTaskDTO: CreateTaskDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskCreate(createTaskDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskGetAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskGetAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskGetAllPaginated(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskPaginatedResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskGetAllPaginated(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskGetOneById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskGetOneById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTaskDTO} updateTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async taskUpdate(id: string, updateTaskDTO: UpdateTaskDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.taskUpdate(id, updateTaskDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateTaskDTO} createTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskCreate(createTaskDTO: CreateTaskDTO, options?: any): AxiosPromise<TaskResponseDTO> {
            return localVarFp.taskCreate(createTaskDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.taskDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskGetAll(options?: any): AxiosPromise<Array<TaskResponseDTO>> {
            return localVarFp.taskGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskGetAllPaginated(page: number, items: number, options?: any): AxiosPromise<TaskPaginatedResponseDTO> {
            return localVarFp.taskGetAllPaginated(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskGetOneById(id: string, options?: any): AxiosPromise<TaskResponseDTO> {
            return localVarFp.taskGetOneById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTaskDTO} updateTaskDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        taskUpdate(id: string, updateTaskDTO: UpdateTaskDTO, options?: any): AxiosPromise<TaskResponseDTO> {
            return localVarFp.taskUpdate(id, updateTaskDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for taskCreate operation in TaskApi.
 * @export
 * @interface TaskApiTaskCreateRequest
 */
export interface TaskApiTaskCreateRequest {
    /**
     * 
     * @type {CreateTaskDTO}
     * @memberof TaskApiTaskCreate
     */
    readonly createTaskDTO: CreateTaskDTO
}

/**
 * Request parameters for taskDelete operation in TaskApi.
 * @export
 * @interface TaskApiTaskDeleteRequest
 */
export interface TaskApiTaskDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTaskDelete
     */
    readonly id: string
}

/**
 * Request parameters for taskGetAllPaginated operation in TaskApi.
 * @export
 * @interface TaskApiTaskGetAllPaginatedRequest
 */
export interface TaskApiTaskGetAllPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof TaskApiTaskGetAllPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof TaskApiTaskGetAllPaginated
     */
    readonly items: number
}

/**
 * Request parameters for taskGetOneById operation in TaskApi.
 * @export
 * @interface TaskApiTaskGetOneByIdRequest
 */
export interface TaskApiTaskGetOneByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTaskGetOneById
     */
    readonly id: string
}

/**
 * Request parameters for taskUpdate operation in TaskApi.
 * @export
 * @interface TaskApiTaskUpdateRequest
 */
export interface TaskApiTaskUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TaskApiTaskUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTaskDTO}
     * @memberof TaskApiTaskUpdate
     */
    readonly updateTaskDTO: UpdateTaskDTO
}

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @param {TaskApiTaskCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskCreate(requestParameters: TaskApiTaskCreateRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskCreate(requestParameters.createTaskDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskApiTaskDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskDelete(requestParameters: TaskApiTaskDeleteRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskGetAll(options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskApiTaskGetAllPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskGetAllPaginated(requestParameters: TaskApiTaskGetAllPaginatedRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskGetAllPaginated(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskApiTaskGetOneByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskGetOneById(requestParameters: TaskApiTaskGetOneByIdRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskGetOneById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TaskApiTaskUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public taskUpdate(requestParameters: TaskApiTaskUpdateRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).taskUpdate(requestParameters.id, requestParameters.updateTaskDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TechnicianTaskManagementApi - axios parameter creator
 * @export
 */
export const TechnicianTaskManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentFindTaskIterationChecklist: async (taskIterationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskIterationId' is not null or undefined
            assertParamExists('technicianTaskManagenmentFindTaskIterationChecklist', 'taskIterationId', taskIterationId)
            const localVarPath = `/task-management/task-iteration-checklist/{taskIterationId}`
                .replace(`{${"taskIterationId"}}`, encodeURIComponent(String(taskIterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentGetCompletedTasksPaginated: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('technicianTaskManagenmentGetCompletedTasksPaginated', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('technicianTaskManagenmentGetCompletedTasksPaginated', 'items', items)
            const localVarPath = `/task-management/paginated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentGetTaskIterationById: async (taskIterationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskIterationId' is not null or undefined
            assertParamExists('technicianTaskManagenmentGetTaskIterationById', 'taskIterationId', taskIterationId)
            const localVarPath = `/task-management/task-iteration/{taskIterationId}`
                .replace(`{${"taskIterationId"}}`, encodeURIComponent(String(taskIterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentGetTaskIterationWithTechniciansById: async (taskIterationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskIterationId' is not null or undefined
            assertParamExists('technicianTaskManagenmentGetTaskIterationWithTechniciansById', 'taskIterationId', taskIterationId)
            const localVarPath = `/task-management/task-iteration-technicians/{taskIterationId}`
                .replace(`{${"taskIterationId"}}`, encodeURIComponent(String(taskIterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentGetTasks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/task-management`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId Task ID
         * @param {SwapTaskTechnicianDTO} swapTaskTechnicianDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentSwapTaskTechnician: async (taskId: number, swapTaskTechnicianDTO: SwapTaskTechnicianDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('technicianTaskManagenmentSwapTaskTechnician', 'taskId', taskId)
            // verify required parameter 'swapTaskTechnicianDTO' is not null or undefined
            assertParamExists('technicianTaskManagenmentSwapTaskTechnician', 'swapTaskTechnicianDTO', swapTaskTechnicianDTO)
            const localVarPath = `/task-management/swap-task-technician/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapTaskTechnicianDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskIterationStatus} taskIterationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentUpdate: async (taskIterationId: number, taskIterationStatus: TaskIterationStatus, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskIterationId' is not null or undefined
            assertParamExists('technicianTaskManagenmentUpdate', 'taskIterationId', taskIterationId)
            // verify required parameter 'taskIterationStatus' is not null or undefined
            assertParamExists('technicianTaskManagenmentUpdate', 'taskIterationStatus', taskIterationStatus)
            const localVarPath = `/task-management/status/{taskIterationId}`
                .replace(`{${"taskIterationId"}}`, encodeURIComponent(String(taskIterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskIterationStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskIterationCheckListId 
         * @param {TaskIterationChecklistDTO} taskIterationChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentUpdateTaskIterationChecklistStatus: async (taskIterationCheckListId: number, taskIterationChecklistDTO: TaskIterationChecklistDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskIterationCheckListId' is not null or undefined
            assertParamExists('technicianTaskManagenmentUpdateTaskIterationChecklistStatus', 'taskIterationCheckListId', taskIterationCheckListId)
            // verify required parameter 'taskIterationChecklistDTO' is not null or undefined
            assertParamExists('technicianTaskManagenmentUpdateTaskIterationChecklistStatus', 'taskIterationChecklistDTO', taskIterationChecklistDTO)
            const localVarPath = `/task-management/task-iteration-checklist/{taskIterationCheckListId}`
                .replace(`{${"taskIterationCheckListId"}}`, encodeURIComponent(String(taskIterationCheckListId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskIterationChecklistDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TechnicianTaskManagementApi - functional programming interface
 * @export
 */
export const TechnicianTaskManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TechnicianTaskManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianTaskManagenmentFindTaskIterationChecklist(taskIterationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianTaskManagenmentFindTaskIterationChecklist(taskIterationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianTaskManagenmentGetCompletedTasksPaginated(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianTaskManagenmentGetCompletedTasksPaginated(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianTaskManagenmentGetTaskIterationById(taskIterationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianTaskManagenmentGetTaskIterationById(taskIterationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianTaskManagenmentGetTaskIterationWithTechniciansById(taskIterationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianTaskManagenmentGetTaskIterationWithTechniciansById(taskIterationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianTaskManagenmentGetTasks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianTaskManagenmentGetTasks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId Task ID
         * @param {SwapTaskTechnicianDTO} swapTaskTechnicianDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianTaskManagenmentSwapTaskTechnician(taskId: number, swapTaskTechnicianDTO: SwapTaskTechnicianDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianTaskManagenmentSwapTaskTechnician(taskId, swapTaskTechnicianDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskIterationStatus} taskIterationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianTaskManagenmentUpdate(taskIterationId: number, taskIterationStatus: TaskIterationStatus, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianTaskManagenmentUpdate(taskIterationId, taskIterationStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskIterationCheckListId 
         * @param {TaskIterationChecklistDTO} taskIterationChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianTaskManagenmentUpdateTaskIterationChecklistStatus(taskIterationCheckListId: number, taskIterationChecklistDTO: TaskIterationChecklistDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianTaskManagenmentUpdateTaskIterationChecklistStatus(taskIterationCheckListId, taskIterationChecklistDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TechnicianTaskManagementApi - factory interface
 * @export
 */
export const TechnicianTaskManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TechnicianTaskManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentFindTaskIterationChecklist(taskIterationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.technicianTaskManagenmentFindTaskIterationChecklist(taskIterationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentGetCompletedTasksPaginated(page: number, items: number, options?: any): AxiosPromise<void> {
            return localVarFp.technicianTaskManagenmentGetCompletedTasksPaginated(page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentGetTaskIterationById(taskIterationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.technicianTaskManagenmentGetTaskIterationById(taskIterationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentGetTaskIterationWithTechniciansById(taskIterationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.technicianTaskManagenmentGetTaskIterationWithTechniciansById(taskIterationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentGetTasks(options?: any): AxiosPromise<void> {
            return localVarFp.technicianTaskManagenmentGetTasks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId Task ID
         * @param {SwapTaskTechnicianDTO} swapTaskTechnicianDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentSwapTaskTechnician(taskId: number, swapTaskTechnicianDTO: SwapTaskTechnicianDTO, options?: any): AxiosPromise<void> {
            return localVarFp.technicianTaskManagenmentSwapTaskTechnician(taskId, swapTaskTechnicianDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskIterationStatus} taskIterationStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentUpdate(taskIterationId: number, taskIterationStatus: TaskIterationStatus, options?: any): AxiosPromise<void> {
            return localVarFp.technicianTaskManagenmentUpdate(taskIterationId, taskIterationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskIterationCheckListId 
         * @param {TaskIterationChecklistDTO} taskIterationChecklistDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianTaskManagenmentUpdateTaskIterationChecklistStatus(taskIterationCheckListId: number, taskIterationChecklistDTO: TaskIterationChecklistDTO, options?: any): AxiosPromise<void> {
            return localVarFp.technicianTaskManagenmentUpdateTaskIterationChecklistStatus(taskIterationCheckListId, taskIterationChecklistDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for technicianTaskManagenmentFindTaskIterationChecklist operation in TechnicianTaskManagementApi.
 * @export
 * @interface TechnicianTaskManagementApiTechnicianTaskManagenmentFindTaskIterationChecklistRequest
 */
export interface TechnicianTaskManagementApiTechnicianTaskManagenmentFindTaskIterationChecklistRequest {
    /**
     * Task Iteration ID
     * @type {number}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentFindTaskIterationChecklist
     */
    readonly taskIterationId: number
}

/**
 * Request parameters for technicianTaskManagenmentGetCompletedTasksPaginated operation in TechnicianTaskManagementApi.
 * @export
 * @interface TechnicianTaskManagementApiTechnicianTaskManagenmentGetCompletedTasksPaginatedRequest
 */
export interface TechnicianTaskManagementApiTechnicianTaskManagenmentGetCompletedTasksPaginatedRequest {
    /**
     * 
     * @type {number}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentGetCompletedTasksPaginated
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentGetCompletedTasksPaginated
     */
    readonly items: number
}

/**
 * Request parameters for technicianTaskManagenmentGetTaskIterationById operation in TechnicianTaskManagementApi.
 * @export
 * @interface TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationByIdRequest
 */
export interface TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationByIdRequest {
    /**
     * Task Iteration ID
     * @type {number}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationById
     */
    readonly taskIterationId: number
}

/**
 * Request parameters for technicianTaskManagenmentGetTaskIterationWithTechniciansById operation in TechnicianTaskManagementApi.
 * @export
 * @interface TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationWithTechniciansByIdRequest
 */
export interface TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationWithTechniciansByIdRequest {
    /**
     * Task Iteration ID
     * @type {number}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationWithTechniciansById
     */
    readonly taskIterationId: number
}

/**
 * Request parameters for technicianTaskManagenmentSwapTaskTechnician operation in TechnicianTaskManagementApi.
 * @export
 * @interface TechnicianTaskManagementApiTechnicianTaskManagenmentSwapTaskTechnicianRequest
 */
export interface TechnicianTaskManagementApiTechnicianTaskManagenmentSwapTaskTechnicianRequest {
    /**
     * Task ID
     * @type {number}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentSwapTaskTechnician
     */
    readonly taskId: number

    /**
     * 
     * @type {SwapTaskTechnicianDTO}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentSwapTaskTechnician
     */
    readonly swapTaskTechnicianDTO: SwapTaskTechnicianDTO
}

/**
 * Request parameters for technicianTaskManagenmentUpdate operation in TechnicianTaskManagementApi.
 * @export
 * @interface TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateRequest
 */
export interface TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateRequest {
    /**
     * Task Iteration ID
     * @type {number}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentUpdate
     */
    readonly taskIterationId: number

    /**
     * 
     * @type {TaskIterationStatus}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentUpdate
     */
    readonly taskIterationStatus: TaskIterationStatus
}

/**
 * Request parameters for technicianTaskManagenmentUpdateTaskIterationChecklistStatus operation in TechnicianTaskManagementApi.
 * @export
 * @interface TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateTaskIterationChecklistStatusRequest
 */
export interface TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateTaskIterationChecklistStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateTaskIterationChecklistStatus
     */
    readonly taskIterationCheckListId: number

    /**
     * 
     * @type {TaskIterationChecklistDTO}
     * @memberof TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateTaskIterationChecklistStatus
     */
    readonly taskIterationChecklistDTO: TaskIterationChecklistDTO
}

/**
 * TechnicianTaskManagementApi - object-oriented interface
 * @export
 * @class TechnicianTaskManagementApi
 * @extends {BaseAPI}
 */
export class TechnicianTaskManagementApi extends BaseAPI {
    /**
     * 
     * @param {TechnicianTaskManagementApiTechnicianTaskManagenmentFindTaskIterationChecklistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianTaskManagementApi
     */
    public technicianTaskManagenmentFindTaskIterationChecklist(requestParameters: TechnicianTaskManagementApiTechnicianTaskManagenmentFindTaskIterationChecklistRequest, options?: AxiosRequestConfig) {
        return TechnicianTaskManagementApiFp(this.configuration).technicianTaskManagenmentFindTaskIterationChecklist(requestParameters.taskIterationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TechnicianTaskManagementApiTechnicianTaskManagenmentGetCompletedTasksPaginatedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianTaskManagementApi
     */
    public technicianTaskManagenmentGetCompletedTasksPaginated(requestParameters: TechnicianTaskManagementApiTechnicianTaskManagenmentGetCompletedTasksPaginatedRequest, options?: AxiosRequestConfig) {
        return TechnicianTaskManagementApiFp(this.configuration).technicianTaskManagenmentGetCompletedTasksPaginated(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianTaskManagementApi
     */
    public technicianTaskManagenmentGetTaskIterationById(requestParameters: TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationByIdRequest, options?: AxiosRequestConfig) {
        return TechnicianTaskManagementApiFp(this.configuration).technicianTaskManagenmentGetTaskIterationById(requestParameters.taskIterationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationWithTechniciansByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianTaskManagementApi
     */
    public technicianTaskManagenmentGetTaskIterationWithTechniciansById(requestParameters: TechnicianTaskManagementApiTechnicianTaskManagenmentGetTaskIterationWithTechniciansByIdRequest, options?: AxiosRequestConfig) {
        return TechnicianTaskManagementApiFp(this.configuration).technicianTaskManagenmentGetTaskIterationWithTechniciansById(requestParameters.taskIterationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianTaskManagementApi
     */
    public technicianTaskManagenmentGetTasks(options?: AxiosRequestConfig) {
        return TechnicianTaskManagementApiFp(this.configuration).technicianTaskManagenmentGetTasks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TechnicianTaskManagementApiTechnicianTaskManagenmentSwapTaskTechnicianRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianTaskManagementApi
     */
    public technicianTaskManagenmentSwapTaskTechnician(requestParameters: TechnicianTaskManagementApiTechnicianTaskManagenmentSwapTaskTechnicianRequest, options?: AxiosRequestConfig) {
        return TechnicianTaskManagementApiFp(this.configuration).technicianTaskManagenmentSwapTaskTechnician(requestParameters.taskId, requestParameters.swapTaskTechnicianDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianTaskManagementApi
     */
    public technicianTaskManagenmentUpdate(requestParameters: TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateRequest, options?: AxiosRequestConfig) {
        return TechnicianTaskManagementApiFp(this.configuration).technicianTaskManagenmentUpdate(requestParameters.taskIterationId, requestParameters.taskIterationStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateTaskIterationChecklistStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnicianTaskManagementApi
     */
    public technicianTaskManagenmentUpdateTaskIterationChecklistStatus(requestParameters: TechnicianTaskManagementApiTechnicianTaskManagenmentUpdateTaskIterationChecklistStatusRequest, options?: AxiosRequestConfig) {
        return TechnicianTaskManagementApiFp(this.configuration).technicianTaskManagenmentUpdateTaskIterationChecklistStatus(requestParameters.taskIterationCheckListId, requestParameters.taskIterationChecklistDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TechniciansSearchApi - axios parameter creator
 * @export
 */
export const TechniciansSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {Array<string>} [skills] 
         * @param {string} [availableTimeStart] 
         * @param {string} [availableTimeEnd] 
         * @param {Array<string>} [availableDays] 
         * @param {Array<string>} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianSearch: async (name?: string, skills?: Array<string>, availableTimeStart?: string, availableTimeEnd?: string, availableDays?: Array<string>, dates?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/technicians/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (skills) {
                localVarQueryParameter['skills'] = skills;
            }

            if (availableTimeStart !== undefined) {
                localVarQueryParameter['availableTimeStart'] = availableTimeStart;
            }

            if (availableTimeEnd !== undefined) {
                localVarQueryParameter['availableTimeEnd'] = availableTimeEnd;
            }

            if (availableDays) {
                localVarQueryParameter['availableDays'] = availableDays;
            }

            if (dates) {
                localVarQueryParameter['dates'] = dates;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TechniciansSearchApi - functional programming interface
 * @export
 */
export const TechniciansSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TechniciansSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {Array<string>} [skills] 
         * @param {string} [availableTimeStart] 
         * @param {string} [availableTimeEnd] 
         * @param {Array<string>} [availableDays] 
         * @param {Array<string>} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async technicianSearch(name?: string, skills?: Array<string>, availableTimeStart?: string, availableTimeEnd?: string, availableDays?: Array<string>, dates?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TechnicianSearchResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.technicianSearch(name, skills, availableTimeStart, availableTimeEnd, availableDays, dates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TechniciansSearchApi - factory interface
 * @export
 */
export const TechniciansSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TechniciansSearchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {Array<string>} [skills] 
         * @param {string} [availableTimeStart] 
         * @param {string} [availableTimeEnd] 
         * @param {Array<string>} [availableDays] 
         * @param {Array<string>} [dates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        technicianSearch(name?: string, skills?: Array<string>, availableTimeStart?: string, availableTimeEnd?: string, availableDays?: Array<string>, dates?: Array<string>, options?: any): AxiosPromise<Array<TechnicianSearchResponseDTO>> {
            return localVarFp.technicianSearch(name, skills, availableTimeStart, availableTimeEnd, availableDays, dates, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for technicianSearch operation in TechniciansSearchApi.
 * @export
 * @interface TechniciansSearchApiTechnicianSearchRequest
 */
export interface TechniciansSearchApiTechnicianSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof TechniciansSearchApiTechnicianSearch
     */
    readonly name?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TechniciansSearchApiTechnicianSearch
     */
    readonly skills?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof TechniciansSearchApiTechnicianSearch
     */
    readonly availableTimeStart?: string

    /**
     * 
     * @type {string}
     * @memberof TechniciansSearchApiTechnicianSearch
     */
    readonly availableTimeEnd?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof TechniciansSearchApiTechnicianSearch
     */
    readonly availableDays?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof TechniciansSearchApiTechnicianSearch
     */
    readonly dates?: Array<string>
}

/**
 * TechniciansSearchApi - object-oriented interface
 * @export
 * @class TechniciansSearchApi
 * @extends {BaseAPI}
 */
export class TechniciansSearchApi extends BaseAPI {
    /**
     * 
     * @param {TechniciansSearchApiTechnicianSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechniciansSearchApi
     */
    public technicianSearch(requestParameters: TechniciansSearchApiTechnicianSearchRequest = {}, options?: AxiosRequestConfig) {
        return TechniciansSearchApiFp(this.configuration).technicianSearch(requestParameters.name, requestParameters.skills, requestParameters.availableTimeStart, requestParameters.availableTimeEnd, requestParameters.availableDays, requestParameters.dates, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimesheetApi - axios parameter creator
 * @export
 */
export const TimesheetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTaskToReviewForOperator: async (technicianId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/timesheet/task-to-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTechnicianTaskToReviewForOperator: async (technicianId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/timesheet/technician-task-to-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTechnicianTimesheetDashboard: async (fromDate: string, toDate: string, technicianId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('timesheetGetTechnicianTimesheetDashboard', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('timesheetGetTechnicianTimesheetDashboard', 'toDate', toDate)
            const localVarPath = `/timesheet/technician-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTechnicianTimesheetTasks: async (technicianId?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/timesheet/technician-tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTimesheetDashboard: async (fromDate: string, toDate: string, technicianId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('timesheetGetTimesheetDashboard', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('timesheetGetTimesheetDashboard', 'toDate', toDate)
            const localVarPath = `/timesheet/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTimesheetTasks: async (technicianId?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/timesheet/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskDecisionBodyDTO} taskDecisionBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetUpdateCalendarEventIterationDecision: async (taskIterationId: number, taskDecisionBodyDTO: TaskDecisionBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskIterationId' is not null or undefined
            assertParamExists('timesheetUpdateCalendarEventIterationDecision', 'taskIterationId', taskIterationId)
            // verify required parameter 'taskDecisionBodyDTO' is not null or undefined
            assertParamExists('timesheetUpdateCalendarEventIterationDecision', 'taskDecisionBodyDTO', taskDecisionBodyDTO)
            const localVarPath = `/timesheet/calendar-event-iteration-decision/{taskIterationId}`
                .replace(`{${"taskIterationId"}}`, encodeURIComponent(String(taskIterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskDecisionBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskDecisionBodyDTO} taskDecisionBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetUpdateTaskDecision: async (taskIterationId: number, taskDecisionBodyDTO: TaskDecisionBodyDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskIterationId' is not null or undefined
            assertParamExists('timesheetUpdateTaskDecision', 'taskIterationId', taskIterationId)
            // verify required parameter 'taskDecisionBodyDTO' is not null or undefined
            assertParamExists('timesheetUpdateTaskDecision', 'taskDecisionBodyDTO', taskDecisionBodyDTO)
            const localVarPath = `/timesheet/task-decision/{taskIterationId}`
                .replace(`{${"taskIterationId"}}`, encodeURIComponent(String(taskIterationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskDecisionBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimesheetApi - functional programming interface
 * @export
 */
export const TimesheetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimesheetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timesheetGetTaskToReviewForOperator(technicianId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timesheetGetTaskToReviewForOperator(technicianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timesheetGetTechnicianTaskToReviewForOperator(technicianId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timesheetGetTechnicianTaskToReviewForOperator(technicianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timesheetGetTechnicianTimesheetDashboard(fromDate: string, toDate: string, technicianId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timesheetGetTechnicianTimesheetDashboard(fromDate, toDate, technicianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timesheetGetTechnicianTimesheetTasks(technicianId?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timesheetGetTechnicianTimesheetTasks(technicianId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timesheetGetTimesheetDashboard(fromDate: string, toDate: string, technicianId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timesheetGetTimesheetDashboard(fromDate, toDate, technicianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timesheetGetTimesheetTasks(technicianId?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timesheetGetTimesheetTasks(technicianId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskDecisionBodyDTO} taskDecisionBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timesheetUpdateCalendarEventIterationDecision(taskIterationId: number, taskDecisionBodyDTO: TaskDecisionBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timesheetUpdateCalendarEventIterationDecision(taskIterationId, taskDecisionBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskDecisionBodyDTO} taskDecisionBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timesheetUpdateTaskDecision(taskIterationId: number, taskDecisionBodyDTO: TaskDecisionBodyDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timesheetUpdateTaskDecision(taskIterationId, taskDecisionBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimesheetApi - factory interface
 * @export
 */
export const TimesheetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimesheetApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTaskToReviewForOperator(technicianId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.timesheetGetTaskToReviewForOperator(technicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTechnicianTaskToReviewForOperator(technicianId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.timesheetGetTechnicianTaskToReviewForOperator(technicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTechnicianTimesheetDashboard(fromDate: string, toDate: string, technicianId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.timesheetGetTechnicianTimesheetDashboard(fromDate, toDate, technicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTechnicianTimesheetTasks(technicianId?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.timesheetGetTechnicianTimesheetTasks(technicianId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTimesheetDashboard(fromDate: string, toDate: string, technicianId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.timesheetGetTimesheetDashboard(fromDate, toDate, technicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetGetTimesheetTasks(technicianId?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.timesheetGetTimesheetTasks(technicianId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskDecisionBodyDTO} taskDecisionBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetUpdateCalendarEventIterationDecision(taskIterationId: number, taskDecisionBodyDTO: TaskDecisionBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.timesheetUpdateCalendarEventIterationDecision(taskIterationId, taskDecisionBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskIterationId Task Iteration ID
         * @param {TaskDecisionBodyDTO} taskDecisionBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timesheetUpdateTaskDecision(taskIterationId: number, taskDecisionBodyDTO: TaskDecisionBodyDTO, options?: any): AxiosPromise<void> {
            return localVarFp.timesheetUpdateTaskDecision(taskIterationId, taskDecisionBodyDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for timesheetGetTaskToReviewForOperator operation in TimesheetApi.
 * @export
 * @interface TimesheetApiTimesheetGetTaskToReviewForOperatorRequest
 */
export interface TimesheetApiTimesheetGetTaskToReviewForOperatorRequest {
    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTaskToReviewForOperator
     */
    readonly technicianId?: string
}

/**
 * Request parameters for timesheetGetTechnicianTaskToReviewForOperator operation in TimesheetApi.
 * @export
 * @interface TimesheetApiTimesheetGetTechnicianTaskToReviewForOperatorRequest
 */
export interface TimesheetApiTimesheetGetTechnicianTaskToReviewForOperatorRequest {
    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTechnicianTaskToReviewForOperator
     */
    readonly technicianId?: string
}

/**
 * Request parameters for timesheetGetTechnicianTimesheetDashboard operation in TimesheetApi.
 * @export
 * @interface TimesheetApiTimesheetGetTechnicianTimesheetDashboardRequest
 */
export interface TimesheetApiTimesheetGetTechnicianTimesheetDashboardRequest {
    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTechnicianTimesheetDashboard
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTechnicianTimesheetDashboard
     */
    readonly toDate: string

    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTechnicianTimesheetDashboard
     */
    readonly technicianId?: string
}

/**
 * Request parameters for timesheetGetTechnicianTimesheetTasks operation in TimesheetApi.
 * @export
 * @interface TimesheetApiTimesheetGetTechnicianTimesheetTasksRequest
 */
export interface TimesheetApiTimesheetGetTechnicianTimesheetTasksRequest {
    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTechnicianTimesheetTasks
     */
    readonly technicianId?: string

    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTechnicianTimesheetTasks
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTechnicianTimesheetTasks
     */
    readonly toDate?: string
}

/**
 * Request parameters for timesheetGetTimesheetDashboard operation in TimesheetApi.
 * @export
 * @interface TimesheetApiTimesheetGetTimesheetDashboardRequest
 */
export interface TimesheetApiTimesheetGetTimesheetDashboardRequest {
    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTimesheetDashboard
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTimesheetDashboard
     */
    readonly toDate: string

    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTimesheetDashboard
     */
    readonly technicianId?: string
}

/**
 * Request parameters for timesheetGetTimesheetTasks operation in TimesheetApi.
 * @export
 * @interface TimesheetApiTimesheetGetTimesheetTasksRequest
 */
export interface TimesheetApiTimesheetGetTimesheetTasksRequest {
    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTimesheetTasks
     */
    readonly technicianId?: string

    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTimesheetTasks
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof TimesheetApiTimesheetGetTimesheetTasks
     */
    readonly toDate?: string
}

/**
 * Request parameters for timesheetUpdateCalendarEventIterationDecision operation in TimesheetApi.
 * @export
 * @interface TimesheetApiTimesheetUpdateCalendarEventIterationDecisionRequest
 */
export interface TimesheetApiTimesheetUpdateCalendarEventIterationDecisionRequest {
    /**
     * Task Iteration ID
     * @type {number}
     * @memberof TimesheetApiTimesheetUpdateCalendarEventIterationDecision
     */
    readonly taskIterationId: number

    /**
     * 
     * @type {TaskDecisionBodyDTO}
     * @memberof TimesheetApiTimesheetUpdateCalendarEventIterationDecision
     */
    readonly taskDecisionBodyDTO: TaskDecisionBodyDTO
}

/**
 * Request parameters for timesheetUpdateTaskDecision operation in TimesheetApi.
 * @export
 * @interface TimesheetApiTimesheetUpdateTaskDecisionRequest
 */
export interface TimesheetApiTimesheetUpdateTaskDecisionRequest {
    /**
     * Task Iteration ID
     * @type {number}
     * @memberof TimesheetApiTimesheetUpdateTaskDecision
     */
    readonly taskIterationId: number

    /**
     * 
     * @type {TaskDecisionBodyDTO}
     * @memberof TimesheetApiTimesheetUpdateTaskDecision
     */
    readonly taskDecisionBodyDTO: TaskDecisionBodyDTO
}

/**
 * TimesheetApi - object-oriented interface
 * @export
 * @class TimesheetApi
 * @extends {BaseAPI}
 */
export class TimesheetApi extends BaseAPI {
    /**
     * 
     * @param {TimesheetApiTimesheetGetTaskToReviewForOperatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public timesheetGetTaskToReviewForOperator(requestParameters: TimesheetApiTimesheetGetTaskToReviewForOperatorRequest = {}, options?: AxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).timesheetGetTaskToReviewForOperator(requestParameters.technicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimesheetApiTimesheetGetTechnicianTaskToReviewForOperatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public timesheetGetTechnicianTaskToReviewForOperator(requestParameters: TimesheetApiTimesheetGetTechnicianTaskToReviewForOperatorRequest = {}, options?: AxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).timesheetGetTechnicianTaskToReviewForOperator(requestParameters.technicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimesheetApiTimesheetGetTechnicianTimesheetDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public timesheetGetTechnicianTimesheetDashboard(requestParameters: TimesheetApiTimesheetGetTechnicianTimesheetDashboardRequest, options?: AxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).timesheetGetTechnicianTimesheetDashboard(requestParameters.fromDate, requestParameters.toDate, requestParameters.technicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimesheetApiTimesheetGetTechnicianTimesheetTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public timesheetGetTechnicianTimesheetTasks(requestParameters: TimesheetApiTimesheetGetTechnicianTimesheetTasksRequest = {}, options?: AxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).timesheetGetTechnicianTimesheetTasks(requestParameters.technicianId, requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimesheetApiTimesheetGetTimesheetDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public timesheetGetTimesheetDashboard(requestParameters: TimesheetApiTimesheetGetTimesheetDashboardRequest, options?: AxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).timesheetGetTimesheetDashboard(requestParameters.fromDate, requestParameters.toDate, requestParameters.technicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimesheetApiTimesheetGetTimesheetTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public timesheetGetTimesheetTasks(requestParameters: TimesheetApiTimesheetGetTimesheetTasksRequest = {}, options?: AxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).timesheetGetTimesheetTasks(requestParameters.technicianId, requestParameters.fromDate, requestParameters.toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimesheetApiTimesheetUpdateCalendarEventIterationDecisionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public timesheetUpdateCalendarEventIterationDecision(requestParameters: TimesheetApiTimesheetUpdateCalendarEventIterationDecisionRequest, options?: AxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).timesheetUpdateCalendarEventIterationDecision(requestParameters.taskIterationId, requestParameters.taskDecisionBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TimesheetApiTimesheetUpdateTaskDecisionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimesheetApi
     */
    public timesheetUpdateTaskDecision(requestParameters: TimesheetApiTimesheetUpdateTaskDecisionRequest, options?: AxiosRequestConfig) {
        return TimesheetApiFp(this.configuration).timesheetUpdateTaskDecision(requestParameters.taskIterationId, requestParameters.taskDecisionBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrackingLogsApi - axios parameter creator
 * @export
 */
export const TrackingLogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new tracking log
         * @param {CreateTrackingLogDto} createTrackingLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackingLogCreateTrackingLog: async (createTrackingLogDto: CreateTrackingLogDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTrackingLogDto' is not null or undefined
            assertParamExists('trackingLogCreateTrackingLog', 'createTrackingLogDto', createTrackingLogDto)
            const localVarPath = `/tracking-logs/new-tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTrackingLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tracking logs paginate by Entity id
         * @param {string} entityId 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackingLogFindEntityByLogsEntityId: async (entityId: string, page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('trackingLogFindEntityByLogsEntityId', 'entityId', entityId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('trackingLogFindEntityByLogsEntityId', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('trackingLogFindEntityByLogsEntityId', 'items', items)
            const localVarPath = `/tracking-logs/{entityId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tracking logs paginate by company id
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackingLogFindTrackingCompanyId: async (page: number, items: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('trackingLogFindTrackingCompanyId', 'page', page)
            // verify required parameter 'items' is not null or undefined
            assertParamExists('trackingLogFindTrackingCompanyId', 'items', items)
            const localVarPath = `/tracking-logs/get-all/by-company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (items !== undefined) {
                localVarQueryParameter['items'] = items;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingLogsApi - functional programming interface
 * @export
 */
export const TrackingLogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackingLogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new tracking log
         * @param {CreateTrackingLogDto} createTrackingLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackingLogCreateTrackingLog(createTrackingLogDto: CreateTrackingLogDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackingLogCreateTrackingLog(createTrackingLogDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tracking logs paginate by Entity id
         * @param {string} entityId 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackingLogFindEntityByLogsEntityId(entityId: string, page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingLogListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackingLogFindEntityByLogsEntityId(entityId, page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tracking logs paginate by company id
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackingLogFindTrackingCompanyId(page: number, items: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingLogListDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackingLogFindTrackingCompanyId(page, items, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrackingLogsApi - factory interface
 * @export
 */
export const TrackingLogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackingLogsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new tracking log
         * @param {CreateTrackingLogDto} createTrackingLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackingLogCreateTrackingLog(createTrackingLogDto: CreateTrackingLogDto, options?: any): AxiosPromise<object> {
            return localVarFp.trackingLogCreateTrackingLog(createTrackingLogDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tracking logs paginate by Entity id
         * @param {string} entityId 
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackingLogFindEntityByLogsEntityId(entityId: string, page: number, items: number, options?: any): AxiosPromise<TrackingLogListDTO> {
            return localVarFp.trackingLogFindEntityByLogsEntityId(entityId, page, items, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tracking logs paginate by company id
         * @param {number} page 
         * @param {number} items 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackingLogFindTrackingCompanyId(page: number, items: number, options?: any): AxiosPromise<TrackingLogListDTO> {
            return localVarFp.trackingLogFindTrackingCompanyId(page, items, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for trackingLogCreateTrackingLog operation in TrackingLogsApi.
 * @export
 * @interface TrackingLogsApiTrackingLogCreateTrackingLogRequest
 */
export interface TrackingLogsApiTrackingLogCreateTrackingLogRequest {
    /**
     * 
     * @type {CreateTrackingLogDto}
     * @memberof TrackingLogsApiTrackingLogCreateTrackingLog
     */
    readonly createTrackingLogDto: CreateTrackingLogDto
}

/**
 * Request parameters for trackingLogFindEntityByLogsEntityId operation in TrackingLogsApi.
 * @export
 * @interface TrackingLogsApiTrackingLogFindEntityByLogsEntityIdRequest
 */
export interface TrackingLogsApiTrackingLogFindEntityByLogsEntityIdRequest {
    /**
     * 
     * @type {string}
     * @memberof TrackingLogsApiTrackingLogFindEntityByLogsEntityId
     */
    readonly entityId: string

    /**
     * 
     * @type {number}
     * @memberof TrackingLogsApiTrackingLogFindEntityByLogsEntityId
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof TrackingLogsApiTrackingLogFindEntityByLogsEntityId
     */
    readonly items: number
}

/**
 * Request parameters for trackingLogFindTrackingCompanyId operation in TrackingLogsApi.
 * @export
 * @interface TrackingLogsApiTrackingLogFindTrackingCompanyIdRequest
 */
export interface TrackingLogsApiTrackingLogFindTrackingCompanyIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TrackingLogsApiTrackingLogFindTrackingCompanyId
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof TrackingLogsApiTrackingLogFindTrackingCompanyId
     */
    readonly items: number
}

/**
 * TrackingLogsApi - object-oriented interface
 * @export
 * @class TrackingLogsApi
 * @extends {BaseAPI}
 */
export class TrackingLogsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new tracking log
     * @param {TrackingLogsApiTrackingLogCreateTrackingLogRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingLogsApi
     */
    public trackingLogCreateTrackingLog(requestParameters: TrackingLogsApiTrackingLogCreateTrackingLogRequest, options?: AxiosRequestConfig) {
        return TrackingLogsApiFp(this.configuration).trackingLogCreateTrackingLog(requestParameters.createTrackingLogDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tracking logs paginate by Entity id
     * @param {TrackingLogsApiTrackingLogFindEntityByLogsEntityIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingLogsApi
     */
    public trackingLogFindEntityByLogsEntityId(requestParameters: TrackingLogsApiTrackingLogFindEntityByLogsEntityIdRequest, options?: AxiosRequestConfig) {
        return TrackingLogsApiFp(this.configuration).trackingLogFindEntityByLogsEntityId(requestParameters.entityId, requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tracking logs paginate by company id
     * @param {TrackingLogsApiTrackingLogFindTrackingCompanyIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingLogsApi
     */
    public trackingLogFindTrackingCompanyId(requestParameters: TrackingLogsApiTrackingLogFindTrackingCompanyIdRequest, options?: AxiosRequestConfig) {
        return TrackingLogsApiFp(this.configuration).trackingLogFindTrackingCompanyId(requestParameters.page, requestParameters.items, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserDataInsightsApi - axios parameter creator
 * @export
 */
export const UserDataInsightsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetDelayedArrives: async (id: string, days?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDataInsightsGetDelayedArrives', 'id', id)
            const localVarPath = `/user-data-insights/delayed-arrives/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetProjectsByTechnicianId: async (id: string, days?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDataInsightsGetProjectsByTechnicianId', 'id', id)
            const localVarPath = `/user-data-insights/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetSkillsData: async (id: string, days?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDataInsightsGetSkillsData', 'id', id)
            const localVarPath = `/user-data-insights/skills-data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetTimesheetReview: async (technicianId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-data-insights/timesheet-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (technicianId !== undefined) {
                localVarQueryParameter['technicianId'] = technicianId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {string} [startDate] Start date
         * @param {string} [endDate] End date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetUserDashboard: async (id: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDataInsightsGetUserDashboard', 'id', id)
            const localVarPath = `/user-data-insights/user-dashboard/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {string} [startDate] Start date
         * @param {string} [endDate] End date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetUserWeekStats: async (id: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userDataInsightsGetUserWeekStats', 'id', id)
            const localVarPath = `/user-data-insights/week-stats/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDataInsightsApi - functional programming interface
 * @export
 */
export const UserDataInsightsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDataInsightsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDataInsightsGetDelayedArrives(id: string, days?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DelayedArrivesListDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDataInsightsGetDelayedArrives(id, days, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDataInsightsGetProjectsByTechnicianId(id: string, days?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectDataResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDataInsightsGetProjectsByTechnicianId(id, days, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDataInsightsGetSkillsData(id: string, days?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SkillsDataResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDataInsightsGetSkillsData(id, days, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDataInsightsGetTimesheetReview(technicianId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDataInsightsGetTimesheetReview(technicianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {string} [startDate] Start date
         * @param {string} [endDate] End date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDataInsightsGetUserDashboard(id: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDashboardResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDataInsightsGetUserDashboard(id, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {string} [startDate] Start date
         * @param {string} [endDate] End date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDataInsightsGetUserWeekStats(id: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserWeekStatsResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDataInsightsGetUserWeekStats(id, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDataInsightsApi - factory interface
 * @export
 */
export const UserDataInsightsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDataInsightsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetDelayedArrives(id: string, days?: number, options?: any): AxiosPromise<Array<DelayedArrivesListDTO>> {
            return localVarFp.userDataInsightsGetDelayedArrives(id, days, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetProjectsByTechnicianId(id: string, days?: number, options?: any): AxiosPromise<ProjectDataResponseDTO> {
            return localVarFp.userDataInsightsGetProjectsByTechnicianId(id, days, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {number} [days] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetSkillsData(id: string, days?: number, options?: any): AxiosPromise<SkillsDataResponseDTO> {
            return localVarFp.userDataInsightsGetSkillsData(id, days, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [technicianId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetTimesheetReview(technicianId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userDataInsightsGetTimesheetReview(technicianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {string} [startDate] Start date
         * @param {string} [endDate] End date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetUserDashboard(id: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<UserDashboardResponseDTO>> {
            return localVarFp.userDataInsightsGetUserDashboard(id, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id User UUID
         * @param {string} [startDate] Start date
         * @param {string} [endDate] End date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDataInsightsGetUserWeekStats(id: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<UserWeekStatsResponseDTO>> {
            return localVarFp.userDataInsightsGetUserWeekStats(id, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for userDataInsightsGetDelayedArrives operation in UserDataInsightsApi.
 * @export
 * @interface UserDataInsightsApiUserDataInsightsGetDelayedArrivesRequest
 */
export interface UserDataInsightsApiUserDataInsightsGetDelayedArrivesRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetDelayedArrives
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof UserDataInsightsApiUserDataInsightsGetDelayedArrives
     */
    readonly days?: number
}

/**
 * Request parameters for userDataInsightsGetProjectsByTechnicianId operation in UserDataInsightsApi.
 * @export
 * @interface UserDataInsightsApiUserDataInsightsGetProjectsByTechnicianIdRequest
 */
export interface UserDataInsightsApiUserDataInsightsGetProjectsByTechnicianIdRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetProjectsByTechnicianId
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof UserDataInsightsApiUserDataInsightsGetProjectsByTechnicianId
     */
    readonly days?: number
}

/**
 * Request parameters for userDataInsightsGetSkillsData operation in UserDataInsightsApi.
 * @export
 * @interface UserDataInsightsApiUserDataInsightsGetSkillsDataRequest
 */
export interface UserDataInsightsApiUserDataInsightsGetSkillsDataRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetSkillsData
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof UserDataInsightsApiUserDataInsightsGetSkillsData
     */
    readonly days?: number
}

/**
 * Request parameters for userDataInsightsGetTimesheetReview operation in UserDataInsightsApi.
 * @export
 * @interface UserDataInsightsApiUserDataInsightsGetTimesheetReviewRequest
 */
export interface UserDataInsightsApiUserDataInsightsGetTimesheetReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetTimesheetReview
     */
    readonly technicianId?: string
}

/**
 * Request parameters for userDataInsightsGetUserDashboard operation in UserDataInsightsApi.
 * @export
 * @interface UserDataInsightsApiUserDataInsightsGetUserDashboardRequest
 */
export interface UserDataInsightsApiUserDataInsightsGetUserDashboardRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetUserDashboard
     */
    readonly id: string

    /**
     * Start date
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetUserDashboard
     */
    readonly startDate?: string

    /**
     * End date
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetUserDashboard
     */
    readonly endDate?: string
}

/**
 * Request parameters for userDataInsightsGetUserWeekStats operation in UserDataInsightsApi.
 * @export
 * @interface UserDataInsightsApiUserDataInsightsGetUserWeekStatsRequest
 */
export interface UserDataInsightsApiUserDataInsightsGetUserWeekStatsRequest {
    /**
     * User UUID
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetUserWeekStats
     */
    readonly id: string

    /**
     * Start date
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetUserWeekStats
     */
    readonly startDate?: string

    /**
     * End date
     * @type {string}
     * @memberof UserDataInsightsApiUserDataInsightsGetUserWeekStats
     */
    readonly endDate?: string
}

/**
 * UserDataInsightsApi - object-oriented interface
 * @export
 * @class UserDataInsightsApi
 * @extends {BaseAPI}
 */
export class UserDataInsightsApi extends BaseAPI {
    /**
     * 
     * @param {UserDataInsightsApiUserDataInsightsGetDelayedArrivesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataInsightsApi
     */
    public userDataInsightsGetDelayedArrives(requestParameters: UserDataInsightsApiUserDataInsightsGetDelayedArrivesRequest, options?: AxiosRequestConfig) {
        return UserDataInsightsApiFp(this.configuration).userDataInsightsGetDelayedArrives(requestParameters.id, requestParameters.days, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDataInsightsApiUserDataInsightsGetProjectsByTechnicianIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataInsightsApi
     */
    public userDataInsightsGetProjectsByTechnicianId(requestParameters: UserDataInsightsApiUserDataInsightsGetProjectsByTechnicianIdRequest, options?: AxiosRequestConfig) {
        return UserDataInsightsApiFp(this.configuration).userDataInsightsGetProjectsByTechnicianId(requestParameters.id, requestParameters.days, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDataInsightsApiUserDataInsightsGetSkillsDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataInsightsApi
     */
    public userDataInsightsGetSkillsData(requestParameters: UserDataInsightsApiUserDataInsightsGetSkillsDataRequest, options?: AxiosRequestConfig) {
        return UserDataInsightsApiFp(this.configuration).userDataInsightsGetSkillsData(requestParameters.id, requestParameters.days, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDataInsightsApiUserDataInsightsGetTimesheetReviewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataInsightsApi
     */
    public userDataInsightsGetTimesheetReview(requestParameters: UserDataInsightsApiUserDataInsightsGetTimesheetReviewRequest = {}, options?: AxiosRequestConfig) {
        return UserDataInsightsApiFp(this.configuration).userDataInsightsGetTimesheetReview(requestParameters.technicianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDataInsightsApiUserDataInsightsGetUserDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataInsightsApi
     */
    public userDataInsightsGetUserDashboard(requestParameters: UserDataInsightsApiUserDataInsightsGetUserDashboardRequest, options?: AxiosRequestConfig) {
        return UserDataInsightsApiFp(this.configuration).userDataInsightsGetUserDashboard(requestParameters.id, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserDataInsightsApiUserDataInsightsGetUserWeekStatsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataInsightsApi
     */
    public userDataInsightsGetUserWeekStats(requestParameters: UserDataInsightsApiUserDataInsightsGetUserWeekStatsRequest, options?: AxiosRequestConfig) {
        return UserDataInsightsApiFp(this.configuration).userDataInsightsGetUserWeekStats(requestParameters.id, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindAll: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} rolesArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindUsersByRole: async (rolesArray: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rolesArray' is not null or undefined
            assertParamExists('usersFindUsersByRole', 'rolesArray', rolesArray)
            const localVarPath = `/users/by-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rolesArray) {
                localVarQueryParameter['rolesArray'] = rolesArray;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SkillResourceDTO} skillResourceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserRources: async (skillResourceDTO: SkillResourceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skillResourceDTO' is not null or undefined
            assertParamExists('usersUserRources', 'skillResourceDTO', skillResourceDTO)
            const localVarPath = `/users/resource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skillResourceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFindAll(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFindAll(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} rolesArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFindUsersByRole(rolesArray: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponseDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFindUsersByRole(rolesArray, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SkillResourceDTO} skillResourceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserRources(skillResourceDTO: SkillResourceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserRources(skillResourceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserManagementApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindAll(name?: string, options?: any): AxiosPromise<Array<UserResponseDTO>> {
            return localVarFp.usersFindAll(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} rolesArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindUsersByRole(rolesArray: Array<string>, options?: any): AxiosPromise<Array<UserResponseDTO>> {
            return localVarFp.usersFindUsersByRole(rolesArray, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SkillResourceDTO} skillResourceDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserRources(skillResourceDTO: SkillResourceDTO, options?: any): AxiosPromise<void> {
            return localVarFp.usersUserRources(skillResourceDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersFindAll operation in UserManagementApi.
 * @export
 * @interface UserManagementApiUsersFindAllRequest
 */
export interface UserManagementApiUsersFindAllRequest {
    /**
     * 
     * @type {string}
     * @memberof UserManagementApiUsersFindAll
     */
    readonly name?: string
}

/**
 * Request parameters for usersFindUsersByRole operation in UserManagementApi.
 * @export
 * @interface UserManagementApiUsersFindUsersByRoleRequest
 */
export interface UserManagementApiUsersFindUsersByRoleRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserManagementApiUsersFindUsersByRole
     */
    readonly rolesArray: Array<string>
}

/**
 * Request parameters for usersUserRources operation in UserManagementApi.
 * @export
 * @interface UserManagementApiUsersUserRourcesRequest
 */
export interface UserManagementApiUsersUserRourcesRequest {
    /**
     * 
     * @type {SkillResourceDTO}
     * @memberof UserManagementApiUsersUserRources
     */
    readonly skillResourceDTO: SkillResourceDTO
}

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
    /**
     * 
     * @param {UserManagementApiUsersFindAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public usersFindAll(requestParameters: UserManagementApiUsersFindAllRequest = {}, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).usersFindAll(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserManagementApiUsersFindUsersByRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public usersFindUsersByRole(requestParameters: UserManagementApiUsersFindUsersByRoleRequest, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).usersFindUsersByRole(requestParameters.rolesArray, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserManagementApiUsersUserRourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserManagementApi
     */
    public usersUserRources(requestParameters: UserManagementApiUsersUserRourcesRequest, options?: AxiosRequestConfig) {
        return UserManagementApiFp(this.configuration).usersUserRources(requestParameters.skillResourceDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WidgetsApi - axios parameter creator
 * @export
 */
export const WidgetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateWidgetDataDTO} createWidgetDataDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetCreateNewWidget: async (createWidgetDataDTO: CreateWidgetDataDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createWidgetDataDTO' is not null or undefined
            assertParamExists('widgetCreateNewWidget', 'createWidgetDataDTO', createWidgetDataDTO)
            const localVarPath = `/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWidgetDataDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetDeleteWidgetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('widgetDeleteWidgetById', 'id', id)
            const localVarPath = `/widgets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetGetAllWidgets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/widgets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'presets' | 'secondaryDataTypes'} category 
         * @param {'Projects' | 'Leads' | 'Proposals' | 'Technicians' | 'Users' | 'Clients' | 'Invoices' | 'Tasks' | 'TasksIteration' | 'CalendarEventIteration' | 'Visits' | 'Assignments' | 'Timesheet'} [primaryData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetGetCategories: async (category: 'presets' | 'secondaryDataTypes', primaryData?: 'Projects' | 'Leads' | 'Proposals' | 'Technicians' | 'Users' | 'Clients' | 'Invoices' | 'Tasks' | 'TasksIteration' | 'CalendarEventIteration' | 'Visits' | 'Assignments' | 'Timesheet', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'category' is not null or undefined
            assertParamExists('widgetGetCategories', 'category', category)
            const localVarPath = `/widgets/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (primaryData !== undefined) {
                localVarQueryParameter['primaryData'] = primaryData;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetGetWidgetById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('widgetGetWidgetById', 'id', id)
            const localVarPath = `/widgets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {UpdateWidgetDTO} updateWidgetDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetUpdateWidget: async (id: number, updateWidgetDTO: UpdateWidgetDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('widgetUpdateWidget', 'id', id)
            // verify required parameter 'updateWidgetDTO' is not null or undefined
            assertParamExists('widgetUpdateWidget', 'updateWidgetDTO', updateWidgetDTO)
            const localVarPath = `/widgets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWidgetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetsApi - functional programming interface
 * @export
 */
export const WidgetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WidgetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateWidgetDataDTO} createWidgetDataDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetCreateNewWidget(createWidgetDataDTO: CreateWidgetDataDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetCreateNewWidget(createWidgetDataDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetDeleteWidgetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetDeleteWidgetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetGetAllWidgets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetGetAllWidgets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'presets' | 'secondaryDataTypes'} category 
         * @param {'Projects' | 'Leads' | 'Proposals' | 'Technicians' | 'Users' | 'Clients' | 'Invoices' | 'Tasks' | 'TasksIteration' | 'CalendarEventIteration' | 'Visits' | 'Assignments' | 'Timesheet'} [primaryData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetGetCategories(category: 'presets' | 'secondaryDataTypes', primaryData?: 'Projects' | 'Leads' | 'Proposals' | 'Technicians' | 'Users' | 'Clients' | 'Invoices' | 'Tasks' | 'TasksIteration' | 'CalendarEventIteration' | 'Visits' | 'Assignments' | 'Timesheet', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetGetCategories200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetGetCategories(category, primaryData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetGetWidgetById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetGetWidgetById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {UpdateWidgetDTO} updateWidgetDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetUpdateWidget(id: number, updateWidgetDTO: UpdateWidgetDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WidgetResponseDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetUpdateWidget(id, updateWidgetDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WidgetsApi - factory interface
 * @export
 */
export const WidgetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WidgetsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateWidgetDataDTO} createWidgetDataDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetCreateNewWidget(createWidgetDataDTO: CreateWidgetDataDTO, options?: any): AxiosPromise<WidgetResponseDTO> {
            return localVarFp.widgetCreateNewWidget(createWidgetDataDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetDeleteWidgetById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.widgetDeleteWidgetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetGetAllWidgets(options?: any): AxiosPromise<WidgetResponseDTO> {
            return localVarFp.widgetGetAllWidgets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'presets' | 'secondaryDataTypes'} category 
         * @param {'Projects' | 'Leads' | 'Proposals' | 'Technicians' | 'Users' | 'Clients' | 'Invoices' | 'Tasks' | 'TasksIteration' | 'CalendarEventIteration' | 'Visits' | 'Assignments' | 'Timesheet'} [primaryData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetGetCategories(category: 'presets' | 'secondaryDataTypes', primaryData?: 'Projects' | 'Leads' | 'Proposals' | 'Technicians' | 'Users' | 'Clients' | 'Invoices' | 'Tasks' | 'TasksIteration' | 'CalendarEventIteration' | 'Visits' | 'Assignments' | 'Timesheet', options?: any): AxiosPromise<WidgetGetCategories200Response> {
            return localVarFp.widgetGetCategories(category, primaryData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetGetWidgetById(id: number, options?: any): AxiosPromise<WidgetResponseDTO> {
            return localVarFp.widgetGetWidgetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id widgetIdParams
         * @param {UpdateWidgetDTO} updateWidgetDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetUpdateWidget(id: number, updateWidgetDTO: UpdateWidgetDTO, options?: any): AxiosPromise<WidgetResponseDTO> {
            return localVarFp.widgetUpdateWidget(id, updateWidgetDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for widgetCreateNewWidget operation in WidgetsApi.
 * @export
 * @interface WidgetsApiWidgetCreateNewWidgetRequest
 */
export interface WidgetsApiWidgetCreateNewWidgetRequest {
    /**
     * 
     * @type {CreateWidgetDataDTO}
     * @memberof WidgetsApiWidgetCreateNewWidget
     */
    readonly createWidgetDataDTO: CreateWidgetDataDTO
}

/**
 * Request parameters for widgetDeleteWidgetById operation in WidgetsApi.
 * @export
 * @interface WidgetsApiWidgetDeleteWidgetByIdRequest
 */
export interface WidgetsApiWidgetDeleteWidgetByIdRequest {
    /**
     * widgetIdParams
     * @type {number}
     * @memberof WidgetsApiWidgetDeleteWidgetById
     */
    readonly id: number
}

/**
 * Request parameters for widgetGetCategories operation in WidgetsApi.
 * @export
 * @interface WidgetsApiWidgetGetCategoriesRequest
 */
export interface WidgetsApiWidgetGetCategoriesRequest {
    /**
     * 
     * @type {'presets' | 'secondaryDataTypes'}
     * @memberof WidgetsApiWidgetGetCategories
     */
    readonly category: 'presets' | 'secondaryDataTypes'

    /**
     * 
     * @type {'Projects' | 'Leads' | 'Proposals' | 'Technicians' | 'Users' | 'Clients' | 'Invoices' | 'Tasks' | 'TasksIteration' | 'CalendarEventIteration' | 'Visits' | 'Assignments' | 'Timesheet'}
     * @memberof WidgetsApiWidgetGetCategories
     */
    readonly primaryData?: 'Projects' | 'Leads' | 'Proposals' | 'Technicians' | 'Users' | 'Clients' | 'Invoices' | 'Tasks' | 'TasksIteration' | 'CalendarEventIteration' | 'Visits' | 'Assignments' | 'Timesheet'
}

/**
 * Request parameters for widgetGetWidgetById operation in WidgetsApi.
 * @export
 * @interface WidgetsApiWidgetGetWidgetByIdRequest
 */
export interface WidgetsApiWidgetGetWidgetByIdRequest {
    /**
     * widgetIdParams
     * @type {number}
     * @memberof WidgetsApiWidgetGetWidgetById
     */
    readonly id: number
}

/**
 * Request parameters for widgetUpdateWidget operation in WidgetsApi.
 * @export
 * @interface WidgetsApiWidgetUpdateWidgetRequest
 */
export interface WidgetsApiWidgetUpdateWidgetRequest {
    /**
     * widgetIdParams
     * @type {number}
     * @memberof WidgetsApiWidgetUpdateWidget
     */
    readonly id: number

    /**
     * 
     * @type {UpdateWidgetDTO}
     * @memberof WidgetsApiWidgetUpdateWidget
     */
    readonly updateWidgetDTO: UpdateWidgetDTO
}

/**
 * WidgetsApi - object-oriented interface
 * @export
 * @class WidgetsApi
 * @extends {BaseAPI}
 */
export class WidgetsApi extends BaseAPI {
    /**
     * 
     * @param {WidgetsApiWidgetCreateNewWidgetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetCreateNewWidget(requestParameters: WidgetsApiWidgetCreateNewWidgetRequest, options?: AxiosRequestConfig) {
        return WidgetsApiFp(this.configuration).widgetCreateNewWidget(requestParameters.createWidgetDataDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WidgetsApiWidgetDeleteWidgetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetDeleteWidgetById(requestParameters: WidgetsApiWidgetDeleteWidgetByIdRequest, options?: AxiosRequestConfig) {
        return WidgetsApiFp(this.configuration).widgetDeleteWidgetById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetGetAllWidgets(options?: AxiosRequestConfig) {
        return WidgetsApiFp(this.configuration).widgetGetAllWidgets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WidgetsApiWidgetGetCategoriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetGetCategories(requestParameters: WidgetsApiWidgetGetCategoriesRequest, options?: AxiosRequestConfig) {
        return WidgetsApiFp(this.configuration).widgetGetCategories(requestParameters.category, requestParameters.primaryData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WidgetsApiWidgetGetWidgetByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetGetWidgetById(requestParameters: WidgetsApiWidgetGetWidgetByIdRequest, options?: AxiosRequestConfig) {
        return WidgetsApiFp(this.configuration).widgetGetWidgetById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WidgetsApiWidgetUpdateWidgetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetsApi
     */
    public widgetUpdateWidget(requestParameters: WidgetsApiWidgetUpdateWidgetRequest, options?: AxiosRequestConfig) {
        return WidgetsApiFp(this.configuration).widgetUpdateWidget(requestParameters.id, requestParameters.updateWidgetDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


